import { createAsyncAction } from 'utils/store';

export const FetchListingContract = createAsyncAction('contract', 'fetchListingContract', {
  REQUEST: ['id'],
  SUCCESS: ['contract'],
});

export const SaveListingContract = createAsyncAction('contract', 'saveListingContract', {
  REQUEST: ['contract', 'state'],
  SUCCESS: ['contract'],
});

export const SaveListingContractState = createAsyncAction('contract', 'saveListingContractState', {
  REQUEST: ['id', 'state'],
  SUCCESS: ['contract'],
});

export const SaveSignatureContract = createAsyncAction('contract', 'saveSignatureContract', {
  REQUEST: ['contract', 'signatures'],
  SUCCESS: ['contract'],
});

export const ContractAsyncActions = {
  FetchListingContract,
  SaveListingContract,
  SaveListingContractState,
  SaveSignatureContract,
};
