import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import { Emoji } from 'components';
import { SectionTitle, SectionText, LogoutContent } from './logoutPage.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';
import { AuthAsyncActions } from 'store/actions/auth';

function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthAsyncActions.LogOut.Actions.REQUEST());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Logout</title>
        <meta name='description' content='Logout' />
      </Helmet>
      <MainContainerMd>
        <LogoutContent>
          <SectionTitle className='mt-1'>See you later!</SectionTitle>
          <div className='row d-flex justify-content-center display-1'>
            <Emoji symbol='👋' label='Congrats' />
          </div>
          <SectionText>You've successfully logged out.</SectionText>
        </LogoutContent>
      </MainContainerMd>
    </>
  );
}

export default Logout;
