import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { breakpoint } from 'styled-components-breakpoint';

export const SmallText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const NormalText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const LightNormalText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

export const MediumText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
`;

export const LargeText = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
`;

export const BlueText = styled.div`
  color: var(--offer1-blue);
  font-weight: 600;
  font-size: 24px;
`;

export const Offer1GreenButton = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  background: var(--offer1-green);
  border-radius: 4px;
  padding: 0 20px;
  white-space: nowrap;
  width: auto;
  outline: 0;
  border: 0;
  color: #fff;

  &:disabled {
    filter: grayscale(1);
  }
`;

export const SoldDiv = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  background: red;
  border-radius: 4px;
  padding: 0 20px;
  white-space: nowrap;
  width: auto;
  outline: 0;
  border: 0;
  color: #fff;
`;

export const Offer1WhiteButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  background: white;
  color: var(--gray2);
  border: 1px solid var(--gray2);
  border-radius: 4px;
  padding: 10px 16px;
  width: auto;
  outline: 0;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  svg {
    margin-right: 10px;
  }

  &:hover,
  &.active {
    border-color: var(--offer1-blue);
    svg > * {
      fill: var(--offer1-blue);
      stroke: #fff;
      transition: fill 0.2s ease;
    }
    color: #fff;
    background-color: var(--offer1-blue);
  }
`;

export const Offer1LikeButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  background-color: ${({ liked }) => (liked ? 'var(--danger)' : '#fff')};
  color: ${({ liked }) => (liked ? '#fff' : 'var(--gray2)')};
  border: 1px solid ${({ liked }) => (liked ? 'var(--danger)' : 'var(--gray2)')};
  border-radius: 4px;
  padding: 10px 16px;
  width: auto;
  outline: 0;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  svg {
    margin-right: 10px;
    > * {
      fill: ${({ liked }) => (liked ? 'var(--danger)' : '#fff')};
      stroke: ${({ liked }) => (liked ? '#fff' : 'var(--gray2)')};
    }
  }
`;

export const Offer1PrimaryButton = styled.button`
  margin: 8px 0;
  width: 100%;
  padding: 12px 0;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: 1px solid var(--gray6);
  color: var(--gray2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0;
  outline: 0;
  background-color: #fff;
  transition: all 0.2s ease;

  svg {
    min-width: 26px;
    margin-right: 10px;
  }

  &:hover,
  &.active {
    path {
      fill: #fff;
      transition: all 0.2s ease;

      ${({ stroke }) => !!stroke && 'stroke: white;'}
    }
    color: #fff;
    background-color: var(--offer1-blue);
  }

  &:disabled {
    filter: grayscale(1);
  }
`;

export const PropertyContainer = styled.div`
  color: var(--gray2);
`;

export const PropertyCarousel = styled.div`
  position: relative;
`;

export const DotsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PropertyImage = styled.img`
  width: 100%;
  height: 455px;
  object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
`;

export const PropertyDetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  border-bottom: 1px solid var(--gray6);

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const PropertyPreviewImage = styled.img`
  width: 51px;
  height: 51px;
  border: 1px solid var(--gray10);
  border-radius: 4px;
  object-fit: cover;
  margin-right: 22px;
  cursor: pointer;
`;

export const PropertyInfoContainer = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('mobile', 'desktop')`
    margin-top: 10px;
  `}
`;

export const PropertyCol = styled.div`
  ${(props) => props.divider && `border-right: 1px solid var(--gray6)`};
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  svg {
    min-width: 22px;
  }
`;

export const PropertyItem = styled.div`
  display: flex;
  align-items: center;

  div {
    padding: 0 10px;
  }
`;

export const PropertyAddress = styled.p`
  padding: 5px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  max-width: 400px;
  margin: 0;

  ${breakpoint('mobile', 'desktop')`
    width: 100%;
    max-width: calc(100vw - 82px);
  `}
`;

export const MainContainer = styled.div`
  padding: 0 5% 24px;

  ${breakpoint('mobile', 'desktop')`
    padding: 0 10px;
  `}
`;

export const OfferContainer = styled(Row)`
  border: 1px solid var(--gray6);
  border-radius: 4px;
  background: #ffffff;
  padding: 22px 0;
  margin: 17px 0 0 !important;

  > div:not(:last-child) {
    ${breakpoint('desktop')`
      border-right: 1px solid var(--gray6);
    `}
  }
`;

export const OfferCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 28px;

  ${breakpoint('mobile', 'desktop')`
    padding: 5px 40px;
    align-items: flex-start;
  `}
`;

export const PropertyReportContainer = styled.div`
  margin-top: 26px;
  padding: 27px 40px;
  border-radius: 4px;
  border: 1px solid var(--gray6);

  ${breakpoint('mobile', 'desktop')`
    margin-top: 35px;
  `}
`;

export const AgentContainer = styled.div`
  border: 1px solid var(--gray6);
  border-radius: 4px;
  padding: 22px 40px;
  color: var(--gray2);
  height: 100%;

  ${breakpoint('mobile', 'desktop')`
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const ProfileContainer = styled.div`
  padding: 16px 0 14px;
  display: flex;
  align-items: center;

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
  `}
`;

export const ProfileDetail = styled.div`
  color: var(--gray2);
  margin-left: 16px;

  ${breakpoint('mobile', 'desktop')`
    margin-left: 0;
    margin-top: 5px;
  `}
`;

export const ProfileName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--gray7);
`;

export const MessageButton = styled.button`
  padding: 0 47px;
  border: 1px solid var(--gray6);
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  color: var(--gray2);
  width: auto;
  outline: 0;
  background-color: #fff;
  transition: all 0.2s ease;

  svg {
    margin-right: 10px;
  }

  &:hover,
  &.active {
    path {
      fill: #fff;
      transition: all 0.2s ease;
    }
    color: #fff;
    background-color: var(--offer1-blue);
  }
`;

export const DescriptionContainer = styled(AgentContainer)`
  color: black;
  font-size: 16px;
  line-height: 32px;
  margin-right: 18px;

  ${breakpoint('mobile', 'desktop')`
    margin-right: 0;
  `}
`;

export const DescriptionTitle = styled.div`
  color: var(--gray2);
  font-weight: 600;
  font-size: 20px;
  line-height: 14px;
  margin-bottom: 11px;
`;

export const ShowMoreButton = styled.div`
  color: var(--gray2);
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 5px;
    transition: all 0.2s;
    ${(props) => props.more && 'transform: rotate(180deg)'};
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1009;
`;

export const ModalBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-60%);
  width: 800px;
  max-width: 90%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.3);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 15px 43px 12px;
  border-bottom: 1px solid var(--gray6);
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: var(--gray7);

  svg {
    cursor: pointer;
    position: absolute;
    top: 21px;
    right: 43px;
    min-width: 22px;
  }
`;

export const ModalContent = styled.div`
  padding: 37px 45px 58px;

  input[type='text'] {
    width: 100%;
    border: 1px solid rgba(0, 142, 255, 0.3);
    padding: 0 18px;
    font-size: 18px;
    line-height: 42px;
    outline: none;
    color: black;
    border-radius: 4px;
    margin: 8px 0;

    ::placeholder {
      color: var(--gray6);
    }
  }

  textarea {
    width: 100%;
    height: 91px;
    border: 1px solid rgba(0, 142, 255, 0.3);
    padding: 10px 18px;
    font-size: 16px;
    line-height: 26px;
    outline: none;
    color: black;
    border-radius: 4px;
    margin: 8px 0;
    resize: none;

    ::placeholder {
      color: var(--gray6);
    }
  }
`;

export const CheckBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

export const MessageArea = styled.textarea`
  width: 300px;
  height: 200px;
`;

export const DoneButton = styled.button`
  padding: 16px 48px;
  border-color: #3373ab;
  border-width: 2px;
  border-radius: 5px;
  background-color: #3373ab;
  color: white;
`;
