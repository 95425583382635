import React from 'react';
import { MainContainerMd } from 'containers/App/mainLayout.styled';
import { SuccessContent, SectionTitle, SectionText, SubText } from './registrationSuccess.styled';
import { URLUtils } from 'utils';

const RegistrationSuccess = () => {
  return (
    <MainContainerMd>
      <SuccessContent className='mt-5 p-3 py-5'>
        <SectionTitle>Almost there!</SectionTitle>
        <img
          className='d-block mx-auto'
          src={URLUtils.getPublicImageUrl('check-email.gif')}
          width={300}
          alt='check-email'
        />
        <SectionText>
          <b>Check your email now</b>
          <SubText>If you don't receive an email in 2-3 minutes, please check your spam and junk folders.</SubText>
        </SectionText>
      </SuccessContent>
    </MainContainerMd>
  );
};

export default RegistrationSuccess;
