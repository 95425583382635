import React from 'react';
import PropTypes from 'prop-types';
import { SidePanelContainer } from './sidePanel.styled';

function SidePanel({ right, children }) {
  return (
    <SidePanelContainer className={right ? 'justify-content-end' : 'justify-content-start'}>
      {children}
    </SidePanelContainer>
  );
}

SidePanel.propTypes = {
  right: PropTypes.bool,
};

export default SidePanel;
