import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Group from 'components/Group';

function RadioButtons({
  className,
  items,
  selectedOption,
  groupName,
  onStateChanged,
  disabled,
  fontSize,
  vertical = false,
  error,
  renderAdditional,
  columns = 1,
}) {
  return (
    <>
      <Group wrap={!vertical} verticalAlign={vertical ? 'center' : 'start'} className={className} columns={columns}>
        {items.map((option) => (
          <React.Fragment key={`${option.value}`}>
            <label
              className={classNames('m-1', {
                'text-danger': !!error,
              })}
              style={{ fontSize }}
            >
              <input
                type='radio'
                name={groupName}
                value={option.value}
                checked={selectedOption === option.value}
                readOnly={!onStateChanged}
                onChange={onStateChanged}
                className='me-1 ms-2'
                disabled={disabled}
              />
              {option.name}
            </label>
            {renderAdditional && renderAdditional(selectedOption === option.value)}
          </React.Fragment>
        ))}
      </Group>
    </>
  );
}

RadioButtons.propTypes = {
  groupName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onStateChanged: PropTypes.func,
  selectedOption: PropTypes.string,
  vertical: PropTypes.bool,
  error: PropTypes.bool,
  renderAdditional: PropTypes.func,
  columns: PropTypes.number,
};

export default RadioButtons;
