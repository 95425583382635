// Offer Reducer Action
export const OFFER_INIT = 'OFFER_INIT';
export const OFFER_UPDATE_TERM = 'OFFER_UPDATE_TERM';
export const OFFER_UPDATE_CONTINGENCIES = 'OFFER_UPDATE_CONTINGENCIES';
export const OFFER_UPDATE_CREDIT_ITEMS = 'OFFER_UPDATE_CREDIT_ITEMS';
export const OFFER_UPDATE_COST_ALLOCATIONS = 'OFFER_UPDATE_COST_ALLOCATIONS';
export const OFFER_UPDATE_SERVICES = 'OFFER_UPDATE_SERVICES';
export const OFFER_UPDATE_CONTACT_INFO = 'OFFER_UPDATE_CONTACT_INFO';
export const OFFER_UPDATE_OTHER_TERMS_ADDENDA = 'OFFER_UPDATE_OTHER_TERMS_ADDENDA';
export const OFFER_UPDATE_REVIEW = 'OFFER_UPDATE_REVIEW';
export const OFFER_UPDATE_SECONDARY_BUYERS = 'OFFER_UPDATE_SECONDARY_BUYERS';
export const OFFER_UPDATE_DOC = 'OFFER_UPDATE_DOC';
export const OFFER_UPDATE_STATE_DATA = 'OFFER_UPDATE_STATE_DATA';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  OFFER_INIT,
  OFFER_UPDATE_TERM,
  OFFER_UPDATE_CONTINGENCIES,
  OFFER_UPDATE_CREDIT_ITEMS,
  OFFER_UPDATE_COST_ALLOCATIONS,
  OFFER_UPDATE_SERVICES,
  OFFER_UPDATE_CONTACT_INFO,
  OFFER_UPDATE_OTHER_TERMS_ADDENDA,
  OFFER_UPDATE_REVIEW,
  OFFER_UPDATE_DOC,
  OFFER_UPDATE_STATE_DATA,
};
