import { all, takeLatest } from 'redux-saga/effects';
import { SubscriptionsAsyncActions } from 'store/actions/subscriptions';
import fetchSubscription from './fetchSubscription';
import fetchPricing from './fetchPricing';

export default function* root() {
  const { FetchSubscription, FetchPricing } = SubscriptionsAsyncActions;
  yield all([takeLatest(FetchSubscription.Types.REQUEST, fetchSubscription)]);
  yield all([takeLatest(FetchPricing.Types.REQUEST, fetchPricing)]);
}
