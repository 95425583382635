import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { ButtonWrapper, Icon, PrimaryBlueButton, PrimaryGreenButton } from 'components';
import { Header } from './Modal.styled';
import SignatureCanvas from 'react-signature-canvas';
import trimCanvas from 'trim-canvas';
import {
  TabContainer,
  TabAction,
  SignatureContainer,
  SignatureCaptureContainer,
  SignatureDivider,
  SignatureClearButton,
  SignatureInput,
} from './addSignatureModal.styled';

const signatureFonts = ['Caveat', 'Dancing Script', 'La Belle Aurore'];

function AddSignatureModal({ show, onClose, onCreate }) {
  const [typedSignature, setTypedSignature] = useState('');
  const [signatureFont, setSignatureFont] = useState('Caveat');
  const [tab, setTab] = useState('type');
  const sigCanvasRef = useRef(null);
  const captureContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  const resizeCanvas = () => {
    if (captureContainerRef.current) {
      const parentWidth = captureContainerRef.current.offsetWidth;
      const parentHeight = captureContainerRef.current.offsetHeight;
      setCanvasWidth(parentWidth);
      setCanvasHeight(parentHeight);
    }
  };

  const adjustFontSizeToFit = (inputElement) => {
    let fontSize = parseInt(window.getComputedStyle(inputElement).fontSize);
    if (inputElement.scrollWidth <= inputElement.offsetWidth) {
      fontSize = 110;
      inputElement.style.fontSize = `${fontSize}px`;
    }
    while (inputElement.scrollWidth > inputElement.offsetWidth && fontSize > 4) {
      fontSize--;
      inputElement.style.fontSize = `${fontSize - 4}px`;
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      adjustFontSizeToFit(inputElement);
    }
  }, [typedSignature, signatureFont]);

  useEffect(() => {
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  const handleTabChange = (newTab) => {
    setTab(newTab);
    if (newTab === 'type') {
      setSignatureFont('Caveat');
      setTypedSignature('');
    }
  };

  const handleClear = () => {
    if (tab === 'draw') {
      sigCanvasRef.current.clear();
    } else {
      setTypedSignature('');
      inputRef.current.focus();
    }
  };

  const convertTextToImage = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = inputRef.current.offsetWidth + 20;
    canvas.height = inputRef.current.offsetHeight;

    context.fillStyle = 'transparent';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = `${inputRef.current.style.fontSize} ${signatureFont}`;
    context.fillStyle = 'blue';

    context.fillText(typedSignature, 0, canvas.height / 2);

    const dataUrl = trimCanvas(canvas).toDataURL('image/png');

    return dataUrl;
  };

  const handleCreate = () => {
    if (tab === 'draw') {
      const dataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      onCreate(dataUrl);
    } else {
      const dataUrl = convertTextToImage();
      onCreate(dataUrl);
    }
  };

  return (
    <Modal show={show} backdrop='static' keyboard={false} size='lg' centered>
      <Header>
        <h3>Add Signature</h3>
        <ButtonWrapper className='close-button' onClick={onClose}>
          <Icon name='close' />
        </ButtonWrapper>
      </Header>
      <Row>
        <Col md={3}>
          <TabContainer>
            <TabAction active={tab === 'type'} onClick={() => handleTabChange('type')}>
              Type
            </TabAction>
            <TabAction active={tab === 'draw'} onClick={() => handleTabChange('draw')}>
              Draw
            </TabAction>
          </TabContainer>
        </Col>
        <Col md={9}>
          <SignatureContainer>
            {tab === 'type' && (
              <Dropdown>
                <Dropdown.Toggle variant='light'>Change style</Dropdown.Toggle>
                <Dropdown.Menu>
                  {signatureFonts.map((font) => (
                    <Dropdown.Item
                      key={font}
                      style={{ fontFamily: font, fontSize: 32 }}
                      active={signatureFont === font}
                      onClick={() => setSignatureFont(font)}
                    >
                      Signature
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <SignatureCaptureContainer ref={captureContainerRef}>
              <SignatureDivider />
              {tab === 'draw' && (
                <SignatureCanvas
                  ref={sigCanvasRef}
                  penColor='blue'
                  canvasProps={{
                    width: canvasWidth,
                    height: canvasHeight,
                    className: 'sigCanvas',
                  }}
                />
              )}
              {tab === 'type' && (
                <SignatureInput
                  ref={inputRef}
                  value={typedSignature}
                  spellCheck={false}
                  style={{ fontFamily: `${signatureFont}, Montserrat` }}
                  onChange={(e) => setTypedSignature(e.target.value)}
                />
              )}
              <SignatureClearButton onClick={handleClear}>Clear</SignatureClearButton>
            </SignatureCaptureContainer>
          </SignatureContainer>
        </Col>
      </Row>
      <Modal.Footer>
        <PrimaryBlueButton onClick={onClose}>Cancel</PrimaryBlueButton>
        <PrimaryGreenButton onClick={handleCreate}>Create</PrimaryGreenButton>
      </Modal.Footer>
    </Modal>
  );
}

AddSignatureModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
};

export default AddSignatureModal;
