import React from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddPropertyImages from './addPropertyImages';
import { FormField, CurrencyField, Address, DropDown, Divider } from 'components';
import { getPropertyTypes } from 'store/selectors/app';

function CreateProperty({
  property: { price, address, propertyType, squareFeet, numberBaths, numberBedrooms, description, legalDescription },
  licenseState,
  onStateChanged,
  primaryImage,
  onPropertyImageChanged,
  onPropertyImageDelete,
  errors,
}) {
  const propertyTypes = useSelector(getPropertyTypes);

  const onAddressChange = (address) => {
    onStateChanged({ field: 'address', value: address });
  };

  const onApnChange = (apn) => {
    const apnValue = apn?.value;
    const updatedAddress = { ...address, apn: apnValue };
    onStateChanged({ field: 'address', value: updatedAddress });
  };

  const handlePropertyChange = (e) => {
    const { name, value } = e.target;
    onStateChanged({ field: name, value });
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <CurrencyField
            fieldId='price'
            label='List Price'
            placeholder='$'
            value={price ? price : ''}
            onChange={onStateChanged}
            required
            error={errors?.price}
          />
        </Col>
      </Row>
      <Address
        onStateChanged={onAddressChange}
        address={address}
        useListingStates={true}
        limitToStates={[licenseState]}
        showCounty
        errors={errors.address}
      >
        <FormField
          type='text'
          fieldId='apn'
          label='APN/Tax ID'
          initialValue={address?.apn ?? ''}
          onStateChanged={onApnChange}
          required
          error={errors.apn}
        />
      </Address>
      <Row>
        <FormField
          type='textarea'
          fieldId='legalDescription'
          label='Legal Description'
          initialValue={legalDescription}
          onStateChanged={onStateChanged}
        />
      </Row>
      <Row>
        <Col sm={6}>
          <DropDown
            label='Property Type*'
            groupName='propertyType'
            selectedOption={propertyType ? propertyType : 'select'}
            onStateChanged={handlePropertyChange}
            items={propertyTypes}
            error={errors.propertyType}
          />
        </Col>
        <Col sm={6}>
          <FormField
            type='number'
            fieldId='squareFeet'
            label='Square Feet'
            initialValue={squareFeet}
            onStateChanged={onStateChanged}
            required
            error={errors.squareFeet}
          />
        </Col>
        <Col sm={6}>
          <FormField
            type='number'
            fieldId='numberBedrooms'
            label='Bedrooms'
            initialValue={numberBedrooms}
            onStateChanged={onStateChanged}
            required
            error={errors.numberBedrooms}
          />
        </Col>
        <Col sm={6}>
          <FormField
            type='number'
            fieldId='numberBaths'
            label='Bathrooms'
            initialValue={numberBaths}
            onStateChanged={onStateChanged}
            required
            error={errors.numberBaths}
          />
        </Col>
      </Row>
      <FormField
        type='textarea'
        fieldId='description'
        label='Description'
        initialValue={description}
        onStateChanged={onStateChanged}
      />
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <AddPropertyImages
        primaryImage={primaryImage}
        onPropertyImageChanged={onPropertyImageChanged}
        onPropertyImageDelete={onPropertyImageDelete}
      />
    </>
  );
}

export default CreateProperty;
