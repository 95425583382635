import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import apiConstants from 'constants/apiConstants';
import { OFFER1_GREEN } from 'constants/styleConstants';
import { Badge } from 'react-bootstrap';
import 'styles/map.css';
import routes from 'containers/App/routeConstants';
import { useHistory } from 'react-router-dom';

const Map = ({ pins, pinCenter, zoomLevel }) => {
  const history = useHistory();

  const [hoverKeyState, setHoverKeyState] = useState();

  const _onChildClick = (key, childProps) => {
    const path = [routes.LISTING_PATH, childProps.listingId].join('/').trim();
    history.push(path);
  };

  const _onChildMouseEnter = (key, childProps) => {
    setHoverKeyState(key);
  };

  const _onChildMouseLeave = (key, childProps) => {
    setHoverKeyState(null);
  };

  return (
    <div className='map'>
      <div className='google-map'>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: apiConstants.GOOGLE_API_KEY,
          }}
          center={pinCenter}
          defaultZoom={zoomLevel}
          onChildClick={_onChildClick}
          onChildMouseEnter={_onChildMouseEnter}
          onChildMouseLeave={_onChildMouseLeave}
        >
          {pins.map((pin, index) => (
            <LocationPin
              key={index}
              lat={pin.lat}
              lng={pin.lng}
              text={pin.text}
              listingId={pin.listingId}
              hover={hoverKeyState === index}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
};

const formatPrice = (listPrice) => {
  var price = (listPrice / 1000).toFixed(0);
  if (price >= 1000) {
    price = (price / 1000).toFixed(2) + 'M';
  } else {
    price = price + 'K';
  }
  return price;
};

const LocationPin = ({ text, hover }) => {
  return (
    <div className='pin'>
      <FaMapMarkerAlt className='pin-icon' size={25} style={{ fill: OFFER1_GREEN }} />
      {text && (
        <Badge bg='' style={{ backgroundColor: OFFER1_GREEN, fontSize: 10 }}>
          {formatPrice(text)}
        </Badge>
      )}
    </div>
  );
};

export default Map;
