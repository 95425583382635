import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { getSaving } from 'store/selectors/app';
import { Typography } from 'components';

function SavingMessage() {
  const isPageSaving = useSelector(getSaving);

  if (!isPageSaving) return null;
  return (
    <div className='loader-container'>
      <div className='loader'>
        <Spinner className='save-spinner-img me-4' as='span' animation='border' role='status' aria-hidden='true' />
        <Typography color='offer1-blue' size={40}>
          Saving...
        </Typography>
      </div>
    </div>
  );
}

export default SavingMessage;
