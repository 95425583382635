import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useAuthUser } from 'hooks';
import { Header, PrimaryGreenButton, ReceiptPanel } from 'components';
import QueryString from 'query-string';
import Icon from 'components/Icon';
import { SubscriptionTitle, SubscriptionText, ConfirmationContainer } from './subscription.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MainContainer } from 'containers/App/mainLayout.styled';
import { SubscriptionsAsyncActions } from 'store/actions/subscriptions';
import { getSubscription } from 'store/selectors/subscriptions';

function SubscriptionConfirmPage() {
  const history = useHistory();
  const { user } = useAuthUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState('/home');

  const subscription = useSelector(getSubscription);
  const { isSuccess } = useSelector(SubscriptionsAsyncActions.FetchSubscription.StatusSelector());

  useEffect(() => {
    dispatch(SubscriptionsAsyncActions.FetchSubscription.Actions.REQUEST());

    return () => {
      dispatch(SubscriptionsAsyncActions.FetchSubscription.Actions.RESET());
    };
  }, [dispatch]);

  const handleContinue = () => {
    history.push(redirectUrl);
  };

  useEffect(() => {
    const params = QueryString.parse(location.search);
    if (params && params.redirectUrl) {
      setRedirectUrl(params.redirectUrl);
    }
  }, [location.search]);

  const titleText = useMemo(() => (user ? `Thank you, ${user.firstName} ${user.lastName}!` : `Thank you!`), [user]);

  return (
    <MainContainer>
      <Helmet>
        <title>Payment Confirmation</title>
        <meta name='description' content='payment confirmation' />
      </Helmet>
      <div>
        <Header title='Payment Confirmation' showInMobile={true} />

        {isSuccess && (
          <ConfirmationContainer>
            <Row className='justify-content-md-center'>
              <Col>
                <Icon name='checkmark' size={96} className={'my-3'} />
              </Col>
            </Row>
            <Row className='justify-content-md-center'>
              <Col>
                <SubscriptionTitle>{titleText}</SubscriptionTitle>
              </Col>
            </Row>
            <Row className='justify-content-md-center'>
              <SubscriptionText>You are all set to continue your listing.</SubscriptionText>
            </Row>
            {subscription && (
              <Row className='justify-content-md-center'>
                <ReceiptPanel itemName={subscription?.item?.name} itemPrice={subscription?.item?.amount} />
              </Row>
            )}
            <PrimaryGreenButton onClick={handleContinue}>Complete Listing</PrimaryGreenButton>
          </ConfirmationContainer>
        )}
      </div>
    </MainContainer>
  );
}

export default SubscriptionConfirmPage;
