import { createAsyncAction } from 'utils/store';

export const LOGIN_REDIRECT = 'auth/LOGIN_REDIRECT';

export const SSO = createAsyncAction('auth', 'sso', {
  REQUEST: [],
  SUCCESS: ['token', 'user'],
});

export const Login = createAsyncAction('auth', 'login', {
  REQUEST: ['email', 'password', 'redirect'],
  SUCCESS: ['token', 'user'],
});

export const ImpostorLogin = createAsyncAction('auth', 'impostorLogin', {
  REQUEST: ['email'],
  SUCCESS: ['token', 'user'],
});

export const SignUp = createAsyncAction('auth', 'signUp', {
  REQUEST: ['registrationRequest'],
  SUCCESS: [],
});

export const LogOut = createAsyncAction('auth', 'logOut', {
  REQUEST: [],
  SUCCESS: ['user', 'token'],
});

export const ResetPassword = createAsyncAction('auth', 'resetPassword', {
  REQUEST: ['token', 'email', 'password'],
  SUCCESS: [],
});

export const ForgotPassword = createAsyncAction('auth', 'forgotPassword', {
  REQUEST: ['email'],
  SUCCESS: [],
});

export const VerifyAccount = createAsyncAction('auth', 'verifyAccount', {
  REQUEST: ['email', 'token'],
  SUCCESS: [],
});

export const UpdateAccount = createAsyncAction('auth', 'updateAccount', {
  REQUEST: ['user'],
  SUCCESS: ['user'],
});

export const RecordQuestionnaire = createAsyncAction('auth', 'recordQuestionnaire', {
  REQUEST: ['questionnaire'],
  SUCCESS: [],
});

export const loginRedirect = (redirect) => ({
  type: LOGIN_REDIRECT,
  payload: { redirect },
});

export const AuthAsyncActions = {
  SSO,
  Login,
  SignUp,
  LogOut,
  ResetPassword,
  ForgotPassword,
  VerifyAccount,
  UpdateAccount,
  RecordQuestionnaire,
  ImpostorLogin,
};
