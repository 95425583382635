import styled from 'styled-components';

export const CloseButton = styled.button`
  position: sticky;
  top: 0;
  font-size: 40px;
  margin-left: auto;
  line-height: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
  z-index: 1;
`;
