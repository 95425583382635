import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1009;
`;

export const ModalBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-60%);
  width: 424px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.3);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 15px 43px 12px;
  border-bottom: 1px solid var(--gray6);
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #4f4f4f;

  svg {
    cursor: pointer;
    position: absolute;
    top: 21px;
    right: 43px;
    min-width: 22px;
  }
`;

export const ModalContent = styled.div`
  padding: 37px 45px 58px;

  a {
    line-break: anywhere;
  }
`;

export const MessageButton = styled.button`
  padding: 0 47px;
  border: 1px solid var(--gray6);
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  color: var(--gray2);
  width: auto;
  outline: 0;
  background-color: #fff;
  transition: all 0.2s ease;

  svg {
    margin-right: 10px;
  }

  &:hover,
  &.active {
    path {
      fill: #fff;
      transition: all 0.2s ease;
    }
    color: #fff;
    background-color: var(--offer1-blue);
  }
`;
