import { call, put } from 'redux-saga/effects';
import { ListingsAsyncActions } from 'store/actions/listings';
import { apiCall } from 'utils/apiCall';

function* list() {
  const {
    ListListings: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ListingsAsyncActions;

  try {
    const response = yield call(apiCall, '/listings/users', 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default list;
