import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AuthAsyncActions } from 'store/actions/auth';
import { EmailField, PrimaryGreenButton } from 'components';
import { ForgotPasswordContent, SectionTitle, EmailContainer } from './forgotPassword.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function ForgotPasswordPage() {
  const dispatch = useDispatch();

  const [userEmail, setUserEmail] = useState();

  const requestNewPassword = useCallback(() => {
    dispatch(AuthAsyncActions.ForgotPassword.Actions.REQUEST(userEmail));
  }, [dispatch, userEmail]);

  const handleEmailChanged = (state) => {
    setUserEmail(state.value);
  };

  return (
    <MainContainerMd>
      <ForgotPasswordContent>
        <SectionTitle>Reset Your Password</SectionTitle>
        <EmailContainer>
          <EmailField
            fieldId='email'
            placeholder='Email'
            initialValue={userEmail}
            onStateChanged={handleEmailChanged}
            required
          />
        </EmailContainer>
        <PrimaryGreenButton
          type='button'
          className='text-uppercase px-3 py-2 w-100'
          onClick={() => requestNewPassword()}>
          Reset
        </PrimaryGreenButton>
      </ForgotPasswordContent>
    </MainContainerMd>
  );
}
export default ForgotPasswordPage;
