export const getPropertyCardStatus = (state, contractId) => {
  const stateMap = {
    ACTIVE: 'Active',
    DRAFT: 'Draft',
    SOLD: 'Sold',
    CANCELLED: 'Lost',
    PENDING_ESCROW: 'Pending',
    SELLER_REVIEW: contractId ? 'Contingent' : 'Active',
    DELETED: 'Deleted',
    PENDING_SELLER_APPROVAL: 'PreMarketing',
    PENDING_AGENT_UPDATES: 'PreMarketing',
    SELLER_APPROVED: 'PreMarketing',
  };
  return stateMap[state] ?? '';
};

export const getPropertyCardActionText = (state) => {
  const stateMap = {
    DRAFT: 'Draft',
    PENDING_AGENT_UPDATES: 'Pending updates from agent',
    PENDING_SELLER_APPROVAL: 'Pending sellers approval',
    SELLER_APPROVED: 'Listing ready to publish',
    SELLER_REVIEW: 'Pending Signatures',
  };
  return stateMap[state] ?? '';
};

export const getOfferBadgeStatus = (state) => {
  if (state.includes('SELLER')) {
    return 'Pending Sellers Response';
  }
  const stateMap = {
    DRAFT: 'Draft',
    PROVISIONAL: 'Draft',
    LIVE_EDIT: 'Pending updates from agent',
    LIVE_EDIT_BUYER: 'Edits pending buyer Approval',
    BUYER_REJECTED: 'Pending updates from agent',
    PENDING_BUYER_APPROVAL: 'Pending Buyer Approval',
    PENDING_BUYER_AGENT_RESPONSE: 'Counter',
    BUYER_REJECTED_COUNTER_OFFER_CREATION: 'Counter',
    PENDING_BUYER_COUNTER_REVIEW: 'Unapproved Counter',
    PENDING_BUYER_ACCEPT_REVIEW: 'Unapproved Acceptance',
    CANCELLED: 'Cancelled',
    ACCEPTED: 'Sign Contract',
    DELETED: 'Deleted',
  };
  return stateMap[state] ?? 'Lost';
};
