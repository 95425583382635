import React, { useState, useCallback, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FormField, RadioButtons, Divider, Typography } from 'components';
import OfferCostAllocationTableFL from './offerCostAllocationTableFL';

function OfferCostAllocationsFL({ costAllocations, stateData, errors, isCounter, onChange, onStateDataChange }) {
  const [additionalItemsMap, setAdditionalItemsMap] = useState({});

  const availableItems = useMemo(() => {
    const responsiblePayers = costAllocations?.additionalItems?.map(({ responsiblePayer }) => responsiblePayer);
    return ['SELLER', 'BUYER'].filter((payer) => !responsiblePayers?.includes(payer));
  }, [costAllocations.additionalItems]);

  const handleItemChange = useCallback(
    (item) => (event) => {
      setAdditionalItemsMap((prev) => ({
        ...prev,
        [item]: event.value,
      }));
    },
    []
  );

  const handleAddItem = (item) => {
    if (!additionalItemsMap[item]) {
      return;
    }
    onChange({
      value: [
        ...(costAllocations.additionalItems || []),
        {
          item: additionalItemsMap[item],
          responsiblePayer: item,
        },
      ],
      field: 'additionalItems',
    });
    delete additionalItemsMap[item];
  };

  const handleDeleteItem = (item) => {
    onChange({
      value: costAllocations.additionalItems.filter((allocator) => allocator.item !== item),
      field: 'additionalItems',
    });
  };

  const onSpecialAssessmentSelected = (event) => {
    const data = {
      value: event.target.value,
      field: event.target.name,
    };
    onStateDataChange(data);
  };
  return (
    <>
      <Typography as='p' size={28}>
        Cost Allocations
      </Typography>
      <OfferCostAllocationTableFL />
      <Divider className='mx-n2 mx-md-n4 my-2' />
      <Typography as='p' size={28}>
        Additional Cost Allocations (Optional)
      </Typography>
      <Typography size={18}>
        <b>DO NOT</b> create a new cost allocation for Title/Closing Services or Home Warranty. These will be addressed
        in the next step.
      </Typography>
      {availableItems.map((item) => (
        <Row className='d-flex' key={item}>
          <Col sm='auto' className='my-2'>
            <Typography as='label' className='pt-2'>
              {item === 'SELLER' ? 'Seller' : 'Buyer'} cost(s):
            </Typography>
          </Col>
          <Col sm='auto'>
            <FormField
              fieldId='item'
              placeholder='Enter Item'
              initialValue={additionalItemsMap[item]}
              maxLength={35}
              onStateChanged={handleItemChange(item)}
            />
          </Col>
          <Col sm='auto'>
            <Button className='ms-1 my-2' variant='outline-primary' onClick={() => handleAddItem(item)}>
              Add
            </Button>
          </Col>
        </Row>
      ))}
      {costAllocations.additionalItems?.map((allocation) => (
        <Row className='d-flex' key={allocation.item}>
          <Col sm='auto' className='my-2'>
            <Typography as='label' className='pt-2'>
              {allocation.responsiblePayer === 'SELLER' ? 'Seller' : 'Buyer'} cost(s)
            </Typography>
          </Col>
          <Col sm='auto'>
            <FormField fieldId={allocation.item} label='' placeholder='Enter Item' initialValue={allocation.item} />
          </Col>
          <Col sm='auto'>
            <Button className='ms-1 my-2' variant='outline-primary' onClick={() => handleDeleteItem(allocation.item)}>
              Delete
            </Button>
          </Col>
        </Row>
      ))}
      <Divider className='mx-n2 mx-md-n4 my-2' />
      <Typography as='p' size={28}>
        Special Assessments
      </Typography>
      <Typography as='p'>
        If (public body, non-HOA) special assessments may be paid in installments{' '}
        <Typography bold>(CHECK ONE)</Typography>
      </Typography>
      <RadioButtons
        items={[
          {
            name: 'Buyer pays any public body special assessments that can be paid in installments after COE',
            value: 'BUYER',
          },

          {
            name: 'Seller pays any public body special assessments that can be paid in full by the close of escrow',
            value: 'SELLER',
          },
        ]}
        vertical
        groupName={'specialAssessments'}
        selectedOption={stateData.specialAssessments}
        onStateChanged={onSpecialAssessmentSelected}
      />
      <Divider className='mx-n2 mx-md-n4 my-2' />
      <FormField
        type='textarea'
        fieldId='costAllocationsComments'
        label='Additional Comments on Cost Allocation: (Comments are visible to all parties and will not be a part of any agreement)'
        placeholder='(Optional)'
        maxLength={500}
        initialValue={costAllocations.costAllocationsComments}
        onStateChanged={onChange}
        error={errors?.costAllocationsComments}
      />
    </>
  );
}

export default OfferCostAllocationsFL;
