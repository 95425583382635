import React from 'react';
import PropTypes from 'prop-types';
import { Container, LineTop, LineMiddle, LineBottom } from './menuButton.styled';

function MenuButton({ className, open, onToggle }) {
  return (
    <Container className={className} onClick={onToggle} id='hamburger-menu'>
      <LineTop isOpen={open} id='hamburger-menu-top' />
      <LineMiddle isOpen={open} id='hamburger-menu-middle' />
      <LineBottom isOpen={open} id='hamburger-menu-bottom' />
    </Container>
  );
}

MenuButton.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default MenuButton;
