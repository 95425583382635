import React from 'react';

const TitleDivider = ({ title, components }) => {
  return (
    <div style={{ color: 'var(--offer1-black)' }} className='d-flex justify-content-between border-bottom my-3'>
      <h5>{title}</h5>
      {components}
    </div>
  );
};

export default TitleDivider;
