import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { CurrencyField } from 'components';
import { Input, DropdownMenu } from './allListingsPage.styled';

class PropertySideBar extends Component {
  state = {
    additionalFilters: {
      price: { min: null, max: null },
      address: null,
      city: null,
      state: null,
      zip: null,
    },
  };

  render() {
    const { onFilter } = this.props;
    const { additionalFilters } = this.state;

    return (
      <div>
        <div className='d-flex align-items-center'>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' id='Price' className='p-1 px-2 m-2' size='sm'>
              Price
            </Dropdown.Toggle>
            <DropdownMenu>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='p-0 ps-2 m-0'>Min:</p>
                <div className='p-2'>
                  <span className='input-symbol-dollar'>
                    <CurrencyField
                      value={additionalFilters.price.min}
                      onChange={({ value }) => {
                        this.setState(
                          {
                            additionalFilters: {
                              ...additionalFilters,
                              price: {
                                ...additionalFilters.price,
                                min: Number.parseInt(value),
                              },
                            },
                          },
                          () => onFilter(additionalFilters)
                        );
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='p-0 ps-2 m-0'>Max:</p>
                <div className='p-2'>
                  <CurrencyField
                    fieldId='price'
                    value={additionalFilters.price.max}
                    onChange={({ value }) => {
                      this.setState(
                        {
                          additionalFilters: {
                            ...additionalFilters,
                            price: {
                              ...additionalFilters.price,
                              max: Number.parseInt(value),
                            },
                          },
                        },
                        () => onFilter(additionalFilters)
                      );
                    }}
                  />
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' id='Address' className='p-1 px-2 m-2' size='sm'>
              Address
            </Dropdown.Toggle>
            <DropdownMenu>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='p-0 ps-2 m-0'>Address:</p>
                <div className='p-2'>
                  <Input
                    value={additionalFilters.address}
                    onChange={(event) => {
                      this.setState(
                        {
                          additionalFilters: {
                            ...additionalFilters,
                            address: event.target.value,
                          },
                        },
                        () => onFilter(additionalFilters)
                      );
                    }}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='p-0 ps-2 m-0'>City:</p>
                <div className='p-2'>
                  <Input
                    value={additionalFilters.city}
                    onChange={(event) => {
                      this.setState(
                        {
                          additionalFilters: {
                            ...additionalFilters,
                            city: event.target.value,
                          },
                        },
                        () => onFilter(additionalFilters)
                      );
                    }}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='p-0 ps-2 m-0'>State:</p>
                <div className='p-2'>
                  <Input
                    value={additionalFilters.state}
                    onChange={(event) => {
                      this.setState(
                        {
                          additionalFilters: {
                            ...additionalFilters,
                            state: event.target.value,
                          },
                        },
                        () => onFilter(additionalFilters)
                      );
                    }}
                  />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='p-0 ps-2 m-0'>Zip:</p>
                <div className='p-2'>
                  <Input
                    value={additionalFilters.zip}
                    onChange={(event) => {
                      this.setState(
                        {
                          additionalFilters: {
                            ...additionalFilters,
                            zip: event.target.value,
                          },
                        },
                        () => onFilter(additionalFilters)
                      );
                    }}
                  />
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default PropertySideBar;
