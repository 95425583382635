import { call, put } from 'redux-saga/effects';
import { DashboardAsyncActions } from 'store/actions/dashboard';
import { apiCall } from 'utils/apiCall';

function* fetch(action) {
  const {
    FetchDashboard: {
      Actions: { SUCCESS, FAILURE },
    },
  } = DashboardAsyncActions;
  const {
    payload: { id = null },
  } = action;

  try {
    const response = yield call(apiCall, id ? `/users/dashboard/${id}` : '/agents/dashboard', 'get', id);
    const { listings, notifications, offers, buyers, tasks } = response.data;
    yield put(SUCCESS(listings, notifications, offers, buyers, tasks));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetch;
