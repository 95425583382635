export const formatCurrency = (number, digits = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  }).format(number);
};

export const formatPercentage = (total, value, digits = 0) => {
  return ((value / total) * 100).toFixed(digits);
};

export const formatPrettyPercentage = (value, digits = 0) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: digits,
  }).format(value / 100);
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '(###) ###-####';
};

export const twoDigits = (n) => {
  return String(n).padStart(2, '0');
};

export const ellipsisText = (text, limit) => {
  if (!text) {
    return null;
  }
  if (text.length <= limit) {
    return text;
  }
  return text.substring(0, limit) + '...';
};

export const insertSpaces = (text) => {
  const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);
  return capitalizedText.replace(/([A-Z])/g, ' $1').trim();
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const joinString = (strs, delimiters = '') => strs.filter(Boolean).join(delimiters);

export const sanitizeFileName = (fileName) => fileName.replace(/[^\w.-]/g, '_');
