import { call, put } from 'redux-saga/effects';
import { ContractAsyncActions } from 'store/actions/contract';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* signContract(action) {
  const {
    SaveSignatureContract: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ContractAsyncActions;
  const {
    payload: { contract, signatures },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/${contract.listingId}/contract/sign`, 'put', signatures);
    yield put(SUCCESS(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default signContract;
