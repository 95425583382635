import { all, fork, takeLatest } from 'redux-saga/effects';
import { AppAsyncActions } from 'store/actions/app';
import loading from './loading';
import saving from './saving';
import meta from './meta';

export default function* root() {
  const { FetchMetadata } = AppAsyncActions;
  yield all([takeLatest(FetchMetadata.Types.REQUEST, meta), fork(saving), fork(loading)]);
}
