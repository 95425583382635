import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Modal from 'react-bootstrap/Modal';
import { CountDown, ShareListingModal, PopoverTooltip, ButtonWrapper, Avatar, Icon } from 'components';
import {
  BlueText,
  PropertyCardContainer,
  PropertyStatus,
  PropertyActionText,
  PropertyAddress,
  PropertyImage,
  PropertyInfoContainer,
  PropertyInfoLabel,
  PropertyInfoValue,
  PropertyActionImg,
  HorizontalSeparator,
  VerticalSeparator,
  GlobalCounterContainer,
  GlobalCounterInfo,
  CounterDownContainer,
  GlobalCounterLabel,
  CountDownContainer,
  AgentContainer,
  AgentName,
  OwnerType,
  ContactContainer,
  AgentProfile,
  ActionButtonContainer,
  CardButton,
  PropertyInfoItem,
} from './propertyCard.styled';
import EllipsisToolTip from 'ellipsis-tooltip-react-chan';
import { NotificationContainer } from './buyerCard.styled';
import routes from 'containers/App/routeConstants';
import { OffersAsyncActions } from 'store/actions/offers';
import ReviewOfferModal from 'containers/ReviewOfferPage/reviewOfferModal';
import { useAuthUser } from 'hooks';
import { getPropertyCardStatus, getOfferBadgeStatus, formatCurrency, formatPhoneNumber, URLUtils } from 'utils';
import { getOffers } from 'store/selectors/offers';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function OfferCard({ offer, className, user, fromBuyer = false }) {
  const [showShare, setShowShare] = useState(false);
  const [show, setShow] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const listingOffers = useSelector(getOffers);

  const { isAgent } = useAuthUser();

  const handleClickOffer = () => {
    if (
      offer.buyerOfferState === 'DRAFT' ||
      offer.buyerOfferState === 'PENDING_BUYER_APPROVAL' ||
      offer.buyerOfferState === 'BUYER_REJECTED' ||
      offer.buyerOfferState === 'LIVE_EDIT' ||
      offer.buyerOfferState === 'LIVE_EDIT_BUYER'
    ) {
      history.push(routes.OFFERS_PATH + `/${offer.offerId}`, {
        listing: offer.listing,
        highestOffer: offer.highestOffer,
        offerCount: offer.offerCount,
      });
    } else {
      if (fromBuyer) {
        dispatch(OffersAsyncActions.ListOffers.Actions.REQUEST(offer.listing.id, 'buyer'));
        setShow(true);
      } else {
        history.push(routes.OFFER_STATUS + `/${offer.listing.id}/buyer`);
      }
    }
  };

  const handleShareClick = (e) => {
    e.stopPropagation();
    setShowShare(true);
  };

  const getImageUrl = (path) => {
    return path
      ? path.startsWith('http')
        ? path
        : URLUtils.getDocumentUrl(path)
      : URLUtils.getPublicImageUrl('house.svg');
  };

  const getCardOwnerName = () => {
    if (user?.id === offer.listing?.listingAgent?.id) {
      return offer.listing?.primaryOwner?.user.firstName && offer.listing?.primaryOwner?.user.lastName
        ? `${offer.listing?.primaryOwner?.user.firstName} ${offer.listing?.primaryOwner?.user.lastName}`
        : 'Owner has not been entered';
    } else {
      return `${offer.listing?.listingAgent.firstName} ${offer.listing?.listingAgent?.lastName}`;
    }
  };

  const getCardOwnerType = () => {
    return user?.id === offer.listing?.listingAgent?.id &&
      offer.listing?.primaryOwner?.user.firstName &&
      offer.listing?.primaryOwner?.user.lastName
      ? 'Primary Owner'
      : 'Agent';
  };

  const getFormatPhoneNumber = () => {
    if (user?.id === offer.listing?.listingAgent.id) {
      formatPhoneNumber(offer.listing?.primaryOwner?.user.phone);
    } else {
      formatPhoneNumber(offer.listing.listingAgent.agent.phone);
    }
  };

  const getEmail = () => {
    if (user?.id === offer.listing?.listingAgent.id) {
      return offer.listing?.primaryOwner?.user.email;
    } else {
      return offer.listing.listingAgent.email;
    }
  };

  return (
    <>
      <PropertyCardContainer className={className}>
        <PropertyStatus status={offer.listing.state}>
          {getPropertyCardStatus(offer.listing.state, offer.listing.contractId)}
        </PropertyStatus>
        <NotificationContainer>
          {getOfferBadgeStatus(offer.buyerOfferState) !== '' && (
            <>
              <PropertyActionImg>
                <Icon name='alarm' color='var(--danger)' />
              </PropertyActionImg>
              <PropertyActionText>{getOfferBadgeStatus(offer.buyerOfferState)}</PropertyActionText>
            </>
          )}
        </NotificationContainer>

        <PropertyAddress>
          <EllipsisToolTip>
            {offer.listing.address.addressLine1}
            <br />
            {offer.listing.address.addressLine2 && (
              <>
                {offer.listing.address.addressLine2}
                <br />
              </>
            )}
            {`${offer.listing.address.city}, ${offer.listing.address.state} ${offer.listing.address.zip}`}
          </EllipsisToolTip>

          <div className='d-flex flex-column'>
            <ButtonWrapper>
              <Icon name='heart' />
            </ButtonWrapper>
            <ButtonWrapper className='mt-2' onClick={handleShareClick}>
              <Icon name='share' />
            </ButtonWrapper>
          </div>
        </PropertyAddress>
        <PropertyImage
          src={getImageUrl(offer.listing.primaryImage ? offer.listing.primaryImage.url : undefined)}
          alt='home'
        />
        <PropertyInfoContainer>
          <PropertyInfoItem>
            <PropertyInfoLabel>[List Price]</PropertyInfoLabel>
            <PropertyInfoValue>
              {offer?.listing?.price ? formatCurrency(offer.listing.price, 0) : '$TBD'}
            </PropertyInfoValue>
          </PropertyInfoItem>
          <VerticalSeparator />
          <PropertyInfoItem>
            <PropertyInfoLabel>[Offers]</PropertyInfoLabel>
            <PropertyInfoValue>{offer.offerCount}</PropertyInfoValue>
          </PropertyInfoItem>
          <VerticalSeparator />
          <PropertyInfoItem>
            <PropertyInfoLabel>[Highest Offer]</PropertyInfoLabel>
            <BlueText>
              {offer.offerCount >= 3 && offer.highestOffer ? formatCurrency(offer.highestOffer, 0) : '$$$'}
            </BlueText>
          </PropertyInfoItem>
        </PropertyInfoContainer>
        <HorizontalSeparator />
        <GlobalCounterContainer>
          <GlobalCounterInfo>
            <CounterDownContainer>
              {offer.auctionExpirationDate ? (
                <>
                  <GlobalCounterLabel>Seller Review Countdown</GlobalCounterLabel>
                  <GlobalCounterLabel>[Days | Hrs | Min | Sec]</GlobalCounterLabel>
                  <CountDownContainer>
                    <CountDown endDate={offer.auctionExpirationDate} showLabel={false} fontSize={12} color={'white'} />
                  </CountDownContainer>
                </>
              ) : (
                <span>NA</span>
              )}
            </CounterDownContainer>
            {!isMobile && <VerticalSeparator />}
            <PropertyInfoItem>
              <PropertyInfoLabel>[Your offer]</PropertyInfoLabel>
              <BlueText>{formatCurrency(offer.buyerOffer, 0)}</BlueText>
            </PropertyInfoItem>
          </GlobalCounterInfo>
        </GlobalCounterContainer>
        <HorizontalSeparator />
        <AgentContainer>
          <AgentProfile>
            <Avatar size={40} src={offer.listing?.listingAgent?.profilePictureUrl} />
            <div className='ms-2'>
              <AgentName>{getCardOwnerName()}</AgentName>
              <br />
              <OwnerType>{getCardOwnerType()}</OwnerType>
            </div>
          </AgentProfile>
          {isAgent && (
            <ContactContainer>
              {isMobile ? (
                <a href={`tel:${getFormatPhoneNumber()}`} onClick={(e) => e.stopPropagation()}>
                  <Icon name='call' />
                </a>
              ) : (
                <PopoverTooltip
                  id='offer-card-phone-number'
                  placement='top'
                  trigger='hover'
                  icon={
                    <CopyToClipboard
                      text={getFormatPhoneNumber()}
                      onCopy={() => toast.success('Copied to clipboard', { icon: '📞' })}
                    >
                      <div>
                        <Icon name='call' />
                      </div>
                    </CopyToClipboard>
                  }
                  content={getFormatPhoneNumber()}
                />
              )}
              {isMobile ? (
                <a href={`mailto:${getEmail()}`} onClick={(e) => e.stopPropagation()}>
                  <Icon name='envelope' />
                </a>
              ) : (
                <PopoverTooltip
                  id='offer-card-email'
                  placement='top'
                  trigger='hover'
                  icon={
                    <CopyToClipboard
                      text={getEmail() || 'N/A'}
                      onCopy={() => toast.success('Copied to clipboard', { icon: '✉️' })}
                    >
                      <div>
                        <Icon name='envelope' />
                      </div>
                    </CopyToClipboard>
                  }
                  content={getEmail() || 'N/A'}
                />
              )}
            </ContactContainer>
          )}
        </AgentContainer>
        <HorizontalSeparator />
        <ActionButtonContainer>
          <CardButton onClick={handleClickOffer}>View Details</CardButton>
        </ActionButtonContainer>
      </PropertyCardContainer>

      <ShareListingModal
        show={showShare}
        onClose={() => setShowShare(false)}
        listingId={offer.listing.customListingUrl}
      />

      {listingOffers && (
        <Modal
          show={show && listingOffers.offers.length > 0}
          aria-labelledby='offer-review-modal'
          backdrop='static'
          dialogClassName='modal-90wh'
          contentClassName='p-3'
          scrollable
          enforceFocus={false}
        >
          <ReviewOfferModal listing={offer.listing} initialOffers={listingOffers} onClose={() => setShow(false)} />
        </Modal>
      )}
    </>
  );
}

OfferCard.propTypes = {
  offer: PropTypes.object,
  className: PropTypes.string,
  user: PropTypes.object,
  fromBuyer: PropTypes.bool,
};

export default OfferCard;
