import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import Tooltip from './index';
import FormattedMessage from '../FormattedMessage';

const PopoverTooltip = ({ id, placement = 'auto', labelId, label, contentId, content, trigger = 'hover', icon }) => {
  const renderOverlay = (
    <Popover id={id}>
      <Popover.Header as='h6'>{labelId ? <FormattedMessage id={labelId} /> : label}</Popover.Header>
      <Popover.Body>{contentId ? <FormattedMessage id={contentId} /> : content}</Popover.Body>
    </Popover>
  );
  return <Tooltip overlay={renderOverlay} placement={placement} trigger={trigger} icon={icon} />;
};

PopoverTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.string,
  labelId: PropTypes.string,
  label: PropTypes.string,
  contentId: PropTypes.string,
  content: PropTypes.string,
  trigger: PropTypes.string,
  icon: PropTypes.element,
};

export default PopoverTooltip;
