import styled from 'styled-components';

export const Property = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  max-width: 100%;
  background-color: ${(props) => (props.included ? 'var(--offer1-green)' : 'var(--offer1-blue)')};
  border-radius: 20px;
  font-size: 14px;
  color: white;
  padding: 2px 10px;
  margin: 5px;
`;
