import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ButtonWrapper, Icon } from 'components';
import { ModalContainer, ModalBox, ModalHeader, ModalContent, MessageButton } from './shareListingModal.styled';

function ShareListingModal({ show, onClose, listingId }) {
  const appUrl = process.env.REACT_APP_URL;
  const listingLink = `${appUrl}/listing/${listingId}`;
  if (!show) {
    return null;
  }

  return (
    <ModalContainer
      onClick={() => {
        onClose();
      }}>
      <ModalBox
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <ModalHeader>
          Share Listing
          <div onClick={onClose}>
            <Icon name='close' />
          </div>
        </ModalHeader>
        <ModalContent>
          {listingId && (
            <div>
              <p>Use the link below to share the listing with friends and family.</p>
              <a href={listingLink}>{listingLink}</a>
              <CopyToClipboard text={listingLink} onCopy={() => toast.success('Copied to clipboard', { icon: '🚀' })}>
                <ButtonWrapper className='ms-1'>
                  <Icon name='clipboard' size={18} color='var(--offer1-blue)' />
                </ButtonWrapper>
              </CopyToClipboard>
            </div>
          )}
          {!listingId && <p>Listing is incomplete</p>}
          <div className='mt-3 d-flex justify-content-center'>
            <MessageButton onClick={onClose}>Close</MessageButton>
          </div>
        </ModalContent>
      </ModalBox>
    </ModalContainer>
  );
}

ShareListingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  listingId: PropTypes.string,
};

export default ShareListingModal;
