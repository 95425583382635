import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';
import routes from 'containers/App/routeConstants';

function* create(action) {
  const {
    DeleteListing: {
      Actions: { SUCCESS, FAILURE },
    },
  } = CreateListingAsyncActions;
  const {
    payload: { id },
  } = action;

  try {
    yield call(apiCall, `/listings/${id}`, 'delete');
    yield put(SUCCESS(null));
    yield put(push(routes.HOME_PATH));
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default create;
