import { createAsyncAction } from 'utils/store';

export const ListBuyers = createAsyncAction('buyers', 'listBuyers', {
  REQUEST: [],
  SUCCESS: ['buyers'],
});

export const FetchBuyer = createAsyncAction('buyers', 'fetchBuyer', {
  REQUEST: ['id'],
  SUCCESS: ['buyer'],
});

export const FetchBuyerProfiles = createAsyncAction('buyers', 'fetchBuyerProfiles', {
  REQUEST: ['buyerId'],
  SUCCESS: ['buyerProfiles'],
});

export const FetchBuyerProfile = createAsyncAction('buyers', 'fetchBuyerProfile', {
  REQUEST: ['id'],
  SUCCESS: ['buyerProfile'],
});

export const FetchBuyerOffers = createAsyncAction('buyers', 'fetchBuyerOffers', {
  REQUEST: [],
  SUCCESS: ['offers'],
});

export const UpdateBuyerProfile = createAsyncAction('buyers', 'updateBuyerProfile', {
  REQUEST: ['buyerProfile'],
  SUCCESS: ['buyerProfile'],
});

export const CreateBuyerProfile = createAsyncAction('buyers', 'createBuyerProfile', {
  REQUEST: ['buyerProfile'],
  SUCCESS: ['buyerProfile'],
});

export const BuyersAsyncActions = {
  ListBuyers,
  FetchBuyer,
  FetchBuyerOffers,
  FetchBuyerProfile,
  FetchBuyerProfiles,
  UpdateBuyerProfile,
  CreateBuyerProfile,
};
