import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ButtonWrapper, Icon } from 'components';
import { Header } from './billing.styled';
import { useAuthUser } from 'hooks';

function BillingPage({ show, title, pricingTable, onClose }) {
  const { user } = useAuthUser();

  return (
    <Modal show={show} backdrop='static' keyboard={false} size='xl' centered>
      <Header>
        <h3>{title}</h3>
        <ButtonWrapper className='close-button' onClick={onClose}>
          <Icon name='close' />
        </ButtonWrapper>
      </Header>
      <Modal.Body>
        <stripe-pricing-table
          pricing-table-id={pricingTable}
          publishable-key={process.env.REACT_APP_STRIPE_KEY}
          client-reference-id={user?.id}
          customer-email={user?.email}
        ></stripe-pricing-table>
      </Modal.Body>
    </Modal>
  );
}

export default BillingPage;
