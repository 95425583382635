import { call, put } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';

function* resetPassword(action) {
  const {
    ResetPassword: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { token, email, password },
  } = action;

  try {
    yield call(apiCall, '/users/password', 'put', {
      token,
      email,
      password,
    });
    yield put(SUCCESS());
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default resetPassword;
