import { call, put } from 'redux-saga/effects';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { apiCall } from 'utils/apiCall';

function* fetchBuyerProfiles(action) {
  const {
    FetchBuyerProfiles: {
      Actions: { SUCCESS, FAILURE },
    },
  } = BuyersAsyncActions;

  const {
    payload: { buyerId },
  } = action;

  try {
    const response = yield call(apiCall, `/buyerProfiles`, 'get', { buyerId });
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchBuyerProfiles;
