import { call, put } from 'redux-saga/effects';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* update(action) {
  const {
    UpdateListing: {
      Actions: { SUCCESS, FAILURE },
    },
  } = CreateListingAsyncActions;
  const {
    payload: { listing },
  } = action;

  try {
    const response = yield call(apiCall, '/listings', 'put', listing);
    yield put(SUCCESS(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default update;
