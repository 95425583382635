import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const PropertyCardContainer = styled.div`
  width: 300px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--gray6);
  padding: 27px;
  color: var(--gray2);
  ${breakpoint('mobile', 'desktop')`
    width: 100%;
  `}
`;
export const PropertyStatus = styled.div`
  width: 100%;
  background-color: ${({ status }) =>
    status === 'ACTIVE' || status === 'SELLER_REVIEW'
      ? 'var(--offer1-green)'
      : status === 'PENDING' ||
        status === 'PENDING_AGENT_UPDATES' ||
        status === 'PENDING_SELLER_APPROVAL' ||
        status === 'SELLER_APPROVED'
      ? 'var(--gray2)'
      : status === 'SOLD'
      ? 'black'
      : 'var(--gray3)'};
  color: white;
  line-height: 25px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
`;

export const PropertyActionImg = styled.div`
  float: left;
  margin-right: 10px;
`;

export const PropertyActionText = styled.span`
  color: ${({ status }) => (status === 'SOLD' ? 'black' : 'var(--danger)')};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  margin-bottom: 10px;
`;

export const PropertyAddress = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray1);
  height: 56px;
`;

export const PropertyImage = styled.img`
  margin-top: 15px;
  width: 100%;
  height: 140px;
  object-fit: cover;
`;

export const VerticalSeparator = styled.div`
  background-color: var(--gray6);
  width: 1px;
  height: 45px;
`;

export const HorizontalSeparator = styled.div`
  background-color: var(--gray6);
  width: 100%;
  height: 1px;
  margin-left: -5px;
  margin-right: -5px;
`;

export const PropertyInfoContainer = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  & > div {
    text-align: center;
  }
`;

export const PropertyInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child,
  &:last-child {
    width: 40%;
  }
`;

export const PropertyInfoLabel = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const PropertyInfoValue = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

export const BlueText = styled.span`
  color: var(--offer1-blue);
  font-size: 16px;
  line-height: 22px;
`;

export const GlobalCounterContainer = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GlobalCounterInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
  `}
`;

export const CounterDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoActiveReviewDay = styled.span`
  line-height: 45px;
`;

export const GlobalCounterLabel = styled.span`
  color: var(--gray2);
  font-size: 11px;
  line-height: 22px;
`;

export const CountDownContainer = styled.div`
  background-color: var(--offer1-green);
  padding: 0 10px;
`;

export const AgentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
`;

export const AgentProfile = styled.div`
  display: flex;
  align-items: center;
`;

export const AgentName = styled.span`
  font-size: 16px;
  color: var(--gray7);
  line-height: 20px;
`;

export const OwnerType = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--gray2);
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 65px;
  margin: 5px 0 0 10px;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardButton = styled.button`
  background-color: var(--offer1-green);
  border: 1px solid var(--offer1-green);
  font-size: 18px;
  text-align: center;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  margin-top: 10px;
`;
