import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useResponsive } from 'hooks';
import { TabsBar } from 'components';
import UsersAdminPage from './usersAdminPage';
import ListingsAdminPage from './listingsAdminPage';
import ContractsAdminPage from './contractsAdminPage';
import { MainContainer } from 'containers/App/mainLayout.styled';

function AdminContainer() {
  const { section } = useParams();
  const isMobile = useResponsive();

  const tabs = useMemo(
    () => [
      { label: 'Users', value: 'users' },
      { label: 'Agents', value: 'agents' },
      { label: 'Listings', value: 'listings' },
      { label: 'Contracts', value: 'contracts' },
    ],
    []
  );

  return (
    <MainContainer>
      {isMobile && <TabsBar tabs={tabs} />}
      {section === 'users' && <UsersAdminPage />}
      {section === 'agents' && <UsersAdminPage isAgent />}
      {section === 'listings' && <ListingsAdminPage />}
      {section === 'contracts' && <ContractsAdminPage />}
    </MainContainer>
  );
}

export default AdminContainer;
