import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';
import routes from 'containers/App/routeConstants';

function* login(action) {
  const {
    Login: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { email, password, redirect },
  } = action;

  try {
    const response = yield call(apiCall, '/login', 'post', { email, password });
    yield put(
      SUCCESS(response.headers['authorization'], {
        ...response.data,
      })
    );
    if (!response.data.agent && !response.data.lastLogin) {
      yield put(push(routes.CONSUMER_ONBOARDING));
      return;
    }
    yield put(push(!redirect || redirect.includes('login') ? routes.HOME_PATH : redirect));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default login;
