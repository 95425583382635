import React from 'react';
import {
  User,
  CheckBox,
  Corporation,
  Partnership,
  Trust,
  UserTypeRadios,
  PrimaryBlueButton,
  Divider,
  Typography,
} from 'components';
import { Note } from './offerContact.styled';

function OfferContact({ primaryBuyer, secondaryBuyers, errors, onStateChanged, onSecondaryBuyerChanged }) {
  const handleAddBuyer = () => {
    onSecondaryBuyerChanged({ value: [...secondaryBuyers, { user: {} }] });
  };

  const handleRemoveBuyer = (index) => {
    const buyers = [...secondaryBuyers];
    buyers.splice(index, 1);
    onSecondaryBuyerChanged({ value: buyers });
  };

  const handleAdditionalBuyerChanged = (user, index) => {
    const buyers = [...secondaryBuyers];
    buyers[index].user = user;
    onSecondaryBuyerChanged({ value: buyers });
  };

  const handleUserTypeChange = (event) => {
    const data = { value: event.target.value, field: event.target.name };
    onStateChanged(data);
  };

  const handleUserChanged = (updatedUser) => {
    const data = { value: updatedUser, field: 'user' };
    onStateChanged(data);
  };

  const handleOrgChanged = (state) => {
    const data = { value: state.value, field: 'orgName' };
    onStateChanged(data);
  };

  const handleFileChanged = (docType, uploadedFile) => {
    const data = { value: uploadedFile, field: docType };

    onStateChanged(data);
  };

  const handleFileDeleted = (docType) => {
    const data = { value: null, field: docType };

    onStateChanged(data);
  };

  const handleCertifyChanged = (event) => {
    const data = { value: event.target.checked, field: event.target.name };
    onStateChanged(data);
  };

  return (
    <>
      <Note className='p-2 mb-3 text-justify' id='primaryBuyer'>
        The Buyer's contact details will not be shared with the seller or seller's agent.
      </Note>
      <UserTypeRadios
        heading='The buyer is a:*'
        groupName='ownerType'
        selectedOption={primaryBuyer.ownerType}
        onStateChanged={handleUserTypeChange}
        error={errors?.primaryBuyer}
      />
      {primaryBuyer.ownerType === 'INDIVIDUAL' && (
        <User
          user={primaryBuyer.user}
          title='Buyer'
          onChanged={handleUserChanged}
          editable={true}
          errors={errors?.user}
        />
      )}
      {primaryBuyer.ownerType === 'CORPORATION' && (
        <Corporation
          name={primaryBuyer.orgName}
          affidavit={primaryBuyer.signerAffidavit}
          articles={primaryBuyer.articlesOfIncorporation}
          user={primaryBuyer.user}
          onUserChanged={handleUserChanged}
          onCorpChanged={handleOrgChanged}
          onFileChanged={handleFileChanged}
          onDeleteFile={handleFileDeleted}
          errors={errors?.user}
        />
      )}
      {primaryBuyer.ownerType === 'PARTNERSHIP' && (
        <Partnership
          name={primaryBuyer.orgName}
          affidavit={primaryBuyer.signerAffidavit}
          partnership={primaryBuyer.partnershipAgreement}
          user={primaryBuyer.user}
          onUserChanged={handleUserChanged}
          onCorpChanged={handleOrgChanged}
          onFileChanged={handleFileChanged}
          onDeleteFile={handleFileDeleted}
          errors={errors?.user}
        />
      )}
      {primaryBuyer.ownerType === 'TRUST' && (
        <Trust
          name={primaryBuyer.orgName}
          affidavit={primaryBuyer.signerAffidavit}
          user={primaryBuyer.user}
          onUserChanged={handleUserChanged}
          onCorpChanged={handleOrgChanged}
          onFileChanged={handleFileChanged}
          onDeleteFile={handleFileDeleted}
          errors={errors?.user}
        />
      )}
      {(primaryBuyer.ownerType === 'INDIVIDUAL' || primaryBuyer.ownerType === 'TRUST') &&
        secondaryBuyers?.map((buyer, index) => (
          <User
            key={index}
            user={buyer.user}
            title='Additional Buyer'
            onChanged={(user) => handleAdditionalBuyerChanged(user, index)}
            editable={true}
            removable
            onRemove={() => handleRemoveBuyer(index)}
          />
        ))}
      {(primaryBuyer.ownerType === 'INDIVIDUAL' || primaryBuyer.ownerType === 'TRUST') &&
        primaryBuyer.user &&
        primaryBuyer.user.firstName &&
        primaryBuyer.user.lastName &&
        primaryBuyer.user.phone &&
        primaryBuyer.user.email && <PrimaryBlueButton onClick={handleAddBuyer}>+ ADDITIONAL BUYER</PrimaryBlueButton>}
      <Divider className='mt-2 mx-n2 mx-md-n4' />
      <p className='my-2'>
        Upon completion of this offer, the buyer(s) will be sent an email and text confirming their information,
        inviting them to the platform and connecting them to you as their agent.
      </p>
      <CheckBox
        name='certifyBuyer'
        checked={!!primaryBuyer.certifyBuyer}
        onChange={handleCertifyChanged}
        error={errors?.certifyBuyer}
      >
        I certify, that the above buyer information is true and accurate.{' '}
        <Typography color='danger'>* Required</Typography>
      </CheckBox>
    </>
  );
}

export default OfferContact;
