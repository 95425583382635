import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

import moment from 'moment';

const hasNotLoggedIn7Days = (user) => {
  return user.lastLogin && moment().diff(moment(user.lastLogin), 'days') > 7;
};

const getOrder = (user) => {
  if (user.status === 'DELETED') {
    return 5;
  }
  if (user.status === 'PROVISIONAL') {
    return 4;
  }
  if (user.status === 'PASSIVE') {
    return 3;
  }
  if (!user.lastLogin && user.status === 'ACTIVE') {
    return 2;
  }
  if (user.lastLogin && user.status === 'ACTIVE') {
    return 1;
  }
  return 6;
};

function* listUsers() {
  const {
    ListUsers: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  try {
    const response = yield call(apiCall, '/admin/users', 'get');
    const users = response.data
      .map((user) => {
        if (hasNotLoggedIn7Days(user)) {
          user.status = 'PASSIVE';
        }
        return user;
      })
      .sort((a, b) => getOrder(a) - getOrder(b));
    yield put(SUCCESS(users));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default listUsers;
