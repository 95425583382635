import { createAsyncAction } from 'utils/store';

export const FetchQuestionnaire = createAsyncAction('agent', 'fetchQuestionnaire', {
  REQUEST: [],
  SUCCESS: ['questionnaire'],
});

export const RecordQuestionnaire = createAsyncAction('agent', 'recordQuestionnaire', {
  REQUEST: ['questionnaire'],
  SUCCESS: ['questionnaire'],
});

export const SendMessage = createAsyncAction('agent', 'sendMessage', {
  REQUEST: ['message'],
  SUCCESS: [],
});

export const AgentAsyncActions = { FetchQuestionnaire, RecordQuestionnaire, SendMessage };
