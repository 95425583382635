import styled from 'styled-components';

export const SidePanelContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 1007;
  overflow: auto;
`;
