import { all, takeLatest } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import listUsers from './listUsers';
import listAgents from './listAgents';
import listListings from './listListings';
import listContracts from './listContracts';
import fetchListingDetails from './fetchListingDetails.js';
import fetchOfferDashboard from './fetchOfferDashboard.js';
import fetchAgentQuestionnaire from './fetchAgentQuestionnaire';
import updateUserRole from './updateUserRole';
import uploadContract from './uploadContract';
import updateContract from './updateContract';
import resetPassword from './resetPassword';
import deleteUser from './deleteUser';

export default function* root() {
  const {
    ListUsers,
    ListAgents,
    ListListings,
    ListContracts,
    FetchAgentQuestionnaire,
    FetchListingDetails,
    FetchOfferDashboard,
    UpdateUserRole,
    UploadContract,
    UpdateContract,
    ResetPassword,
    DeleteUser,
  } = AdminAsyncActions;
  yield all([
    takeLatest(ListUsers.Types.REQUEST, listUsers),
    takeLatest(ListAgents.Types.REQUEST, listAgents),
    takeLatest(ListListings.Types.REQUEST, listListings),
    takeLatest(ListContracts.Types.REQUEST, listContracts),
    takeLatest(FetchAgentQuestionnaire.Types.REQUEST, fetchAgentQuestionnaire),
    takeLatest(FetchListingDetails.Types.REQUEST, fetchListingDetails),
    takeLatest(FetchOfferDashboard.Types.REQUEST, fetchOfferDashboard),
    takeLatest(UpdateUserRole.Types.REQUEST, updateUserRole),
    takeLatest(UploadContract.Types.REQUEST, uploadContract),
    takeLatest(UpdateContract.Types.REQUEST, updateContract),
    takeLatest(ResetPassword.Types.REQUEST, resetPassword),
    takeLatest(DeleteUser.Types.REQUEST, deleteUser),
  ]);
}
