import { call, put, select } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { getContracts } from 'store/selectors/admin';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* updateContract(action) {
  const {
    UpdateContract: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { listingId, docuSignSent, finalRpaUrl },
  } = action;

  try {
    const response = yield call(apiCall, 'admin/contract', 'put', { listingId, docuSignSent, finalRpaUrl });
    const newContracts = yield select(getContracts);

    yield put(
      SUCCESS(
        newContracts.map((contract) => {
          if (contract.listingId === listingId) {
            return { ...contract, docuSignSent: response.data.docuSignSent, finalRpaUrl: response.data.finalRpaUrl };
          }
          return contract;
        })
      )
    );
  } catch (error) {
    toast.error('Error occurred while updating contract');
    yield put(FAILURE(error));
  }
}

export default updateContract;
