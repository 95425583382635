import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

export const Header = styled.div`
  padding: 20px 30px;
  color: white;
  background-color: var(--offer1-blue);
`;

export const TableContent = styled.div`
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
`;

export const ItemStatus = styled.span`
  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.active ? 'var(--offer1-green)' : 'red')};
    margin-right: 8px;
  }
`;

export const ListingStatus = styled.span`
  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.state === 'Active' ? 'var(--offer1-green)' : props.state === 'Sold' ? 'var(--offer1-blue)' : 'grey'};
    margin-right: 8px;
  }
`;

export const AgentName = styled.div`
  color: var(--offer1-blue);
  text-decoration: underline;
  cursor: pointer;
`;

export const ListingAddress = styled.div`
  color: var(--offer1-blue);
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalHeader = styled(Modal.Header)`
  position: relative;
  display: block;
  width: 100%;
  padding: 24px;

  h3 {
    font-size: 32px;
    font-weight: bold;
    color: var(--offer1-green);
  }

  p {
    color: var(--gray2);
  }

  .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;

export const DropContainer = styled.div`
  padding: 10px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: var(--offer1-blue);
  border-style: dashed;
  background-color: #fafafa;
  color: var(--offer1-blue);
  outline: none;
  transition: border 0.24s ease-in-out;
`;
