export const listingSteps = [
  { number: 1, name: 'Property' },
  { number: 2, name: 'Seller Preferences' },
  { number: 3, name: 'Items' },
  { number: 4, name: 'Reports & Services' },
  { number: 5, name: 'Owners/Sellers' },
  // { number: 6, name: 'Seller Preferences' },
  { number: 6, name: 'Contract Addenda' },
  { number: 7, name: 'Review & Submit' },
];

export const listingReviewSteps = [
  { number: 1, name: 'Property' },
  { number: 2, name: 'Seller Preferences' },
  { number: 3, name: 'Items' },
  { number: 4, name: 'Reports & Services' },
  { number: 5, name: 'Owners/Sellers' },
  { number: 6, name: 'Contract Addenda' },
  { number: 7, name: 'Customize Listing URL' },
  { number: 8, name: 'Agent' },
];

export const propertyAddendaItemsCA = [
  { name: 'probateAgreement', label: 'Probate Agreement Purchase Addendum (C.A.R. form PA-PA)' },
  { name: 'manufacturedHome', label: 'Manufactured Home Purchase Addendum (C.A.R. form MH-PA)' },
  { name: 'tenancyInCommon', label: 'Tenancy in Common Purchase Addendum (C.A.R. form TIC-PA)' },
  { name: 'stockCooperative', label: 'Stock Cooperative Purchase Addendum (C.A.R form COOP-PA)' },
  {
    name: 'tenantOccupied',
    label:
      'Tenant Occupied Property Addendum (C.A.R. from TOPA) *Check this box whether current tenants will remain or not.',
  },
  { name: 'shortSale', label: 'Short Sale Addendum (C.A.R. form SSA)' },
  { name: 'courtConfirmation', label: 'Court Confirmation Addendum (C.A.R. form CCA)' },
  {
    name: 'septicWellPropertyMonumentPropane',
    label: 'Septic, Well, Property Monument, Propane (C.A.R form SWPI)',
  },
  { name: 'seller1031IntentToExchange', label: 'Seller 1031 Intent to Exchange Addendum (C.A.R. form SXA)' },
];

export const propertyAddendaItemsFL = [
  { name: 'condoRider', label: 'Condominium Rider' },
  { name: 'hoaRider', label: "Homeowners' Assn" },
  { name: 'shortsale', label: 'Short Sale' },
  { name: 'postClosingOccSeller', label: 'Post-Closing Occupancy' },
  {
    name: 'kickOutClause',
    label: 'Kick-out Clause',
  },
  {
    name: 'sellerAttorneyApproval',
    label: "Seller's Attorney Approval",
  },
  { name: 'licenseePropertyInterest', label: 'Licensee Property Interest' },
  {
    name: 'bindingArbitration',
    label: 'Binding Arbitration',
  },
  { name: 'miamiDadeCountySpecialTaxing', label: 'Miami-Dade County Special Taxing' },
  { name: 'other', label: 'Other' },
];

export const advisoriesItemsCA = [
  { name: 'stateWideBuyerAndSeller', label: 'Statewide Buyer and Seller Advisory (C.A.R. form SBSA)' },
  { name: 'wildfireDisaster', label: 'Wildfire Disaster Advisory (C.A.R form WDFA)' },
  { name: 'reo', label: 'REO Advisory (C.A.R. form REO)' },
  { name: 'shortSale', label: 'Short Sale Advisory (C.A.R. form SSIA)' },
  { name: 'trust', label: 'Trust Advisory (C.A.R. from TA)' },
];

export const advisoriesItemsFL = [
  { name: 'defectiveDrywall', label: 'Defective Drywall' },
  { name: 'coastalControlLine', label: 'Coastal Construction Control Line' },
  { name: 'insulationDisc', label: 'Insulation Disclosure' },
  { name: 'leadDisc', label: 'Lead Paint Disclosure (Pre-1978)' },
  { name: 'seniorCommunityDisc', label: 'Housing for Older Persons' },
  { name: 'paceDisc', label: 'Pace Disclosure' },
];

export const listingFilters = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Sold', value: 'sold' },
  { label: 'Cancelled', value: 'lost' },
];

export const listingFiltersLabel = listingFilters.map(({ label }) => label);

export const floridaCostAllocationItems = [
  { label: 'Documentary stamp taxes and surtax on deed, if any', payer: 'Seller' },
  { label: 'Charges for FIRPTA withholding and reporting', payer: 'Seller' },
  { label: 'HOA/Condominium Association estoppel fees', payer: 'Seller' },
  { label: 'Recording and other fees needed to cure title', payer: 'Seller' },
  { label: 'Seller’s attorneys’ fees', payer: 'Seller' },
  { label: 'Taxes and recording fees on notes and mortgages', payer: 'Buyer' },
  { label: 'Recording fees for deed and financing instruments', payer: 'Buyer' },
  { label: 'Survey (and elevation certification, if required)', payer: 'Buyer' },
  { label: 'Lender’s title policy and endorsements', payer: 'Buyer' },
  { label: 'Loan Expenses', payer: 'Buyer' },
  { label: 'Appraisal fees', payer: 'Buyer' },
  { label: 'Buyer’s inspections', payer: 'Buyer' },
  { label: 'Buyer’s attorneys’ fees', payer: 'Buyer' },
  { label: 'All property related insurance', payer: 'Buyer' },
];
