import React from 'react';
import { useSelector } from 'react-redux';
import { CurrencyField, RadioButtons, PercentField, FormField, DatePicker } from 'components';
import { getOwnershipTypes, getLoanTypes } from 'store/selectors/app';
import { useResponsive } from 'hooks';
import moment from 'moment';
import { isNil } from 'lodash';
import Swal from 'sweetalert2';
import { yesNoOptions as preapprovedOptions, daysToCloseOptions, purchaseOptions } from 'constants/optionConstants';
import { isDateValid, isDatePickerValid } from 'utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BuyerTerms({ selectedProfile, onStateChange, errors }) {
  const isMobile = useResponsive();
  const ownershipPurposes = useSelector(getOwnershipTypes);
  const loanTypes = useSelector(getLoanTypes);

  const onStateChanged = (e) => {
    const { field, value } = e;
    const newTerms = {
      ...selectedProfile.details.terms,
      [field]: value,
    };
    onStateChange(newTerms);
  };

  const onPurchaseMethodSelected = (event) => {
    const newTerms = { ...selectedProfile?.details?.terms, loan: event.target.value === 'loan' };
    onStateChange(newTerms);
  };

  const onDaysToCloseMethodSelected = (event) => {
    const newTerms = { ...selectedProfile?.details?.terms, useDaysToClose: event.target.value === 'day' };
    onStateChange(newTerms);
  };

  const onPreapprovedSelected = (event) => {
    const newTerms = {
      ...selectedProfile?.details?.terms,
      preapproved: event.target.value === 'yes',
    };
    onStateChange(newTerms);
  };

  const onRadioSelected = (event) => {
    const { name, value } = event.target;

    const newTerms = {
      ...selectedProfile?.details?.terms,
      [name]: value,
    };
    onStateChange(newTerms);
  };

  const onLoanTypeRadioSelected = (event) => {
    const { value } = event.target;
    const newTerms = {
      ...selectedProfile?.details?.terms,
      loanType: value,
    };

    onStateChange(newTerms);

    if (value === 'CARRY' || value === 'ASSUMPTION') {
      Swal.fire({
        text: 'This loan option requires seller approval, only select this option if the seller is aware and willing to cooperate.',
      });
    }
  };

  const onDownPaymentPctChange = (percentData) => {
    const dpAmt = (percentData.value * parseInt(selectedProfile?.details?.terms?.offerAmount)) / 100;
    const updatedState = {
      ...selectedProfile?.details?.terms,
      downPaymentPercentage: percentData.value,
      downPaymentDollar: dpAmt,
      loanAmountPercentage: 100 - percentData.value,
      loanAmountDollar: parseInt(selectedProfile?.details?.terms?.offerAmount) - dpAmt,
    };
    onStateChange(updatedState);
  };

  const onLoanAmountPctChange = (percentData) => {
    const dpAmt = Math.round((percentData.value * parseInt(selectedProfile?.details?.terms?.offerAmount)) / 100);
    const updatedState = {
      ...selectedProfile?.details?.terms,
      loanAmountPercentage: percentData.value,
      loanAmountDollar: dpAmt,
      downPaymentPercentage: 100 - percentData.value,
      downPaymentDollar: parseInt(selectedProfile?.details?.terms?.offerAmount) - dpAmt,
    };

    onStateChange(updatedState);
  };

  const onEarnestPctChange = (percentData) => {
    const dpAmt = (percentData.value * parseInt(selectedProfile?.details?.terms?.offerAmount)) / 100;
    const updatedState = {
      ...selectedProfile?.details?.terms,
      earnestDepositPercentage: percentData.value,
      earnestDepositDollar: dpAmt,
    };
    onStateChange(updatedState);
  };

  const onIncreasedPctChange = (percentData) => {
    const dpAmt = (percentData.value * parseInt(selectedProfile?.details?.terms?.offerAmount)) / 100;
    const updatedState = {
      ...selectedProfile?.details?.terms,
      increasedDepositPercentage: percentData.value,
      increasedDepositDollar: dpAmt,
    };
    onStateChange(updatedState);
  };

  return (
    <div>
      <div className='my-2' id='ownershipPurpose'>
        <label htmlFor='purchase-method' className='control-label'>
          Ownership Purpose
        </label>
        <RadioButtons
          items={ownershipPurposes}
          groupName='ownershipPurpose'
          selectedOption={selectedProfile?.details?.terms?.ownershipPurpose}
          onStateChanged={onRadioSelected}
          vertical={isMobile}
          error={errors?.ownershipPurpose}
        />
      </div>
      <Row className='my-2'>
        <Col sm={4}>
          <CurrencyField
            fieldId='offerAmount'
            label='Offer Amount'
            placeholder='$'
            value={selectedProfile?.details?.terms?.offerAmount}
            onChange={onStateChanged}
            error={errors?.offerAmount}
          />
        </Col>
      </Row>
      <div id='useDaysToClose'>
        <label htmlFor='days-to-close-method' className='control-label'>
          Closing Date
        </label>
        <RadioButtons
          items={daysToCloseOptions}
          groupName='days-to-close-method'
          selectedOption={
            selectedProfile?.details?.terms?.useDaysToClose === undefined ||
            selectedProfile?.details?.terms?.useDaysToClose === null
              ? ''
              : selectedProfile?.details?.terms?.useDaysToClose === true
              ? 'day'
              : 'date'
          }
          onStateChanged={onDaysToCloseMethodSelected}
          error={errors?.useDaysToClose}
        />
      </div>
      <Row className='my-2 ms-0'>
        <Col sm={3}>
          {selectedProfile?.details?.terms?.useDaysToClose && (
            <FormField
              type='number'
              fieldId='daysToClose'
              label=''
              initialValue={selectedProfile?.details?.terms?.daysToClose}
              onStateChanged={onStateChanged}
              error={errors?.daysToClose}
            />
          )}
          {!isNil(selectedProfile?.details?.terms?.useDaysToClose) &&
            !selectedProfile?.details?.terms?.useDaysToClose && (
              <DatePicker
                onStateChanged={onStateChanged}
                fieldId='exactClosingDate'
                label=''
                placeholder='MM/DD/YYYY'
                initialValue={
                  selectedProfile?.details?.terms?.exactClosingDate &&
                  (isDateValid(selectedProfile?.details?.terms?.exactClosingDate, 'YYYY-MM-DD')
                    ? moment(selectedProfile?.details?.terms?.exactClosingDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
                    : null)
                }
                validator={(value) => isDatePickerValid(value, 'Closing Date')}
                error={errors?.exactClosingDate}
              />
            )}
        </Col>
      </Row>
      <div className='my-2' id='loan'>
        <label htmlFor='purchase-method' className='control-label'>
          Method of Purchase
        </label>
        <RadioButtons
          items={purchaseOptions}
          groupName='purchase-method'
          selectedOption={
            selectedProfile?.details?.terms?.loan === undefined || selectedProfile?.details?.terms?.loan === null
              ? ''
              : selectedProfile?.details?.terms?.loan === true
              ? 'loan'
              : 'cash'
          }
          onStateChanged={onPurchaseMethodSelected}
          error={errors?.loan}
        />
      </div>
      <div className='ms-5 my-2' id='loanType'>
        {selectedProfile?.details?.terms?.loan && (
          <>
            <div className='ms-0'>
              <label htmlFor='purchase-method' className='control-label'>
                Type of Loan
              </label>
              <RadioButtons
                items={loanTypes}
                groupName='loanType'
                selectedOption={selectedProfile?.details?.terms?.loanType}
                onStateChanged={onLoanTypeRadioSelected}
                vertical={isMobile}
                error={errors?.loanType}
              />
            </div>
            <div className='ms-0 my-2' id='preapproved'>
              <label htmlFor='purchase-method' className='control-label'>
                Pre-approved
              </label>
              <RadioButtons
                items={preapprovedOptions}
                groupName='preapproved'
                selectedOption={
                  isNil(selectedProfile?.details?.terms?.preapproved)
                    ? ''
                    : selectedProfile?.details?.terms?.preapproved
                    ? 'yes'
                    : 'no'
                }
                onStateChanged={onPreapprovedSelected}
                error={errors?.preapproved}
              />
            </div>
          </>
        )}
        {selectedProfile?.details?.terms?.loan && (
          <>
            <Row className='align-items-end'>
              <Col sm={4}>
                <PercentField
                  fieldId='downPaymentPercentage'
                  label='Down Payment'
                  placeholder='%'
                  value={selectedProfile?.details?.terms?.downPaymentPercentage}
                  onChange={onDownPaymentPctChange}
                  error={errors?.downPaymentPercentage}
                />
              </Col>
              <Col sm={4}>
                <PercentField
                  fieldId='loanAmountPercentage'
                  label='Loan Amount'
                  placeholder='%'
                  value={selectedProfile?.details?.terms?.loanAmountPercentage}
                  onChange={onLoanAmountPctChange}
                  error={errors?.loanAmountPercentage}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className='my-2' id='emd'>
        <Row className='align-items-end ms-1 my-2'>
          <Col sm={4}>
            <PercentField
              fieldId='earnestDepositPercentage'
              label='Earnest Money and Initial Deposit'
              placeholder='%'
              value={selectedProfile?.details?.terms?.earnestDepositPercentage}
              onChange={onEarnestPctChange}
              error={errors?.earnestDepositPercentage}
            />
          </Col>
        </Row>
      </div>
      <div className='my-2' id='emd'>
        <Row className='align-items-end ms-1 my-2'>
          <Col sm={4}>
            <PercentField
              fieldId='increasedDepositPercentage'
              label='Additional Deposit'
              placeholder='%'
              value={selectedProfile?.details?.terms?.increasedDepositPercentage}
              onChange={onIncreasedPctChange}
              error={errors?.increasedDepositPercentage}
            />
          </Col>
        </Row>
      </div>
      {'' === 'CA' && (
        <FormField
          type='textarea'
          fieldId='additionalFinanceTerms'
          label='Additional Financial Terms'
          placeholder='(Optional)'
          initialValue={selectedProfile?.details?.terms?.additionalFinanceTerms ?? ''}
          onStateChanged={onStateChange}
          maxLength={170}
        />
      )}
    </div>
  );
}

export default BuyerTerms;
