import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Container, SectionTitle, SectionItem } from './offerCommon.styled';
import { isParamsNil } from 'utils';

function OfferContingencies({
  listingState,
  isSellerPreference = false,
  placeholder = false,
  sellerPreferenceOffer,
  offer,
  globalCounter,
  isEditing = false,
}) {
  const formatContingencies = useCallback(
    (isContingent, days, showDaysLabel = true) => {
      if (isParamsNil(isContingent)) {
        return isSellerPreference ? '' : 'Not Specified';
      }
      return isContingent ? 'Yes, ' + days + (showDaysLabel ? ' days' : '') : 'No';
    },
    [isSellerPreference]
  );

  const isEdit = useMemo(() => offer?.state === 'LIVE_EDIT', [offer]);

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle>{isSellerPreference && 'Home to Sell'}</SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              formatContingencies(
                offer.contingencies.contingentOnSale,
                moment(offer.contingencies.listingDate).format('MM/DD/YYYY'),
                false
              ) !==
                formatContingencies(
                  globalCounter.contingencies.contingentOnSale,
                  moment(globalCounter.contingencies.listingDate).format('MM/DD/YYYY'),
                  false
                )
            }
            isEdit={isEdit}
          >
            {!isSellerPreference &&
              !isEditing &&
              formatContingencies(
                offer.contingencies.contingentOnSale,
                moment(offer.contingencies.listingDate).format('MM/DD/YYYY'),
                false
              )}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.loan, sellerPreferenceOffer.loanDays) &&
              formatContingencies(sellerPreferenceOffer.loan, sellerPreferenceOffer.loanDays) ===
                formatContingencies(offer.contingencies.contingentOnLoan, offer.contingencies.daysLoan)
            }
          >
            {isSellerPreference && 'Loan'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              formatContingencies(offer.contingencies.contingentOnLoan, offer.contingencies.daysLoan) !==
                formatContingencies(globalCounter.contingencies.contingentOnLoan, globalCounter.contingencies.daysLoan)
            }
          >
            {isSellerPreference && formatContingencies(sellerPreferenceOffer.loan, sellerPreferenceOffer.loanDays)}
            {!isSellerPreference &&
              !isEditing &&
              formatContingencies(offer.contingencies.contingentOnLoan, offer.contingencies.daysLoan)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.appraisal, sellerPreferenceOffer.appraisalDays) &&
              formatContingencies(sellerPreferenceOffer.appraisal, sellerPreferenceOffer.appraisalDays) ===
                formatContingencies(offer.contingencies.contingentOnAppraisal, offer.contingencies.daysAppraisal)
            }
          >
            {isSellerPreference && 'Appraisal'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              formatContingencies(offer.contingencies.contingentOnAppraisal, offer.contingencies.daysAppraisal) !==
                formatContingencies(
                  globalCounter.contingencies.contingentOnAppraisal,
                  globalCounter.contingencies.daysAppraisal
                )
            }
          >
            {isSellerPreference &&
              formatContingencies(sellerPreferenceOffer.appraisal, sellerPreferenceOffer.appraisalDays)}
            {!isSellerPreference &&
              !isEditing &&
              formatContingencies(offer.contingencies.contingentOnAppraisal, offer.contingencies.daysAppraisal)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.inspection, sellerPreferenceOffer.inspectionDays) &&
              formatContingencies(sellerPreferenceOffer.inspection, sellerPreferenceOffer.inspectionDays) ===
                formatContingencies(offer.contingencies.contingentOnInspection, offer.contingencies.daysInspection)
            }
          >
            {isSellerPreference && 'Inspection'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              formatContingencies(offer.contingencies.contingentOnInspection, offer.contingencies.daysInspection) !==
                formatContingencies(
                  globalCounter.contingencies.contingentOnInspection,
                  globalCounter.contingencies.daysInspection
                )
            }
          >
            {isSellerPreference &&
              formatContingencies(sellerPreferenceOffer.inspection, sellerPreferenceOffer.inspectionDays)}
            {!isSellerPreference &&
              !isEditing &&
              formatContingencies(offer.contingencies.contingentOnInspection, offer.contingencies.daysInspection)}
            {isEditing && 'Details being edited'}
          </SectionItem>
          {listingState === 'CA' && (
            <>
              <SectionTitle
                match={
                  !isSellerPreference &&
                  !isParamsNil(sellerPreferenceOffer.daysRequiredOfBuyerReviewDisclosures) &&
                  sellerPreferenceOffer.daysRequiredOfBuyerReviewDisclosures ===
                    offer.daysRequiredOfBuyerReviewDisclosures
                }
              >
                {isSellerPreference && 'Days for Buyer to Review Seller Disclosures'}
              </SectionTitle>
              <SectionItem
                highlight={
                  globalCounter &&
                  offer.contingencies.daysRequiredOfBuyerReviewDisclosures !==
                    globalCounter.contingencies.daysRequiredOfBuyerReviewDisclosures
                }
              >
                {isSellerPreference &&
                  sellerPreferenceOffer.daysRequiredOfBuyerReviewDisclosures &&
                  `${sellerPreferenceOffer.daysRequiredOfBuyerReviewDisclosures} days`}
                {!isSellerPreference &&
                  !isEditing &&
                  offer.contingencies.daysRequiredOfBuyerReviewDisclosures &&
                  `${offer.contingencies.daysRequiredOfBuyerReviewDisclosures} days`}
                {isEditing && 'Details being edited'}
              </SectionItem>

              <SectionTitle
                match={
                  !isSellerPreference &&
                  !isParamsNil(sellerPreferenceOffer.daysBuyerInfoAccess) &&
                  sellerPreferenceOffer.daysBuyerInfoAccess === offer.daysBuyerInfoAccess
                }
              >
                {isSellerPreference && 'Informational Access to Property'}
              </SectionTitle>
              <SectionItem
                highlight={
                  globalCounter &&
                  offer.contingencies.daysBuyerInfoAccess !== globalCounter.contingencies.daysBuyerInfoAccess
                }
              >
                {isSellerPreference &&
                  sellerPreferenceOffer.daysBuyerInfoAccess &&
                  `${sellerPreferenceOffer.daysBuyerInfoAccess} days`}
                {!isSellerPreference &&
                  !isEditing &&
                  offer.contingencies.daysBuyerInfoAccess &&
                  `${offer.contingencies.daysBuyerInfoAccess} days`}
                {isEditing && 'Details being edited'}
              </SectionItem>
            </>
          )}
          {listingState === 'FL' && (
            <>
              <SectionTitle
                match={
                  !isSellerPreference &&
                  !isParamsNil(sellerPreferenceOffer.floodOrElevationDays) &&
                  sellerPreferenceOffer.floodOrElevationDays === offer.floodOrElevationDays
                }
              >
                {isSellerPreference && 'Days for Buyer to Discover Flood zone and Elevation concerns'}
              </SectionTitle>
              <SectionItem
                highlight={
                  globalCounter && offer.flData?.floodOrElevationDays !== globalCounter.flData?.floodOrElevationDays
                }
              >
                {isSellerPreference &&
                  sellerPreferenceOffer.floodOrElevationDays &&
                  `${sellerPreferenceOffer.floodOrElevationDays} days`}
                {!isSellerPreference &&
                  !isEditing &&
                  offer.flData?.floodOrElevationDays &&
                  `${offer.flData?.floodOrElevationDays} days`}
                {isEditing && 'Details being edited'}
              </SectionItem>
            </>
          )}
        </>
      )}
    </Container>
  );
}

OfferContingencies.propTypes = {
  listingState: PropTypes.string,
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  sellerPreferenceOffer: PropTypes.object,
  offer: PropTypes.object,
  globalCounter: PropTypes.object,
};
export default OfferContingencies;
