import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FastestValidator from 'fastest-validator';
import { RadioButtons, CheckBox, Icon } from 'components';
import { timeFrameOptions } from 'constants/optionConstants';
import {
  Container,
  HorizontalRule,
  Header,
  Subheader,
  DescriptionText,
  InputLabel,
  FormPartOne,
  FormPartTwo,
  FormPartThree,
  CheckContainer,
  Input,
  InputContainer,
  ContinueButton,
  ButtonsBox,
  ErrorMessage,
} from './registrationCard.styled';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const renderTooltip = (props) => (
  <Tooltip id='primary-seller-tooltip' className='text-start' {...props}>
    <p>
      The Primary Seller is the owner who will be "speaking for" all owners if there are multiple owners of the subject
      property.
    </p>
    <p>
      If there are multiple owners in the property all can be notified by adding them in the Listing Creation Owners
      section, but ONLY the Primary Seller will have the ability to accept or reject a listing or offer.
    </p>
    <p>It is critical that the correct Phone and Email address is provided.</p>
  </Tooltip>
);

function RegistrationCard() {
  let query = useQuery();

  const [error, setError] = useState({
    messages: [],
    fields: [],
  });

  const [registration, setRegistration] = useState({
    firstName: query.get('firstname'),
    lastName: query.get('lastname'),
    phone: '',
    email: query.get('email'),
    password: '',
    confirmPassword: '',
    timeframe: null,
    reasons: [],
    textMessages: false,
  });

  const updateField = useCallback(
    (field, value) => {
      setRegistration({ ...registration, [field]: value });
    },
    [registration]
  );

  const updateReasons = (value) => {
    const index = registration.reasons.indexOf(value);
    if (index === -1) {
      setRegistration({
        ...registration,
        reasons: [...registration.reasons, value],
      });
    } else {
      const newReasons = [...registration.reasons];
      newReasons.splice(index, 1);
      setRegistration({ ...registration, reasons: newReasons });
    }
  };

  const validator = new FastestValidator();
  const onRegister = (value) => {
    const validation = validator.validate(registration, {
      firstName: { type: 'string', trim: true, empty: false },
      lastName: { type: 'string', trim: true, empty: false },
      phone: { type: 'string', trim: true, empty: false }, //TODO?: Regex for phone number
      email: { type: 'email', normalize: true, empty: false },
      password: { type: 'string', min: '6' },
      confirmPassword: { type: 'string', min: '6' },
      timeframe: { type: 'string', enum: ['0-1', '1-3', '3-6', '6-12', '12+'] },
      reasons: {
        type: 'array',
        items: 'string',
        empty: false,
        enum: [
          'Need more space',
          'Closer to work',
          'Divorce / separation',
          'Need less space',
          'Schools',
          'Neighbors/Neighborhood',
          'Estate/Probate Sale',
          'Job Relocation',
          'Upgrading Layout',
          'New Marriage',
          'New Born',
          'Upgrading Condition',
        ],
      },
      textMessages: 'boolean',
    });

    const passwordCheck = registration.password === registration.confirmPassword;

    if (validation === true && passwordCheck) {
      setError({ messages: [], fields: [] });
      //TODO: Access API to finish registratioon
    } else {
      const errorMessages = [];
      const errorFields = [];
      if (!passwordCheck) {
        errorMessages.push('Passwords do not match.');
        errorFields.push('confirmPassword');
      }
      validation.forEach((error) => {
        switch (error.field) {
          case 'timeframe':
            errorMessages.push('Must select an ideal timeframe for selling.');
            break;
          case 'reasons':
            errorMessages.push('Must select at least one reason for selling.');
            break;
          default:
            break;
        }
        errorFields.push(error.field);
      });
      setError({ messages: errorMessages, fields: errorFields });
    }
  };

  function ReasonCheckbox({ reason }) {
    return (
      <CheckContainer>
        <CheckBox name={reason} checked={registration.reasons.includes(reason)} onChange={() => updateReasons(reason)}>
          <p>{reason}</p>
        </CheckBox>
      </CheckContainer>
    );
  }

  return (
    <Container>
      <Header>Hello {query.get('firstname')} </Header>
      <HorizontalRule />
      <DescriptionText topMargin rightMargin>
        A local agent, <b>{query.get('agentname')}</b>, has invited you to utilize the Offer1 Platform to sell your
        home. This platform is the only method that guarantees you will see and be able to easily understand and compare
        all of the offers on your home.
      </DescriptionText>
      <Subheader>Please verify we have all the correct and best contact info:</Subheader>
      <div className='d-flex align-items-baseline'>
        <DescriptionText larger>Primary Seller</DescriptionText>
        <OverlayTrigger placement='bottom' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
          <Icon name='exclamation-circle' />
        </OverlayTrigger>
        <HorizontalRule />
      </div>
      <FormPartOne>
        <InputContainer>
          <InputLabel>First Name</InputLabel>
          <Input
            error={error.fields.includes('firstName')}
            onChange={(event) => updateField('firstName', event.target.value)}
            value={registration.firstName}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Last Name</InputLabel>
          <Input
            error={error.fields.includes('lastName')}
            onChange={(event) => updateField('lastName', event.target.value)}
            value={registration.lastName}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Mobile</InputLabel>
          <Input
            error={error.fields.includes('phone')}
            onChange={(event) => updateField('phone', event.target.value)}
            value={registration.phone}
            placeholder='(xxx) xxx-xxxx'
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Email Address</InputLabel>
          <Input
            error={error.fields.includes('email')}
            type='email'
            onChange={(event) => updateField('email', event.target.value)}
            value={registration.email}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Create Password</InputLabel>
          <Input
            error={error.fields.includes('password')}
            type='password'
            onChange={(event) => updateField('password', event.target.value)}
            value={registration.password}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Confirm Password</InputLabel>
          <Input
            error={error.fields.includes('confirmPassword')}
            type='password'
            onChange={(event) => updateField('confirmPassword', event.target.value)}
            value={registration.confirmPassword}
          />
        </InputContainer>
      </FormPartOne>
      <Subheader>What is the ideal timeframe in which you are looking to sell?</Subheader>
      <FormPartTwo>
        <RadioButtons
          items={timeFrameOptions}
          groupName='newMethods'
          selectedOption={registration.timeframe}
          onStateChanged={(event) => updateField('timeframe', event.target.value)}
        />
      </FormPartTwo>
      <Subheader>What would you say is the primary reason you are selling?</Subheader>
      <FormPartThree>
        <ReasonCheckbox reason='Need more space' />
        <ReasonCheckbox reason='Closer to work' />
        <ReasonCheckbox reason='Divorce / separation' />
        <ReasonCheckbox reason='Need less space' />
        <ReasonCheckbox reason='Schools' />
        <ReasonCheckbox reason='Neighbors/Neighborhood' />
        <ReasonCheckbox reason='Estate/Probate Sale' />
        <ReasonCheckbox reason='Job Relocation' />
        <ReasonCheckbox reason='Upgrading Layout' />
        <ReasonCheckbox reason='New Marriage' />
        <ReasonCheckbox reason='New Born' />
        <ReasonCheckbox reason='Upgrading Condition' />
      </FormPartThree>
      <DescriptionText topMargin rightMargin>
        If you wish to make any changes to your contact information including changing your agent, you are free to do
        this under your account page on your dashboard.
      </DescriptionText>
      <CheckContainer margins smallText>
        <CheckBox
          name='textMessages'
          checked={registration.textMessages}
          onChange={() => updateField('textMessages', !registration.textMessages)}
        >
          <p>
            Please check the box if you would like to receive text notifications from Offer1 that are critical to your
            transaction, you may incur charges from your phone carrier depending on your plan.
          </p>
        </CheckBox>
      </CheckContainer>
      <ErrorMessage>
        {error.messages.map((message) => (
          <span>
            {message}
            <br />
          </span>
        ))}
      </ErrorMessage>
      <ButtonsBox>
        <ContinueButton onClick={onRegister}>CONTINUE</ContinueButton>
      </ButtonsBox>
    </Container>
  );
}

export default RegistrationCard;
