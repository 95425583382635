import styled from 'styled-components';
import BootstrapProgressBar from 'react-bootstrap/ProgressBar';

export const ProgressBar = styled(BootstrapProgressBar)`
  margin-bottom: 4px;
  max-width: 100%;
  width: 400px;
  height: 3px;
  border-radius: 3px;
  > div {
    background-color: var(--offer1-green);
  }
`;

export const FileMetaContainer = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: var(--offer1-black);
  display: flex;
  align-items: baseline;
  padding-bottom: 8px;
`;

export const MiddleDot = styled.span`
  color: var(--gray9);
`;

export const FileSize = styled.span`
  font-size: 10px;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.7);
`;
