import { createSelector } from 'reselect';

export const getState = (state) => state.buyers;

export const getBuyers = createSelector(getState, ({ buyers }) => buyers);

export const getBuyer = createSelector(getState, ({ buyer }) => buyer ?? []);

export const getBuyerOffers = createSelector(getState, ({ offers }) => offers ?? []);

export const getBuyerProfile = createSelector(getState, ({ buyerProfile }) => buyerProfile ?? []);

export const getBuyerProfiles = createSelector(getState, ({ buyerProfiles }) => buyerProfiles ?? []);
