import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const EmptyContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--gray5);
  padding: 100px 16px;

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: 20px;
    }
  `}
`;

export const EmptyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 305px;
  margin-left: 0px;

  ${breakpoint('desktop')} {
    margin-left: 20px;
  }
`;

export const EmptyHeader = styled.span`
  color: var(--offer1-blue);
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
`;

export const EmptyDescription = styled.span`
  margin-top: 6px;
  color: var(--offer1-black);
  font-size: 16px;
  line-height: 20px;
`;
