import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, SectionTitle, SectionItem } from './offerCommon.styled';
import { capitalize } from 'lodash';
import { formatCurrency, isParamsNil } from 'utils';

function OfferServices({
  isSellerPreference = false,
  placeholder = false,
  sellerPreferenceOffer,
  offer,
  globalCounter,
  listingState,
  isEditing = false,
}) {
  const formatPayer = useCallback(
    (payer) => {
      if (!isSellerPreference && isParamsNil(payer)) {
        return '';
      }
      return payer ? (payer === 'PAY_OWN' ? 'Each Pay Own' : `${capitalize(payer)} Pays`) : '';
    },
    [isSellerPreference]
  );

  const getCompanyName = useCallback((company) => {
    return company ? company.name : 'Company Not Specified';
  }, []);

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          {listingState === 'CA' && (
            <SectionTitle
              match={
                !isSellerPreference &&
                !isParamsNil(sellerPreferenceOffer.naturalHazard) &&
                formatPayer(sellerPreferenceOffer.naturalHazard) === formatPayer(offer.services?.hazardPayer)
              }
            >
              {isSellerPreference && 'Natural Hazard'}
            </SectionTitle>
          )}
          {listingState === 'FL' && <SectionTitle>{isSellerPreference && 'Confirmation of Services'}</SectionTitle>}
          {listingState === 'CA' && (
            <SectionItem
              highlight={
                globalCounter &&
                formatPayer(offer.services?.hazardPayer) !== formatPayer(globalCounter.services?.hazardPayer)
              }
            >
              {isSellerPreference &&
                !isParamsNil(sellerPreferenceOffer.naturalHazard) &&
                formatPayer(sellerPreferenceOffer.naturalHazard)}
              {!isSellerPreference && !isEditing && (
                <>
                  <div>Hazard: {formatPayer(offer.services?.hazardPayer)}</div>
                  <div>{getCompanyName(offer.services?.hazardCompany)}</div>
                </>
              )}
              {isEditing && 'Details being edited'}
            </SectionItem>
          )}
          {listingState === 'FL' && (
            <SectionItem
              highlight={
                globalCounter && offer.services?.confirmationServices !== globalCounter.services?.confirmationServices
              }
            >
              {!isSellerPreference && !isEditing && (
                <>
                  <div>
                    {offer.services?.confirmationServices === 'SELLER'
                      ? 'Seller'
                      : offer.services?.confirmationServices === 'BUYER'
                      ? 'Buyer'
                      : offer.services?.confirmationServices === 'BOTH'
                      ? 'Miami-Dade/Broward Provisional'
                      : ''}
                  </div>
                </>
              )}
              {isEditing && 'Details being edited'}
            </SectionItem>
          )}
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(
                sellerPreferenceOffer.homeWarrantyResponsibility,
                sellerPreferenceOffer.homeWarrantyMaxAmount
              ) &&
              formatPayer(sellerPreferenceOffer.homeWarrantyResponsibility) ===
                formatPayer(offer.services?.warrantyPayer) &&
              sellerPreferenceOffer.homeWarrantyMaxAmount === offer.services?.warrantyMaxCost
            }
          >
            {isSellerPreference && 'Home Warranty'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              (formatPayer(offer.services?.warrantyPayer) !== formatPayer(globalCounter.services?.warrantyPayer) ||
                formatCurrency(offer.services?.warrantyMaxCost ?? 0) !==
                  formatCurrency(globalCounter.services?.warrantyMaxCost ?? 0))
            }
          >
            {isSellerPreference &&
              !isParamsNil(
                sellerPreferenceOffer.homeWarrantyResponsibility,
                sellerPreferenceOffer.homeWarrantyMaxAmount
              ) &&
              sellerPreferenceOffer.homeWarranty && (
                <>
                  <div>{formatPayer(sellerPreferenceOffer.homeWarrantyResponsibility)}</div>
                  <div>Not to Exceed {formatCurrency(sellerPreferenceOffer.homeWarrantyMaxAmount ?? 0)}</div>
                </>
              )}
            {!isSellerPreference && !isEditing && (
              <>
                <div>{formatPayer(offer.services?.warrantyPayer)}</div>
                <div>Not to Exceed {formatCurrency(offer.services?.warrantyMaxCost ?? 0)}</div>
              </>
            )}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.ownerTitleInsurance) &&
              formatPayer(sellerPreferenceOffer.ownerTitleInsurance) === formatPayer(offer.services.titlePayer)
            }
          >
            {isSellerPreference && "Owner's Title Insurance"}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              formatPayer(offer.services?.titlePayer) !== formatPayer(globalCounter.services?.titlePayer)
            }
          >
            {isSellerPreference && formatPayer(sellerPreferenceOffer.ownerTitleInsurance)}
            {!isSellerPreference && !isEditing && (
              <>
                <div>Title: {formatPayer(offer.services.titlePayer)}</div>
                <div>{getCompanyName(offer.services.titleCompany)}</div>
              </>
            )}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle
            match={
              !isSellerPreference &&
              !isParamsNil(sellerPreferenceOffer.escrow) &&
              formatPayer(sellerPreferenceOffer.escrow) === formatPayer(offer.services.escrowPayer)
            }
          >
            {isSellerPreference && 'Escrow'}
          </SectionTitle>
          <SectionItem
            highlight={
              globalCounter &&
              formatPayer(offer.services.escrowPayer) !== formatPayer(globalCounter.services.escrowPayer)
            }
          >
            {isSellerPreference && formatPayer(sellerPreferenceOffer.escrow)}
            {!isSellerPreference && !isEditing && (
              <>
                <div>Escrow: {formatPayer(offer.services.escrowPayer)}</div>
                <div>{getCompanyName(offer.services.escrowCompany)}</div>
              </>
            )}
            {isEditing && 'Details being edited'}
          </SectionItem>
        </>
      )}
    </Container>
  );
}

OfferServices.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  sellerPreferenceOffer: PropTypes.object,
  offer: PropTypes.object,
  globalCounter: PropTypes.object,
};
export default OfferServices;
