import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ICON_BLUE } from 'constants/styleConstants';
import { LabelText, ValueText } from './viewField.styled';

function ViewField({ label, value, icon: TheIcon, children }) {
  return (
    <Container className='m-0 p-0'>
      <Row className='m-0 p-0'>
        {TheIcon && (
          <Col className='d-flex align-items-center p-0'>
            <TheIcon size={40} style={{ fill: ICON_BLUE }} />
          </Col>
        )}
        <Col className='d-flex flex-column'>
          <LabelText>{label}</LabelText>
          <ValueText>{value}</ValueText>
          {children}
        </Col>
      </Row>
    </Container>
  );
}

export default ViewField;
