import { call, put } from 'redux-saga/effects';
import { ListingsAsyncActions } from 'store/actions/listings';
import { apiCall } from 'utils/apiCall';

function* fetchDetails(action) {
  const {
    FetchListingDetails: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ListingsAsyncActions;
  const {
    payload: { listingId },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/view/${listingId}`, 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchDetails;
