import { call, put } from 'redux-saga/effects';
import { AgentAsyncActions } from 'store/actions/agent';
import { apiCall } from 'utils/apiCall';
import Swal from 'sweetalert2';

function* recordAgentQuestionnaire(action) {
  const {
    RecordQuestionnaire: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AgentAsyncActions;
  const {
    payload: { questionnaire },
  } = action;

  try {
    const response = yield call(apiCall, '/agents/questionnaire', 'post', questionnaire);
    Swal.fire({
      title: 'Your Listing Agent Application Has Been Submitted Successfully.',
      text: 'Please check emails for updates from OFFER1',
      icon: 'success',
      confirmButtonText: 'OK',
    });
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default recordAgentQuestionnaire;
