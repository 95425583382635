import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { breakpoint } from 'styled-components-breakpoint';

export const UpdateText = styled.p`
  font-size: 18px;
  color: ${(props) => (props.error ? 'red' : 'var(--offer1-green)')};
  margin-bottom: 0;

  ${breakpoint('mobile', 'desktop')`
    margin-top: 16px;
  `}
`;

export const TabItem = styled.span`
  color: ${(props) => (!props.active ? 'black' : 'var(--offer1-blue)')};
  ${(props) => (props.active ? `border-bottom: 3px solid var(--offer1-blue)` : `padding-bottom: 3px`)};
  font-size: 20px;
  padding: 0px 15px 10px;
  text-align: center;
  cursor: pointer;
`;

export const StyledTabWrap = styled.div`
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active,
  .open > .dropdown-toggle.btn-primary {
    background-color: transparent;
    color: var(--offer1-blue);
    border-color: var(--offer1-blue);
  }
  button {
    width: 100%;
  }
  button + button {
    margin-left: 6px;
  }
`;

const tabActive = `
  background-color: transparent;
  color: var(--offer1-blue);
  border-color: var(--offer1-blue);
`;
const noneTabActive = `
  color: var(--gray7);
  border-color: var(--gray7);
`;

export const TabButton = styled(Button)`
  background-color: transparent;
  color: var(--gray7);
  border-color: var(--gray7);
  //${(props) => (props.active ? tabActive : noneTabActive)};
`;
