import React, { useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Typography } from 'components';
import { capitalizeFirstLetter } from 'utils/formattingUtils';
import { useResponsive } from 'hooks';

function OfferCostAllocationTable({ costAllocations }) {
  const isOneColumn = useResponsive('lg');

  const responsibilities = useMemo(() => {
    return !costAllocations?.stateItems && !costAllocations?.additionalItems
      ? []
      : [
          ...(costAllocations?.stateItems ?? []).filter(
            (stateItem) =>
              stateItem.responsiblePayer === 'SELLER' ||
              stateItem.responsiblePayer === 'BUYER' ||
              stateItem.responsiblePayer === 'BOTH'
          ),
          ...(costAllocations?.additionalItems
            ? costAllocations.additionalItems.reduce((cur, allocation) => {
                if (
                  allocation.responsiblePayer === 'SELLER' ||
                  allocation.responsiblePayer === 'BUYER' ||
                  allocation.responsiblePayer === 'BOTH'
                ) {
                  return [...cur, { ...allocation, fieldId: allocation.item, item: allocation.item }];
                }
                return cur;
              }, [])
            : []),
        ];
  }, [costAllocations]);

  const renderTable = (items = []) => {
    if (!items.length) {
      return null;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cost</th>
            <th>Allocation</th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ fieldId, item, responsiblePayer }) => (
            <tr key={`${fieldId}-${responsiblePayer}`}>
              <td>{item}</td>
              <td>
                <Typography
                  bold
                  color={
                    responsiblePayer === 'BUYER' ? 'offer1-blue' : responsiblePayer === 'SELLER' ? 'offer1-green' : ''
                  }
                >
                  {responsiblePayer !== 'BOTH' ? capitalizeFirstLetter(responsiblePayer) : 'Split 50/50'}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Row>
      <Col md={12} lg={6}>
        {renderTable(
          isOneColumn ? responsibilities : responsibilities.slice(0, Math.ceil(responsibilities.length / 2))
        )}
      </Col>
      {!isOneColumn && (
        <Col md={12} lg={6}>
          {renderTable(responsibilities.slice(Math.ceil(responsibilities.length / 2)))}
        </Col>
      )}
    </Row>
  );
}

export default OfferCostAllocationTable;
