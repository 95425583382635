import { createSelector } from 'reselect';

export const getState = (state) => state.listings;

export const getListing = createSelector(getState, ({ listing }) => listing);

export const getListingDetails = createSelector(getState, ({ listingDetails }) => listingDetails ?? {});

export const getListings = createSelector(getState, ({ listings }) => listings);

export const getSearchListings = createSelector(getState, ({ searchListings }) => searchListings);

export const isListingsLiked = createSelector(getState, ({ listingsLiked }) => listingsLiked);

export const getStateIncludedItems = createSelector(getState, ({ stateIncludedItems }) => stateIncludedItems);

export const getLoanTypes = createSelector(getState, ({ loanTypes }) => loanTypes);
