import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/selectors/auth';

const hasRole = (roles, name) => roles?.some((role) => role.name === name);

const useAuthUser = (props) => {
  const { listing, offer } = props || {};
  const user = useSelector(getCurrentUser);

  const isAgent = useMemo(() => {
    return hasRole(user?.roles, 'AGENT');
  }, [user]);

  const isListingAgent = useMemo(() => {
    return hasRole(user?.roles, 'LISTING');
  }, [user]);

  const isAdmin = useMemo(() => {
    return hasRole(user?.roles, 'ADMIN');
  }, [user]);

  const isSellerAgent = useMemo(() => {
    return user?.id && user.id === listing?.listingAgent?.id;
  }, [user, listing]);

  const isBuyerAgent = useMemo(() => {
    return user?.id && user.id === offer?.agent?.id;
  }, [user, offer]);

  const isOfferBuyer = useMemo(() => {
    return (
      user?.id &&
      (offer?.primaryBuyer?.user?.id === user.id || offer?.secondaryBuyers?.some((buyer) => buyer.user.id === user.id))
    );
  }, [offer, user]);

  const isPrimaryOwner = useMemo(() => {
    return (
      user?.id &&
      (user.id === listing?.primaryOwner?.user?.id ||
        (listing?.secondaryOwners || []).some((owner) => owner.user.id === user.id))
    );
  }, [user, listing]);

  const isSecondaryOwner = useMemo(() => {
    return (listing?.secondaryOwners || []).some((owner) => owner.user.id === user?.id);
  }, [user, listing]);

  return {
    isAgent,
    isListingAgent,
    isAdmin,
    isSellerAgent,
    isBuyerAgent,
    isOfferBuyer,
    isPrimaryOwner,
    isSecondaryOwner,
    user,
  };
};

export default useAuthUser;
