import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormField,
  CurrencyField,
  PercentField,
  RadioButtons,
  PropertyItem,
  CheckBox,
  Tooltip,
  Divider,
  Typography,
  IncludedItemsPanel,
} from 'components';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RpaItem29Tooltip, RpaItem31Tooltip } from './rpaTooltip';
import { yesNoOptions as requestSellerOptions } from 'constants/optionConstants';
import { useDispatch, useSelector } from 'react-redux';
import { ListingsAsyncActions } from 'store/actions/listings';
import { getStateIncludedItems } from 'store/selectors/listings';

function OfferCreditItems(props) {
  const {
    listingState,
    creditItems,
    listingPrice,
    offerRpaBreachContract,
    offerRpaDisputeResolution,
    listingRpaBreachContract,
    listingRpaDisputeResolution,
    onRpaUpdate,
    listing,
    errors,
    isCounter,
  } = props;

  const getSelectedValue = (selectOption) => {
    return selectOption === undefined || selectOption === null ? '' : selectOption ? 'yes' : 'no';
  };

  const dispatch = useDispatch();
  const stateIncludedOptions = useSelector(getStateIncludedItems);
  useEffect(() => {
    if (listing?.address?.state) {
      dispatch(
        ListingsAsyncActions.FetchStateIncludedItems.Actions.REQUEST(listing.address.state, listing.address.city)
      );
    }
  }, [dispatch, listing?.address?.state, listing?.address?.city]);

  const onRadioSelected = (event) => {
    const data = {
      value: event.target.value === 'yes',
      field: event.target.name,
    };
    onStateChanged(data);
  };

  const onClosingCostCreditDollarChange = (dollarData) => {
    if (!isNaN(listingPrice)) {
      const dpPct = (dollarData.value / listingPrice) * 100;
      const updatedState = {
        ...creditItems,
        closingCostCreditPercentage: dpPct,
        closingCostCreditDollar: parseFloat(dollarData.value),
      };
      props.onStateChanged(updatedState);
    }
  };

  const onClosingCostCreditPctChange = (percentData) => {
    if (!isNaN(listingPrice)) {
      const dpAmt = (percentData.value * listingPrice) / 100;
      const updatedState = {
        ...creditItems,
        closingCostCreditPercentage: percentData.value,
        closingCostCreditDollar: dpAmt,
      };
      props.onStateChanged(updatedState);
    }
  };

  const onStateChanged = (data) => {
    const updatedCreditItems = { ...creditItems, [data.field]: data.value };
    props.onStateChanged(updatedCreditItems);
  };

  const onClickRemoveIncludedItem = (index) => {
    const includedItems = [...creditItems.includedItems];
    includedItems.splice(index, 1);
    onStateChanged({
      field: 'includedItems',
      value: includedItems,
    });
  };

  const onClickRemoveExcludedItem = (index) => {
    const excludedItems = [...creditItems.excludedItems];
    excludedItems.splice(index, 1);
    onStateChanged({
      field: 'excludedItems',
      value: excludedItems,
    });
  };

  const onAddIncludedItem = () => {
    if (creditItems.additionalIncludedPersonalProperty && creditItems.includedItems) {
      props.onStateChanged({
        ...creditItems,
        includedItems: [...(creditItems.includedItems || []), { name: creditItems.additionalIncludedPersonalProperty }],
        additionalIncludedPersonalProperty: '',
      });
    }
  };

  const handleAddStateItemsToList = (newItems) => {
    onStateChanged({
      field: 'includedStateSpecifiedItems',
      value: newItems,
    });
  };

  return (
    <>
      {listing?.address?.state === 'CA' && (
        <div id='requestSeller'>
          <label htmlFor='requestSeller' className='control-label'>
            1) Are you requesting the seller to pay any of your closing costs or provide other credits?*
          </label>
          <RadioButtons
            items={requestSellerOptions}
            groupName='requestSeller'
            selectedOption={getSelectedValue(creditItems?.requestSeller)}
            onStateChanged={onRadioSelected}
            error={errors?.requestSeller}
          />
        </div>
      )}
      {creditItems?.requestSeller && (
        <>
          <Row className='ms-2 align-items-end'>
            <Col sm={5}>
              <CurrencyField
                fieldId='closingCostCreditDollar'
                placeholder='$'
                label='Buyer requesting closing cost credit of'
                value={creditItems.closingCostCreditDollar}
                onChange={onClosingCostCreditDollarChange}
                error={errors?.closingCostCreditDollar}
              />
            </Col>
            <Col sm={5}>
              <PercentField
                fieldId='closingCostCreditPercentage'
                placeholder='%'
                value={creditItems.closingCostCreditPercentage}
                onChange={onClosingCostCreditPctChange}
                error={errors?.closingCostCreditPercentage}
              />
            </Col>
          </Row>
          <Divider className='mx-n2 mx-md-n4 my-2' />
        </>
      )}
      <Typography as='h4' size={28}>
        Property Items
      </Typography>
      <Typography as='p'>
        * If existing on the property at the submission of this offer, the following items are included in the sale. As
        a rule personal property is not included in the sale.
      </Typography>
      <Row>
        <Col md={4}>
          <p>
            * <u>Included Items</u>
          </p>
          {!creditItems?.includedStateSpecifiedItems?.length && !stateIncludedOptions?.length && (
            <span className='text-muted'>No items included</span>
          )}
          <IncludedItemsPanel
            stateIncludedOptions={stateIncludedOptions}
            onSelected={handleAddStateItemsToList}
            includedOptions={creditItems.includedStateSpecifiedItems}
            listingState={listing.address.state}
            showHeader={false}
            blockUncheck={listingState === 'FL'}
          />
        </Col>
        <Col md={4}>
          <p>
            <u>Additional Included Items</u>
          </p>
          {!creditItems?.includedItems?.length && <span className='text-muted'>No Additional items included</span>}
          {creditItems?.includedItems?.map((property, index) => (
            <PropertyItem
              key={`${property.name}-${index}`}
              name={property.name}
              onClickRemove={() => onClickRemoveIncludedItem(index)}
            />
          ))}
        </Col>
        <Col md={4}>
          <p>
            <u>Excluded Items</u>
          </p>
          {!creditItems?.excludedItems?.length ? (
            <span className='text-muted'>No items excluded</span>
          ) : (
            creditItems.excludedItems.map((property, index) => (
              <PropertyItem
                key={`${property.name}-${index}`}
                name={property.name}
                onClickRemove={() => onClickRemoveExcludedItem(index)}
              />
            ))
          )}
        </Col>
      </Row>
      <div className='d-flex align-items-end'>
        <FormField
          fieldId='additionalIncludedPersonalProperty'
          label={
            listing?.address?.state === 'FL'
              ? 'List additional personal property items you would like included in the sale'
              : '2) List additional personal property items you would like included in the sale'
          }
          placeholder='Name and location of items'
          initialValue={creditItems.additionalIncludedPersonalProperty}
          onStateChanged={onStateChanged}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onAddIncludedItem();
            }
          }}
        />
        <Button className='ms-1' style={{ marginBottom: '0.85em' }} onClick={onAddIncludedItem}>
          ＋ ITEM
        </Button>
      </div>
      {listingState === 'CA' && (
        <>
          <Divider className='mx-n2 mx-md-n4 my-2' />
          <Typography as='h4' size={28}>
            Agreement Clauses
          </Typography>
          {(listingRpaBreachContract || listingRpaDisputeResolution) && (
            <p>
              The seller has indicated the below contract clauses are required therefore they have already been checked
              to be included in your offer
            </p>
          )}
          <div className='d-flex my-2'>
            <CheckBox
              name='rpaBreachContract'
              checked={!!offerRpaBreachContract}
              onChange={() => {
                onRpaUpdate({
                  field: 'rpaBreachContract',
                  value: offerRpaBreachContract ? !offerRpaBreachContract : true,
                });
              }}
            >
              Buyer and Seller to initial item 29 in the CA contract "Liquidated Damages Clause"
            </CheckBox>
            <Tooltip placement='left' overlay={RpaItem29Tooltip} trigger='click' />
          </div>
          <div className='d-flex'>
            <CheckBox
              name='rpaDisputeResolution'
              checked={!!offerRpaDisputeResolution}
              onChange={() => {
                onRpaUpdate({
                  field: 'rpaDisputeResolution',
                  value: offerRpaDisputeResolution ? !offerRpaDisputeResolution : true,
                });
              }}
            >
              Buyer and Seller to initial item 31 in the CA contract "Arbitration of Disputes"
            </CheckBox>
            <Tooltip placement='left' overlay={RpaItem31Tooltip} trigger='click' />
          </div>
          <FormField
            type='textarea'
            fieldId='creditItemsComments'
            label='Additional Comments on Included Items: (Comments are visible to all parties and will not be a part of any agreement)'
            placeholder='(Optional)'
            maxLength={500}
            initialValue={creditItems.creditItemsComments}
            onStateChanged={onStateChanged}
            error={errors?.creditItemsComments}
            editable={!isCounter}
          />
        </>
      )}
    </>
  );
}

OfferCreditItems.propTypes = {
  listingState: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  creditItems: PropTypes.object,
  listingPrice: PropTypes.number,
  onStateChanged: PropTypes.func,
  onCloseModal: PropTypes.func,
  offerRpaBreachContract: PropTypes.bool,
  offerRpaDisputeResolution: PropTypes.bool,
  listingRpaBreachContract: PropTypes.bool,
  listingRpaDisputeResolution: PropTypes.bool,
};

export default OfferCreditItems;
