import React from 'react';
import PropTypes from 'prop-types';
import NavStep from './navStep';

function CreateLeftNavbar({ title, steps, currentStep, onNavigate, clickable = false }) {
  return (
    <div className='m-4'>
      <h4>{title}</h4>
      <div className='d-flex align-items-start flex-column mt-4'>
        {steps.map((step) => (
          <NavStep
            key={step.number}
            stepNumber={step.number}
            stepName={step.name}
            active={currentStep === step.number}
            onClick={() => currentStep !== step.number && onNavigate && onNavigate(step.number)}
            clickable={clickable}
          />
        ))}
      </div>
    </div>
  );
}

CreateLeftNavbar.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  onNavigate: PropTypes.func,
  clickable: PropTypes.bool,
};

export default CreateLeftNavbar;
