import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import Dropzone from './dropzone';
import * as FaIcons from 'react-icons/fa';
import { FileName } from './file.styled';
import { uploadFile } from 'services/client';
import { Icon } from 'components';
import { URLUtils, sanitizeFileName } from 'utils';
import Swal from 'sweetalert2';

const FileUpload = ({
  id,
  icon,
  width,
  height,
  type,
  title,
  className,
  showDropMessage,
  file,
  deleteFile,
  onFileSelected,
  readOnly = false,
}) => {
  const [uploading, setUploading] = useState(false);

  const handleChange = async (files) => {
    if (files.length) {
      if (files[0].size > 20971520) {
        Swal.fire({
          text: 'Your file size is too big. Please only upload files of 20MB or less.',
          icon: 'error',
        });
      } else {
        try {
          setUploading(true);
          const formData = new FormData();
          const sanitizedFileName = sanitizeFileName(files[0].name);
          formData.append('file', files[0], sanitizedFileName);
          const uploadedFile = await uploadFile(formData, type);
          onFileSelected(uploadedFile, type);
          setUploading(false);
        } catch (error) {
          setUploading(false);
          Swal.fire({
            text: 'Something went wrong while uploading your document',
            icon: 'error',
          });
        }
      }
    }
  };

  const imageSize = 20;
  const cardWidth = width ?? 350;
  const cardHeight = height ?? 350;
  const TheIcon = icon ? icon : FaIcons.FaFileUpload;
  const uploadTitle = title ? title : 'Add File';

  const isFileURLValid = file?.url && (URLUtils.isURL(file.url) || file.url.startsWith('images/'));

  return (
    <div className={classNames(className, 'd-flex flex-column')} style={{ width: cardWidth }} id={id}>
      {file ? (
        <>
          <div className='card shadow-sm' style={{ flex: 1 }} onClick={() => URLUtils.openDocument(file.url)}>
            {isFileURLValid && (
              <img
                src={file.url.startsWith('images/') ? URLUtils.getDocumentUrl(file.url) : file.url}
                style={{ objectFit: 'cover' }}
                alt='no preview'
                width='100%'
                height='100%'
              />
            )}
            {!isFileURLValid && <Icon name='pdf-preview' width='100%' height='100%' />}
            {!readOnly && (
              <FaIcons.FaMinusCircle
                size={imageSize}
                className='position-absolute m-1'
                color='red'
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFile(type);
                }}
              />
            )}
          </div>
          <FileName>{file.name}</FileName>
        </>
      ) : (
        <Dropzone handleChange={handleChange}>
          <div
            className='card d-flex align-items-center justify-content-center shadow-sm border-secondary bg-light'
            style={{
              width: cardWidth,
              height: cardHeight,
            }}
          >
            <span>
              <TheIcon size={imageSize} className='text-muted mb-2' />
            </span>
            {uploading && (
              <div className='d-flex align-items-center'>
                <Spinner variant='secondary' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span className='ms-1 text-muted'>Uploading...</span>
              </div>
            )}
            {!uploading && <span className='text-muted'>{uploadTitle}</span>}
            {!uploading && showDropMessage && <span className='pt-2 text-muted'>Choose a file or drag it here</span>}
          </div>
        </Dropzone>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  file: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onFileSelected: PropTypes.func,
  deleteFile: PropTypes.func,
  showDropMessage: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FileUpload;
