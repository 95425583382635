import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import { breakpoint } from 'styled-components-breakpoint';

export const DescriptionText = styled.h5`
  font-size: 16px;
  line-height: 22px;
  color: #000;
  text-align: center;
`;

export const Container = styled.div``;

export const DropContainer = styled.div`
  background-color: lightGray;
  padding: 10px;
`;

export const ContractImage = styled.img`
  width: 528px;
  height: 333px;
  max-width: 100%;
`;
export const ReviewContainer = styled(Col)`
  ${breakpoint('mobile', 'desktop')`
    margin-top: 20px;
  `}
`;

export const InstructionsContainer = styled.div`
  background-color: var(--gray5);
  padding: 20px;
`;

export const InstructionHeader = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: var(--offer1-black);
`;

export const InstructionContainer = styled.div`
  padding: 20px 0;
  display: flex;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ active }) => (active ? 'var(--offer1-black)' : 'rgba(51, 51, 51, 0.6)')};
`;

export const Instruction = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ol {
    list-style: lower-alpha;
    padding-left: 16px;
    margin-bottom: 0;
  }
`;

export const Separator = styled.div`
  margin: 0 16px;
  border-bottom: 1px solid var(--gray11);
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NotifyAll = styled.div`
  margin: 32px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  gap: 32px;
`;

export const BlueText = styled.h2`
  color: var(--offer1-blue);
  font-weight: 600;
  font-size: 24px;
  display: block;
  text-align: center;
`;

export const GreenText = styled.h3`
  color: var(--offer1-green);
  font-weight: 600;
  font-size: 20px;
  display: block;
  text-align: center;
  margin-top: 1rem;
`;
