import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QueryString from 'query-string';
import { AuthAsyncActions } from 'store/actions/auth';
import { Icon, EmailField } from 'components';
import routeConstants from 'containers/App/routeConstants';
import { URLUtils } from 'utils';
import { Container, SectionBody, SectionTitle, HouseImage } from './accountVerificationPage.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function AccountVerificationPage() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isSuccess, error } = useSelector(AuthAsyncActions.VerifyAccount.StatusSelector());

  const params = QueryString.parse(location.search);
  const [userEmail, setUserEmail] = useState();

  if (!params.token || !params.email) {
    history.push('/login');
  }

  useEffect(() => {
    return () => dispatch(AuthAsyncActions.VerifyAccount.Actions.RESET());
  }, [dispatch]);

  useEffect(() => {
    dispatch(AuthAsyncActions.VerifyAccount.Actions.REQUEST(params.email, params.token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const requestNewLink = () => {
  //   dispatch({
  //     type: actionTypes.FORGOT_EMAIL_REQUEST,
  //     email: userEmail,
  //   });
  // };

  const handleSignin = () => {
    history.push(routeConstants.LOGIN_PATH);
  };

  const handleEmailChanged = (state) => {
    setUserEmail(state.value);
  };

  return (
    <>
      <Helmet>
        <title>Account Verification</title>
        <meta name='description' content='Account Verification' />
      </Helmet>
      <MainContainerMd className='d-flex justify-content-center'>
        {isSuccess && (
          <Container>
            <SectionTitle>Welcome!</SectionTitle>
            <hr />
            <Row className='d-flex justify-content-center'>
              <Col className='d-flex justify-content-center'>
                <HouseImage src={URLUtils.getPublicImageUrl('house.svg')} />
                <SectionBody className='px-2 pt-4 text-md-left text-center'>
                  Your email has been verified! Click Log In to continue.
                </SectionBody>
              </Col>
            </Row>
            <Row className='d-flex justify-content-center pt-1'>
              <button type='button' className='btn text-uppercase auth-button' onClick={handleSignin}>
                Continue
              </button>
            </Row>
          </Container>
        )}
        {error && (
          <Container>
            <SectionTitle>Oops!</SectionTitle>
            <hr />
            <Row className='d-flex justify-content-center'>
              <Col className='d-flex justify-content-center'>
                <div className='d-md-block d-none'>
                  <Icon name='warning-cone' size={110} />
                </div>
                <SectionBody className='px-2 text-md-left text-center'>
                  Something went wrong while verifying your account. Enter your email below to request a new link.
                </SectionBody>
              </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-4'>
              <EmailField
                fieldId='email'
                label='Email'
                placeholder='Enter Email'
                initialValue={userEmail}
                onStateChanged={handleEmailChanged}
                required
              />
            </Row>
            <Row className='d-flex justify-content-center'>
              <button
                type='button'
                className='btn btn-primary text-uppercase px-3 py-2 float-right'
                // onClick={requestNewLink}
              >
                Request New Link
              </button>
            </Row>
          </Container>
        )}
      </MainContainerMd>
    </>
  );
}

export default AccountVerificationPage;
