import React from 'react';
import { RadioButtons } from 'components';
import { useSelector } from 'react-redux';
import { useResponsive } from 'hooks';
import { getOwnerTypes } from 'store/selectors/app';

function UserTypeRadios({ heading, groupName, selectedOption, onStateChanged, error }) {
  const isMobile = useResponsive();
  const ownerTypes = useSelector(getOwnerTypes);

  return (
    <React.Fragment>
      <span>{heading}</span>
      <RadioButtons
        items={ownerTypes}
        groupName={groupName}
        selectedOption={selectedOption}
        onStateChanged={onStateChanged}
        vertical={isMobile}
        error={error}
      />
    </React.Fragment>
  );
}

export default UserTypeRadios;
