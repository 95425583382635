import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { useOnClickOutside, useAuthUser, useResponsive } from 'hooks';
import { URLUtils } from 'utils';

import { Avatar, Typography, Icon } from 'components';
import routes from 'containers/App/routeConstants';
import MenuButton from './menuButton';
import { StyledProSidebar, NavHeader, MobileHeader, ProfileMenuItem } from './mainLeftNavBar.styled';

function MainLeftNavBar() {
  const history = useHistory();
  const { pathname } = useLocation();

  const { isAdmin, user } = useAuthUser();
  const isMobile = useResponsive();

  const [open, setOpen] = useState(false);

  const sideNavRef = useRef();

  const isInBlackList = useMemo(() => [routes.CONSUMER_ONBOARDING].includes(pathname), [pathname]);

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile, pathname]);

  const handleClickOutside = (e) => {
    if (isMobile && !e.target.id?.startsWith('hamburger-menu')) {
      setOpen(false);
    }
  };

  useOnClickOutside(sideNavRef, handleClickOutside);

  const isPathActive = (path) => !!matchPath(pathname, { path });

  if (user && !isInBlackList) {
    return (
      <React.Fragment>
        {isMobile && (
          <MobileHeader>
            <Link to={routes.HOME_PATH}>
              <img
                src={URLUtils.getPublicImageUrl('logo.svg')}
                alt='Offer1'
                onClick={() => history.push(routes.HOME_PATH)}
              />
            </Link>
            <MenuButton className='ms-auto' open={open} onToggle={() => setOpen((prev) => !prev)} />
          </MobileHeader>
        )}
        {(!isMobile || open) && (
          <StyledProSidebar
            ref={sideNavRef}
            width={230}
            style={{ position: isMobile ? 'fixed' : 'sticky', top: isMobile ? 58 : 0, left: 0 }}
          >
            {!isMobile && (
              <SidebarHeader>
                <NavHeader>
                  <Link to={routes.HOME_PATH}>
                    <img src={URLUtils.getPublicImageUrl('logo.svg')} alt='Offer1' />
                  </Link>
                </NavHeader>
              </SidebarHeader>
            )}
            <SidebarContent>
              <Menu iconShape='square'>
                <MenuItem active={isPathActive(routes.AGENT_PROFILE_PATH)}>
                  <ProfileMenuItem>
                    <Avatar
                      src={user.profilePictureUrl}
                      placeholderIcon='profile-dark'
                      size={40}
                      color={isPathActive(routes.AGENT_PROFILE_PATH) ? 'offer1-blue' : 'gray7'}
                    />
                    <Typography size={16}>View Profile</Typography>
                  </ProfileMenuItem>
                  <Link to={routes.AGENT_PROFILE_PATH} />
                </MenuItem>
                <MenuItem icon={<Icon name='buying' />} active={isPathActive(routes.HOME_PATH)}>
                  <Typography size={16}>Home</Typography>
                  <Link to={routes.HOME_PATH} />
                </MenuItem>
                <MenuItem
                  icon={<Icon name='buying' />}
                  active={isPathActive([
                    routes.BUYING_PATH,
                    routes.OFFERS_PATH,
                    routes.OFFERS_DETAIL_PATH,
                    routes.CREATE_OFFER_PATH,
                    routes.CONSUMER_CREATE_OFFER_PATH,
                    routes.SEARCH_PAGE,
                  ])}
                >
                  <Typography size={16}>Buying</Typography>
                  <Link to={!user.agent ? routes.BUYING_PATH : routes.OFFERS_PATH} />
                </MenuItem>
                <MenuItem
                  icon={<Icon name='selling' />}
                  active={isPathActive([
                    routes.LISTINGS_PATH,
                    routes.LISTING_CREATE_PATH,
                    routes.COMPLETE_LISTING_PATH,
                    routes.LISTING_PATH,
                    routes.LISTINGS_DETAIL_PATH,
                    routes.OFFER_STATUS,
                    routes.REVIEW_OFFER_PATH,
                  ])}
                >
                  <Typography size={16}>Selling</Typography>
                  <Link to={routes.LISTINGS_PATH_ALL} />
                </MenuItem>
                {isAdmin && (
                  <SubMenu title='Admin' style={{ fontSize: 16 }} icon={<Icon name='admin' />}>
                    <MenuItem active={isPathActive(routes.ADMIN_USERS_PATH)}>
                      <Typography size={16}>Users</Typography>
                      <Link to={routes.ADMIN_USERS_PATH} />
                    </MenuItem>
                    <MenuItem active={isPathActive(routes.ADMIN_AGENTS_PATH)}>
                      <Typography size={16}>Agents</Typography>
                      <Link to={routes.ADMIN_AGENTS_PATH} />
                    </MenuItem>
                    <MenuItem active={isPathActive(routes.ADMIN_LISTINGS_PATH)}>
                      <Typography size={16}>Listings</Typography>
                      <Link to={routes.ADMIN_LISTINGS_PATH} />
                    </MenuItem>
                    <MenuItem active={isPathActive(routes.ADMIN_CONTRACTS_PATH)}>
                      <Typography size={16}>Contracts</Typography>
                      <Link to={routes.ADMIN_CONTRACTS_PATH} />
                    </MenuItem>
                  </SubMenu>
                )}
              </Menu>
            </SidebarContent>
            <SidebarFooter>
              <Menu iconShape='square'>
                <MenuItem icon={<Icon name='logout' />}>
                  <Typography size={16}>Sign Out</Typography>
                  <Link to={routes.LOGOUT_PATH} />
                </MenuItem>
              </Menu>
            </SidebarFooter>
          </StyledProSidebar>
        )}
      </React.Fragment>
    );
  } else {
    return <div />;
  }
}

export default MainLeftNavBar;
