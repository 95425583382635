import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import App from 'containers/App';
import 'styles/app.scss';
import { breakpoints } from 'constants/themeConstants';
import { ThemeProvider } from 'styled-components';

import registerServiceWorker from 'registerServiceWorker';
import configureStore from 'store/configureStore';
import configureAxios from 'services/configureAxios';

const theme = {
  breakpoints,
};

const store = configureStore();

configureAxios(store);

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

registerServiceWorker();
