import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

function* fetchListingDetails(action) {
  const {
    FetchListingDetails: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { id },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/${id}/details`, 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchListingDetails;
