import styled from 'styled-components';

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
`;
