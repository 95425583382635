import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthAsyncActions } from 'store/actions/auth';
import { PrimaryGreenButton } from 'components';
import routeConstants from 'containers/App/routeConstants';

import { ForgotPasswordContent, SectionTitle, SectionBody } from './forgotPassword.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function ForgotPasswordSuccessPage() {
  const history = useHistory();
  const { isSuccess } = useSelector(AuthAsyncActions.ForgotPassword.StatusSelector());

  return (
    <MainContainerMd>
      {isSuccess && (
        <ForgotPasswordContent>
          <Row className='justify-content-center'>
            <Col md={8}>
              <SectionTitle>Success</SectionTitle>
              <SectionBody className='d-flex justify-content-center my-3'>
                Check your email to complete resetting your password.
              </SectionBody>
              <PrimaryGreenButton
                type='button'
                className='text-uppercase px-3 py-2'
                onClick={() => history.push(routeConstants.LOGIN_PATH)}
              >
                LOG IN
              </PrimaryGreenButton>
            </Col>
          </Row>
        </ForgotPasswordContent>
      )}
    </MainContainerMd>
  );
}
export default ForgotPasswordSuccessPage;
