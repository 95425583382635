import React, { useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

function Typography({ as, children, className, size, color, bold, italic, center, underline }) {
  const renderAs = as || 'span';
  const classNames = useMemo(
    () =>
      cn(
        {
          'fw-bold': bold,
          'fst-italic': italic,
          'text-decoration-underline': underline,
          'text-center': center,
        },
        className
      ),
    [className, bold, italic, underline, center]
  );

  const style = useMemo(() => {
    const s = {};
    if (size) {
      s.fontSize = size;
    }
    if (color) {
      s.color = `var(--${color})`;
    }

    return s;
  }, [size, color]);

  return React.createElement(
    renderAs,
    {
      className: classNames,
      style,
    },
    children
  );
}

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  as: PropTypes.string,
  className: PropTypes.string,
};

export default Typography;
