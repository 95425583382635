import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import { breakpoint } from 'styled-components-breakpoint';

export const AllocationQuestion = styled(Col)`
  font-size: 20px;

  ${breakpoint('mobile', 'desktop')`
    order: 1;
  `}
`;
