import styled from 'styled-components';

export const PasswordHintsContainer = styled.div`
  margin-top: 4px;
  margin-left: 20px;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
`;

export const PasswordHintError = styled.div`
  color: red;
  margin-bottom: 0;
`;

export const PasswordHintSuccess = styled.p`
  color: green;
  margin-bottom: 0;
`;
