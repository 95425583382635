import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const TopContainer = styled.div`
  margin-top: 43px;
  display: flex;
  gap: 70px;
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
  }

  ${breakpoint('desktop')`
    img {
      border-radius: 6px;
    }
  `}

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
    gap: 30px;
    margin-top: 0;

    img {
      margin: -10px -16px 14px;
    }
  `}
`;

export const WelcomeContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  span {
    margin: 10px 0;
  }
`;

export const HelpItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid var(--gray6);

  span {
    font-size: 16px;
    line-height: 38px;
    font-weight: 400;
  }
`;

export const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 8px;

  > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray7);
  }

  ${breakpoint('mobile', 'desktop')`
    margin-top: 28px;
  `}
`;

export const TaskItem = styled.div`
  border: 1px solid var(--gray12);
  border-radius: 6px;
  padding: 13px 17px;
  font-size: 12px;

  ${breakpoint('mobile', 'desktop')`
    padding: 14px;
  `}
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;

  span:nth-child(2) {
    color: var(--gray7);
    margin-left: 8px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  span {
    font-size: 12px;
    line-height: 14px;
    color: #000;
  }

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 10px;
    }

    button {
      margin-left: auto;
    }
  `}
`;
