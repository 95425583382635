import qs from 'query-string';
import axios from 'axios';

export const apiCall = async (url, method = 'get', params = null, isMultipartFormData = false, onUploadProgress) => {
  const query = !isMultipartFormData
    ? method === 'post' || method === 'put' || method === 'delete'
      ? JSON.stringify(params)
      : qs.stringify(params, { arrayFormat: 'brackets' })
    : params;

  const fullUrl =
    method === 'post' || method === 'put' || method === 'delete' ? `${url}` : `${url}${query && '?'}${query}`;

  const options = {
    method,
    url: fullUrl,
    headers: { 'Content-Type': isMultipartFormData ? 'multipart/form-data' : 'application/json' },
    data: query,
    onUploadProgress,
  };

  try {
    const response = await axios(options);
    if (response.code > 300) {
      throw response;
    } else {
      return response;
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Error occurred';
    const details = error?.response?.data?.details || 'Error occurred';
    axios.post('/ui-logger', {
      level: 'ERROR',
      message: message.length > 200 ? message.substring(0, 199) : message,
      stackTrace: details.length > 512 ? details.substring(0, 511) : details,
    });
    throw error?.response?.data ?? { message: 'Error occurred', details: 'Error occurred' };
  }
};
