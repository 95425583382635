import { call, put } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';

function* signup(action) {
  const {
    SignUp: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { registrationRequest },
  } = action;

  try {
    yield call(apiCall, '/users/registration', 'post', registrationRequest);
    yield put(SUCCESS(true));
  } catch (error) {
    if (error.httpStatus === 400) {
      error.message = error.details;
    }
    yield put(FAILURE(error));
  }
}

export default signup;
