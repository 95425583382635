import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';
import routes from 'containers/App/routeConstants';

function* impostorLogin(action) {
  const {
    ImpostorLogin: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { email },
  } = action;

  try {
    const response = yield call(apiCall, '/admin/login', 'post', { email });
    yield put(
      SUCCESS(response.headers['authorization'], {
        ...response.data,
      })
    );

    yield put(push(routes.HOME_PATH));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default impostorLogin;
