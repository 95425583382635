import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import {
  FormField,
  RadioButtons,
  PropertyItem,
  PopoverTooltip,
  IncludedItemsPanel,
  Divider,
  Typography,
} from 'components';
import { ListingsAsyncActions } from 'store/actions/listings';
import { yesNoOptions } from 'constants/optionConstants';
import { useDispatch } from 'react-redux';

const AddItem = ({
  id,
  title,
  subTitle,
  tooltipId,
  tooltipContentId,
  label,
  placeholder,
  hasItems,
  radioGroupName,
  items,
  onRadioSelection,
  onAdd,
  onRemove,
  error,
}) => {
  const [item, setItem] = useState('');

  const handleAdd = () => {
    if (!item) {
      return;
    }

    onAdd(item);
    setItem('');
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleAdd();
    }
  };

  return (
    <>
      <Typography as='p' className='mt-3' color={error && 'danger'}>
        {title}
        {tooltipId && <PopoverTooltip id={tooltipId} labelId={tooltipId} contentId={tooltipContentId} />}
      </Typography>
      <RadioButtons
        items={yesNoOptions}
        groupName={radioGroupName}
        selectedOption={hasItems !== null ? (hasItems === true ? 'yes' : 'no') : null}
        onStateChanged={onRadioSelection}
        error={error}
      />
      <Collapse in={hasItems}>
        <div className='ms-3'>
          <p className='mt-1'>{subTitle}</p>
          <Row>
            <Col sm={8}>
              <FormField
                label={label}
                placeholder={placeholder}
                initialValue={item}
                editable={true}
                onStateChanged={({ value }) => setItem(value)}
                onKeyPress={handleEnter}
              />
            </Col>
            <Col sm={3} className='align-self-center'>
              <Button className='btn btn-primary mt-auto ms-3' onClick={handleAdd}>
                ADD
              </Button>
            </Col>
          </Row>
          <div className='d-flex flex-wrap mt-2'>
            {items?.map((item, index) => (
              <PropertyItem key={`${item.name}-${index}`} name={item.name} onClickRemove={() => onRemove(index)} />
            ))}
          </div>
        </div>
      </Collapse>
    </>
  );
};

const AddListingItems = ({ listing, listingState, onListingItemsChanged, stateIncludedOptions, errors }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (listing?.address?.state) {
      dispatch(
        ListingsAsyncActions.FetchStateIncludedItems.Actions.REQUEST(listing.address.state, listing.address.city)
      );
    }
  }, [dispatch, listing?.address?.state, listing?.address?.city]);

  const handleRadioSelection = (items) => (event) => {
    const { name, value } = event.target;
    onListingItemsChanged({ ...listing, ...(value === 'yes' ? { [name]: true } : { [name]: false, [items]: [] }) });
  };

  const handleAddItemToList = (field, value) => {
    const newItems = listing[field] || [];
    newItems.push({ name: value });
    onListingItemsChanged({ ...listing, [field]: newItems });
  };

  const handleAddStateItemsToList = (newItems) => {
    onListingItemsChanged({ ...listing, includedStateSpecifiedItems: newItems });
  };

  const handleRemove = (field, index) => {
    const newItems = listing[field] || [];
    newItems.splice(index, 1);
    onListingItemsChanged({ ...listing, [field]: newItems });
  };

  return (
    <>
      <IncludedItemsPanel
        stateIncludedOptions={stateIncludedOptions}
        onSelected={handleAddStateItemsToList}
        includedOptions={listing.includedStateSpecifiedItems}
        listingState={listingState}
      />

      <AddItem
        id='includedItems'
        title='Do you have additional included Items not specified above?'
        subTitle='Please identify additional "personal property" items you would like to include in the sale of the property.'
        placeholder='Additional items'
        hasItems={listing.hasIncludedItems}
        radioGroupName='hasIncludedItems'
        items={listing.includedItems}
        onRadioSelection={handleRadioSelection('includedItems')}
        onAdd={(value) => handleAddItemToList('includedItems', value)}
        onRemove={(index) => handleRemove('includedItems', index)}
        error={errors?.includedItems}
      />
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <AddItem
        id='excludedItems'
        title='Do you have Excluded Items?*'
        subTitle='It is critical that you list any fixtures and attached items you wish to EXCLUDE from the sale (and take with you).'
        tooltipId='tooltip.excludedItem.label'
        tooltipContentId='tooltip.excludedItem.description'
        label='Excluded Items'
        placeholder='Wall AV mounts, side shed, chandelier, built-in credenza, etc.'
        hasItems={listing.hasExcludedItems}
        radioGroupName='hasExcludedItems'
        items={listing.excludedItems}
        onRadioSelection={handleRadioSelection('excludedItems')}
        onAdd={(value) => handleAddItemToList('excludedItems', value)}
        onRemove={(index) => handleRemove('excludedItems', index)}
        error={errors?.excludedItems}
      />
      {/* <Divider className='mx-n2 mx-md-n4 my-3' /> */}
      {/* <AddItem
        id='lienItems'
        title='Do you have Leased/Not Owned Items that will not be covered by the sale?*'
        subTitle='It is critical that you list any fixtures and attached items that are currently being leased or not owned by you (and will stay with the home). Include the balance for each item.'
        tooltipId='tooltip.leasedItem.label'
        tooltipContentId='tooltip.leasedItem.description'
        label='Leased Items'
        placeholder='Solar power system, alarm system propane tank, water softener'
        hasItems={listing.hasLienItems}
        radioGroupName='hasLienItems'
        items={listing.lienItems}
        onRadioSelection={handleRadioSelection('lienItems')}
        onAdd={(value) => handleAddItemToList('lienItems', value)}
        onRemove={(index) => handleRemove('lienItems', index)}
        error={errors?.lienItems}
      /> */}
    </>
  );
};

export default AddListingItems;
