import styled from 'styled-components';

export const BuyerProfileContainer = styled.div`
  min-height: 36px;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const OfferCardContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--gray6);
  color: var(--gray2);
  cursor: pointer;
  margin-top: 10px;
`;

export const BoldText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #333333;
`;

export const LightText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #6c757d;
`;

export const HeaderBoldText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

export const HeaderLightText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: white;
`;

export const OfferCardBody = styled.div`
  padding: 0px 20px 30px 20px;
`;

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;
  line-height: 30px;
  color: ${(props) => (props.active ? 'var(--offer1-green)' : 'black')};
  border-bottom: ${(props) => props.active && '4px solid var(--offer1-green)'};
  cursor: pointer;
`;

export const ColHeader = styled.div``;

export const ColValue = styled.div``;
