import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UpdateText, TabButton, StyledTabWrap } from './tabBar.styled';

function TabBar({ updateStatus, tabs, value, onChange, otherButtons }) {
  return (
    <Row className='my-3 align-items-center'>
      <Col xs={12} md='auto' xl={8}>
        <StyledTabWrap className='d-flex align-items-center'>
          {tabs.map((tab) => (
            <TabButton key={tab.value} active={value === tab.value} onClick={() => onChange(tab.value)}>
              {tab.name}
            </TabButton>
          ))}
          {otherButtons}
        </StyledTabWrap>
      </Col>
      <Col xs={12} md='auto' xl={4}>
        <UpdateText error={updateStatus.includes('Error')}>{updateStatus}</UpdateText>
      </Col>
    </Row>
  );
}

TabBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TabBar;
