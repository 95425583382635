import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const RootContainer = styled.div`
  display: flex;
  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;
  `}
`;

export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const MainContainerMd = styled.div`
  max-width: 992px;
  margin: auto;
  margin-top: 30px;
`;
