import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { getLoading } from 'store/selectors/app';
import { Typography } from 'components';

function LoadingMessage() {
  const isPageLoading = useSelector(getLoading);

  if (!isPageLoading) return null;
  return (
    <div className='loader-container'>
      <div className='loader'>
        <Spinner className='spinner-img me-4' as='span' animation='border' role='status' aria-hidden='true' />
        <Typography color='offer1-green' size={40}>
          Loading...
        </Typography>
      </div>
    </div>
  );
}

export default LoadingMessage;
