const getDocumentUrl = (url) => {
  return `${process.env.REACT_APP_DOC_URL}/${url}`;
};

const getPublicImageUrl = (url) => {
  return `${process.env.PUBLIC_URL}/${url}`;
};

const openNewWindow = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const openDocument = (url) => {
  openNewWindow(getDocumentUrl(url));
};

const isURL = (str) => {
  const urlRegex =
    '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  const url = new RegExp(urlRegex, 'i');
  return str.length < 2083 && url.test(str);
};

const URLUtils = {
  getDocumentUrl,
  getPublicImageUrl,
  openNewWindow,
  openDocument,
  isURL,
};

export default URLUtils;
