import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Header, CardDeck, OfferCard, Empty } from 'components';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { getBuyerOffers } from 'store/selectors/buyers';
import { MainContainer } from 'containers/App/mainLayout.styled';
import { URLUtils } from 'utils';
import routes from 'containers/App/routeConstants';

function BuyingPage() {
  const dispatch = useDispatch();
  const activeOffers = useSelector(getBuyerOffers);
  const { isSuccess } = useSelector(BuyersAsyncActions.FetchBuyerOffers.StatusSelector());

  useEffect(() => {
    dispatch(BuyersAsyncActions.FetchBuyerOffers.Actions.REQUEST());

    return () => {
      dispatch(BuyersAsyncActions.FetchBuyerOffers.Actions.RESET());
    };
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Your Offers</title>
        <meta name='description' content='Your Offers' />
      </Helmet>

      <MainContainer>
        <Header
          title='Your Offers'
          ctas={[
            {
              title: 'Create an Offer',
              to: routes.SEARCH_PAGE,
            },
          ]}
        />

        {isSuccess && activeOffers.length > 0 && (
          <CardDeck className='bg-light w-100 my-3 py-3' center>
            {activeOffers?.map((offer) => (
              <OfferCard key={offer.listing.id} offer={offer} />
            ))}
          </CardDeck>
        )}
        {isSuccess && activeOffers.length === 0 && (
          <Empty
            src={URLUtils.getPublicImageUrl('homesearch-man.svg')}
            width={229}
            height={219}
            title='Looking for a property?'
            description='Get started by Clicking create an offer on the top right corner.'
          />
        )}
      </MainContainer>
    </>
  );
}

export default BuyingPage;
