import React from 'react';
import PropTypes from 'prop-types';
import { FaCameraRetro } from 'react-icons/fa';
import { FileUpload, Typography } from 'components';

function AddPropertyImages({ primaryImage, onPropertyImageChanged, onPropertyImageDelete }) {
  return (
    <div>
      <Typography as='h6' size={24}>
        Main Display Photo
      </Typography>
      <FileUpload
        height={300}
        width={300}
        type='PRIMARY_IMAGE'
        icon={FaCameraRetro}
        title='Add Main Photo'
        file={primaryImage}
        onFileSelected={onPropertyImageChanged}
        deleteFile={onPropertyImageDelete}
        showDropMessage={true}
      />
    </div>
  );
}

AddPropertyImages.propTypes = {
  primaryImage: PropTypes.object,
  onPropertyImageChanged: PropTypes.func,
  onPropertyImageDelete: PropTypes.func,
};

export default AddPropertyImages;
