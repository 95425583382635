import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Timeline, PrimaryGreenButton, PropertyItem, Divider, AddSignatureModal, Icon } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import filesize from 'filesize';
import moment from 'moment';
import {
  DescriptionText,
  Container,
  ContractImage,
  ReviewContainer,
  InstructionsContainer,
  InstructionHeader,
  InstructionContainer,
  Instruction,
  DocumentContainer,
  BlueText,
  GreenText,
} from './contractContainer.styled';
import OfferReview from 'containers/CreateOfferPage/offerReview';
import { ContractAsyncActions } from 'store/actions/contract';
import { getContract } from 'store/selectors/contract';
import { offerSteps } from 'constants/offerConstants';
import { URLUtils } from 'utils';
import { isNil } from 'lodash';
import { useAuthUser } from 'hooks';
import {
  PDFCanvas,
  PageNav,
  PageText,
  SignatureContainer,
  InitialsCanvasPanel,
  SignatureCanvasPanel,
  SignatureLabel,
  SignatureImg,
} from 'components/Signature/signature.styled';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import Offer1Modal from 'components/Modal/Modal';
import { useHistory } from 'react-router';

const FileChip = ({ name, url, onRemove }) => {
  return (
    <PropertyItem name={name} included onClick={() => (url ? URLUtils.openDocument(url) : null)} onRemove={onRemove} />
  );
};
function PropertyContractContainer({ listing, offer }) {
  const history = useHistory();
  const contract = useSelector(getContract);

  const dispatch = useDispatch();

  const { isSuccess: isSaveSignatureContractSuccess, error: isSaveSignatureContractError } = useSelector(
    ContractAsyncActions.SaveSignatureContract.StatusSelector()
  );

  const { isSuccess: isSaveContractStateSuccess, error: isSaveContractStateError } = useSelector(
    ContractAsyncActions.SaveListingContractState.StatusSelector()
  );

  const [contractView, setContractView] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [editingSignatureType, setEditingSignatureType] = useState('');
  const [showSignModal, setShowSignModal] = useState(false);

  const { isOfferBuyer, isPrimaryOwner, isBuyerAgent, isSellerAgent, user } = useAuthUser({ listing, offer });

  const [signatureBase64, setSignatureBase64] = useState(user?.signature || '');
  const [initialsBase64, setInitialsBase64] = useState(user?.initials || '');

  useEffect(() => {
    if (listing.id) {
      dispatch(ContractAsyncActions.FetchListingContract.Actions.REQUEST(listing.id));
    }

    return () => {
      if (listing.id) {
        dispatch(ContractAsyncActions.FetchListingContract.Actions.RESET());
      }
      dispatch(ContractAsyncActions.SaveSignatureContract.Actions.RESET());
      dispatch(ContractAsyncActions.SaveListingContractState.Actions.RESET());
    };
  }, [dispatch, listing]);

  useEffect(() => {
    if (isSaveSignatureContractSuccess) {
      Swal.fire(
        'Signing Complete',
        isBuyerAgent
          ? 'Your signing is complete. Once all buyers have signed we will notify the seller(s) it is their turn to sign. When all parties have signed you are officially in contract.'
          : 'Your signing is complete. Once all sellers have signed the agreement you will officially be in contract.  Congratulations.',
        'success'
      );
    }
  }, [isSaveSignatureContractSuccess, isBuyerAgent]);

  useEffect(() => {
    if (isSaveSignatureContractError) {
      Swal.fire('Error', 'Your signing is failed', 'error');
    }
  }, [isSaveSignatureContractError]);

  useEffect(() => {
    if (isSaveContractStateSuccess) {
      Swal.fire('Success', 'Your contract is cancelled', 'success');
      if (isBuyerAgent) {
        history.goBack('/buying');
      } else if (isSellerAgent) {
        history.goBack('/listings/filter/active');
      } else {
        history.goBack();
      }
    }
  }, [isSaveContractStateSuccess, history, isSellerAgent, isBuyerAgent]);

  useEffect(() => {
    if (isSaveContractStateError) {
      Swal.fire('Error', 'Your contract is failed to cancel', 'error');
    }
  }, [isSaveContractStateError]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const isContractInProgress = useMemo(() => contract?.state !== 'COMPLETED', [contract]);

  const allStatusSteps = useMemo(
    () => [
      'Buyer Agent: Review and Sign',
      'Buyer: Review and Sign',
      'Seller Agent: Review and Sign',
      'Seller: Review and Sign',
      'Fully ratified agreement',
    ],
    []
  );

  const consumerStatusSteps = useMemo(
    () => ['Buyer: Review and Sign', 'Seller: Review and Sign', 'Fully ratified agreement'],
    []
  );

  const statusSteps = useMemo(() => {
    return listing?.address?.state === 'CA' ? allStatusSteps : consumerStatusSteps;
  }, [listing, consumerStatusSteps, allStatusSteps]);

  const currentStep = useMemo(() => {
    for (let i = 0; i < statusSteps?.length; i++) {
      if (statusSteps[i] === 'Buyer: Review and Sign' && contract?.state === 'PENDING_BUYER_REVIEW') {
        return i;
      } else if (
        statusSteps[i] === 'Buyer Agent: Review and Sign' &&
        contract?.state === 'PENDING_BUYER_AGENT_REVIEW'
      ) {
        return i;
      } else if (statusSteps[i] === 'Seller: Review and Sign' && contract?.state === 'PENDING_SELLER_REVIEW') {
        return i;
      } else if (
        statusSteps[i] === 'Seller Agent: Review and Sign' &&
        contract?.state === 'PENDING_SELLER_AGENT_REVIEW'
      ) {
        return i;
      } else if (statusSteps[i] === 'Fully ratified agreement' && contract?.state === 'COMPLETED') {
        return i;
      }
    }
    return -1;
  }, [contract, statusSteps]);

  const contractRpaUrl = useMemo(() => {
    return `${process.env.REACT_APP_DOC_URL}/${contract?.signedRpa?.url}`;
  }, [contract]);

  const offerRpaUrl = useMemo(() => {
    return `${process.env.REACT_APP_DOC_URL}/${offer.rpaUrl}`;
  }, [offer]);

  const pageMessaging = useMemo(() => {
    if (contract?.state === 'PENDING_BUYER_REVIEW') return 'Waiting for Buyer(s) to sign.';
    if (contract?.state === 'PENDING_BUYER_AGENT_REVIEW') return 'Waiting for Buyer Agent to sign.';
    if (contract?.state === 'PENDING_SELLER_REVIEW') return 'Waiting for Seller(s) to sign.';
    if (contract?.state === 'PENDING_SELLER_AGENT_REVIEW') return 'Waiting for Seller Agent to sign.';
    if (contract?.state === 'COMPLETED')
      return 'We’re in contract folks! Fully ratified agreement is ready to be downloaded.';
  }, [contract]);

  const signingTurn = useMemo(() => {
    let userSignature = { isSigned: true };
    if (isOfferBuyer && contract?.state === 'PENDING_BUYER_REVIEW') {
      userSignature = contract?.buyerSigners?.find((signer) => signer?.userId === user?.id);
    } else if (isPrimaryOwner && contract?.state === 'PENDING_SELLER_REVIEW') {
      userSignature = contract?.sellerSigners?.find((signer) => signer?.userId === user?.id);
    } else if (isSellerAgent && contract?.state === 'PENDING_SELLER_AGENT_REVIEW') {
      userSignature = contract?.sellerSigners?.find((signer) => signer?.userId === user?.id);
    } else if (isBuyerAgent && contract?.state === 'PENDING_BUYER_AGENT_REVIEW') {
      userSignature = contract?.sellerSigners?.find((signer) => signer?.userId === user?.id);
    }
    return !userSignature?.isSigned;
  }, [contract, isOfferBuyer, isPrimaryOwner, isBuyerAgent, isSellerAgent, user]);

  const cancelContract = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Cancel Contract',
      text: isSellerAgent
        ? 'By clicking cancel contract below the property will revert back to active status and all parties will be notified. You may renegotiate this agreement or engage with any other buyers.'
        : 'By clicking cancel contract below the property will revert back to active status and all parties will be notified. No further action will be required.',
      confirmButtonText: 'Continue',
      showCancelButton: true,
    });
    if (isConfirmed) {
      dispatch(ContractAsyncActions.SaveListingContractState.Actions.REQUEST(contract.listingId, 'CANCELLED'));
    }
  };

  const getFileInfo = (name, updateTime, size) => {
    return `${name}${updateTime ? ' · ' + moment(updateTime).format('MM/DD/YYYY, hh:mm A') : ''}${
      !isNil(size) ? ' · ' + filesize(size) : ''
    }`;
  };

  const clearSignature = () => {
    setSignatureBase64(null);
    setInitialsBase64(null);
  };

  const handleEditSignature = async (signatureType) => {
    setEditingSignatureType(signatureType);
  };

  const handleCreateSignature = (base64) => {
    if (editingSignatureType === 'signature') {
      setSignatureBase64(base64);
    }
    if (editingSignatureType === 'initials') {
      setInitialsBase64(base64);
    }
    setEditingSignatureType('');
  };

  const handleOpenSign = async () => {
    if (!signatureBase64 && !initialsBase64) {
      const { value: accept } = await Swal.fire({
        title: 'Let the signing Begin',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder:
          "I have reviewed the full agreement and agree to sign. I agree to OFFER1's Terms of Use and Privacy Policy. I agree the electronic signature contained on the documents and/or contract are equivalent to handwritten signatures for the purpose of evaluating validity, enforceability, and admissibility.  I agree to the use of electronic signatures on these documents.",
        confirmButtonText: 'Continue',
        inputValidator: (result) => {
          return !result && 'You need to agree';
        },
      });
      if (accept) {
        setShowSignModal(true);
      }
    } else {
      setShowSignModal(true);
    }
  };

  const handleSign = () => {
    setShowSignModal(false);
    dispatch(
      ContractAsyncActions.SaveSignatureContract.Actions.REQUEST(contract, {
        signature: signatureBase64,
        initials: initialsBase64,
      })
    );
  };

  return (
    <Container>
      <BlueText>{isContractInProgress ? 'Almost There!' : 'All Done!'}</BlueText>
      <div className='d-block'>
        <DescriptionText>{pageMessaging}</DescriptionText>
      </div>

      {!isContractInProgress && (
        <Row className='d-flex align-items-center'>
          <div className='d-flex justify-content-center'>
            <ContractImage
              src={URLUtils.getPublicImageUrl('property-agreement.png')}
              alt='property contract agreement'
            />
          </div>
          <div className='d-flex justify-content-center mb-4'>
            <PrimaryGreenButton
              style={{ fontSize: 16 }}
              onClick={() => URLUtils.openDocument(contract?.signedRpa?.url)}
            >
              View Final Contract
            </PrimaryGreenButton>
          </div>
        </Row>
      )}
      {isContractInProgress && (
        <>
          <Row className='d-flex align-items-center'>
            <Col sm={6}>
              <ReviewContainer sm={6}>
                <ContractImage
                  src={URLUtils.getPublicImageUrl(
                    isContractInProgress ? 'property-contract.svg' : 'property-agreement.svg'
                  )}
                  alt='property contract'
                />
              </ReviewContainer>
            </Col>
            <Col sm={6}>
              <p className='fw-bold'>Status</p>
              <Timeline steps={statusSteps} step={currentStep} />
            </Col>
          </Row>
          <Divider />
          <GreenText>Final Steps</GreenText>
          <Row className='mt-3'>
            <p>Please read all the instructions below prior to proceeding.</p>
            <ol className='mx-4'>
              <li>
                Review the Contract below. If the terms are accurate to what you agreed, move to step 2, If they are
                not, contact your agent and the agreement can be cancelled.
              </li>
              <li>Scroll down to the Signature Box.</li>

              <li>Complete the Signature by either typing or writing your name.</li>
              <li>
                OFFER1 will automatically add your signature on the contract in the appropriate spots and notify the
                other parties. You can see the total progress in the status above.
              </li>
              <li>
                Addendums and additional documents (if any) need to be signed separately. These documents will be
                located at the bottom of the page and may be downloaded. Buyer's Agent please download these documents
                and have them signed by your client and then send to the Seller's Agent in a separate email or upload
                here.
              </li>
            </ol>
            <p>
              <b>Important Note: </b>
              The signing is sequential and always starts with buy side and ends with the listing side. The seller(s)
              can't sign until after the buyer(s) have signed. You will receive an email or text once it is your turn to
              sign.
            </p>
          </Row>
        </>
      )}
      <Divider />
      <GreenText>Review Contract</GreenText>
      <Row className='mt-3'>
        <div className='d-flex justify-content-center'>
          <label htmlFor='material-switch'>
            <span className='m-3 '>View Offer Details</span>
            <Switch
              checked={contractView}
              onChange={() => setContractView(!contractView)}
              onColor='#86d3ff'
              onHandleColor='#2693e6'
              handleDiameter={24}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
              activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
              height={25}
              width={48}
              id='material-switch'
            />
            <span className='m-3'>View Contract</span>
          </label>
        </div>
      </Row>
      {!contractView && <OfferReview listing={listing} offer={offer} steps={offerSteps} readOnly collapseAll={true} />}
      {contractView && (
        <PDFCanvas>
          <Document file={contract?.signedRpa ? contractRpaUrl : offerRpaUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber}></Page>
          </Document>
          <PageText>
            Page {pageNumber} of {numPages}
          </PageText>
          <PageNav>
            <Button onClick={goToPrevPage} variant='secondary' size='sm' className='m-2'>
              Prev
            </Button>
            <Button variant='secondary' size='sm' className='m-2' onClick={goToNextPage}>
              Next
            </Button>
          </PageNav>
        </PDFCanvas>
      )}

      <Divider className='mt-3' />
      <GreenText>Sign and Initial</GreenText>
      <p>Please sign and initial your contract below.</p>
      {!signatureBase64 && !initialsBase64 && (
        <div className='d-flex my-3'>
          <Button variant='primary mx-2' onClick={() => handleOpenSign()} disabled={!signingTurn}>
            Sign
          </Button>
        </div>
      )}
      {(signatureBase64 || initialsBase64) && (
        <SignatureContainer>
          <div className='mx-2'>
            <SignatureLabel>Signature</SignatureLabel>
            {signatureBase64 && (
              <SignatureCanvasPanel>
                <SignatureImg src={signatureBase64} />
              </SignatureCanvasPanel>
            )}
            {!signatureBase64 && (
              <Button variant='primary m-2' onClick={() => handleEditSignature('signature')}>
                Add Signature
              </Button>
            )}
          </div>
          <div className='mx-2'>
            <SignatureLabel>Initials</SignatureLabel>
            {initialsBase64 && (
              <InitialsCanvasPanel>
                <SignatureImg src={initialsBase64} />
              </InitialsCanvasPanel>
            )}
            {!initialsBase64 && (
              <Button variant='primary m-2' onClick={() => handleEditSignature('initials')}>
                Add Initials
              </Button>
            )}
          </div>
        </SignatureContainer>
      )}
      {signatureBase64 && initialsBase64 && (
        <div className='d-flex my-3'>
          <Button variant='secondary mx-2' onClick={() => handleOpenSign()}>
            Edit Signature and Initials
          </Button>
          <PrimaryGreenButton onClick={() => handleSign()}>Sign Contract</PrimaryGreenButton>
        </div>
      )}

      <Divider className='my-3' />
      <GreenText>Review Documents</GreenText>
      <InstructionsContainer>
        <InstructionHeader>Contract</InstructionHeader>
        <InstructionContainer>
          <Instruction>
            {contract?.signedRpa && isContractInProgress && (
              <DocumentContainer>
                <FileChip name='Contract In Progress' url={contract?.signedRpa?.url} />
              </DocumentContainer>
            )}
            {contract?.signedRpa && !isContractInProgress && (
              <DocumentContainer>
                <FileChip name='Signed Contract' url={contract?.signedRpa?.url} />
              </DocumentContainer>
            )}
            <DocumentContainer>
              <FileChip name='Unsigned Contract' url={offer.rpaUrl} />
            </DocumentContainer>
          </Instruction>
        </InstructionContainer>
      </InstructionsContainer>
      <InstructionsContainer>
        <InstructionHeader>Additional Documents</InstructionHeader>
        <InstructionContainer>
          <Instruction>
            {(!contract?.initialDocuments || contract?.initialDocuments?.length === 0) && (
              <p>No additional documents</p>
            )}
            <DocumentContainer>
              {contract?.initialDocuments?.map((report) => (
                <FileChip key={report.id} name={getFileInfo(report.name, report.updateTime)} url={report.url} />
              ))}
            </DocumentContainer>
          </Instruction>
        </InstructionContainer>
      </InstructionsContainer>
      {(isBuyerAgent || isSellerAgent) && isContractInProgress && (
        <div className='d-flex justify-content-center mt-3'>
          <PrimaryGreenButton onClick={() => cancelContract()}>Cancel Agreement</PrimaryGreenButton>
        </div>
      )}
      {editingSignatureType && (
        <AddSignatureModal show onClose={() => setEditingSignatureType('')} onCreate={handleCreateSignature} />
      )}
      <Offer1Modal
        show={showSignModal}
        title='Signatures'
        subTitle='Please sign and initial your contract.'
        disableOk={!signatureBase64 || !initialsBase64}
        okButtonLabel='Sign Contract'
        onClose={() => setShowSignModal(false)}
        onOk={handleSign}
      >
        <SignatureContainer>
          <div className='mx-2'>
            <SignatureLabel>
              Signature <Icon name='pencil-square' onClick={() => handleEditSignature('signature')} />
            </SignatureLabel>
            {signatureBase64 && (
              <SignatureCanvasPanel>
                <SignatureImg src={signatureBase64} />
              </SignatureCanvasPanel>
            )}
            {!signatureBase64 && (
              <Button variant='primary m-2' onClick={() => handleEditSignature('signature')}>
                Add Signature
              </Button>
            )}
          </div>
          <div className='mx-2'>
            <SignatureLabel>
              Initials <Icon name='pencil-square' onClick={() => handleEditSignature('initials')} />
            </SignatureLabel>
            {initialsBase64 && (
              <InitialsCanvasPanel>
                <SignatureImg src={initialsBase64} />
              </InitialsCanvasPanel>
            )}
            {!initialsBase64 && (
              <Button variant='primary m-2' onClick={() => handleEditSignature('initials')}>
                Add Initials
              </Button>
            )}
          </div>
        </SignatureContainer>
        <Button variant='secondary m-2' onClick={() => clearSignature()}>
          Clear
        </Button>
      </Offer1Modal>
    </Container>
  );
}

//(tony add an "upload documents " box on the righthand side of the contract line. As documents are added make
//      them downloadable and placed next to the "contract in progress" green icon)

PropertyContractContainer.propTypes = {
  listing: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
};

export default PropertyContractContainer;
