import { call, put } from 'redux-saga/effects';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { apiCall } from 'utils/apiCall';

function* list() {
  const {
    ListBuyers: {
      Actions: { SUCCESS, FAILURE },
    },
  } = BuyersAsyncActions;

  try {
    const response = yield call(apiCall, '/offers/buyers', 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default list;
