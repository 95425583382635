import { call, put, select } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';
import Swal from 'sweetalert2';

function* uploadContract(action) {
  const {
    UploadContract: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { listingId, file },
  } = action;

  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = yield call(apiCall, `/listings/${listingId}/contract`, 'post', formData, true);

    const newContracts = yield select((state) => state.admin.contracts);
    yield put(
      SUCCESS(
        newContracts.map((contract) => {
          if (contract.listingId === listingId) {
            return { ...contract, finalRpaUrl: response.data.finalRpa };
          }
          return contract;
        })
      )
    );
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'Something went wrong while uploading your document',
    });
    yield put(FAILURE(error));
  }
}

export default uploadContract;
