import React from 'react';
import PropTypes from 'prop-types';
import { AvatarContainer } from './avatar.styled';
import { URLUtils } from 'utils';
import Icon from 'components/Icon';
function Avatar({ size = 80, color = 'gray7', src, placeholderIcon = 'profile' }) {
  return (
    <AvatarContainer size={size} color={color}>
      {src && <img src={src.startsWith('http') ? src : URLUtils.getDocumentUrl(src)} alt='avatar' />}
      {!src && <Icon name={placeholderIcon} />}
    </AvatarContainer>
  );
}

Avatar.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  src: PropTypes.string,
  placeholderIcon: PropTypes.string,
};

export default Avatar;
