import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import { Icon, Pagination, Typography } from 'components';
import OfferHeader from './offerHeader';

import { OffersContainer, ControllerContainer, CloseButton } from './reviewOfferModal.styled';
import { Container, SectionItemWithNoHeight, SectionTitle } from './offerCommon.styled';
import Swal from 'sweetalert2';

const MAX_COUNT_IN_LINE = 4;

function OfferOtherTermsCompareModal({ initialPage, show, listing, offers, onClose }) {
  const [page, setPage] = useState(initialPage);

  const totalPage = useMemo(() => Math.ceil(offers.length / MAX_COUNT_IN_LINE), [offers.length]);

  const pageOffers = useMemo(
    () => offers.slice(MAX_COUNT_IN_LINE * page, MAX_COUNT_IN_LINE * (page + 1)),
    [page, offers]
  );

  const placeHolderCount = useMemo(() => MAX_COUNT_IN_LINE - pageOffers.length, [pageOffers.length]);

  const handleOpenOtherTerms = (otherTerms) => {
    Swal.fire({
      titleText: 'Other Terms',
      text: otherTerms,
      showCloseButton: true,
    });
  };

  return (
    <Modal show={show} aria-labelledby='offer-history' backdrop='static' dialogClassName='modal-90wh' scrollable>
      <Modal.Body className='d-flex flex-column' scrollable>
        <div className='mb-3'>
          <CloseButton onClick={onClose}>
            <Icon name='close' />
          </CloseButton>
        </div>
        <OffersContainer>
          <ControllerContainer>
            <Pagination
              page={page}
              size={totalPage}
              onLeft={() => setPage(page - 1)}
              onRight={() => setPage(page + 1)}
              className='ms-auto'
            />
          </ControllerContainer>
          <div className='d-flex'>
            <OfferHeader isSellerPreference />
            {pageOffers.map((offer, pageIndex) => {
              const offerToShow = offer.counterOffers?.length > 0 ? offer.counterOffers[0] : offer.originalOffer;
              return (
                <React.Fragment key={offerToShow.id}>
                  <OfferHeader
                    listing={listing}
                    offer={offerToShow}
                    counterOffers={offer.counterOffers}
                    originalOffer={offer.originalOffer}
                    actionRequired={offer.isActionRequired}
                  />
                </React.Fragment>
              );
            })}
            {Array(placeHolderCount)
              .fill()
              .map((_, index) => (
                <OfferHeader key={index} placeholder />
              ))}
          </div>
          <div className='d-flex'>
            <Container preference>
              <SectionTitle>
                <Typography>Other Terms</Typography>
              </SectionTitle>
            </Container>
            {pageOffers.map((offer) => {
              const offerToShow = offer.counterOffers?.length > 0 ? offer.counterOffers[0] : offer.originalOffer;

              const isGlobalCounter = offerToShow.offerType === 'GLOBAL_COUNTER';
              const isEditing = offerToShow.state === 'LIVE_EDIT';

              return (
                <Container>
                  <SectionTitle>
                    {!isGlobalCounter && !isEditing && !!offer.otherTerms && (
                      <Icon name='pencil-square' onClick={() => handleOpenOtherTerms(offer.otherTerms)} />
                    )}
                  </SectionTitle>
                  <SectionItemWithNoHeight>
                    {!isGlobalCounter && !isEditing && <Typography>{offerToShow.otherTerms}</Typography>}
                    {isEditing && 'Details being edited'}
                  </SectionItemWithNoHeight>
                </Container>
              );
            })}
            {Array(placeHolderCount)
              .fill()
              .map((_, placeholderIndex) => (
                <Container key={placeholderIndex} $placeholder />
              ))}
          </div>
        </OffersContainer>
      </Modal.Body>
    </Modal>
  );
}

OfferOtherTermsCompareModal.propTypes = {
  show: PropTypes.bool,
  initialPage: PropTypes.number,
  listing: PropTypes.object.isRequired,
  offers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OfferOtherTermsCompareModal;
