/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Helmet from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import { PropertyCard, CardDeck, MlsPropertyCard, Icon, DropDown, Map, OutlineButton } from 'components';
import { useAuthUser, useResponsive } from 'hooks';
import { ListingsAsyncActions } from 'store/actions/listings';
import { getSearchListings } from 'store/selectors/listings';
import { Input, SearchButton } from './searchPage.styled';
import { MainContainer } from 'containers/App/mainLayout.styled';
import { getMetaListingStates } from 'store/selectors/app';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { OFFER1_GREEN } from 'constants/styleConstants';

function ListingSearchPage({ importModal }) {
  const dispatch = useDispatch();
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [listingState, setListingState] = useState();
  const [showListings, setShowListings] = useState(true);

  const states = useSelector(getMetaListingStates);

  const listingsToShow = useSelector(getSearchListings);
  const { isLoading } = useSelector(ListingsAsyncActions.SearchListings.StatusSelector());
  const { user } = useAuthUser();

  const isMobile = useResponsive();

  useEffect(() => {
    return () => {
      dispatch(ListingsAsyncActions.SearchListings.Actions.RESET());
    };
  }, [dispatch]);

  const state = useMemo(() => user?.agent?.licenseState, [user]);

  const onViewClick = () => {
    setShowListings(!showListings);
  };

  const search = useCallback(() => {
    dispatch(ListingsAsyncActions.SearchListings.Actions.REQUEST(address, city, state ?? listingState));
  }, [address, city, state, listingState, dispatch]);

  const listingsDisplay = importModal
    ? listingsToShow?.map((listing) => (
        <MlsPropertyCard
          key={listing.listing.id}
          listing={listing.listing}
          offerCount={listing.offerCount}
          highestOffer={listing.highestOffer}
          buyerOffer={listing.buyerOffer}
          buyerOfferState={listing.buyerOfferState}
          buyer={listing.buyer}
          auctionExpirationDate={listing.auctionExpirationDate}
          user={user}
        />
      ))
    : listingsToShow?.map((listing) => (
        <PropertyCard
          key={listing.listing.id}
          listing={listing.listing}
          offerCount={listing.offerCount}
          highestOffer={listing.highestOffer}
          buyerOffer={listing.buyerOffer}
          buyerOfferState={listing.buyerOfferState}
          buyer={listing.buyer}
          auctionExpirationDate={listing.auctionExpirationDate}
          user={user}
        />
      ));

  const listingsMapDisplay = () => {
    const pins = listingsToShow?.map((listing) => ({
      lat: listing?.listing.address.latitude,
      lng: listing?.listing.address.longitude,
      text: listing?.listing.price,
      listingId: listing?.listing.id,
    }));
    const pinCenter = pins && pins[0];
    return pins && <Map pins={pins} pinCenter={pinCenter} zoomLevel={14} />;
  };

  return (
    <>
      <Helmet>
        <title>Search listing</title>
        <meta name='description' content='Search listing' />
      </Helmet>

      <MainContainer
        className={classNames({
          'text-center': isMobile,
        })}
      >
        {!importModal && (
          <h3 className='d-flex align-items-center'>
            {!isMobile ? 'Search For A Listing To Create An Offer' : 'Search Listings'}
            <a data-tip data-for='searchTooltip'>
              <Icon name='exclamation-circle' size={24} />
            </a>
            <ReactTooltip id='searchTooltip' effect='solid'>
              If you can't find a particular listing please let us know, it likely means the listing agent hasn't added
              it to the platform
            </ReactTooltip>
          </h3>
        )}
        <div className='container mt-4'>
          <Row>
            <Col md={4} sm={12}>
              Street Address
              <Input
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Col>
            <Col md={4} sm={12} className={classNames({ 'mt-3': isMobile })}>
              City
              <Input
                value={city}
                onChange={(event) => setCity(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Col>
            {!state && (
              <Col md={4} sm={12} className={classNames({ 'mt-3': isMobile })}>
                State
                <DropDown
                  label=''
                  groupName='state'
                  selectedOption={listingState}
                  onStateChanged={(event) => setListingState(event.target.value)}
                  items={states}
                  className='mb-2'
                />
              </Col>
            )}
            <Col
              md={3}
              sm={12}
              className={classNames('d-flex align-items-end mt-3', {
                'justify-content-center': isMobile,
              })}
            >
              <SearchButton onClick={search}>Search</SearchButton>
            </Col>
          </Row>
          {isLoading && (
            <div className='mt-4 w-100 d-flex justify-content-center'>
              <Spinner className='spinner-img' as='span' animation='border' role='status' aria-hidden='true' />
            </div>
          )}
          {!isLoading && (
            <>
              {listingsToShow?.length > 0 && (
                <div className='mt-4'>
                  {!showListings && (
                    <>
                      <OutlineButton color={OFFER1_GREEN} className='m-2' onClick={onViewClick}>
                        <Icon name='history' className='me-2' />
                        View as List
                      </OutlineButton>
                      <div className='mt-3 bg-light'>{listingsMapDisplay()}</div>
                    </>
                  )}
                  {showListings && (
                    <>
                      <OutlineButton color={OFFER1_GREEN} className='m-2' onClick={onViewClick}>
                        <FaMapMarkerAlt className='pin-icon mb-1 me-2' size={15} style={{ fill: OFFER1_GREEN }} />
                        View on Map
                      </OutlineButton>
                      <div className='mt-3 bg-light'>
                        <CardDeck center>{listingsDisplay}</CardDeck>
                      </div>
                    </>
                  )}
                </div>
              )}
              {listingsToShow?.length === 0 && <h4>No results found</h4>}
            </>
          )}
        </div>
      </MainContainer>
    </>
  );
}

export default ListingSearchPage;
