import styled from 'styled-components';

export const OfferBackgroundContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ historyView }) => (historyView ? '#c6d799' : 'transparent')};
  border-radius: 4px 4px 0px 0px;
`;

export const HistoryLabel = styled.div`
  position: absolute;
  right: 11px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: var(--gray7);
  font-style: italic;
`;
