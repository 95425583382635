import { call, put } from 'redux-saga/effects';
import { ListingsAsyncActions } from 'store/actions/listings';
import { apiCall } from 'utils/apiCall';

function* search(action) {
  const {
    SearchListings: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ListingsAsyncActions;
  const {
    payload: { address, city, state },
  } = action;

  try {
    const response = yield call(apiCall, '/search/listings', 'get', { address, city, state });
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default search;
