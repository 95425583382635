import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FooterContainer, OfferInfoContainer, ActionButton, OfferIdText } from './offerFooter.styled';
import { Icon } from 'components';
import { getOfferTitle, getOfferIdStr } from 'utils';
function OfferFooter({
  isSellerPreference = false,
  placeholder = false,
  offer,
  globalCounter,
  isGlobalCounter,
  onAccept,
  onReject,
  onRescind,
  onCancel,
  onBuyerRescind,
  onApprove,
  onEdit,
  onCounter,
  isMultipleCounter,
  isFinalCounter,
  historyView = false,
  hideOfferInfo,
}) {
  const getName = (user) => {
    return `${user.firstName.charAt(0)}. ${user.lastName}`;
  };

  return (
    <FooterContainer preference={isSellerPreference} historyView={historyView} mobileView={hideOfferInfo}>
      {!placeholder && (
        <>
          {isSellerPreference && (
            <div className='d-flex flex-column m-auto'>
              <div className='d-flex align-items-center'>
                <Icon name='selling' />
                <OfferInfoContainer className='fw-bold'>Review Offer</OfferInfoContainer>
              </div>
              <div className='mt-1'>Consult with your seller and respond to any offer.</div>
            </div>
          )}
          {!isSellerPreference && (
            <>
              {!historyView && (
                <>
                  {!hideOfferInfo && (
                    <div className='d-flex'>
                      <OfferInfoContainer>
                        <OfferIdText>[ID# ${getOfferIdStr(offer?.id)}]</OfferIdText>
                        {offer.primaryBuyer && (
                          <span className='fw-bold'>
                            {offer.primaryBuyer.ownerType === 'INDIVIDUAL'
                              ? getName(offer.primaryBuyer.user)
                              : offer.primaryBuyer.orgName}
                          </span>
                        )}
                        {offer.secondaryBuyers?.length > 0 && offer.secondaryBuyers[0]?.user && (
                          <span className='fw-bold'>{getName(offer.secondaryBuyers[0].user)}</span>
                        )}
                      </OfferInfoContainer>
                    </div>
                  )}
                  {onAccept && (
                    <ActionButton green onClick={onAccept}>
                      Accept
                    </ActionButton>
                  )}
                  {onReject && (
                    <ActionButton white onClick={onReject}>
                      Reject
                    </ActionButton>
                  )}
                  {onCancel && (
                    <ActionButton white onClick={onCancel}>
                      Cancel
                    </ActionButton>
                  )}
                  {onRescind && (
                    <ActionButton red onClick={onRescind}>
                      Rescind Offer
                    </ActionButton>
                  )}
                  {onBuyerRescind && (
                    <ActionButton red onClick={onBuyerRescind}>
                      Rescind Offer
                    </ActionButton>
                  )}
                  {onApprove && (
                    <ActionButton green onClick={onApprove}>
                      Approve
                    </ActionButton>
                  )}
                  {onEdit && (
                    <ActionButton white onClick={onEdit}>
                      {!isGlobalCounter
                        ? 'Edit'
                        : globalCounter.state === 'ACCEPTED'
                        ? 'Edit GC End Date'
                        : 'Change GC End Date'}
                    </ActionButton>
                  )}
                  {onCounter && (
                    <ActionButton white onClick={onCounter}>
                      {isMultipleCounter ? 'Multiple Counter' : isFinalCounter ? 'Final Counter' : 'Counter'}
                    </ActionButton>
                  )}
                </>
              )}
              {historyView && (
                <>
                  <div className='d-flex m-auto'>
                    <Icon className='m-auto' name='offer' />
                    <OfferInfoContainer>
                      <span>
                        [{offer.createTime ? moment(offer.createTime).format('MM/DD/YYYY') : 'Not Specified'}]
                      </span>
                      {offer.primaryBuyer && <span className='fw-bold'>{getOfferTitle(offer.offerType)}</span>}
                    </OfferInfoContainer>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </FooterContainer>
  );
}

OfferFooter.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  offer: PropTypes.object,
  globalCounter: PropTypes.object,
  isGlobalCounter: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onRescind: PropTypes.func,
  onBuyerRescind: PropTypes.func,
  onApprove: PropTypes.func,
  onEdit: PropTypes.func,
  onCounter: PropTypes.func,
  isMultipleCounter: PropTypes.bool,
  isFinalCounter: PropTypes.bool,
  historyView: PropTypes.bool,
};

export default OfferFooter;
