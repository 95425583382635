import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 216px;
  height: ${({ historyView }) => (historyView ? 110 : 240)}px;
  margin-left: ${({ preference }) => (preference ? 0 : 10)}px;
  padding: 35px 20px;
  border: 1px solid var(--gray6);
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ background }) => background ?? 'var(--gray8)'};
  font-size: 14px;
  line-height: 20px;
`;

export const OfferIdText = styled.span`
  line-break: anywhere;
`;

export const OfferInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionBanner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 21px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  color: ${(props) => (props.variant === 'warning' ? 'var(--offer1-black)' : 'white')};
  background-color: ${(props) =>
    props.variant === 'warning'
      ? 'var(--orange)'
      : props.variant === 'warning-cone'
      ? 'var(--offer1-blue)'
      : 'var(--danger)'};

  svg {
    margin-right: 10px;
  }
`;
