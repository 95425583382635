import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import { ButtonWrapper, Icon, OutGrayButton, PrimaryGreenButton } from 'components';
import Swal from 'sweetalert2';
import { ModalHeader, DropContainer } from './adminPage.styled';

function UploadContractModal({ show, onClose, onUpload }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    validator: fileLengthValidator,
  });

  function fileLengthValidator(file) {
    if (file.size > 20971520) {
      Swal.fire({
        text: 'Your file size is too big. Please only upload files of 20MB or less.',
        icon: 'error',
      });
      return true;
    }

    return null;
  }

  return (
    <>
      <Modal show={show} backdrop='static' keyboard={false} size='lg' centered>
        <ModalHeader>
          <h3>Upload Contract</h3>
          <ButtonWrapper className='close-button' onClick={onClose}>
            <Icon name='close' />
          </ButtonWrapper>
        </ModalHeader>
        <Modal.Body>
          <DropContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {!acceptedFiles.length && (
              <span>
                To upload a contract document <u>click here</u> or drop a file in this box
              </span>
            )}
            {acceptedFiles.length > 0 &&
              acceptedFiles.map((file, index) => (
                <span key={`${file.path}-${index}`}>
                  {file.path} - {file.size} bytes
                </span>
              ))}
          </DropContainer>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryGreenButton disabled={acceptedFiles.length === 0} onClick={() => onUpload(acceptedFiles[0])}>
            Upload
          </PrimaryGreenButton>
          <OutGrayButton onClick={onClose}>Close</OutGrayButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

UploadContractModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  listingId: PropTypes.string.isRequired,
};

export default UploadContractModal;
