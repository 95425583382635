import styled from 'styled-components';

export const Header = styled.div`
  background-color: var(--offer1-green);
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: white;
  padding: 8px 8px;
  font-weight: 500;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 39%;
  height: 100%;
  border: 1px solid black;
  margin-bottom: 64px;
`;

export const HorizontalRule = styled.div`
  width: 90%;
  border-style: solid none none none;
  border-width: 2px;
  border-color: var(--gray3);
  margin: 0px 0px 8px 0px;
  align-self: center;
`;

export const DescriptionText = styled.p`
  padding: ${(props) => (props.topMargin ? '12px' : '0px')} 32px 0px 32px;
  font-size: 13px;
`;

export const SubheadingText = styled.p`
  padding: 8px 32px 0px 32px;
  font-size: 13px;
  font-weight: bold;
`;
