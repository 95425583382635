import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { ModalContainer } from './listingDetailPage.styled';
import {
  ModalBox,
  ModalContent,
  ModalHeader,
  HouseImage,
  WelcomeText,
  LoginText,
  GetStartedButton,
} from './loginRedirectModal.styled';

function LoginRedirectModal({ onClose, onRedirect }) {
  return (
    <ModalContainer>
      <ModalBox>
        <ModalHeader>
          <div onClick={onClose}>
            <Icon name='close' />
          </div>
        </ModalHeader>
        <ModalContent>
          <HouseImage></HouseImage>
          <WelcomeText>Welcome to Offer1</WelcomeText>
          <LoginText>
            Log in or register to fully experience
            <br />
            Offer1’s powerful tools.
          </LoginText>
          <GetStartedButton className='' onClick={onRedirect}>
            Get Started
          </GetStartedButton>
        </ModalContent>
      </ModalBox>
    </ModalContainer>
  );
}

LoginRedirectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

export default LoginRedirectModal;
