import { createAsyncAction } from 'utils/store';

export const FetchSubscription = createAsyncAction('subscriptions', 'fetchSubscription', {
  REQUEST: [],
  SUCCESS: ['subscription'],
});

export const FetchPricing = createAsyncAction('subscriptions', 'fetchPricing', {
  REQUEST: [],
  SUCCESS: ['pricing'],
});

export const SubscriptionsAsyncActions = {
  FetchSubscription,
  FetchPricing,
};
