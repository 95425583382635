import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

export const Header = styled(Modal.Header)`
  position: relative;
  display: block;
  width: 100%;
  padding: 24px;

  h3 {
    font-size: 32px;
    font-weight: bold;
    color: var(--offer1-green);
  }

  p {
    color: var(--gray2);
  }

  .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  padding: 12px;
`;

export const HorizontalRule = styled.div`
  border-style: solid none none none;
  border-width: 1px;
  border-color: var(--gray3);
  margin: 8px 0;
`;

export const ErrorMessage = styled(Col)`
  color: red;
`;
