import { isNil } from 'lodash';
import { OFFER1_BLUE, OFFER1_GREEN } from 'constants/styleConstants';

export const getOfferTitle = (offerType) => {
  const title = {
    GLOBAL: 'Global Counter',
    GLOBAL_COUNTER: 'Global Counter',
    GLOBAL_COUNTER_RESPONSE: 'GCR',
    INITIAL: 'Original Offer',
    BUYER_COUNTER: 'BCO',
    SELLER_COUNTER: 'SCO',
    SELLER_MULTIPLE_COUNTER: 'MCO',
    BUYER_MULTIPLE_COUNTER: 'BCO',
  };
  return title[offerType] ?? '';
};

export const getOfferIdStr = (id) => {
  return id.substring(0, 4) + '-' + id.substring(id.length - 4);
};

export const getOfferHeaderBackground = (offerType, offerState) => {
  if (!isNil(offerState) && (offerState === 'LIVE_EDIT' || offerState === 'LIVE_EDIT_BUYER')) return 'var(--gray2)';
  if (isNil(offerType)) return 'var(--gray8)';
  if (
    'GLOBAL_COUNTER_RESPONSE' === offerType ||
    'BUYER_COUNTER' === offerType ||
    'BUYER_MULTIPLE_COUNTER' === offerType ||
    'INITIAL' === offerType
  ) {
    return OFFER1_GREEN;
  }
  return OFFER1_BLUE;
};
