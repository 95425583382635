import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import app from './app';
import dashboard from './dashboard';
import admin from './admin';
import listings from './listings';
import buyers from './buyers';
import offers from './offers';
import agent from './agent';
import createOffer from './createOffer';
import createListing from './createListing';
import contract from './contract';
import subscriptions from './subscriptions';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    app,
    dashboard,
    admin,
    listings,
    buyers,
    offers,
    agent,
    createOffer,
    createListing,
    contract,
    subscriptions,
  });

export default createRootReducer;
