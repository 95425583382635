import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ButtonWrapper, Icon } from 'components';
import { getListingDetails } from 'store/selectors/admin';
import { ModalHeader, AgentName } from './adminPage.styled';
import { formatCurrency } from 'utils/formattingUtils';
import AdminTable from './adminTable';
import ListingOfferDashboardModal from './listingOfferDashboard';
import { AdminAsyncActions } from 'store/actions/admin';
import { Offer1GreenButton } from 'containers/ListingDetailPage/listingDetailPage.styled';

function ListingDetailsModal({ show, onClose }) {
  const dispatch = useDispatch();
  const details = useSelector(getListingDetails);

  const [showOfferDashboard, setShowOfferDashboard] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [showBuyerInfo, setShowBuyerInfo] = useState(false);
  const { isSuccess } = useSelector(AdminAsyncActions.FetchOfferDashboard.StatusSelector());

  useEffect(() => {
    if (isSuccess) {
      setShowOfferDashboard(true);
    }
  }, [isSuccess]);

  const handleClickBuyer = useCallback(
    (offer) => {
      dispatch(
        AdminAsyncActions.FetchOfferDashboard.Actions.REQUEST(
          offer.row.original.listingId,
          offer.row.original.primaryBuyer.user.id,
          'buyer'
        )
      );
      setSelectedUser(offer.row.original.primaryBuyer.user);
    },
    [dispatch]
  );

  const handleClickBuyerAgent = useCallback(
    (offer) => {
      dispatch(
        AdminAsyncActions.FetchOfferDashboard.Actions.REQUEST(
          offer.row.original.listingId,
          offer.row.original.agent.id,
          'buyer'
        )
      );
      setSelectedUser(offer.row.original.agent);
    },
    [dispatch]
  );

  const handleClickListingAgent = useCallback(
    (listing) => {
      dispatch(AdminAsyncActions.FetchOfferDashboard.Actions.REQUEST(listing?.id, listing?.listingAgent.id, 'seller'));
      setSelectedUser(listing?.listingAgent);
      setShowBuyerInfo(true);
    },
    [dispatch]
  );

  const handleClickSeller = useCallback(
    (listing) => {
      dispatch(
        AdminAsyncActions.FetchOfferDashboard.Actions.REQUEST(listing?.id, listing?.primaryOwner.user.id, 'seller')
      );
      setSelectedUser(listing?.primaryOwner.user);
    },
    [dispatch]
  );

  const handleCloseDashboard = () => {
    setShowOfferDashboard(false);
    setSelectedUser(undefined);
    setShowBuyerInfo(false);
  };

  const columns = useMemo(
    () => [
      {
        id: 'offerAmount',
        Header: 'Offer Amount',
        accessor: (offer) => `${formatCurrency(offer.terms.offerAmount)}`,
        filter: 'text',
      },
      {
        id: 'agent',
        Header: 'Buyer Agent',
        accessor: (offer) => (offer.agent ? `${offer.agent.firstName} ${offer.agent.lastName}` : ''),
        Cell: ({ cell }) => <AgentName onClick={() => handleClickBuyerAgent(cell)}>{cell.value}</AgentName>,
        filter: 'text',
      },
      {
        id: 'buyer',
        Header: 'Primary Buyer',
        accessor: (offer) => `${offer.primaryBuyer.user.firstName} ${offer.primaryBuyer.user.lastName}`,
        Cell: ({ cell }) => <AgentName onClick={() => handleClickBuyer(cell)}>{cell.value}</AgentName>,
        filter: 'text',
      },
    ],
    [handleClickBuyer, handleClickBuyerAgent]
  );

  return (
    <>
      <Modal show={show} backdrop='static' keyboard={false} size='xl' centered>
        <ModalHeader>
          <h3>Listing Details</h3>
          <ButtonWrapper className='close-button' onClick={onClose}>
            <Icon name='close' />
          </ButtonWrapper>
        </ModalHeader>
        <Modal.Body>
          <Offer1GreenButton onClick={() => handleClickListingAgent(details?.listing)}>
            List Agent View
          </Offer1GreenButton>{' '}
          <Offer1GreenButton onClick={() => handleClickSeller(details?.listing)}>Seller View</Offer1GreenButton>
          <AdminTable columns={columns} data={details?.offers} />
        </Modal.Body>
      </Modal>
      <ListingOfferDashboardModal
        show={showOfferDashboard}
        onClose={handleCloseDashboard}
        user={selectedUser}
        showBuyerAgent={showBuyerInfo}
      />
    </>
  );
}

ListingDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ListingDetailsModal;
