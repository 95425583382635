import { call, put } from 'redux-saga/effects';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { apiCall } from 'utils/apiCall';

function* createProvisional(action) {
  const {
    CreateProvisionalListing: {
      Actions: { SUCCESS, FAILURE },
    },
  } = CreateListingAsyncActions;
  const {
    payload: { listing },
  } = action;

  try {
    yield call(apiCall, '/listings/provisional', 'post', listing);
    yield put(SUCCESS());
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default createProvisional;
