import styled from 'styled-components';

export const TabContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--gray4);
`;

export const TabAction = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-left: 4px solid ${(props) => (props.active ? 'var(--offer1-blue)' : 'rgba(0, 0, 0, 0)')};
  background-color: ${(props) => (props.active ? 'var(--gray10)' : 'rgba(0, 0, 0, 0)')};
  padding: 0px 24px 0px 20px;
  cursor: pointer;
  outline: 0px;
  user-select: none;
  height: 56px;

  &:hover {
    background-color: ${(props) => (props.active ? 'var(--gray10)' : 'var(--gray5)')};
  }
`;

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 32px 32px 16px;
  gap: 16px;
`;

export const SignatureCaptureContainer = styled.div`
  position: relative;
  background-color: var(--gray5);
  height: 220px;

  > canvas {
    position: relative;
  }
`;

export const SignatureDivider = styled.div`
  position: absolute;
  bottom: 64px;
  left: 24px;
  right: 24px;
  height: 1px;
  background-color: rgb(221, 221, 221);
  pointer-events: none;
`;

export const SignatureClearButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border: none;
  user-select: none;
  color: var(--offer1-blue);
  position: absolute;
  bottom: 32px;
  right: 32px;
  background: transparent;
`;

export const SignatureInput = styled.input`
  font-size: 110px;
  line-height: 1;
  position: absolute;
  top: 32px;
  bottom: 32px;
  left: 32px;
  right: 32px;
  background-color: transparent;
  outline: none;
  border: none;
  overflow: hidden;
  padding: 0;
`;
