import React from 'react';
import PropTypes from 'prop-types';

import { EmptyContainer, EmptyTextContainer, EmptyHeader, EmptyDescription } from './empty.styled';

function Empty({ src, title, description, width, height }) {
  return (
    <EmptyContainer>
      <img src={src} alt='empty' width={width} height={height} />

      <EmptyTextContainer>
        <EmptyHeader>{title}</EmptyHeader>
        <EmptyDescription>{description}</EmptyDescription>
      </EmptyTextContainer>
    </EmptyContainer>
  );
}

Empty.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Empty;
