import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useResponsive } from 'hooks';
import CreateOfferPage from 'containers/CreateOfferPage';
import NotFoundPage from 'containers/NotFoundPage';
import { OffersAsyncActions } from 'store/actions/offers';
import { getOffer } from 'store/selectors/offers';

function OfferDetailPage() {
  const isMobile = useResponsive();
  const dispatch = useDispatch();
  const params = useParams();
  const offer = useSelector(getOffer);
  const { isSuccess, error } = useSelector(OffersAsyncActions.FetchOffer.StatusSelector());

  useEffect(() => {
    dispatch(OffersAsyncActions.FetchOffer.Actions.REQUEST(params.id));
    return () => {
      dispatch(OffersAsyncActions.FetchOffer.Actions.RESET());
    };
  }, [dispatch, params.id]);

  if (error?.httpStatus === 403 || error?.httpStatus === 404) {
    return <NotFoundPage />;
  }

  return <div className={isMobile ? 'mb-4' : 'mb-4 p-3'}>{isSuccess && <CreateOfferPage existingOffer={offer} />}</div>;
}

export default OfferDetailPage;
