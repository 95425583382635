import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import {
  FormField,
  PasswordField,
  EmailField,
  DropDown,
  RadioButtons,
  PhoneField,
  QuestionnaireModal,
  PrimaryGreenButton,
  Typography,
  CheckBox,
} from 'components';
import { isNil } from 'lodash';
import RegistrationSuccess from './registrationSuccess';
import { AuthAsyncActions } from 'store/actions/auth';
import { verifyAgent } from 'services/client';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import QueryString from 'query-string';
import { URLUtils } from 'utils';
import {
  SectionText,
  TosText,
  SectionTitle,
  StateNotification,
  HouseImg,
  RegisterContent,
} from './customerRegistration.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';
import { yesNoOptions } from 'constants/optionConstants';
import { getMetaListingStates } from 'store/selectors/app';
import { getSignupError, getSignupSuccess } from 'store/selectors/auth';
import { useLocation } from 'react-router-dom';

const CustomerRegistration = () => {
  const dispatch = useDispatch();
  const stateList = useSelector(getMetaListingStates);
  const location = useLocation();

  const error = useSelector(getSignupError);
  const isSuccess = useSelector(getSignupSuccess);

  const [accountType, setAccountType] = useState(null);
  const [isAgent, setIsAgent] = useState(null);

  // initialize state to hold validity of form fields
  const [newAccount, setNewAccount] = useState({
    firstname: '',
    firstnameValid: false,
    lastname: '',
    lastnameValid: false,
    licenseNumber: '',
    licenseNumberValid: false,
    brokerageLicenseNumber: '',
    brokerageLicenseNumberValid: false,
    email: '',
    password: '',
    emailValid: false,
    passwordValid: false,
    verifyPassword: '',
    verifyPasswordValid: false,
    licenseState: undefined,
    phone: '',
    phoneNumberValid: false,
    brokerageName: '',
    brokerageNameValid: false,
    agentVerified: false,
    agentVerifiedMessage: '',
    isSubmitted: false,
    accountType: '',
    showQuestionnaire: false,
    optIn: false,
    optInValid: true,
  });

  useEffect(() => {
    const params = QueryString.parse(location.search);
    if (params.affiliate) {
      setAccountType(params.affiliate);
      setIsAgent(true);
    }
  }, [location.search]);

  useEffect(() => {
    return () => {
      dispatch(AuthAsyncActions.SignUp.Actions.RESET());
    };
  }, [dispatch]);

  // state change watch functions for each field
  const firstnameChanged = (state) => {
    setNewAccount({
      ...newAccount,
      firstname: state.value,
      firstnameValid: state.errors.length === 0,
    });
  };

  const lastnameChanged = (state) => {
    setNewAccount({
      ...newAccount,
      lastname: state.value,
      lastnameValid: state.errors.length === 0,
    });
  };

  const emailChanged = (state) => {
    setNewAccount({
      ...newAccount,
      email: state.value.trim(),
      emailValid: state.errors.length === 0,
    });
  };

  const passwordChanged = (state) => {
    setNewAccount({
      ...newAccount,
      password: state.value,
      passwordValid: state.errors.length === 0,
    });
  };

  const verifypasswordChanged = (state) => {
    setNewAccount({
      ...newAccount,
      verifyPassword: state.value,
      verifyPasswordValid: state.errors.length === 0,
    });
  };

  const licenseNumberChanged = (state) => {
    setNewAccount({
      ...newAccount,
      licenseNumber: state.value,
      licenseNumberValid: state.errors.length === 0,
    });
  };

  const brokerageLicenseNumberChanged = (state) => {
    setNewAccount({
      ...newAccount,
      brokerageLicenseNumber: state.value,
      brokerageLicenseNumberValid: state.errors.length === 0,
    });
  };

  const phoneChanged = (state) => {
    setNewAccount({
      ...newAccount,
      phone: state.value,
      phoneNumberValid: state.errors === false,
    });
  };

  const brokerageNameChanged = (state) => {
    setNewAccount({
      ...newAccount,
      brokerageName: state.value,
      brokerageNameValid: state.errors.length === 0,
    });
  };

  const handleStateChange = (e) => {
    const { value } = e.target;
    setNewAccount({
      ...newAccount,
      licenseState: value,
    });
  };

  const handleAgentSelection = (event) => {
    setIsAgent(event.target.value === 'yes');
    setNewAccount({
      ...newAccount,
      isAgent: event.target.value === 'yes',
      licenseState: event.target.value === 'yes' ? 'CA' : undefined,
    });
  };

  const handleOptInChange = (event) => {
    setNewAccount({
      ...newAccount,
      optIn: event.target.checked,
    });
  };

  const formValidated = () => {
    const {
      firstnameValid,
      lastnameValid,
      emailValid,
      passwordValid,
      verifyPasswordValid,
      isAgent,
      licenseNumberValid,
      phoneNumberValid,
      brokerageNameValid,
      brokerageLicenseNumberValid,
      agentVerified,
    } = newAccount;
    return (
      firstnameValid &&
      lastnameValid &&
      emailValid &&
      phoneNumberValid &&
      passwordValid &&
      verifyPasswordValid &&
      (isAgent === false ||
        (isAgent && licenseNumberValid && brokerageNameValid && agentVerified && brokerageLicenseNumberValid))
    );
  };

  const handleRegistrationSubmit = () => {
    setNewAccount({
      ...newAccount,
      optInValid: !!newAccount.optIn,
    });
    if (formValidated() && newAccount.optIn) {
      setNewAccount({
        ...newAccount,
        isSubmitted: true,
      });
      onFinish(true);
    }
  };

  const verifyAgentAccount = async () => {
    let messages = '';

    if (!isAgent || !newAccount.licenseNumberValid || !newAccount.lastnameValid) {
      return;
    }
    let verifyResponse = false;
    if (isAgent && newAccount.licenseNumberValid && newAccount.lastnameValid && newAccount.licenseState) {
      try {
        const data = await verifyAgent(newAccount.licenseState, newAccount.licenseNumber, newAccount.lastname);
        verifyResponse = data?.validAgent;
        if (verifyResponse) {
          messages = 'Success';
        } else {
          messages = 'Agent information does not match any BRE/DRE records.';
        }
      } catch (error) {
        messages = 'Failed to verify license';
      }
    }
    setNewAccount({
      ...newAccount,
      agentVerifiedMessage: messages,
      agentVerified: verifyResponse,
    });
  };

  const buildRequest = (newAccount) => {
    const {
      firstname,
      lastname,
      email,
      password,
      licenseNumber,
      licenseState,
      phone,
      brokerageName,
      brokerageLicenseNumber,
    } = newAccount;

    const payload = {
      email,
      phone,
      firstName: firstname,
      lastName: lastname,
      password,
    };

    return {
      ...payload,
      agent: isAgent
        ? {
            licenseNumber,
            licenseState,
            phone: phone,
            brokerage: {
              name: brokerageName,
              licenseNumber: brokerageLicenseNumber,
            },
          }
        : null,
    };
  };

  const onFinish = (formValid) => {
    if (formValid) {
      if (accountType === 'trailblazer') {
        setNewAccount({
          ...newAccount,
          showQuestionnaire: true,
        });
      } else if (!isNil(accountType)) {
        dispatch(
          AuthAsyncActions.SignUp.Actions.REQUEST({
            ...buildRequest(newAccount),
            campaign: accountType,
          })
        );
      } else {
        dispatch(AuthAsyncActions.SignUp.Actions.REQUEST(buildRequest(newAccount)));
      }
    }
  };

  const onFinishTrailblazer = (questionnaire) => {
    dispatch(
      AuthAsyncActions.SignUp.Actions.REQUEST({
        ...buildRequest(newAccount),
        questionnaire,
        campaign: 'trailblazer',
      })
    );
  };

  const validateName = (value, label) => {
    const regex = /^[a-z]{2,}/i;
    if (!regex.test(value)) throw new Error(label + ' is invalid');
  };

  const validateLicense = (value, label) => {
    const regex = /^[a-zA-Z0-9]{1}/i;
    if (!regex.test(value)) throw new Error(label + ' is invalid');
  };

  //const { error, isSuccess } = this.props;
  const {
    firstname,
    lastname,
    email,
    licenseNumber,
    phone,
    brokerageName,
    brokerageLicenseNumber,
    licenseState,
    isSubmitted,
    showQuestionnaire,
    optIn,
    optInValid,
  } = newAccount;

  return !isSuccess ? (
    <>
      <Helmet>
        <title>Customer registration</title>
        <meta name='description' content='Customer registration' />
      </Helmet>
      <MainContainerMd>
        <RegisterContent>
          <Row>
            <Col md={3} className='d-flex justify-content-center'>
              <img
                className='w-100 d-none d-md-block'
                src={URLUtils.getPublicImageUrl('homebuying-man.svg')}
                alt='person buying home'
              />
            </Col>

            <Col md={6}>
              <div className='row d-flex justify-content-center mt-4'>
                <SectionTitle>Welcome!</SectionTitle>
              </div>
              <SectionText>
                We make complex problems simple through our behind the scenes Tech. Worry less, and sell more!
              </SectionText>
              <div className='pt-3 border-gray'>
                {error && (
                  <Typography as='h6' className='mt-2' color={'danger'} center>
                    {error.message.includes('email') ? 'Email has already been registered' : error.message}
                  </Typography>
                )}
                {/** Render the agent fields **/}
                <div>
                  {accountType !== 'trailblazer' && accountType !== 'listing' && (
                    <>
                      <div className='control-label'>Are you a licensed agent?*</div>
                      <RadioButtons
                        items={yesNoOptions}
                        groupName='isAgent'
                        selectedOption={isAgent === null ? null : isAgent ? 'yes' : 'no'}
                        onStateChanged={handleAgentSelection}
                        required
                      />
                    </>
                  )}
                  <Collapse in={isAgent}>
                    <div id='included-items'>
                      <FormField
                        fieldId='licenseNumber'
                        label='License Number'
                        placeholder='BRE# or DRE#'
                        validator={(value) => validateLicense(value, 'License Number')}
                        initialValue={licenseNumber}
                        onStateChanged={licenseNumberChanged}
                        onBlur={verifyAgentAccount}
                        required
                        autoComplete='new-license-number'
                        error={isSubmitted && !licenseNumber && 'License Number is required'}
                      />
                      {newAccount.agentVerifiedMessage === 'Success' && (
                        <StateNotification className='text-success'>
                          <AiOutlineCheck /> License has been verified
                        </StateNotification>
                      )}
                      {!newAccount.agentVerified && newAccount.agentVerifiedMessage !== '' && (
                        <>
                          <StateNotification className='text-danger'>
                            <AiOutlineClose />
                            {newAccount.agentVerifiedMessage}
                          </StateNotification>
                          <StateNotification className='text-danger'>
                            Please check that the DRE#, state and last name fields are correct.
                          </StateNotification>
                        </>
                      )}

                      <DropDown
                        label='State*'
                        groupName='state'
                        selectedOption={licenseState}
                        onStateChanged={handleStateChange}
                        items={stateList}
                      />
                      <StateNotification>
                        Note: Currently only CA &amp; FL agents are allowed to list and create offers
                      </StateNotification>

                      <FormField
                        fieldId='brokerageName'
                        label='Brokerage Name'
                        validator={(value) => validateName(value, 'Brokerage Name')}
                        initialValue={brokerageName}
                        onStateChanged={brokerageNameChanged}
                        required
                        autoComplete='new-brokerage-name'
                        error={isSubmitted && !brokerageName && 'Brokerage Name is required'}
                      />

                      <FormField
                        fieldId='brokerageLicenseNumber'
                        label='Brokerage License Number'
                        placeholder='DRE#'
                        validator={(value) => validateLicense(value, 'Brokerage License Number')}
                        initialValue={brokerageLicenseNumber}
                        onStateChanged={brokerageLicenseNumberChanged}
                        required
                        autoComplete='new-brokerage-license-number'
                        error={isSubmitted && !brokerageLicenseNumber && 'Brokerage License Number is required'}
                      />
                    </div>
                  </Collapse>
                </div>
                {isSubmitted && 'isSubmitted'}
                {/** Render the fullname form field passing the name validation fn **/}
                {isAgent !== null && (
                  <>
                    <FormField
                      fieldId='firstname'
                      label='First Name'
                      validator={(value) => validateName(value, 'First Name')}
                      initialValue={firstname}
                      onStateChanged={firstnameChanged}
                      required
                      autoComplete='new-first-name'
                      error={isSubmitted && !firstname && 'First Name is required'}
                    />

                    <FormField
                      fieldId='lastname'
                      label='Last Name'
                      validator={(value) => validateName(value, 'Last Name')}
                      initialValue={lastname}
                      onStateChanged={lastnameChanged}
                      onBlur={verifyAgentAccount}
                      required
                      autoComplete='new-last-name'
                      error={isSubmitted && !lastname && 'Last Name is required'}
                    />
                    {!newAccount.agentVerified && newAccount.agentVerifiedMessage !== '' && (
                      <>
                        <StateNotification className='text-danger'>
                          <AiOutlineClose />
                          {newAccount.agentVerifiedMessage}
                        </StateNotification>
                        <StateNotification className='text-danger'>
                          Please check that the DRE#, state and last name fields are correct.
                        </StateNotification>
                      </>
                    )}

                    <PhoneField
                      fieldId='phone'
                      onChange={phoneChanged}
                      value={phone}
                      label='Mobile Phone Number'
                      validationMessage='Mobile Phone Number is required'
                      error={isSubmitted && !phone && 'Mobile Phone Number is required'}
                    />

                    {/** Render the email field component **/}
                    <EmailField
                      fieldId='email'
                      label='Email'
                      initialValue={email}
                      onStateChanged={emailChanged}
                      required
                      autoComplete='new-email'
                      error={isSubmitted && !email && 'Email is required'}
                    />
                    {/** Render the password field component using thresholdLength of 8 and minStrength of 2 **/}
                    <PasswordField
                      fieldId='password'
                      label='Password'
                      onStateChanged={passwordChanged}
                      onVerifyStateChanged={verifypasswordChanged}
                      thresholdLength={8}
                      minStrength={1}
                      required
                      autoComplete='new-password'
                      touched={isSubmitted}
                    />
                    <CheckBox checked={optIn} onChange={handleOptInChange} error={!optInValid}>
                      By registering, I agree to OFFER1 Inc.'s{' '}
                      <a href='https://offer1.com/terms.html' target='_blank' rel='noreferrer'>
                        Terms & Conditions
                      </a>{' '}
                      and{' '}
                      <a href='https://offer1.com/privacy.html' target='_blank' rel='noreferrer'>
                        Privacy Policy
                      </a>
                      . By completing this form, I give consent to OFFER1 Inc. to send SMS messages related to their
                      services. We send messages based on offers received for a property in which you have an interest
                      It's possible to receive multiple messages per week. For HELP, email us at{' '}
                      <a href='mailto:support@offer1.com'>support@offer1.com</a> or reply to any text with the word
                      "Help". To unsubscribe from SMS messages, reply STOP. Standard message and data rates may apply.
                    </CheckBox>
                  </>
                )}
              </div>
              <div className='d-flex flex-column justify-content-center align-items-center mb-4'>
                {!newAccount.agentVerified && newAccount.agentVerifiedMessage !== '' && (
                  <>
                    <StateNotification className='text-danger'>{newAccount.agentVerifiedMessage}</StateNotification>
                    <StateNotification className='text-danger'>
                      Please check that the DRE#, state and last name fields are correct.
                    </StateNotification>
                  </>
                )}
                <PrimaryGreenButton
                  type='button'
                  className='text-uppercase py-2 px-5'
                  onClick={handleRegistrationSubmit}
                >
                  Register
                </PrimaryGreenButton>
                {!formValidated() && (
                  <TosText className='text-danger'>
                    Please ensure there are no errors above before trying to Register. All fields are required.
                  </TosText>
                )}
              </div>
            </Col>
            <Col md={3} className='d-none d-md-block align-self-end'>
              <HouseImg src={URLUtils.getPublicImageUrl('house.svg')} />
            </Col>
          </Row>
        </RegisterContent>
      </MainContainerMd>
      <QuestionnaireModal
        show={showQuestionnaire}
        title='Listing Agent Application'
        saveButtonLabel='Register'
        onClose={() => setNewAccount({ ...newAccount, showQuestionnaire: false })}
        onSaveAgentQuestionnaire={onFinishTrailblazer}
      />
    </>
  ) : (
    <RegistrationSuccess />
  );
};

export default CustomerRegistration;
