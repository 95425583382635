import React from 'react';
import classNames from 'classnames';
import { RadioButtons, CheckBox, Divider, AddendumUpload, FormField } from 'components';
import { CheckBoxContainer } from './rapAddenda.styled';
import { isNil } from 'lodash';
import {
  propertyAddendaItemsCA,
  propertyAddendaItemsFL,
  advisoriesItemsCA,
  advisoriesItemsFL,
} from 'constants/listingConstants';
import { yesNoOptions, remainInPropertyOptions } from 'constants/optionConstants';

function RPAAddenda({
  listingState,
  propertyAddenda,
  remainInPropertyAnswer,
  remainInPropertyFile,
  customAddendum,
  customAddendumFile,
  advisories,
  errors,
  onStateChanged,
}) {
  const onRemainInPropertyChange = (event) => {
    const { name, value } = event.target;
    onStateChanged({
      field: name,
      value,
    });
    handleFileSelected('remainInPropertyFile')(null);
  };

  const onRadioYesNoChange = (event) => {
    const { name, value } = event.target;
    onStateChanged({
      field: name,
      value: value === 'yes' ?? false,
    });
    if (value === 'no') {
      onStateChanged({
        field: 'customAddendumFile',
        value: null,
      });
    }
  };

  const onCheckboxChange = (field) => (event) => {
    const { name, checked } = event.target;
    const value = {
      ...(field === 'propertyAddenda' ? propertyAddenda : advisories),
      [name]: checked,
    };
    if (field === 'propertyAddenda' && checked === false) {
      value[`${name}File`] = null;
    }
    onStateChanged({
      field,
      value,
    });
  };

  const handlePropertyAddendaSelected = (name) => (file) => {
    onStateChanged({
      field: 'propertyAddenda',
      value: {
        ...propertyAddenda,
        [name]: file,
      },
    });
  };

  const handleFileSelected = (field) => (file) => {
    onStateChanged({
      field,
      value: file,
    });
  };

  const handlePropertyOtherDescriptionChange = (value) => {
    onStateChanged({
      field: 'propertyAddenda',
      value: {
        ...propertyAddenda,
        otherDescription: value,
      },
    });
  };

  const propertyAddendaItems = listingState === 'FL' ? propertyAddendaItemsFL : propertyAddendaItemsCA;
  const advisoriesItems = listingState === 'FL' ? advisoriesItemsFL : advisoriesItemsCA;

  return (
    <>
      <p className='my-2'>1.) Is the property subject to any of the following property addenda?</p>
      <CheckBoxContainer>
        {propertyAddendaItems.map((item) => (
          <React.Fragment key={`addenda-${item.name}`}>
            <CheckBox
              name={item.name}
              checked={propertyAddenda?.[item.name]}
              onChange={onCheckboxChange('propertyAddenda')}
            >
              {item.label}
            </CheckBox>
            {item.name === 'other' && propertyAddenda?.[item.name] && (
              <FormField
                fieldId='otherDescription'
                initialValue={propertyAddenda?.['otherDescription']}
                onStateChanged={({ value }) => handlePropertyOtherDescriptionChange(value)}
              />
            )}
            {propertyAddenda?.[item.name] && (
              <AddendumUpload
                id={item.name}
                addendumFile={propertyAddenda?.[`${item.name}File`]}
                onFileChange={handlePropertyAddendaSelected(`${item.name}File`)}
                error={errors?.propertyAddenda?.[item.name]}
              />
            )}
          </React.Fragment>
        ))}
      </CheckBoxContainer>
      {listingState === 'CA' && (
        <>
          <Divider className='mx-n2 mx-md-n4 my-3' />
          <p className='my-2'>2.) Does the Seller wish to remain in the property after the close of escrow?</p>
          <RadioButtons
            items={remainInPropertyOptions}
            groupName='remainInPropertyAnswer'
            vertical
            selectedOption={remainInPropertyAnswer}
            onStateChanged={onRemainInPropertyChange}
            error={errors?.remainInPropertyAnswer}
            renderAdditional={(checked) =>
              checked &&
              remainInPropertyAnswer !== 'no' && (
                <AddendumUpload
                  id='remainInProperty'
                  addendumFile={remainInPropertyFile}
                  onFileChange={handleFileSelected('remainInPropertyFile')}
                  error={errors?.remainInProperty}
                />
              )
            }
          />
          <Divider className='mx-n2 mx-md-n4 my-3' />
          <p className={classNames('my-2', { 'is-invalid': errors?.customAddendum })}>
            3.) Do you wish to add a custom Addendum?
          </p>
          <RadioButtons
            items={yesNoOptions}
            groupName='customAddendum'
            selectedOption={isNil(customAddendum) ? '' : customAddendum ? 'yes' : 'no'}
            onStateChanged={onRadioYesNoChange}
            error={errors?.customAddendum}
          />
          {customAddendum && (
            <AddendumUpload
              id='customAddendumFile'
              addendumFile={customAddendumFile}
              error={errors?.customAddendumFile}
              onFileChange={handleFileSelected('customAddendumFile')}
            />
          )}
        </>
      )}
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <p className={classNames('my-2', { 'is-invalid': errors?.occupancyType })}>
        {listingState === 'CA' ? 4 : 2}.) Do you wish to include any of the following Advisories?
      </p>
      <CheckBoxContainer>
        {advisoriesItems.map((item) => (
          <CheckBox
            key={`advisory-${item.name}`}
            name={item.name}
            checked={advisories?.[item.name]}
            onChange={onCheckboxChange('advisories')}
          >
            {item.label}
          </CheckBox>
        ))}
      </CheckBoxContainer>
    </>
  );
}

export default RPAAddenda;
