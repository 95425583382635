import { all, takeLatest } from 'redux-saga/effects';
import { AuthAsyncActions, LOGIN_REDIRECT } from 'store/actions/auth';
import sso from './sso';
import login from './login';
import signup from './signup';
import logout from './logout';
import redirect from './redirect';
import resetPassword from './resetPassword';
import forgotPassword from './forgotPassword';
import verify from './verify';
import update from './update';
import impostorLogin from './impostorLogin';

export default function* root() {
  const { SSO, Login, SignUp, LogOut, ResetPassword, ForgotPassword, VerifyAccount, UpdateAccount, ImpostorLogin } =
    AuthAsyncActions;
  yield all([
    takeLatest(SSO.Types.REQUEST, sso),
    takeLatest(Login.Types.REQUEST, login),
    takeLatest(SignUp.Types.REQUEST, signup),
    takeLatest(ResetPassword.Types.REQUEST, resetPassword),
    takeLatest(ForgotPassword.Types.REQUEST, forgotPassword),
    takeLatest(VerifyAccount.Types.REQUEST, verify),
    takeLatest(UpdateAccount.Types.REQUEST, update),
    takeLatest(LogOut.Types.REQUEST, logout),
    takeLatest(LOGIN_REDIRECT, redirect),
    takeLatest(ImpostorLogin.Types.REQUEST, impostorLogin),
  ]);
}
