import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { ProSidebar } from 'react-pro-sidebar';

export const StyledProSidebar = styled(ProSidebar)`
  height: 100vh !important;
  ${breakpoint('mobile', 'desktop')`
    height: calc(100vh - 58px) !important;
  `}
  .pro-menu {
    padding: 4px 0;
  }

  .pro-menu-item.active {
    font-weight: 700;
  }
  .pro-menu {
    > ul > li > .pro-inner-item {
      border-left: 3px solid transparent;
      &:hover {
        border-left: 3px solid var(--offer1-blue);
      }
    }
    > ul > li.active > .pro-inner-item {
      border-left: 3px solid var(--offer1-blue);
      background-color: white;
    }
  }
  .pro-sidebar-content {
    flex-grow: 0 !important;
  }
`;

export const NavHeader = styled.div`
  line-height: 20px;
  padding: 10px 0 10px 25px;

  img {
    width: 160px;
    height: 38px;
  }
`;

export const MobileHeader = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--gray5);
  height: 58px;
  width: 100%;
  padding: 0 25px;
  z-index: 10;

  img {
    width: 160px;
    height: 38px;
  }
`;

export const ProfileMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 4px;
`;
