import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthUser } from 'hooks';
import { CheckBox, Icon } from 'components';
import {
  ModalContainer,
  ModalBox,
  ModalHeader,
  ModalContent,
  MessageButton,
  MessageArea,
} from './listingDetailPage.styled';
import { formatPhoneNumber } from 'utils';

function MessageAgentModal({ onSubmit, onClose, listing }) {
  const [message, setMessage] = useState('Hi ');
  const [contactInfo, setContactInfo] = useState(true);

  const { isAgent, user } = useAuthUser();

  const appendContactInfo = (message) => {
    return message + `\nEmail:${user.email}\nPhone:${formatPhoneNumber(user.phone)}`;
  };

  useEffect(() => {
    const defaultMsg = [
      'Hi ' + listing.listingAgent.firstName + ',',
      'I have an inquiry about your listing at ' + listing.address.addressLine1 + '...',
      'Sincerely,',
      user.firstName,
    ].join('\n');
    setMessage(defaultMsg);
  }, [user, listing]);

  return (
    <ModalContainer>
      <ModalBox>
        <ModalHeader>
          Message Agent
          <div onClick={onClose}>
            <Icon name='close' />
          </div>
        </ModalHeader>
        <ModalContent>
          <MessageArea
            maxLength={2500}
            rows={5}
            placeholder='Message'
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />

          <CheckBox
            name='contactInfo'
            checked={contactInfo}
            className='w-50 h-50'
            onChange={() => setContactInfo(!contactInfo)}>
            Include contact information
          </CheckBox>
          <MessageButton
            className='w-100'
            onClick={() =>
              onSubmit(contactInfo ? appendContactInfo(message) : message, isAgent ? 'AGENT' : 'CONSUMER')
            }>
            <Icon name='paper-plane' />
            Message
          </MessageButton>
        </ModalContent>
      </ModalBox>
    </ModalContainer>
  );
}

MessageAgentModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MessageAgentModal;
