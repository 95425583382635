import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Helmet from 'react-helmet';
import { FormattedMessage, PasswordField } from 'components';
import routeConstants from 'containers/App/routeConstants';
import QueryString from 'query-string';
import { AuthAsyncActions } from 'store/actions/auth';
import { verifyEmail } from 'services/client';
import { URLUtils } from 'utils';
import { Container, SectionTitle, SectionText, HouseImg, TosText } from './buyerRegistrationPage.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';
import Spinner from 'react-bootstrap/Spinner';

function BuyerRegistrationPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // initialize state to hold validity of form fields
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [verifyPasswordValid, setVerifyPasswordValid] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const { isSuccess, error } = useSelector(AuthAsyncActions.ResetPassword.StatusSelector());
  const params = QueryString.parse(location.search);

  if (!params.token || !params.email) {
    history.push('/login');
  }

  useEffect(() => {
    return () => {
      dispatch(AuthAsyncActions.ResetPassword.Actions.RESET());
    };
  }, [dispatch]);

  useEffect(() => {
    const verify = async () => {
      try {
        setLoading(true);
        await verifyEmail(params.token, params.email);
      } catch (error) {
        setVerificationError(error?.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewPassword = () => {
    setRequestSent(true);
    dispatch(AuthAsyncActions.ResetPassword.Actions.REQUEST(params.token, params.email, password));
  };

  const passwordChanged = (state) => {
    setPassword(state.value);
    setPasswordValid(state.errors.length === 0);
  };

  const verifypasswordChanged = (state) => {
    setVerifyPasswordValid(state.errors.length === 0);
  };

  return (
    <>
      <Helmet>
        <title>Buyer registration</title>
        <meta name='description' content='Buyer registration' />
      </Helmet>
      {loading && (
        <MainContainerMd className='mb-4 d-flex align-items-center justify-content-center'>
          <Spinner className='spinner-img me-4' as='span' animation='border' role='status' aria-hidden='true' />
          <SectionTitle>Verifying...</SectionTitle>
        </MainContainerMd>
      )}
      {!loading && (
        <MainContainerMd className='mb-4 d-flex justify-content-center'>
          <Container>
            <Row>
              <Col sm={3} className='d-flex justify-content-center pt-4'>
                <img
                  className='w-100 d-none d-sm-block'
                  src={URLUtils.getPublicImageUrl('homebuying-man.svg')}
                  alt='person buying home'
                />
              </Col>
              {!verificationError && !error && !isSuccess && (
                <Col sm={6}>
                  <SectionTitle>Hello {params.firstName}!</SectionTitle>
                  <SectionText>
                    {params.consumer === 'buyer' && <FormattedMessage id='auth.accountVerification.welcome.buyer' />}
                    {params.consumer === 'seller' && <FormattedMessage id='auth.accountVerification.welcome.seller' />}
                  </SectionText>
                  <div className='pt-3 border-gray'>
                    {/** Render the password field component using thresholdLength of 8 and minStrength of 3 **/}
                    <PasswordField
                      fieldId='password'
                      placeholder='Password'
                      label='Create Password'
                      value={password}
                      onStateChanged={passwordChanged}
                      onVerifyStateChanged={verifypasswordChanged}
                      thresholdLength={8}
                      minStrength={1}
                      required
                    />
                  </div>
                  <Row className='d-flex justify-content-center mt-4'>
                    {passwordValid && verifyPasswordValid && (
                      <>
                        <button type='button' className='btn text-uppercase auth-button mb-3' onClick={setNewPassword}>
                          Register
                        </button>
                        <TosText>
                          By clicking Register, you agree to our{' '}
                          <a href='https://offer1.com/terms.html'>Terms of Service </a>
                          and <a href='https://offer1.com/privacy.html'>Privacy Policy</a>
                        </TosText>
                      </>
                    )}
                  </Row>
                </Col>
              )}
              {isSuccess && (
                <Col sm={6}>
                  <SectionTitle>Welcome!</SectionTitle>
                  <SectionText>
                    <FormattedMessage id='auth.accountVerification.password.success' />
                  </SectionText>
                  <Row className='d-flex justify-content-center mt-4'>
                    <button
                      type='button'
                      className='btn text-uppercase auth-button'
                      onClick={() => history.push(routeConstants.LOGIN_PATH)}
                    >
                      Login
                    </button>
                  </Row>
                </Col>
              )}
              {(verificationError || (error && requestSent)) && (
                <Col sm={6}>
                  <SectionTitle>
                    <FormattedMessage id='auth.accountVerification.password.cannotVerify' />
                  </SectionTitle>
                  <SectionText>
                    {verificationError || (error && <FormattedMessage id='auth.accountVerification.password.error' />)}
                  </SectionText>
                </Col>
              )}
              <Col sm={3} className='d-none d-sm-flex align-items-end'>
                <HouseImg src={URLUtils.getPublicImageUrl('house.svg')} />
              </Col>
            </Row>
          </Container>
        </MainContainerMd>
      )}
    </>
  );
}

export default BuyerRegistrationPage;
