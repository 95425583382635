import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ButtonWrapper, Icon, OutGrayButton } from 'components';
import AdminTable from './adminTable';
import { ModalHeader } from './adminPage.styled';
import { SelectColumnFilter } from './filters';

function ViewParticipantsModal({ show, onClose, data }) {
  const columns = useMemo(
    () => [
      {
        id: 'role',
        Header: 'Role',
        Filter: SelectColumnFilter,
        accessor: 'type',
        filter: 'includes',
      },
      {
        id: 'name',
        Header: 'Full Name',
        accessor: (user) => `${user.firstName ?? ''} ${user.lastName ?? ''}`,
        filter: 'text',
      },
      {
        Header: 'Cell',
        accessor: 'phone',
        filter: 'text',
      },
      { Header: 'Email', accessor: 'email', filter: 'text' },
      {
        id: 'brokerageName',
        Header: 'Brokerage',
        accessor: 'brokerageName',
        filter: 'text',
      },
    ],
    []
  );

  return (
    <Modal show={show} backdrop='static' keyboard={false} size='lg' centered dialogClassName='modal-90wh' scrollable>
      <ModalHeader>
        <h3>View Participants</h3>
        <ButtonWrapper className='close-button' onClick={onClose}>
          <Icon name='close' />
        </ButtonWrapper>
      </ModalHeader>
      <Modal.Body>
        <AdminTable columns={columns} data={data} />
      </Modal.Body>
      <Modal.Footer>
        <OutGrayButton onClick={onClose}>Close</OutGrayButton>
      </Modal.Footer>
    </Modal>
  );
}

ViewParticipantsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewParticipantsModal;
