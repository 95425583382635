import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const SectionRow = styled(Row)`
  align-items: center;
  border-bottom: 1px solid darkgray;
`;

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid darkgray;
  background-color: white;
  cursor: default;
  width: 720px;
  max-width: 100%;
  ${breakpoint('mobile', 'desktop')`
    margin-top: auto;
  `}
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
  padding: 10px 20px;
  background-color: var(--offer1-blue);
  font-size: 28px;
  font-weight: bold;
`;

export const CloseButton = styled.button`
  font-size: 40px;
  line-height: 56px;
  border: none;
  color: #fff;
  background-color: transparent;
`;

export const Comment = styled.p`
  font-size: 12px;
  color: gray;
  margin-left: 2px;
`;

export const ShowMoreButton = styled.button`
  border: none;
  background: none;
  color: var(--gray2);
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 5px;
    transition: all 0.2s;
    ${(props) => props.more && 'transform: rotate(180deg)'};
  }
`;
