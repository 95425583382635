import { useEffect } from 'react';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      const el = ref?.current;

      if (!el || el.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('touchend', listener, true);
    document.addEventListener('mousedown', listener, true);

    return () => {
      document.removeEventListener('touchend', listener, true);
      document.removeEventListener('mousedown', listener, true);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
