import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function CheckBox({
  className,
  name,
  checked = false,
  onChange,
  children,
  right = false,
  readOnly = false,
  disabled,
  error,
}) {
  return (
    <>
      <div
        className={classNames(
          'form-check form-check-inline px-0',
          {
            'flex-row-reverse': right,
          },
          className
        )}
      >
        <input
          type='checkbox'
          name={name}
          value={name}
          id={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          style={{ minWidth: '20px' }}
          className={classNames({
            'is-invalid': !!error,
          })}
        />
        {children && (
          <label
            className={classNames('form-check-label', {
              'ms-2': !right,
              'me-2': right,
              'is-invalid': !!error,
            })}
            htmlFor={name}
          >
            {children}
          </label>
        )}
      </div>
    </>
  );
}

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  right: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CheckBox;
