import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { CustomFileUpload, ImageWrapper, StyledProfilePicNotice } from './profilePicture.styled';
import { AiOutlineEdit } from 'react-icons/ai';
import { uploadFile } from 'services/client';
import { URLUtils, sanitizeFileName } from 'utils';
import Swal from 'sweetalert2';

function ProfilePicture({ onChange, src, isAgent }) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  useEffect(() => {
    setImagePreviewUrl(src ? (!src.startsWith('http') ? URLUtils.getDocumentUrl(src) : src) : null);
  }, [src]);

  const handlePhotoUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setImagePreviewUrl(URL.createObjectURL(file));

    try {
      const formData = new FormData();
      const sanitizedFileName = sanitizeFileName(file.name);

      formData.append('file', file, sanitizedFileName);
      const uploadedFile = await uploadFile(formData, 'PROFILE_PICTURE');
      onChange(uploadedFile);
    } catch (error) {
      Swal.fire({
        text: 'Error occurred while uploading your avatar',
        icon: 'error',
      });
      setImagePreviewUrl(null);
    }
  };

  return (
    <>
      <div className='d-flex flex-row align-items-end my-4 flex-wrap'>
        <CustomFileUpload htmlFor='photo-upload'>
          <ImageWrapper>
            <img src={imagePreviewUrl || process.env.REACT_APP_URL + '/avatar.svg'} alt='profile-avatar' />
            <div className='edit-avatar'>
              <AiOutlineEdit />
              <span>Edit Photo</span>
            </div>
          </ImageWrapper>
          <input id='photo-upload' type='file' accept='image/*' onChange={handlePhotoUpload} hidden />
        </CustomFileUpload>
        {src && (
          <Button
            variant='secondary'
            className='mt-1'
            onClick={() => onChange({ url: process.env.REACT_APP_URL + '/avatar.svg' })}
          >
            Remove Photo
          </Button>
        )}
        {isAgent && (
          <StyledProfilePicNotice>
            Profile Photo will only be visible between your agent and yourself.
          </StyledProfilePicNotice>
        )}
      </div>
    </>
  );
}

ProfilePicture.propTypes = {
  onChange: PropTypes.func.isRequired,
  src: PropTypes.string,
};

export default ProfilePicture;
