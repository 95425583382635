import React from 'react';
import PropTypes from 'prop-types';
import { Dot } from './dots.styled';

function Dots({ length, active, onChange }) {
  return (
    <div className='d-flex'>
      {[...Array(length)].map((_, index) => (
        <Dot
          key={index}
          isActive={active === index}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
}

Dots.propTypes = {
  length: PropTypes.number,
  active: PropTypes.number,
  onChange: PropTypes.func,
};

export default Dots;
