import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  BuyerCardContainer,
  BuyerContainer,
  BuyerName,
  BuyerTitle,
  ContactContainer,
  HorizontalSeparator,
  CountContainer,
  OfferCount,
  Count,
  BlueText,
} from './buyerCard.styled';
import { Icon, PopoverTooltip, Avatar } from 'components';
import { twoDigits, formatPhoneNumber } from 'utils';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function BuyerCard({ buyer, onActionRequired, className }) {
  const handleClickBuyer = () => {
    onActionRequired();
  };

  return (
    <BuyerCardContainer className={className} onClick={handleClickBuyer}>
      <BuyerContainer>
        {buyer?.user?.profilePictureUrl && <Avatar size={40} src={buyer?.user?.profilePictureUrl} />}
        <div>
          <BuyerName first>
            {buyer.user.firstName} {buyer.user.lastName}
          </BuyerName>
          <BuyerTitle>Primary Buyer</BuyerTitle>
        </div>
        <div>
          <ContactContainer>
            {isMobile ? (
              <a href={`tel:${buyer.user.phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon name='call' />
              </a>
            ) : (
              <PopoverTooltip
                id='buyer-card-primary-phone-number'
                placement='top'
                trigger='hover'
                icon={
                  <CopyToClipboard
                    text={buyer.user.phone ? formatPhoneNumber(buyer.user.phone) : 'N/A'}
                    onCopy={() => toast.success('Copied to clipboard', { icon: '📞' })}
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <Icon name='call' />
                    </div>
                  </CopyToClipboard>
                }
                content={buyer.user.phone ? formatPhoneNumber(buyer.user.phone) : 'N/A'}
              />
            )}
            {isMobile ? (
              <a href={`mailto:${buyer.user.email}`} onClick={(e) => e.stopPropagation()}>
                <Icon name='envelope' />
              </a>
            ) : (
              <PopoverTooltip
                id='buyer-card-primary-email'
                placement='top'
                trigger='hover'
                icon={
                  <CopyToClipboard
                    text={buyer.user.email || 'N/A'}
                    onCopy={() => toast.success('Copied to clipboard', { icon: '✉️' })}
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <Icon name='envelope' />
                    </div>
                  </CopyToClipboard>
                }
                content={buyer.user.email || 'N/A'}
              />
            )}
          </ContactContainer>
        </div>
      </BuyerContainer>
      <BuyerContainer empty={!buyer.secondaryUser}>
        {buyer.secondaryUser && (
          <>
            <div>
              <BuyerName>
                {buyer.secondaryUser.firstName} {buyer.secondaryUser.lastName}
              </BuyerName>
              <BuyerTitle>Secondary Buyer</BuyerTitle>
            </div>
            <div>
              <ContactContainer>
                {isMobile ? (
                  <a href={`tel:${buyer.secondaryUser.phone}`} onClick={(e) => e.stopPropagation()}>
                    <Icon name='call' />
                  </a>
                ) : (
                  <PopoverTooltip
                    id='buyer-card-secondary-phone-number'
                    placement='top'
                    trigger='hover'
                    icon={
                      <CopyToClipboard
                        text={buyer.secondaryUser.phone || 'N/A'}
                        onCopy={() => toast.success('Copied to clipboard', { icon: '📞' })}
                      >
                        <div>
                          <Icon name='call' />
                        </div>
                      </CopyToClipboard>
                    }
                    content={buyer.secondaryUser.phone || 'N/A'}
                  />
                )}
                {isMobile ? (
                  <a href={`mailto:${buyer.secondaryUser.email}`} onClick={(e) => e.stopPropagation()}>
                    <Icon name='envelope' />
                  </a>
                ) : (
                  <PopoverTooltip
                    id='buyer-card-secondary-email'
                    placement='top'
                    trigger='hover'
                    icon={
                      <CopyToClipboard
                        text={buyer.secondaryUser.email || 'N/A'}
                        onCopy={() => toast.success('Copied to clipboard', { icon: '✉️' })}
                      >
                        <div>
                          <Icon name='envelope' />
                        </div>
                      </CopyToClipboard>
                    }
                    content={buyer.secondaryUser.email || 'N/A'}
                  />
                )}
              </ContactContainer>
            </div>
          </>
        )}
      </BuyerContainer>
      <HorizontalSeparator />
      <CountContainer>
        <OfferCount>
          <Count>
            <BlueText>{twoDigits(buyer.activeOffers)}</BlueText>
            <Icon name='thunder' />
          </Count>
          <div>[Active Offers]</div>
        </OfferCount>
        {/* <VerticalSeparator />
          <OfferCount>
            <Count>
              <BlueText>{twoDigits(1)}</BlueText>
              <Watching />
            </Count>
            <div>[Watching]</div>
          </OfferCount> */}
      </CountContainer>
    </BuyerCardContainer>
  );
}

BuyerCard.propTypes = {
  offer: PropTypes.object,
  onActionRequired: PropTypes.func,
  className: PropTypes.string,
};

export default BuyerCard;
