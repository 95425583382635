import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const ForgotPasswordContent = styled.div`
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  border: 3px solid var(--light-blue);
  padding: 30px;
  text-align: center;
`;

export const SectionTitle = styled.span`
  color: var(--offer1-green);
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  ${breakpoint('mobile', 'desktop')`
    font-size: 26px;
  `}
`;

export const SectionBody = styled.span`
  font-size: 18px;
  margin-top: 0.5rem;
  width: 100%;
`;

export const EmailContainer = styled.div`
  width: 100%;
  margin: 2rem 0;
  div.form-group.px-3.pb-2 {
    padding: 0 !important;
  }
`;
