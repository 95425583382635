import styled from 'styled-components';

export const ReceiptCardContainer = styled.div`
  width: 70%;
  border-radius: 4px;
  border: 2px solid var(--gray6);
  padding: 30px 20px 30px 20px;
  background: var(--gray8);
  color: var(--gray2);
  margin: 10px 20px 10px 20px;
`;

export const ItemName = styled.div`
  color: 'var(--gray7)';
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`;

export const ItemCost = styled.div`
  color: 'var(--gray7)';
  font-size: 16px;
  line-height: 20px;
`;

export const ItemSeparator = styled.span`
  display: flex;
  align-items: center;
  height: 20px;
`;
