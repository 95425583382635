import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { isNil } from 'lodash';

const defaultMaskOptions = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: true,
  allowLeadingZeroes: true,
};
function PercentField({
  className = '',
  fieldId,
  label,
  placeholder,
  value,
  onChange,
  touched,
  required,
  disabled = false,
  min = 0,
  max = 100,
  error,
}) {
  const [fieldTouched, setFieldTouched] = useState(touched || false);

  const errors = useMemo(() => {
    if ((isNil(value) || value === '') && required) {
      return `Required`;
    }
    if (value < min || value > max) {
      return 'Invalid range';
    }
  }, [required, value, min, max]);

  const percentMask = useMemo(
    () =>
      createNumberMask({
        ...defaultMaskOptions,
      }),
    []
  );

  return (
    <React.Fragment>
      <Form.Group className={classNames('my-2', className)}>
        {label && label !== '' && (
          <Form.Label htmlFor={fieldId}>
            {label === ' ' ? <span>&nbsp;</span> : label + (required ? '*' : '')}
          </Form.Label>
        )}
        <MaskedInput
          mask={percentMask}
          className={classNames('form-control', {
            'is-invalid': error || (fieldTouched && errors),
          })}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={(e) => {
            setFieldTouched(true);
            onChange({
              field: fieldId,
              value: e.target.value.replaceAll('%', '').replaceAll(',', ''),
              errors: errors,
            });
          }}
          min={min}
          max={max}
          disabled={disabled}
        />
        {fieldTouched && errors && (
          <Form.Text className='error form-hint fw-bold text-end m-0 mb-2'>{errors}</Form.Text>
        )}
      </Form.Group>
    </React.Fragment>
  );
}

PercentField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default PercentField;
