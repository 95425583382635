import React from 'react';
import { useAuthUser } from 'hooks';
import NotFoundPage from 'containers/NotFoundPage';
import AdminContainer from './adminContainer';

function Admin() {
  const { isAdmin } = useAuthUser();
  if (!isAdmin) {
    return <NotFoundPage />;
  }
  return <AdminContainer />;
}

export default Admin;
