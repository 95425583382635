import { FileUpload, PropertyItem } from 'components';
import { URLUtils } from 'utils';

const AddendumUpload = ({ id, addendumFile, onFileChange, error }) => {
  return !addendumFile ? (
    <>
      <FileUpload
        id={id}
        className='ms-4'
        type='OTHER'
        title='Upload Addendum'
        file={addendumFile}
        onFileSelected={onFileChange}
        height={60}
        width={170}
      />
      {!!error && <span className='ps-4 text-danger'>***Form NEEDED</span>}
    </>
  ) : (
    <PropertyItem
      included
      name={addendumFile.name}
      onClick={() => URLUtils.openDocument(addendumFile.url)}
      onClickRemove={() => onFileChange(null)}
    />
  );
};

export default AddendumUpload;
