import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { useAuthUser } from 'hooks';
import { Icon } from 'components';
import { AgentAsyncActions } from 'store/actions/agent';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import BillingPage from 'containers/BillingPage';
import { SubscriptionsAsyncActions } from 'store/actions/subscriptions';

const MySwal = withReactContent(Swal);

function CreateBottomNavBar({
  isListing,
  isReview,
  isEnd,
  showBack,
  showContinue,
  onBack,
  onContinue,
  onUpdateStatus,
  onDelete,
  isBuyerAgent,
  isOfferBuyer,
  isSellerAgent,
  isPrimaryOwner,
  offerCount,
  status,
  canSubmit,
  consumer,
  onHandleViewRpa,
  showDelete,
  isCounter,
  onCounter,
  dirty,
  offerType,
  validSubscription,
  pricingTable,
}) {
  const dispatch = useDispatch();
  const { isListingAgent } = useAuthUser();
  const [showPricingTable, setShowPricingTable] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(AgentAsyncActions.RecordQuestionnaire.Actions.RESET());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(SubscriptionsAsyncActions.FetchPricing.Actions.REQUEST());

    return () => {
      dispatch(SubscriptionsAsyncActions.FetchPricing.Actions.RESET());
    };
  }, [dispatch]);

  const handleDelete = async () => {
    if (offerCount && offerCount > 0 && isListing) {
      MySwal.fire({
        html: (
          <p>
            This property can't be deleted once an offer has been received. Please contact support with any further
            questions. <a href='mailto:support@offer1.com'>support@offer1.com</a> Thank you.
          </p>
        ),
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    const { isConfirmed } = await Swal.fire({
      title: `Delete ${isListing ? 'Listing' : 'Offer'}`,
      text: `Deleting ${
        isListing ? 'a listing' : 'an offer'
      } will completely remove it from your dashboard. Any associated agents and/or ${
        isListing ? 'seller(s) ' : 'buyer(s)'
      } will only be notified if it was a ${isListing ? 'published listing' : 'sent offer'}.`,
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Ok Delete',
    });

    if (isConfirmed) {
      onDelete();
    }
  };

  const counterButtonLabel = useMemo(() => {
    return offerType === 'SELLER_COUNTER' ||
      offerType === 'SELLER_FINAL_COUNTER' ||
      offerType === 'SELLER_MULTIPLE_COUNTER'
      ? 'Send to Buyer'
      : 'Send to Seller';
  }, [offerType]);

  return (
    <>
      {showDelete && (
        <Button variant='outline-danger' size='sm' className='m-2 fit-content' onClick={handleDelete}>
          Delete
        </Button>
      )}
      {!onCounter && isEnd && !isListing && (isBuyerAgent || isOfferBuyer) && (
        <Button variant='secondary' size='sm' className='m-2' onClick={() => onHandleViewRpa()}>
          View Contract
        </Button>
      )}
      <div className='d-flex align-items-start justify-content-between'>
        {showBack && (
          <Button variant='primary' size='sm' className='m-2' onClick={() => onBack()}>
            <Icon name='chevron-left' />
          </Button>
        )}
        <div className='ms-auto text-end'>
          {isEnd && consumer && (
            <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdateStatus('PROVISIONAL')}>
              Submit Provisional Offer
            </Button>
          )}
          {isReview && !isEnd && (
            <Button variant='secondary' size='sm' className='m-2' onClick={() => onBack(true)}>
              {onCounter ? 'Save' : 'Back to Review'}
            </Button>
          )}
          {showContinue && (
            <Button variant='primary' size='sm' className='m-2' onClick={onContinue}>
              <Icon name='chevron-right' />
            </Button>
          )}
          {isEnd &&
            isListing &&
            isListingAgent &&
            (status === 'PENDING_SELLER_APPROVAL' || status === 'PENDING_AGENT_UPDATES') &&
            isSellerAgent && (
              <Button
                variant='primary'
                size='sm'
                className='m-2'
                onClick={() => onUpdateStatus('PENDING_SELLER_APPROVAL')}
              >
                Send to Seller
              </Button>
            )}
          {isEnd &&
            isListing &&
            !validSubscription &&
            (status === 'DRAFT' || status === 'PENDING_SELLER_APPROVAL' || status === 'PENDING_AGENT_UPDATES') &&
            isSellerAgent && (
              <>
                <BillingPage
                  show={showPricingTable}
                  title='Offer1 Subscription'
                  pricingTable={pricingTable?.pricingTableId ?? process.env.REACT_APP_PRODUCT_TABLE}
                  onClose={() => setShowPricingTable(false)}
                />
                <Button variant='primary' size='sm' className='m-2' onClick={() => setShowPricingTable(true)}>
                  Continue
                </Button>
              </>
            )}
          {isEnd && isListing && status === 'PENDING_SELLER_APPROVAL' && isPrimaryOwner && (
            <div className='d-flex justify-content-end'>
              <Button
                variant='secondary'
                size='sm'
                className='m-2'
                onClick={() => onUpdateStatus('PENDING_AGENT_UPDATES')}
              >
                Reject
              </Button>
              <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdateStatus('SELLER_APPROVED')}>
                Approve
              </Button>
            </div>
          )}
          {isEnd && isListing && status === 'DRAFT' && isSellerAgent && (isListingAgent || validSubscription) && (
            <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdateStatus('ACTIVE')}>
              Publish Listing
            </Button>
          )}
          {isEnd && isListing && status === 'ACTIVE' && offerCount === 0 && isSellerAgent && (
            <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdateStatus('UNPUBLISH')}>
              Unpublish
            </Button>
          )}
          {isEnd && !isListing && isBuyerAgent && (status === 'BUYER_REJECTED' || status === 'DRAFT') && (
            <div className='d-flex  justify-content-end'>
              <Button
                variant='primary'
                size='sm'
                className='m-2'
                disabled={!canSubmit}
                onClick={() => onUpdateStatus('PENDING_BUYER_APPROVAL')}
              >
                Send for Buyer Approval
              </Button>
            </div>
          )}
          {isEnd && !isListing && status === 'PENDING_BUYER_APPROVAL' && isOfferBuyer && (
            <div className='d-flex justify-content-end'>
              <Button variant='secondary' size='sm' className='m-2' onClick={() => onUpdateStatus('BUYER_REJECTED')}>
                Reject
              </Button>
              <Button
                variant='primary'
                size='sm'
                className='m-2'
                onClick={() => onUpdateStatus('PENDING_SELLER_AGENT_RESPONSE')}
              >
                Approve
              </Button>
            </div>
          )}
          {isEnd && !isListing && status === 'LIVE_EDIT_BUYER' && isOfferBuyer && (
            <div className='d-flex justify-content-end'>
              <Button
                variant='primary'
                size='sm'
                className='m-2'
                onClick={() => onUpdateStatus('PENDING_SELLER_AGENT_RESPONSE')}
              >
                Confirm Edits
              </Button>
            </div>
          )}
          {isEnd &&
            !isListing &&
            (status === 'LIVE_EDIT' || status === 'PENDING_SELLER_AGENT_RESPONSE') &&
            isBuyerAgent && (
              <div className='d-flex'>
                <Button
                  variant='primary'
                  size='sm'
                  className='m-2'
                  onClick={() => onUpdateStatus('PENDING_SELLER_AGENT_RESPONSE')}
                >
                  Confirm Edits
                </Button>
              </div>
            )}
          {isEnd && !isListing && isCounter && (
            <div className='d-flex justify-content-end'>
              <Button variant='primary' size='sm' className='m-2' disabled={!dirty} onClick={() => onCounter()}>
                {counterButtonLabel}
              </Button>
            </div>
          )}
          <div className='me-2'>
            <small>* denotes a required field</small>
          </div>
        </div>
      </div>
    </>
  );
}

CreateBottomNavBar.propTypes = {
  isListing: PropTypes.bool,
  isReview: PropTypes.bool.isRequired,
  isEnd: PropTypes.bool.isRequired,
  isBuyerAgent: PropTypes.bool,
  isSellerAgent: PropTypes.bool,
  isPrimaryBuyer: PropTypes.bool,
  showBack: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  offerCount: PropTypes.number,
  status: PropTypes.string,
  onHandleViewRpa: PropTypes.func,
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  isCounter: PropTypes.bool,
  onCounter: PropTypes.func,
  onSaveBuyerProfile: PropTypes.func,
  validSubscription: PropTypes.bool,
  pricingTable: PropTypes.object,
};

export default CreateBottomNavBar;
