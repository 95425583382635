import { call, put } from 'redux-saga/effects';
import { SubscriptionsAsyncActions } from 'store/actions/subscriptions';
import { apiCall } from 'utils/apiCall';

function* fetchPricing() {
  const {
    FetchPricing: {
      Actions: { SUCCESS, FAILURE },
    },
  } = SubscriptionsAsyncActions;

  try {
    const response = yield call(apiCall, `/billing/pricing`, 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchPricing;
