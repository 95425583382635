import styled from 'styled-components';

export const PaginationContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--offer1-black);
`;

export const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PageNumber = styled.span`
  padding: 0 8px;
`;
