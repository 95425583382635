import { ReactComponent as AdminIcon } from './svgs/admin.svg';
import { ReactComponent as AlarmIcon } from './svgs/alarm.svg';
import { ReactComponent as ArrowRightIcon } from './svgs/arrow_right.svg';
import { ReactComponent as BathIcon } from './svgs/bath.svg';
import { ReactComponent as BedIcon } from './svgs/bed.svg';
import { ReactComponent as BugIcon } from './svgs/bug.svg';
import { ReactComponent as BuyingIcon } from './svgs/buying.svg';
import { ReactComponent as Call } from './svgs/call.svg';
import { ReactComponent as ChartIcon } from './svgs/chart.svg';
import { ReactComponent as ChecklistIcon } from './svgs/checklist.svg';
import { ReactComponent as CheckmarkIcon } from './svgs/checkmark.svg';
import { ReactComponent as ChevronDownIcon } from './svgs/chevron_down.svg';
import { ReactComponent as ChevronLeftIcon } from './svgs/chevron_left.svg';
import { ReactComponent as ChevronRightIcon } from './svgs/chevron_right.svg';
import { ReactComponent as ClipboardIcon } from './svgs/clipboard.svg';
import { ReactComponent as CloseIcon } from './svgs/close.svg';
import { ReactComponent as DartIcon } from './svgs/dart.svg';
import { ReactComponent as EvelopeIcon } from './svgs/envelope.svg';
import { ReactComponent as ExclamationCircleIcon } from './svgs/exclamation_circle.svg';
import { ReactComponent as HeartIcon } from './svgs/heart.svg';
import { ReactComponent as HeavyCheckmarkIcon } from './svgs/heavy_checkmark.svg';
import { ReactComponent as HistoryIcon } from './svgs/history.svg';
import { ReactComponent as LogOutIcon } from './svgs/logout.svg';
import { ReactComponent as NotificationIcon } from './svgs/notification.svg';
import { ReactComponent as OfferIcon } from './svgs/offer.svg';
import { ReactComponent as Offer1Icon } from './svgs/offer1.svg';
import { ReactComponent as PaperPlaneIcon } from './svgs/paper_plane.svg';
import { ReactComponent as PencilSquareIcon } from './svgs/pencil_square.svg';
import { ReactComponent as PdfPreviewIcon } from './svgs/pdf_preview.svg';
import { ReactComponent as ProfileIcon } from './svgs/profile.svg';
import { ReactComponent as ProfileDarkIcon } from './svgs/profile_dark.svg';
import { ReactComponent as ProfileOutlineIcon } from './svgs/profile_outline.svg';
import { ReactComponent as ScrollIcon } from './svgs/scroll.svg';
import { ReactComponent as SellingIcon } from './svgs/selling.svg';
import { ReactComponent as ShareIcon } from './svgs/share.svg';
import { ReactComponent as SquareIcon } from './svgs/square.svg';
import { ReactComponent as ThreeDotsIcon } from './svgs/three_dots.svg';
import { ReactComponent as ThunderIcon } from './svgs/thunder.svg';
import { ReactComponent as ToolsIcon } from './svgs/tools.svg';
import { ReactComponent as UploadCloudIcon } from './svgs/upload_cloud.svg';
import { ReactComponent as WarningIcon } from './svgs/warning.svg';
import { ReactComponent as WarningConeIcon } from './svgs/warning_cone.svg';

const IconMap = {
  admin: AdminIcon,
  alarm: AlarmIcon,
  'arrow-right': ArrowRightIcon,
  bath: BathIcon,
  bed: BedIcon,
  bug: BugIcon,
  buying: BuyingIcon,
  call: Call,
  chart: ChartIcon,
  checklist: ChecklistIcon,
  checkmark: CheckmarkIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  clipboard: ClipboardIcon,
  close: CloseIcon,
  dart: DartIcon,
  envelope: EvelopeIcon,
  'exclamation-circle': ExclamationCircleIcon,
  heart: HeartIcon,
  'heavy-checkmark': HeavyCheckmarkIcon,
  history: HistoryIcon,
  logout: LogOutIcon,
  notification: NotificationIcon,
  offer: OfferIcon,
  offer1: Offer1Icon,
  'paper-plane': PaperPlaneIcon,
  'pencil-square': PencilSquareIcon,
  'pdf-preview': PdfPreviewIcon,
  profile: ProfileIcon,
  'profile-dark': ProfileDarkIcon,
  'profile-outline': ProfileOutlineIcon,
  scroll: ScrollIcon,
  selling: SellingIcon,
  share: ShareIcon,
  square: SquareIcon,
  'three-dots': ThreeDotsIcon,
  thunder: ThunderIcon,
  tools: ToolsIcon,
  'upload-cloud': UploadCloudIcon,
  warning: WarningIcon,
  'warning-cone': WarningConeIcon,
};

export default IconMap;
