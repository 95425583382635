import { all, takeLatest } from 'redux-saga/effects';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import fetchPreFilled from './fetchPreFilled';
import create from './create';
import update from './update';
import deleteOffer from './delete';
import updateState from './updateState';

export default function* root() {
  const { FetchPreFilledOffer, CreateOffer, DeleteOffer, UpdateOffer, UpdateState } = CreateOfferAsyncActions;
  yield all([
    takeLatest(FetchPreFilledOffer.Types.REQUEST, fetchPreFilled),
    takeLatest(CreateOffer.Types.REQUEST, create),
    takeLatest(UpdateOffer.Types.REQUEST, update),
    takeLatest(DeleteOffer.Types.REQUEST, deleteOffer),
    takeLatest(UpdateState.Types.REQUEST, updateState),
  ]);
}
