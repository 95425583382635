import styled from 'styled-components';

export const OfferCardContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--gray6);
  color: var(--gray2);
  cursor: pointer;
  margin-top: 10px;
`;

export const BoldText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #333333;
`;

export const LightText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #6c757d;
`;

export const OfferCardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${({ background }) => background ?? 'var(--gray8)'};
  padding: 30px 20px 30px 20px;
`;

export const HeaderBoldText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: white;
`;

export const HeaderLightText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: white;
`;

export const OfferCardBody = styled.div`
  padding: 0px 20px 30px 20px;
`;

export const ColHeader = styled.div``;

export const ColValue = styled.div``;
