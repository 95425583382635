import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

function Dropzone({ handleChange, children }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleChange(acceptedFiles);
    },
    [handleChange]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

export default Dropzone;
