import styled, { css } from 'styled-components';

export const StyledGroup = styled.div`
  align-items: ${({ verticalAlign }) => (verticalAlign === 'center' ? 'center' : `flex-${verticalAlign}`)};
  row-gap: ${({ verticalGutter, gutter }) =>
    verticalGutter === true
      ? '8px'
      : verticalGutter || gutter === true
      ? '8px'
      : typeof gutter === 'number'
      ? `${gutter}px`
      : gutter || 'unset'};
  column-gap: ${({ horizontalGutter, gutter }) =>
    horizontalGutter === true
      ? '8px'
      : horizontalGutter || gutter === true
      ? '8px'
      : typeof gutter === 'number'
      ? `${gutter}px`
      : gutter || 'unset'};
  ${({ wrap, columns }) =>
    wrap
      ? css`
          display: flex;
          flex-wrap: wrap;
          overflow-x: auto;
        `
      : css`
          display: grid;
          width: fit-content;
          grid-auto-flow: ${columns ? 'row' : 'column'};
        `}

  ${({ wrap, columns }) =>
    !wrap &&
    columns &&
    css`
      overflow-x: auto;
      grid-template-columns: repeat(${columns}, auto);
    `}
`;
