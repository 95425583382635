import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

function* listAgents() {
  const {
    ListAgents: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  try {
    const response = yield call(apiCall, '/admin/users', 'get');
    const agents = response.data.filter((user) => !!user.agent);
    yield put(SUCCESS(agents));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default listAgents;
