import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CurrencyField, Modal } from 'components';
import { SeeMore } from './offerCommon.styled';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function OfferEditAmountModal({ offer, onEditAdditionalTerms, onClose, onSave }) {
  const [offerAmount, setOfferAmount] = useState(offer.offerAmount);

  return (
    <Modal
      show
      title='Edit Offer'
      size='md'
      onClose={onClose}
      onOk={() => onSave(offerAmount)}
      okButtonLabel='Save and Send'
    >
      <Row className='mt-2'>
        <CurrencyField
          fieldId='offerAmount'
          label='Edit Offer Amount'
          required
          value={offerAmount}
          onChange={({ value }) => setOfferAmount(value)}
          error={!offerAmount}
        />
        {!offerAmount && <Form.Text className='error form-hint fw-bold text-end m-0 mb-2'>Required</Form.Text>}
      </Row>
      <div className='mt-3'>
        <SeeMore onClick={onEditAdditionalTerms}>Edit additional terms</SeeMore>
      </div>
    </Modal>
  );
}

OfferEditAmountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  offer: PropTypes.object.isRequired,
  counterOffers: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export default OfferEditAmountModal;
