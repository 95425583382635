import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const FooterText = styled.span`
  font-size: 16px;
  padding: 10px 0px;
  color: white;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray2);
  font-size: 16px;
  line-height: 24px;

  svg {
    margin: 10px 0;
  }

  p {
    margin-bottom: 16px;
  }

  ${breakpoint('mobile', 'desktop')`
    margin-bottom: 8px;
  `}
`;

export const FooterContainerWithBackground = styled.div`
  margin-top: auto;
  width: 100%;
  height: 40vh;
  background-image: url('/footer-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;

  ${breakpoint('desktop')`
    height: 60vh;
  `}

  ${breakpoint('tablet')`
    height: 50vh;
  `}
`;
