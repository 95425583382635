import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const ListingInfoContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid var(--gray10);
  display: flex;
  align-items: center;
  color: var(--gray7);

  ${breakpoint('mobile', 'desktop')`
    align-items: flex-start;
    flex-wrap: wrap;
  `}
`;

export const AddressInfo = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding-right: 20px;
  margin-bottom: auto;
`;

export const ListInfo = styled.div`
  margin-bottom: auto;
  color: var(--gray7);
  padding: 0 15px;
  border-left: 1px solid #bdbdbd;

  ${breakpoint('mobile', 'desktop')`
    padding: 0 15px;
    margin: 5px 0;
    border-left: none;
  `}
`;

export const ListInfoLabel = styled.div`
  font-size: 14px;
`;

export const ListInfoValue = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
`;
