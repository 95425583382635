import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormField, ViewField, EmailField, PhoneField, Divider } from 'components';
import { formatPhoneNumber } from 'utils';

function User({ user, title, editable, onChanged, removable, onRemove, errors }) {
  const onStateChanged = (event) => {
    onChanged({
      ...(user || { [event.field]: '' }),
      [event.field]: event.value,
    });
  };

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h6>{title}</h6>
        {removable && (
          <button className='btn btn-link' onClick={onRemove}>
            REMOVE ✕
          </button>
        )}
      </div>
      <Divider />
      {editable && (
        <React.Fragment>
          <Row>
            <Col sm={6}>
              <FormField
                fieldId='firstName'
                label='First Name'
                initialValue={user?.firstName}
                onStateChanged={onStateChanged}
                required
                error={errors?.firstName}
              />
            </Col>
            <Col sm={6}>
              <FormField
                fieldId='lastName'
                label='Last Name'
                initialValue={user?.lastName}
                onStateChanged={onStateChanged}
                required
                error={errors?.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <PhoneField
                fieldId='phone'
                label='Mobile'
                placeholder='Phone'
                value={user?.phone || ''}
                onChange={onStateChanged}
                error={errors?.phone}
              />
            </Col>
            <Col sm={6}>
              <EmailField
                fieldId='email'
                label='Email'
                initialValue={user?.email || ''}
                onStateChanged={onStateChanged}
                editable={editable}
                required
                error={errors?.email}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {!editable && (
        <React.Fragment>
          <Row>
            <ViewField fieldId='name' label='Name' value={user.firstName + ' ' + user.lastName} />
          </Row>
          <Row>
            <ViewField fieldId='phone' label='Phone' value={formatPhoneNumber(user.phone)} />
          </Row>
          <Row>
            <ViewField fieldId='email' label='Email' value={user.email} />
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}

export default User;
