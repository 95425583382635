import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Header, ItemStatus } from './adminPage.styled';
import { AdminAsyncActions } from 'store/actions/admin';
import { getContracts } from 'store/selectors/admin';
import AdminTable from './adminTable';
import { SelectColumnFilter } from './filters';
import { getPropertyCardStatus, formatPhoneNumber, URLUtils } from 'utils';
import { PrimaryGreenButton, CheckBox } from 'components';
import ViewParticipantsModal from './viewParticipantsModal';
import UploadContractModal from './uploadContractModal';

export default function ContractsAdminPage() {
  const [currentContract, setCurrentContract] = useState();
  const [activeListing, setActiveListing] = useState();

  const dispatch = useDispatch();

  const normalizeAgent = useCallback((type, agent) => {
    return {
      type,
      firstName: agent.firstName,
      lastName: agent.lastName,
      state: agent.address?.state ?? '',
      phone: agent.phone ? formatPhoneNumber(agent.phone) : '',
      email: agent.email,
      brokerageName: agent.agent?.brokerage?.name,
      licenseNumber: agent.agent?.licenseNumber,
      campaign: agent.campaign,
    };
  }, []);

  const normalizeConsumer = useCallback((type, consumer) => {
    return {
      type,
      firstName: consumer.user.firstName,
      lastName: consumer.user.lastName,
      state: consumer.address?.state ?? '',
      phone: consumer.user.phone ? formatPhoneNumber(consumer.user.phone) : '',
      email: consumer.user.email,
      brokerageName: '',
      licenseNumber: '',
      campaign: consumer.user.campaign,
    };
  }, []);

  const normalizeParticipants = useCallback(
    (listing) => {
      const participants = [];

      if (listing.listingAgent) {
        participants.push(normalizeAgent('Listing Agent', listing.listingAgent));
      }

      if (listing.primaryOwner) {
        participants.push(normalizeConsumer('Primary Seller', listing.primaryOwner));
      }

      listing.secondaryOwners?.forEach((secondaryOwner) =>
        participants.push(normalizeConsumer('Secondary Seller', secondaryOwner))
      );

      if (listing.buyerAgent) {
        participants.push(normalizeAgent('Buyer Agent', listing.buyerAgent));
      }

      if (listing.primaryBuyer) {
        participants.push(normalizeConsumer('Primary Buyer', listing.primaryBuyer));
      }

      listing.secondaryBuyers?.forEach((secondaryBuyer) =>
        participants.push(normalizeConsumer('Secondary Buyer', secondaryBuyer))
      );

      return participants;
    },
    [normalizeAgent, normalizeConsumer]
  );

  const handleDocuSignClick = useCallback(
    (contract) =>
      dispatch(
        AdminAsyncActions.UpdateContract.Actions.REQUEST(
          contract.listingId,
          contract.docuSignSent === null ? true : !contract.docuSignSent,
          contract.finalRpaUrl
        )
      ),
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: (contract) => getPropertyCardStatus(contract.listingState),
        Cell: ({ cell: { value } }) => <ItemStatus active={true}>{value}</ItemStatus>,
        id: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
      {
        id: 'address',
        Header: 'Listing Address',
        accessor: 'listingAddress',
        filter: 'text',
      },
      {
        id: 'contract',
        Header: 'Contract',
        accessor: (listing) => URLUtils.getDocumentUrl(`rpa/${listing.listingId}/rpa-${listing.offerId}.pdf`),
        Cell: ({ cell: { value } }) =>
          value && <PrimaryGreenButton onClick={() => URLUtils.openNewWindow(value)}>View Contract</PrimaryGreenButton>,
        disableFilters: true,
      },
      {
        id: 'viewParticipants',
        Header: 'View Participants',
        accessor: (listing) => normalizeParticipants(listing),
        Cell: ({ cell: { value } }) => (
          <PrimaryGreenButton onClick={() => setCurrentContract(value)}>View Participants</PrimaryGreenButton>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'DocuSign Sent',
        accessor: (value) => value,
        Cell: ({ cell: { value } }) => (
          <CheckBox
            name='docuSignSent'
            checked={value.docuSignSent}
            onChange={() => handleDocuSignClick(value)}
            readOnly
          />
        ),
        disableFilters: true,
      },
      {
        id: 'uploadContract',
        Header: 'Upload Contract',
        accessor: (value) => value,
        Cell: ({ cell: { value } }) => (
          <PrimaryGreenButton onClick={() => setActiveListing(value)}>Upload Contract</PrimaryGreenButton>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        id: 'viewFinalContract',
        Header: 'Final Contract',
        accessor: (value) => value,
        Cell: ({ cell: { value } }) =>
          value.finalRpaUrl ? (
            <PrimaryGreenButton onClick={() => URLUtils.openDocument(value.finalRpaUrl)}>
              View Final Contract
            </PrimaryGreenButton>
          ) : (
            <div></div>
          ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [normalizeParticipants, handleDocuSignClick]
  );

  const contracts = useSelector(getContracts);

  useEffect(() => {
    dispatch(AdminAsyncActions.ListContracts.Actions.REQUEST());
  }, [dispatch]);

  const handleUploadContract = useCallback(
    (file) => {
      dispatch(AdminAsyncActions.UploadContract.Actions.REQUEST(activeListing.listingId, file));
      setActiveListing(null);
    },
    [dispatch, activeListing]
  );

  return (
    <>
      <Helmet>
        <title>Admin | Contracts</title>
        <meta name='description' content='Admin | Contracts' />
      </Helmet>
      <div className='shadow-sm border rounded'>
        <Header>
          <h2 className='fw-bold'>Contracts</h2>
          <h6>({contracts.length} Total Results)</h6>
        </Header>
        <AdminTable columns={columns} data={contracts} />
      </div>
      {currentContract && (
        <ViewParticipantsModal
          show={!!currentContract}
          data={currentContract}
          onClose={() => setCurrentContract(null)}
        />
      )}
      {activeListing && (
        <UploadContractModal
          show={!!activeListing}
          listingId={activeListing.listingId}
          onUpload={handleUploadContract}
          onClose={() => setActiveListing(null)}
        />
      )}
    </>
  );
}
