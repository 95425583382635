import React from 'react';
import invitedImage from '../../assets/images/seller-registration.png';
import { Header, Container, HorizontalRule, DescriptionText, SubheadingText } from './informationCard.styled';

function InformationCard() {
  return (
    <Container>
      <Header>You're Invited!</Header>
      <img className='w-100' src={invitedImage} alt='Couple holding hands in front of a home' />
      <DescriptionText topMargin>
        Offer1 is the best tool to maximize the sales price of your property, which turns out to be the number one
        objective of most sellers. In addition we provide state of the art tools to prevent Escrow Fallout, the second
        most common area of frustration to sellers.
      </DescriptionText>
      <HorizontalRule />
      <SubheadingText>A Predictable Closing Timeline = Less Stress Less Effort</SubheadingText>
      <DescriptionText>
        When selling you need to make plans and arrangements, things like: movers, utilities, a new home search,
        packing, days off, etc. It's devastating when a buyer who is in contract to purchase your home backs out. Aside
        from having to now adjust all of your plans you also lost valuable marketing time potentially causing your home
        to sell for less. With Offer1 say hello to rock solid dependability and cutting edge technology designed to
        protect the integrity of your sale while maximizing your return. #winning
      </DescriptionText>
      <HorizontalRule />
      <SubheadingText>Some of the Other Offer1 Benefits:</SubheadingText>
      <DescriptionText>
        <ul>
          <li>Organized Offer presentation</li>
          <li>Side by Side Offer Comparison</li>
          <li>Instant Notification</li>
          <li>Patented Global Counter Offer system</li>
          <li>Agent Review and Recommendation</li>
          <li>Offer View Guarantee</li>
          <li>Increased Agent Productivity</li>
          <li>Increased Agent availability</li>
          <li>Offer Integrity</li>
          <li>Increased Buyer engagement</li>
          <li>Buyer's Offer Validation</li>
          <li>Buyer's Credit Validation</li>
          <li>Buyer's Loan Qualification Verified</li>
          <li>Transparency of the process</li>
          <li>Fraud protection</li>
        </ul>
      </DescriptionText>
    </Container>
  );
}

export default InformationCard;
