import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Icon, Pagination, Collapse } from 'components';
import OfferHeader from './offerHeader';
import OfferTerms from './offerTerms';
import OfferContingencies from './offerContingencies';
import OfferCreditItems from './offerCreditItems';
import OfferCostAllocations from './offerCostAllocations';
import OfferServices from './offerServices';
import OfferDocuments from './offerDocuments';
import OfferComments from './offerComments';
import OfferContact from './offerContact';
import OfferFooter from './offerFooter';

import { offerStepsLabel } from 'constants/offerConstants';
import { OffersContainer, ControllerContainer, CollapseHeader } from './reviewOfferModal.styled';
import { OfferBackgroundContainer, HistoryLabel } from './offerInLine.styled';
import OfferOtherTermsAddenda from './offerOtherTermsAddenda';

const MAX_COUNT_IN_LINE = 4;

function OfferInLine({ listing, offers, globalCounter, isCompareLine = false, showOnlyDetails }) {
  const [page, setPage] = useState(0);

  const totalPage = useMemo(() => Math.ceil(offers.length / MAX_COUNT_IN_LINE), [offers.length]);

  const renderOfferStep = (step, props) => {
    switch (step) {
      case 0:
        return <OfferTerms {...props} />;
      case 1:
        return <OfferContingencies {...props} />;
      case 2:
        return <OfferCreditItems {...props} />;
      case 3:
        return <OfferCostAllocations {...props} />;
      case 4:
        return <OfferServices {...props} />;
      case 5:
        return <OfferContact {...props} />;
      case 6:
        return <OfferOtherTermsAddenda {...props} />;
      case 7:
        return <OfferDocuments {...props} />;
      case 8:
        return <OfferComments {...props} />;
      default:
        return null;
    }
  };

  const pageOffers = useMemo(
    () => offers.slice(MAX_COUNT_IN_LINE * page, MAX_COUNT_IN_LINE * (page + 1)),
    [page, offers]
  );

  const placeHolderCount = useMemo(() => MAX_COUNT_IN_LINE - pageOffers.length, [pageOffers]);

  return (
    <OffersContainer>
      {!showOnlyDetails && (
        <>
          <ControllerContainer>
            <Pagination
              page={page}
              size={totalPage}
              onLeft={() => setPage(page - 1)}
              onRight={() => setPage(page + 1)}
              className='ms-auto'
            />
          </ControllerContainer>
          <OfferBackgroundContainer className='align-items-end' historyView={!isCompareLine}>
            {!isCompareLine && (
              <HistoryLabel style={{ top: 6 }}>
                [ID# {offers[0].id}]{' '}
                {offers[0].primaryBuyer && offers[0].primaryBuyer.ownerType === 'INDIVIDUAL'
                  ? `${offers[0].primaryBuyer.user.firstName.charAt(0)}. ${offers[0].primaryBuyer.user.lastName}`
                  : offers[0].primaryBuyer.orgName}{' '}
                History
              </HistoryLabel>
            )}
            <OfferHeader isSellerPreference />
            {pageOffers.map((offer, index) => (
              <OfferHeader
                key={offer.id}
                offer={offer}
                globalCounter={!isCompareLine && index > 0 ? globalCounter : null}
                actionDisabled
                historyDisabled
                historyView={!isCompareLine}
              />
            ))}
            {Array(placeHolderCount)
              .fill()
              .map((_, index) => (
                <OfferHeader key={index} placeholder historyView={!isCompareLine} />
              ))}
          </OfferBackgroundContainer>
        </>
      )}

      {offerStepsLabel.map((step, index) => (
        <Collapse
          header={CollapseHeader}
          headerContent={
            <>
              <span style={{ width: 226 }}>
                {index + 1}. {step}
              </span>
              <Icon name='chevron-down' />
            </>
          }
        >
          <OfferBackgroundContainer historyView={!isCompareLine}>
            {renderOfferStep(index, {
              isSellerPreference: true,
              listingState: listing.address.state,
              sellerPreferenceOffer: listing.offerDetail?.instantAuction ?? {},
            })}
            {pageOffers.map((offer, offerIndex) => {
              return renderOfferStep(index, {
                key: offer.id,
                listingState: listing.address.state,
                sellerPreferenceOffer: listing.offerDetails?.instantAuction ?? {},
                offer: offer,
                globalCounter: !isCompareLine && offerIndex > 0 ? globalCounter : null,
              });
            })}
            {!showOnlyDetails &&
              Array(placeHolderCount)
                .fill()
                .map((_, placeholderIndex) => renderOfferStep(placeholderIndex, { placeholder: true }))}
          </OfferBackgroundContainer>
        </Collapse>
      ))}

      {!showOnlyDetails && (
        <>
          <OfferBackgroundContainer historyView={!isCompareLine}>
            {!isCompareLine && (
              <HistoryLabel style={{ bottom: 12 }}>
                [ID# {offers[0].id}]{' '}
                {offers[0].primaryBuyer && offers[0].primaryBuyer.ownerType === 'INDIVIDUAL'
                  ? `${offers[0].primaryBuyer.user.firstName.charAt(0)}. ${offers[0].primaryBuyer.user.lastName}`
                  : offers[0].primaryBuyer.orgName}{' '}
                History
              </HistoryLabel>
            )}
            <OfferFooter isSellerPreference />
            {pageOffers.map((offer, index) => (
              <OfferFooter
                key={offer.id}
                offer={offer}
                globalCounter={!isCompareLine && index > 0 ? globalCounter : null}
                actionDisabled
                historyView={!isCompareLine}
              />
            ))}
            {Array(placeHolderCount)
              .fill()
              .map((_, index) => (
                <OfferFooter key={index} placeholder historyView={!isCompareLine} />
              ))}
          </OfferBackgroundContainer>

          <ControllerContainer>
            <Pagination
              page={page}
              size={totalPage}
              onLeft={() => setPage(page - 1)}
              onRight={() => setPage(page + 1)}
              className='ms-auto'
            />
          </ControllerContainer>
        </>
      )}
    </OffersContainer>
  );
}

OfferInLine.propTypes = {
  listing: PropTypes.object.isRequired,
  offers: PropTypes.object.isRequired,
  globalCounter: PropTypes.object,
  isCompareLine: PropTypes.bool,
  showOnlyDetails: PropTypes.bool,
};
export default OfferInLine;
