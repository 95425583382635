import { createSelector } from 'reselect';

export const getState = (state) => state.offers;

export const getOffer = createSelector(getState, ({ offer }) => offer);

export const getOffers = createSelector(getState, ({ offers }) => offers);

export const getGlobalCounter = createSelector(getState, ({ globalCounter }) => globalCounter);

export const getCounterOffer = createSelector(getState, ({ counterOffer }) => counterOffer);
