import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const TabsBarContainer = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 16px 0;
`;

export const TabsBarItem = styled(NavLink)`
  width: fit-content;
  padding: 0 10px;
  border: 1px solid ${({ active, color }) => (active ? color ?? 'var(--offer1-blue)' : 'var(--offer1-grey3)')};
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  line-height: 32px;
  color: ${({ active, color }) => (active ? color ?? 'var(--offer1-blue)' : 'var(--offer1-grey3)')};
  text-align: center;
  transition: all 0.2s ease;
  text-decoration: none;

  &:hover,
  &.active {
    color: ${({ color }) => color ?? 'var(--offer1-blue)'};
    border-color: ${({ color }) => color ?? 'var(--offer1-blue)'};
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
`;
