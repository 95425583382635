export const OFFER1_BLUE = '#008EFF';
export const OFFER1_GREEN = '#8db033';
export const OFFER1_GRAY = '#828282';
export const WARNING_COLOR = '#FFCC00';
export const OFFER1_RED = '#ff6961';
export const ICON_BLUE = '#adbecc';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  OFFER1_BLUE,
  OFFER1_GREEN,
  OFFER1_GRAY,
  OFFER1_RED,
  WARNING_COLOR,
  ICON_BLUE,
};
