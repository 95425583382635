import { call, put } from 'redux-saga/effects';
import { AgentAsyncActions } from 'store/actions/agent';
import { apiCall } from 'utils/apiCall';

function* fetchQuestionnaire() {
  const {
    FetchQuestionnaire: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AgentAsyncActions;

  try {
    const response = yield call(apiCall, '/agents/questionnaire', 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchQuestionnaire;
