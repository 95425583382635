import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import { PrimaryGreenButton, OutGrayButton } from 'components';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SectionRow, Container, SectionContainer, Header, CloseButton, Comment } from './offerReviewDay.styled';
import { getGlobalCounter } from 'store/selectors/offers';
import { OffersAsyncActions } from 'store/actions/offers';

const MySwal = withReactContent(Swal);

const SaveModalContent = () => {
  return (
    <div>
      <p>
        We advise you to cancel all counters prior to setting the "highest and best" request. You may counter any offer
        after the "highest and best" has been set.
      </p>
      <p>If you wish to proceed without canceling existing counters, select "continue".</p>
    </div>
  );
};
const OfferReviewDay = ({ listing, hasMultipleCounterOffer, onSave, onClose }) => {
  const [globalCounterOffer, setGlobalCounterOffer] = useState({});
  const [auctionTimeDays, setAuctionTimeDays] = useState(1);
  const dispatch = useDispatch();
  const { isSuccess } = useSelector(OffersAsyncActions.FetchGlobalCounterOffer.StatusSelector());
  const globalCounter = useSelector(getGlobalCounter);

  useEffect(() => {
    if (isSuccess) {
      setGlobalCounterOffer(globalCounter);
      setAuctionTimeDays(Math.max(moment(globalCounter.auctionExpirationDate).diff(moment(), 'days') + 1, 0));
    }
  }, [globalCounter, isSuccess]);

  useEffect(() => {
    dispatch(OffersAsyncActions.FetchGlobalCounterOffer.Actions.REQUEST(listing.id));

    return () => {
      dispatch(OffersAsyncActions.FetchGlobalCounterOffer.Actions.RESET());
    };
  }, [dispatch, listing.id]);

  const handleReviewDayChange = (e) => {
    const value = Math.max(e.target.value, 1);

    var newDate = new Date();
    // convert current time to local time
    const offset = newDate.getTimezoneOffset();
    newDate = new Date(newDate.getTime() - offset * 60 * 1000);
    // add number of review days
    newDate.setDate(newDate.getDate() + value);

    setAuctionTimeDays(value);
    setGlobalCounterOffer({
      ...globalCounterOffer,
      auctionExpirationDate: newDate.toISOString(),
    });
  };

  const handleSave = async () => {
    if (!hasMultipleCounterOffer) {
      return onSave(globalCounterOffer);
    }
    const { isConfirmed } = await MySwal.fire({
      title: 'Existing MCOS should be canceled to proceed.',
      html: <SaveModalContent />,
      showCloseButton: true,
      confirmButtonText: 'Continue',
    });
    if (isConfirmed) {
      onSave(globalCounterOffer);
    }
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <Container>
      <Header>
        <span>Set Highest and Best Due Date</span>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Header>

      <SectionContainer>
        <SectionRow className='text-muted align-items-start pt-3'>
          <Col sm={2}>Set Days Left to review</Col>
          <Col sm={3}>
            <Comment>Buyers have until 6pm on the day selected to submit their final offer</Comment>
          </Col>
          <Col sm={2}>
            <FormControl
              type='number'
              min={1}
              name='auctionTimeDays'
              value={auctionTimeDays}
              onChange={handleReviewDayChange}
            />
          </Col>
          <Col sm={5}>
            <FormControl
              type='date'
              name='auctionTimeClose'
              value={moment().add(auctionTimeDays, 'days').format('YYYY-MM-DD')}
              readOnly
            />
          </Col>
        </SectionRow>
      </SectionContainer>
      <SectionContainer className='my-4'>
        <div className='px-2 d-flex justify-content-end'>
          <OutGrayButton variant='outline-secondary' onClick={onClose}>
            Cancel
          </OutGrayButton>
          <PrimaryGreenButton className='ms-3' onClick={handleSave}>
            Save
          </PrimaryGreenButton>
        </div>
      </SectionContainer>
    </Container>
  );
};

OfferReviewDay.prototype = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OfferReviewDay;
