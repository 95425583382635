import React, { useState, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import routeConstants from '../App/routeConstants';

function AddCustomUrlModal({ show, title, listingUrl, onClose, onContinue, onChange }) {
  const [customUrl, setCustomUrl] = useState('');

  const urlInvalid = useMemo(() => {
    const regex = /^[a-zA-Z0-9]*$/;
    return !regex.test(customUrl);
  }, [customUrl]);

  return (
    <Modal show={show} onHide={onContinue} className='rounded' size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row mx-4' centered>
          <form>
            <Form.Label htmlFor='basic-url'>
              URL should contain no spaces, no special characters, and only letters and numbers
            </Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text id='basic-addon3'>
                {process.env.REACT_APP_URL + routeConstants.LISTING_PATH}/
              </InputGroup.Text>
              <FormControl
                id='basic-url'
                aria-describedby='basic-addon3'
                placeholder={listingUrl}
                value={customUrl}
                onChange={(e) => setCustomUrl(e.target.value)}
              />
            </InputGroup>
          </form>
          {urlInvalid && <p style={{ color: 'red' }}> URL is invalid</p>}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='primary' disabled={urlInvalid} onClick={() => onContinue(customUrl)}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCustomUrlModal;
