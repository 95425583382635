import styled from 'styled-components';

export const CloseButton = styled.button`
  font-size: 40px;
  border: none;
  background-color: white;
`;

export const MessageBox = styled.div`
  background-color: #ebebeb;
`;

export const InviteTitle = styled.span`
  color: var(--offer1-blue)
  font-size: 20px;
  font-weight: bold;
`;

export const SectionTitle = styled.h4`
  color: var(--offer1-blue);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;
