import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ButtonWrapper, Icon, PrimaryGreenButton } from 'components';
import { Header } from './Modal.styled';

function Offer1Modal({ children, show, title, subTitle, size = 'lg', onClose, disableOk, onOk, okButtonLabel = 'OK' }) {
  return (
    <Modal show={show} backdrop='static' keyboard={false} size={size} centered>
      <Header>
        <h3>{title}</h3>
        {subTitle && <p>{subTitle}</p>}
        <ButtonWrapper className='close-button' onClick={onClose}>
          <Icon name='close' />
        </ButtonWrapper>
      </Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {onOk && (
          <PrimaryGreenButton disabled={disableOk} onClick={onOk}>
            {okButtonLabel}
          </PrimaryGreenButton>
        )}
      </Modal.Footer>
    </Modal>
  );
}

Offer1Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  size: PropTypes.string,
  okButtonLabel: PropTypes.string,
  disableOk: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default Offer1Modal;
