import styled from 'styled-components';

export const CountDownContainer = styled.div`
  color: ${(props) => props.color};
  display: flex;
  font-size: ${(props) => `${props.fontSize}px`};
  letter-spacing: 0.16em;
`;

export const CountSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30px;
`;

export const Label = styled.div`
  font-size: 10px;
`;
