import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';
import routes from 'containers/App/routeConstants';

function* updateState(action) {
  const {
    UpdateState: {
      Actions: { SUCCESS, FAILURE },
    },
  } = CreateListingAsyncActions;
  const {
    payload: { listingId, status, comment },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/${listingId}/${status}`, 'put', { comment });
    yield put(SUCCESS(response.data));
    if (status === 'PENDING_AGENT_UPDATES') {
      yield put(push(routes.HOME_PATH));
    }
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default updateState;
