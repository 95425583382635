import React, { useState, useMemo } from 'react';
import { useInterval } from 'hooks';
import { CountDownContainer, CountSection, Label } from './countDown.styled';
import { twoDigits } from 'utils';

function CountDown({ endDate, showLabel = true, color = 'red', fontSize = 24 }) {
  const [isPlaying, setPlaying] = useState(!!endDate);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const dateFuture = useMemo(() => new Date(endDate), [endDate]);

  useInterval(
    () => {
      const dateNow = new Date();
      let diffInSeconds = (dateFuture - dateNow) / 1000;

      if (diffInSeconds <= 0) {
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        setPlaying(false);
        return;
      }

      const d = Math.floor(diffInSeconds / 86400);
      diffInSeconds -= d * 86400;

      const h = Math.floor(diffInSeconds / 3600) % 24;
      diffInSeconds -= h * 3600;

      const m = Math.floor(diffInSeconds / 60) % 60;
      diffInSeconds -= m * 60;

      const s = Math.floor(diffInSeconds);

      setDays(twoDigits(d));
      setHours(twoDigits(h));
      setMinutes(twoDigits(m));
      setSeconds(twoDigits(s));
    },
    isPlaying ? 1000 : null
  );

  return (
    <CountDownContainer color={isPlaying ? color : 'red'} fontSize={fontSize}>
      <CountSection>
        <div>{days}</div>
        {showLabel && <Label>days</Label>}
      </CountSection>
      <div>:</div>
      <CountSection>
        <div>{hours}</div>
        {showLabel && <Label>hours</Label>}
      </CountSection>
      <div>:</div>
      <CountSection>
        <div>{minutes}</div>
        {showLabel && <Label>min</Label>}
      </CountSection>
      <div>:</div>
      <CountSection>
        <div>{seconds}</div>
        {showLabel && <Label>sec</Label>}
      </CountSection>
    </CountDownContainer>
  );
}

export default CountDown;
