import { createAsyncAction } from 'utils/store';

export const ListUsers = createAsyncAction('admin', 'fetchUsers', {
  REQUEST: [],
  SUCCESS: ['users'],
});

export const ListAgents = createAsyncAction('admin', 'listAgents', {
  REQUEST: [],
  SUCCESS: ['agents'],
});

export const ListListings = createAsyncAction('admin', 'listListings', {
  REQUEST: [],
  SUCCESS: ['listings'],
});

export const ListContracts = createAsyncAction('admin', 'listContracts', {
  REQUEST: [],
  SUCCESS: ['contracts'],
});

export const FetchAgentQuestionnaire = createAsyncAction('admin', 'fetchAgentQuestionnaire', {
  REQUEST: ['email'],
  SUCCESS: ['questionnaire'],
});

export const FetchListingDetails = createAsyncAction('admin', 'fetchListingDetails', {
  REQUEST: ['id'],
  SUCCESS: ['listingDetails'],
});

export const FetchOfferDashboard = createAsyncAction('admin', 'fetchOfferDashboard', {
  REQUEST: ['listingId', 'userId', 'actions'],
  SUCCESS: ['offerDashboard'],
});

export const ResetPassword = createAsyncAction('admin', 'resetPassword', {
  REQUEST: ['email'],
  SUCCESS: [],
});

export const DeleteUser = createAsyncAction('admin', 'deleteUser', {
  REQUEST: ['id'],
  SUCCESS: [],
});

export const UpdateUserRole = createAsyncAction('admin', 'updateUserRole', {
  REQUEST: ['userId', 'isDelete'],
  SUCCESS: ['agents'],
});

export const UploadContract = createAsyncAction('admin', 'uploadContract', {
  REQUEST: ['listingId', 'file'],
  SUCCESS: ['contracts'],
});

export const UpdateContract = createAsyncAction('admin', 'updateContract', {
  REQUEST: ['listingId', 'docuSignSent', 'finalRpaUrl'],
  SUCCESS: ['contracts'],
});

export const AdminAsyncActions = {
  ListUsers,
  ListAgents,
  ListListings,
  ListContracts,
  FetchAgentQuestionnaire,
  FetchListingDetails,
  FetchOfferDashboard,
  ResetPassword,
  UpdateUserRole,
  UploadContract,
  UpdateContract,
  DeleteUser,
};
