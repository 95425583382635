import { call, put, select } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { getAgents } from 'store/selectors/admin';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* updateUserRole(action) {
  const {
    UpdateUserRole: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { userId, isDelete },
  } = action;

  try {
    yield call(apiCall, '/admin/updateRoles', isDelete ? 'delete' : 'put', { userId, roles: ['Listing'] });

    const newAgents = yield select(getAgents);
    yield put(
      SUCCESS(
        newAgents.map((agent) => {
          if (agent.id === userId) {
            let newRoles = [];
            if (isDelete) {
              newRoles = agent.roles.filter(({ name }) => name !== 'LISTING');
            } else {
              newRoles = [...agent.roles, { name: 'LISTING' }];
            }
            return {
              ...agent,
              roles: newRoles,
            };
          }
          return agent;
        })
      )
    );
  } catch (error) {
    toast.error('Error occurred while updating roles');
    yield put(FAILURE(error));
  }
}

export default updateUserRole;
