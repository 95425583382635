import { call, put } from 'redux-saga/effects';
import { OffersAsyncActions } from 'store/actions/offers';
import { apiCall } from 'utils/apiCall';

function* fetchGlobal(action) {
  const {
    FetchGlobalCounterOffer: {
      Actions: { SUCCESS, FAILURE },
    },
  } = OffersAsyncActions;
  const {
    payload: { listingId },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/global-counter/${listingId}`, 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchGlobal;
