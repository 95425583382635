import React from 'react';
import { StyledCardDeck } from './cardDeck.styled';

function CardDeck({ gap = 16, className, style, center, children }) {
  return (
    <StyledCardDeck className={className} style={style} gap={gap} center={center}>
      {children}
    </StyledCardDeck>
  );
}

export default CardDeck;
