import { createAsyncAction } from 'utils/store';

export const FetchPreFilledOffer = createAsyncAction('createOffer', 'fetchPreFilledOffer', {
  REQUEST: ['listingId', 'profileId'],
  SUCCESS: ['offer'],
});

export const CreateOffer = createAsyncAction('createOffer', 'createOffer', {
  REQUEST: ['offer'],
  SUCCESS: ['offer'],
});

export const UpdateOffer = createAsyncAction('createOffer', 'updateOffer', {
  REQUEST: ['offer'],
  SUCCESS: ['offer'],
});

export const DeleteOffer = createAsyncAction('createOffer', 'deleteOffer', {
  REQUEST: ['id'],
  SUCCESS: ['offer'],
});

export const UpdateState = createAsyncAction('createOffer', 'updateState', {
  REQUEST: ['offerId', 'status', 'comment'],
  SUCCESS: ['offer'],
});

export const CreateOfferAsyncActions = {
  FetchPreFilledOffer,
  CreateOffer,
  UpdateOffer,
  UpdateState,
  DeleteOffer,
};
