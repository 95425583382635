import React, { useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import { FormikField, Typography } from 'components';
import { useAuthUser } from 'hooks';
import { AuthAsyncActions } from 'store/actions/auth';
import routeConstants from 'containers/App/routeConstants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCookies } from 'react-cookie';
import { URLUtils } from 'utils';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Swal from 'sweetalert2';
import { LoginContent, SectionTitle, SectionText, HouseImg, AccountAltText } from './loginPage.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function LoginForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { error } = useSelector(AuthAsyncActions.Login.StatusSelector());
  const { user } = useAuthUser();
  const [cookie, setCookie] = useCookies(['enableCookies']);
  const cookieTouched = cookie.enableCookies === 'true' || cookie.enableCookies === 'false';

  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().email('Invalid email address').required('Please provide an email.'),
        password: Yup.string()
          .min(8, 'Password length must be at least 8 characters.')
          .required('Please provide a password.'),
      }),
    []
  );

  useEffect(() => {
    return () => {
      dispatch(AuthAsyncActions.Login.Actions.RESET());
    };
  }, [dispatch]);

  const login = useCallback(
    (userEmail, userPassword, redirect) =>
      dispatch(AuthAsyncActions.Login.Actions.REQUEST(userEmail, userPassword, redirect)),
    [dispatch]
  );

  const handleSubmit = async (values) => {
    if (!cookieTouched) {
      const { value: accepted } = await Swal.fire({
        title: 'Cookie Consent',
        text: 'This website uses cookies to ensure you get the best experience.',
        confirmButtonText: 'Alow Cookies',
        cancelButtonText: 'Disable Cookies',
        showCancelButton: true,
      });
      if (!accepted) {
        window['ga-disable-UA-XXXXX-Y'] = true;
      }
      setCookie('enableCookies', !!accepted, { path: '/' });
    }
    login(values.email, values.password, location.state?.redirect);
  };

  if (user) {
    return <Redirect to={routeConstants.HOME_PATH} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name='description' content='Login' />
      </Helmet>
      <div className='mb-4 d-flex justify-content-center'>
        <MainContainerMd>
          <LoginContent>
            <Row>
              <Col md={3} className='d-flex justify-content-center'>
                <img
                  className='w-100 d-none d-md-block'
                  src={URLUtils.getPublicImageUrl('homebuying-man.svg')}
                  alt='person buying home'
                />
              </Col>
              <Col md={6}>
                <SectionTitle>Welcome!</SectionTitle>
                <SectionText className='mx-3'>
                  We make complex problems simple through our behind the scenes Tech. Worry less, and sell more!
                </SectionText>
                {error?.message && (
                  <Typography as='h6' className='text-md-center mt-2 h6' color='danger'>
                    {error.message}
                  </Typography>
                )}
                <Formik
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <FormikField name='email' label='Email' type='email' />
                      </Row>
                      <Row className='mt-2'>
                        <FormikField name='password' label='Password' type='password' />
                      </Row>
                      <div className='mx-3'>
                        <AccountAltText className='text-md-center mt-2'>
                          New to Offer1?&nbsp;
                          <Link to={routeConstants.CUSTOMER_REGISTRATION_PATH}>Register here</Link>
                        </AccountAltText>
                        <AccountAltText className='text-md-center'>
                          <Link to={routeConstants.FORGOT_PASSWORD_PATH}>Forgot your password?</Link>
                        </AccountAltText>
                      </div>
                      <button
                        className='d-block btn text-uppercase auth-button my-4 mx-auto'
                        type='submit'
                        onClick={handleSubmit}
                      >
                        Login
                      </button>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col md={3} className='d-none d-md-block position-relative'>
                <HouseImg src={URLUtils.getPublicImageUrl('house.svg')} />
              </Col>
            </Row>
          </LoginContent>
        </MainContainerMd>
      </div>
    </>
  );
}

export default LoginForm;
