import { call, put } from 'redux-saga/effects';
import { AgentAsyncActions } from 'store/actions/agent';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* sendMessage(action) {
  const {
    SendMessage: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AgentAsyncActions;
  const {
    payload: { message },
  } = action;

  try {
    yield call(apiCall, 'notifications', 'put', message);
    toast.success('Successfully sent message to the agent');
    yield put(SUCCESS());
  } catch (error) {
    toast.error('Failed sent message to the agent');
    yield put(FAILURE(error));
  }
}

export default sendMessage;
