import styled from 'styled-components';

export const StepNumber = styled.div`
  font-size: 16px;
  margin: 12px 4px;
  cursor: default;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;

  > span:first-child {
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    padding: 4px;
    margin-right: 8px;

    background: var(--offer1-green);
    border: 2px solid var(--offer1-green);
    color: #fff;
    text-align: center;

    font: 16px Arial, sans-serif;
  }
`;

export const CreateHeaderContainer = styled.div`
  padding-top: 4px;
  padding-left: 20px;
`;

export const StepName = styled.span`
  align-items: center;
  font-weight: bold;
  text-align: left;
  color: #008eff;
  font-size: 20px;
`;

export const StepSubHeader = styled.span`
  font-weight: 500;
  color: #008eff;
  font-size: 12px;
  color: #333333;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: -15px;
`;
