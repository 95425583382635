import { createSelector } from 'reselect';

export const getState = (state) => state.admin;

export const getUsers = createSelector(getState, ({ users }) => users ?? []);

export const getAgents = createSelector(getState, ({ agents }) => agents ?? []);

export const getListings = createSelector(getState, ({ listings }) => listings ?? []);

export const getContracts = createSelector(getState, ({ contracts }) => contracts ?? []);

export const getListingDetails = createSelector(getState, ({ listingDetails }) => listingDetails);

export const getOfferDashboard = createSelector(getState, ({ offerDashboard }) => offerDashboard);
