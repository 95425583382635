import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

function* listContracts() {
  const {
    ListContracts: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  try {
    const response = yield call(apiCall, '/admin/contract', 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default listContracts;
