import { call, put } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';

function* verify(action) {
  const {
    VerifyAccount: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { email, token },
  } = action;

  try {
    yield call(apiCall, `/users/activation/${email}/${token}`, 'put');
    yield put(SUCCESS());
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default verify;
