import React, { useMemo } from 'react';
import { CheckBox, PropertyItem } from 'components';
import { URLUtils } from 'utils';
import { CheckBoxContainer } from './offerSellerAddenda.styled';

function OfferAddenda({
  addendaItems,
  addenda,
  remainInPropertyAnswer,
  remainInPropertyFile,
  customAddendum,
  customAddendumFile,
}) {
  const hasAddenda = useMemo(
    () =>
      addendaItems.some((item) => addenda?.[item.name] === true) ||
      remainInPropertyAnswer === 'less_30' ||
      remainInPropertyAnswer === 'more_30' ||
      customAddendum,
    [addendaItems, addenda, remainInPropertyAnswer, customAddendum]
  );

  const renderAddendum = (name, label, file, description) => {
    return (
      <>
        <CheckBox name={name} checked readOnly>
          {label}
        </CheckBox>
        {description && <span>{description}</span>}
        {file && <PropertyItem included name={file.name} onClick={() => URLUtils.openDocument(file.url)} />}
        {!file && <span className='ps-4'>(Contact list agent for details and form)</span>}
      </>
    );
  };

  return (
    <>
      {!hasAddenda && <p className='ps-1'>***Seller didn't include any addenda stand out***</p>}
      {hasAddenda && (
        <CheckBoxContainer>
          {addendaItems.map(
            (item) =>
              addenda?.[item.name] && (
                <React.Fragment key={`addenda-${item.name}`}>
                  {renderAddendum(
                    item.name,
                    item.label,
                    addenda[`${item.name}File`],
                    item.name === 'other' ? addenda[`${item.name}Description`] : ''
                  )}
                </React.Fragment>
              )
          )}
          {(remainInPropertyAnswer === 'less_30' || remainInPropertyAnswer === 'more_30') &&
            renderAddendum(
              remainInPropertyAnswer,
              remainInPropertyAnswer === 'less_30'
                ? '29 days or less use Seller in Possession (C.A.R from SIP)'
                : '30 days or more use Lease After Sale (C.A.R form RLAS)',
              remainInPropertyFile
            )}
          {customAddendum && renderAddendum('customAddendum', 'Custom Addendum', customAddendumFile)}
        </CheckBoxContainer>
      )}
    </>
  );
}

export default OfferAddenda;
