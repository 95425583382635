import React from 'react';
import PropTypes from 'prop-types';
import { URLUtils } from 'utils';
import { Container, SectionTitle, SectionItem, SeeMore } from './offerCommon.styled';

function OfferDocuments({ isSellerPreference = false, placeholder = false, offer, isEditing = false }) {
  const handleClickDocument = (doc) => {
    if (doc.url) {
      URLUtils.openDocument(doc.url);
    } else {
      console.error('Cannot find document');
    }
  };

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle>{isSellerPreference && 'Proof of Funds'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference &&
              !isEditing &&
              (offer.proofOfFunds ? (
                <SeeMore onClick={() => handleClickDocument(offer.proofOfFunds)}>{offer.proofOfFunds.name}</SeeMore>
              ) : (
                'Not Specified'
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Loan Approval'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference &&
              !isEditing &&
              (offer.loanApproval ? (
                <SeeMore onClick={() => handleClickDocument(offer.loanApproval)}>{offer.loanApproval.name}</SeeMore>
              ) : (
                'Not Specified'
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Letter to Seller'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference &&
              !isEditing &&
              (offer.letterToSeller ? (
                <SeeMore onClick={() => handleClickDocument(offer.letterToSeller)}>{offer.letterToSeller.name}</SeeMore>
              ) : (
                'Not Specified'
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Additional document'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference &&
              !isEditing &&
              (offer.additionalOfferDocument ? (
                <SeeMore onClick={() => handleClickDocument(offer.additionalOfferDocument)}>
                  {offer.additionalOfferDocument.name}
                </SeeMore>
              ) : (
                'Not Specified'
              ))}
            {isEditing && 'Details being edited'}
          </SectionItem>
        </>
      )}
    </Container>
  );
}

OfferDocuments.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  offer: PropTypes.object,
};
export default OfferDocuments;
