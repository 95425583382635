import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Header, ListingStatus, ListingAddress } from './adminPage.styled';
import { AdminAsyncActions } from 'store/actions/admin';
import { getListings } from 'store/selectors/admin';
import AdminTable from './adminTable';
import { SelectColumnFilter } from './filters';
import CellWithLineBreak from './CellWithLineBreak';
import { getPropertyCardStatus, joinString } from 'utils';
import ListingDetailsModal from './listingDetailsModal';

export default function ListingsAdminPage() {
  const dispatch = useDispatch();

  const [showListingDetails, setShowListingDetails] = useState(false);
  const { isSuccess } = useSelector(AdminAsyncActions.FetchListingDetails.StatusSelector());

  const handleClickListing = useCallback(
    (listing) => {
      dispatch(AdminAsyncActions.FetchListingDetails.Actions.REQUEST(listing.row.original.id));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => dispatch(AdminAsyncActions.FetchListingDetails.Actions.RESET());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setShowListingDetails(true);
    }
  }, [isSuccess]);

  const columns = useMemo(
    () => [
      {
        Header: 'State',
        accessor: (row) => getPropertyCardStatus(row.state),
        Cell: ({ cell: { value } }) => <ListingStatus state={value}>{value}</ListingStatus>,
        id: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
      {
        id: 'address',
        Header: 'Listing Address',
        accessor: (listing) =>
          joinString(
            [
              listing.address?.addressLine1,
              listing.address?.addressLine2,
              listing.address?.city,
              listing.address?.state,
              listing.address?.zip,
            ],
            ', '
          ),
        Cell: ({ cell }) => <ListingAddress onClick={() => handleClickListing(cell)}>{cell.value}</ListingAddress>,
        filter: 'text',
      },
      {
        id: 'agent',
        Header: 'Listing Agent',
        accessor: (listing) =>
          `${listing.listingAgent.firstName} ${listing.listingAgent.lastName}\n${listing.listingAgent.email}`,
        Cell: ({ cell }) => <CellWithLineBreak value={cell.value} />,
        filter: 'text',
      },
      {
        id: 'primarySeller',
        Header: 'Primary Seller',
        accessor: (listing) =>
          listing.primaryOwner?.user
            ? `${listing.primaryOwner.user.firstName} ${listing.primaryOwner.user.lastName}\n${listing.primaryOwner.user.email}`
            : '',
        Cell: ({ cell }) => <CellWithLineBreak value={cell.value} />,
        filter: 'text',
      },
    ],
    [handleClickListing]
  );

  const listings = useSelector(getListings);

  useEffect(() => {
    dispatch(AdminAsyncActions.ListListings.Actions.REQUEST());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Admin | Listings</title>
        <meta name='description' content='Admin | Listings' />
      </Helmet>
      <div className='shadow-sm border rounded'>
        <Header>
          <h2 className='fw-bold'>Listings</h2>
          <h6>({listings.length} Total Results)</h6>
        </Header>
        <AdminTable columns={columns} data={listings} />
      </div>
      <ListingDetailsModal
        show={showListingDetails}
        onClose={() => {
          setShowListingDetails(false);
        }}
      />
    </>
  );
}
