import moment from 'moment';

export const isDateValid = (value, format) => moment(moment(value).format(format), format, true).isValid();

export const isDatePickerValid = (value, label) => {
  let date = moment(value, 'MM/DD/YYYY', true);
  if (!date.isValid()) {
    throw new Error(`Enter a valid ${label} (MM/DD/YYYY)`);
  }
  return true;
};

export const isDateInPast = (compareDate) => {
  var today = new Date();
  var endDate = new Date(compareDate);
  if (endDate <= today) {
    return true;
  }
  return false;
};
