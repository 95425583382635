import styled from 'styled-components';

export const ResetContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 25px;
  border: 3px solid var(--light-blue);
  background-image: url('/pre-auth-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
`;

export const SectionTitle = styled.span`
  color: var(--offer1-green);
  font-size: 32px;
  font-weight: bold;
`;

export const SectionBody = styled.span`
  font-size: 18px;
  margin-top: 0.5rem;
  padding: 10px;
`;

export const Button = styled.button`
  background-color: var(--offer1-green);
  color: white;
`;
