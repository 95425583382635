import React from 'react';
import { SectionTitle, SectionText, LogoutContent } from './notFoundPage';
import Helmet from 'react-helmet';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page not found</title>
        <meta name='description' content='Page not found' />
      </Helmet>
      <MainContainerMd>
        <LogoutContent>
          <SectionTitle className='mt-1'>Oops</SectionTitle>
          <div className='row d-flex justify-content-center display-1'>404</div>
          <SectionText>Page Not Found</SectionText>
        </LogoutContent>
      </MainContainerMd>
    </>
  );
}

export default NotFound;
