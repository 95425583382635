import { call, put } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';

function* sso() {
  const {
    SSO: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;

  try {
    const response = yield call(apiCall, '/users', 'get');
    yield put(
      SUCCESS(response.headers['authorization'], {
        ...response.data,
      })
    );
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default sso;
