const LOGIN_PATH = '/login';
const LOGOUT_PATH = '/logout';
const CUSTOMER_REGISTRATION_PATH = '/account-registration';
const LISTINGS_PATH = '/listings/filter/:filter';
const LISTINGS_PATH_ALL = '/listings/filter/active';
const LISTING_CREATE_PATH = '/create-listing';
const COMPLETE_LISTING_PATH = '/create-listing/:id?';
const LISTING_PATH = '/listing';
const LISTINGS_DETAIL_PATH = '/listing/:id';
const HOME_PATH = '/home';
const OFFERS_PATH = '/offers';
const OFFERS_DETAIL_PATH = '/offers/:id';
const CREATE_OFFER_PATH = '/create-offer';
const CONSUMER_CREATE_OFFER_PATH = '/create-offer-consumer';
const AGENT_APPLICATION_PATH = '/agent-application';
const SELLER_REGISTRATION_PATH = '/seller-registration';
const ALL_LISTINGS_PATH = '/all-listings';
const AGENT_PROFILE_PATH = '/agent-profile';
const OFFER_STATUS = '/offer-status';
const REVIEW_OFFER_PATH = '/offer-status/:id/:actions';
const SEARCH_PAGE = '/search';
const CREATE_PROPERTY_PATH = '/create-property';
const EMAIL_VERIFICATION_PATH = '/email-verification';
const PROPERTY_CONTRACT_PATH = '/property-contract/:id';
const FORGOT_PASSWORD_PATH = '/forgot-password';
const FORGOT_PASSWORD_SUCCESS_PATH = '/forgot-password/success';
const RESET_PASSWORD_PATH = '/reset-password';
const BUYER_REGISTRATION_PATH = '/account-verification';
const BUYING_PATH = '/buying';
const ADMIN_PATH = '/admin/:section';
const ADMIN_LISTINGS_PATH = '/admin/listings';
const ADMIN_USERS_PATH = '/admin/users';
const ADMIN_AGENTS_PATH = '/admin/agents';
const ADMIN_CONTRACTS_PATH = '/admin/contracts';
const CONSUMER_ONBOARDING = '/consumer-onboarding';
const PAY_CONFIRMATION = '/pay-confirmation';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LOGIN_PATH,
  LOGOUT_PATH,
  CUSTOMER_REGISTRATION_PATH,
  LISTING_PATH,
  LISTINGS_DETAIL_PATH,
  LISTINGS_PATH,
  LISTINGS_PATH_ALL,
  HOME_PATH,
  LISTING_CREATE_PATH,
  COMPLETE_LISTING_PATH,
  OFFERS_PATH,
  OFFERS_DETAIL_PATH,
  CREATE_OFFER_PATH,
  AGENT_APPLICATION_PATH,
  SELLER_REGISTRATION_PATH,
  ALL_LISTINGS_PATH,
  AGENT_PROFILE_PATH,
  OFFER_STATUS,
  REVIEW_OFFER_PATH,
  SEARCH_PAGE,
  CREATE_PROPERTY_PATH,
  EMAIL_VERIFICATION_PATH,
  PROPERTY_CONTRACT_PATH,
  FORGOT_PASSWORD_PATH,
  FORGOT_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD_PATH,
  BUYER_REGISTRATION_PATH,
  BUYING_PATH,
  ADMIN_PATH,
  ADMIN_LISTINGS_PATH,
  ADMIN_USERS_PATH,
  ADMIN_AGENTS_PATH,
  ADMIN_CONTRACTS_PATH,
  CONSUMER_CREATE_OFFER_PATH,
  CONSUMER_ONBOARDING,
  PAY_CONFIRMATION,
};
