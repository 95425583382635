import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  PropertyCardContainer,
  PropertyStatus,
  PropertyActionText,
  PropertyAddress,
  PropertyInfoContainer,
  PropertyInfoItem,
  PropertyInfoLabel,
  PropertyInfoValue,
  HorizontalSeparator,
  AgentContainer,
  AgentName,
  OwnerType,
  ContactContainer,
  AgentProfile,
  ActionButtonContainer,
  CardButton,
} from './propertyCard.styled';
import { NotificationContainer } from './buyerCard.styled';
import { PopoverTooltip, Avatar, Icon } from 'components';
import { getPropertyCardStatus, getPropertyCardActionText, formatCurrency, formatPhoneNumber } from 'utils';
import { useAuthUser } from 'hooks';
import EllipsisToolTip from 'ellipsis-tooltip-react-chan';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function MlsPropertyCard({ listing, user, className, isPendingAction }) {
  const { isAgent } = useAuthUser();
  const history = useHistory();
  const address = listing.address === null ? undefined : listing.address ?? undefined;

  const handleOnClick = () => {
    const state = { listing: { ...listing, id: null, customListingUrl: null, state: 'DRAFT' } };
    const path = `/create-listing`;
    history.push(path, state);
  };

  const getPropertyOwner = () => {
    if (user?.id === listing?.listingAgent?.id) {
      return listing.primaryOwner !== null &&
        listing.primaryOwner.user !== null &&
        listing.primaryOwner?.user.firstName &&
        listing.primaryOwner?.user.lastName
        ? `${listing.primaryOwner?.user.firstName} ${listing.primaryOwner?.user.lastName}`
        : 'Owner has not been entered';
    } else {
      return `${listing.listingAgent.firstName} ${listing.listingAgent?.lastName}`;
    }
  };

  const getPropertyOwnerType = () => {
    if (user?.id === listing?.listingAgent?.id) {
      return listing.primaryOwner !== null &&
        listing.primaryOwner.user !== null &&
        listing.primaryOwner?.user.firstName &&
        listing.primaryOwner?.user.lastName &&
        listing.primaryOwner !== null
        ? 'Primary Owner'
        : '';
    } else {
      return 'Agent';
    }
  };

  const getFormatPhoneNumber = () => {
    if (user?.id === listing?.listingAgent.id) {
      return formatPhoneNumber(
        listing.primaryOwner && listing.primaryOwner.user ? listing.primaryOwner.user.phone : ''
      );
    } else {
      return formatPhoneNumber(listing.listingAgent.agent.phone);
    }
  };

  const getEmail = () => {
    if (user?.id === listing?.listingAgent.id) {
      return listing.primaryOwner && listing.primaryOwner.user ? listing.primaryOwner.user.email : '';
    } else {
      return listing.listingAgent.email;
    }
  };

  return (
    <>
      <div className={className}>
        <PropertyCardContainer>
          <PropertyStatus status={listing.state}>
            {getPropertyCardStatus(listing.state, listing.contractId)}
          </PropertyStatus>
          <div>
            {(listing.state === 'SOLD' || listing.state === 'PENDING_ESCROW') && (
              <NavLink to={`/property-contract/${listing.id}`} onClick={(e) => e.stopPropagation()}>
                Contract Summary
              </NavLink>
            )}
          </div>

          <NotificationContainer>
            {(getPropertyCardActionText(listing.state) !== '' || isPendingAction) &&
              listing.state !== 'PENDING_ESCROW' && (
                <PropertyActionText status={listing.state}>
                  {isPendingAction && listing.state === 'ACTIVE'
                    ? 'Review offer'
                    : getPropertyCardActionText(listing.state)}
                </PropertyActionText>
              )}
          </NotificationContainer>
          <PropertyAddress>
            {address && (
              <EllipsisToolTip>
                {address?.addressLine1}
                <br />
                {address?.addressLine2 && (
                  <>
                    {address.addressLine2}
                    <br />
                  </>
                )}
                {`${address?.city}, ${address?.state} ${address?.zip}`}
              </EllipsisToolTip>
            )}
          </PropertyAddress>
          <HorizontalSeparator />
          <PropertyInfoContainer>
            <PropertyInfoItem>
              <PropertyInfoLabel>[List Price]</PropertyInfoLabel>
              <PropertyInfoValue>
                <EllipsisToolTip>{listing?.price ? formatCurrency(listing.price, 0) : '$TBD'}</EllipsisToolTip>
              </PropertyInfoValue>
            </PropertyInfoItem>
          </PropertyInfoContainer>
          <HorizontalSeparator />
          <AgentContainer>
            <AgentProfile>
              <Avatar
                size={40}
                src={
                  isAgent ? listing?.primaryOwner?.user?.profilePictureUrl : listing?.listingAgent?.profilePictureUrl
                }
              />
              <div className='d-flex flex-column ms-2'>
                <AgentName>{getPropertyOwner()}</AgentName>
                <OwnerType>{getPropertyOwnerType()}</OwnerType>
              </div>
            </AgentProfile>
            {isAgent && (
              <ContactContainer>
                {isMobile ? (
                  <a href={`tel:${getFormatPhoneNumber()}`} onClick={(e) => e.stopPropagation()}>
                    <Icon name='call' />
                  </a>
                ) : (
                  <PopoverTooltip
                    id='property-card-phone-number'
                    placement='top'
                    trigger='hover'
                    icon={
                      <CopyToClipboard
                        text={getFormatPhoneNumber()}
                        onCopy={() => toast.success('Copied to clipboard', { icon: '📞' })}
                      >
                        <div>
                          <Icon name='call' />
                        </div>
                      </CopyToClipboard>
                    }
                    content={getFormatPhoneNumber()}
                  />
                )}
                {isMobile ? (
                  <a href={`mailto:${getEmail()}`} onClick={(e) => e.stopPropagation()}>
                    <Icon name='envelope' />
                  </a>
                ) : (
                  <PopoverTooltip
                    id='property-card-email'
                    placement='top'
                    trigger='hover'
                    icon={
                      <CopyToClipboard
                        text={getEmail() || 'N/A'}
                        onCopy={() => toast.success('Copied to clipboard', { icon: '✉️' })}
                      >
                        <div>
                          <Icon name='envelope' />
                        </div>
                      </CopyToClipboard>
                    }
                    content={getEmail() || 'N/A'}
                  />
                )}
              </ContactContainer>
            )}
          </AgentContainer>
          <HorizontalSeparator />
          <ActionButtonContainer>
            <CardButton onClick={handleOnClick}>Import</CardButton>
          </ActionButtonContainer>
        </PropertyCardContainer>
      </div>
    </>
  );
}

MlsPropertyCard.propTypes = {
  listing: PropTypes.object,
  user: PropTypes.object,
  className: PropTypes.string,
  highestOffer: PropTypes.number,
  offerCount: PropTypes.number,
  auctionExpirationDate: PropTypes.string,
  isPendingAction: PropTypes.bool,
};

export default MlsPropertyCard;
