import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const SectionText = styled.p`
  font-size: 14px;
  color: var(--gray1);
  text-align: center;
`;

export const TosText = styled.p`
  font-size: 12px;
  color: var(--gray1);
  text-align: center;
  font-style: italic;
  margin: 10px;
`;

export const SectionTitle = styled.span`
  color: var(--offer1-green);
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  ${breakpoint('xs', 'md')`
    font-size: 36px;
  `}
`;

export const RegisterContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  border: 3px solid var(--light-blue);
  padding: 5px;
`;

export const StateNotification = styled.p`
  font-size: 0.75rem;
`;

export const HouseImg = styled.img`
  width: 100%;
  margin-left: -20px;
`;
