import { call, put } from 'redux-saga/effects';
import { ContractAsyncActions } from 'store/actions/contract';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* updateState(action) {
  const {
    SaveListingContractState: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ContractAsyncActions;
  const {
    payload: { id, state },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/${id}/contract/state/${state}`, 'put');
    yield put(SUCCESS(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default updateState;
