import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PrimaryGreenButton, PrimaryBlueButton } from 'components';
import { CTAContainer, HeaderContainer } from './header.styled';

function Header({ title, showInMobile = true, ctas }) {
  const history = useHistory();

  return (
    <HeaderContainer showInMobile={showInMobile}>
      <h1>{title}</h1>
      <CTAContainer>
        {ctas?.map((cta, index) => {
          const CTAComponent = cta ? (cta.variant === 'primary' ? PrimaryGreenButton : PrimaryBlueButton) : null;

          return (
            cta && (
              <CTAComponent key={`cta-${index}`} onClick={cta.to ? () => history.push(cta.to) : cta.onClick}>
                {cta.title}
              </CTAComponent>
            )
          );
        })}
      </CTAContainer>
    </HeaderContainer>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  showInMobile: PropTypes.bool,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(['primary', 'secondary']),
      to: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Header;
