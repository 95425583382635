import { merge, get, set, capitalize } from 'lodash';
import { createSelector } from 'reselect';

export const createAsyncAction = (prefix, name, params) => {
  const defaultParams = {
    REQUEST: [],
    SUCCESS: [],
    FAILURE: ['error'],
    RESET: [],
  };

  const {
    REQUEST: paramsForRequest,
    SUCCESS: paramsForSuccess,
    FAILURE: paramsForFailure,
    RESET: paramsForReset,
  } = merge({}, params, defaultParams);

  const Types = {
    REQUEST: `${prefix}.${name}.REQUEST`,
    SUCCESS: `${prefix}.${name}.SUCCESS`,
    FAILURE: `${prefix}.${name}.FAILURE`,
    RESET: `${prefix}.${name}.RESET`,
  };

  const Actions = {
    REQUEST: (...payload) => ({
      type: Types.REQUEST,
      payload: paramsForRequest.reduce((res, param, index) => ({ ...res, [param]: payload[index] }), {}),
    }),
    SUCCESS: (...payload) => ({
      type: Types.SUCCESS,
      payload: paramsForSuccess.reduce((res, param, index) => ({ ...res, [param]: payload[index] }), {}),
    }),
    FAILURE: (...payload) => ({
      type: Types.FAILURE,
      payload: paramsForFailure.reduce((res, param, index) => ({ ...res, [param]: payload[index] }), {}),
    }),
    RESET: (...payload) => ({
      type: Types.RESET,
      payload: paramsForReset.reduce((res, param, index) => ({ ...res, [param]: payload[index] }), {}),
    }),
  };

  const StatusSelector = () => {
    const getState = (state) => state[prefix];
    return createSelector(getState, (state) => {
      const isLoading = state[`isLoading${capitalize(name)}`];
      const isSuccess = state[`isSuccess${capitalize(name)}`];
      const error = state[`${name}Error`];
      return { isLoading, isSuccess, error };
    });
  };

  return { Actions, Types, Name: name, StatusSelector };
};

export const createField = (field) => {
  return {
    [`isLoading${capitalize(field)}`]: false,
    [`isSuccess${capitalize(field)}`]: false,
    [`${field}Error`]: '',
  };
};

export const createReducerHandlers = (action) => {
  const actionName = action.Name;
  const key = capitalize(actionName);

  return {
    [action.Types.REQUEST]: (state) =>
      merge({}, state, {
        [`isLoading${key}`]: true,
        [`isSuccess${key}`]: false,
        [`${actionName}Error`]: null,
      }),
    [action.Types.FAILURE]: (state, action) =>
      merge({}, state, {
        [`isLoading${key}`]: false,
        [`isSuccess${key}`]: false,
        [`${actionName}Error`]: {
          message: get(action, 'payload.error.message', ''),
          httpStatus: get(action, 'payload.error.httpStatus', ''),
        },
      }),
    [action.Types.SUCCESS]: (state, action) => {
      let nextState = {
        ...state,
        [`isLoading${key}`]: false,
        [`isSuccess${key}`]: true,
        [`${actionName}Error`]: null,
      };

      const payloadResource = get(action, 'payload', {});
      Object.keys(payloadResource).forEach((key) => {
        set(nextState, key, payloadResource[key]);
      });

      return merge({}, nextState);
    },
    [action.Types.RESET]: (state) =>
      merge({}, state, {
        [`isLoading${key}`]: false,
        [`isSuccess${key}`]: false,
        [`${actionName}Error`]: null,
      }),
  };
};

export const mergeFields = (...actions) =>
  actions.reduce((res, action) => ({ ...res, ...createField(action.Name) }), {});

export const mergeHandlers = (...actions) =>
  actions.reduce((res, action) => ({ ...res, ...createReducerHandlers(action) }), {});

export const generateFieldsAndHandlers = (...actions) => ({
  initialState: mergeFields(...actions),
  handler: mergeHandlers(...actions),
});

export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export const createReducerFromActions = (...actions) => {
  const { initialState, handler } = generateFieldsAndHandlers(...actions);

  return createReducer(initialState, handler);
};
