import { createAsyncAction } from 'utils/store';

export const CreateListing = createAsyncAction('createListing', 'createListing', {
  REQUEST: ['listing'],
  SUCCESS: ['listing'],
});

export const UpdateListing = createAsyncAction('createListing', 'updateListing', {
  REQUEST: ['listing'],
  SUCCESS: ['listing'],
});

export const DeleteListing = createAsyncAction('createListing', 'deleteListing', {
  REQUEST: ['id'],
  SUCCESS: ['listing'],
});

export const UpdateState = createAsyncAction('createListing', 'updateState', {
  REQUEST: ['listingId', 'status', 'comment'],
  SUCCESS: ['listing'],
});

export const SendListingToSeller = createAsyncAction('createListing', 'sendListingToSeller', {
  REQUEST: ['listing'],
  SUCCESS: ['listing'],
});

export const CreateProvisionalListing = createAsyncAction('createListing', 'createProvisionalListing', {
  REQUEST: ['listing'],
  SUCCESS: [],
});

export const EditListingURL = createAsyncAction('createListing', 'editListingURL', {
  REQUEST: ['listing'],
  SUCCESS: ['listing'],
});

export const CreateListingAsyncActions = {
  CreateListing,
  UpdateListing,
  DeleteListing,
  UpdateState,
  SendListingToSeller,
  CreateProvisionalListing,
  EditListingURL,
};
