import React from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Typography } from 'components';
import { floridaCostAllocationItems } from 'constants/listingConstants';
import { useResponsive } from 'hooks';

function OfferCostAllocationTable() {
  const isOneColumn = useResponsive('lg');

  const renderTable = (items = []) => {
    if (!items.length) {
      return null;
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cost</th>
            <th>Allocation</th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ label, payer }) => (
            <tr key={label}>
              <td>{label}</td>
              <td>
                <Typography bold color={payer === 'Buyer' ? 'offer1-blue' : payer === 'Seller' ? 'offer1-green' : ''}>
                  {payer}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Row>
      <Col md={12} lg={6}>
        {renderTable(
          isOneColumn
            ? floridaCostAllocationItems
            : floridaCostAllocationItems.slice(0, Math.ceil(floridaCostAllocationItems.length / 2))
        )}
      </Col>
      {!isOneColumn && (
        <Col md={12} lg={6}>
          {renderTable(floridaCostAllocationItems.slice(Math.ceil(floridaCostAllocationItems.length / 2)))}
        </Col>
      )}
    </Row>
  );
}

export default OfferCostAllocationTable;
