import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import OfferInLine from './offerInLine';
import { CloseButton } from './offerHistoryModal.styled';

function OfferExpandModal({ show, listing, offers, globalCounter, onClose }) {
  return (
    <Modal show={show} aria-labelledby='offer-history' backdrop='static' dialogClassName='modal-90wh' scrollable>
      <Modal.Body className='d-flex flex-column' scrollable>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <OfferInLine listing={listing} offers={offers} globalCounter={globalCounter} showOnlyDetails />
      </Modal.Body>
    </Modal>
  );
}

OfferExpandModal.propTypes = {
  show: PropTypes.bool.isRequired,
  listing: PropTypes.object.isRequired,
  offers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OfferExpandModal;
