import React from 'react';
import {
  StyledPrimaryButton,
  StyledOutLineGrayButton,
  ButtonWrapperWrapper,
  StyledOutLineButton,
} from './customButton.styled';
import { OFFER1_GREEN, OFFER1_BLUE } from 'constants/styleConstants';

export const PrimaryGreenButton = (props) => {
  return <StyledPrimaryButton $primary color={OFFER1_GREEN} {...props} />;
};

export const PrimaryBlueButton = (props) => {
  return <StyledPrimaryButton $primary color={OFFER1_BLUE} {...props} />;
};

export const OutGrayButton = (props) => {
  return <StyledOutLineGrayButton $primary color='var(--gray7)' {...props} />;
};

export const ButtonWrapper = ({ children, ...rest }) => {
  return <ButtonWrapperWrapper {...rest}>{children}</ButtonWrapperWrapper>;
};

export const OutlineButton = (props) => {
  return <StyledOutLineButton {...props} />;
};
