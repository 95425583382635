import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 700px;
  max-width: 70%;
  text-align: center;
`;

export const ButtonRow = styled.div`
  justify-content: center;
`;
