import styled from 'styled-components';

export const AvatarContainer = styled.div`
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  border: 2px solid ${({ color }) => `var(--${color})`};
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
