import React from 'react';
import { StepSubHeader, StepName, StepNumber, CreateHeaderContainer, HeaderRow } from './createHeader.styled';
import PropTypes from 'prop-types';

function CreateHeader({ stepNumber, stepName, totalSteps }) {
  return (
    <CreateHeaderContainer>
      <HeaderRow>
        <StepNumber>
          <span>{stepNumber}</span>
        </StepNumber>
        <div className='d-flex flex-column'>
          <StepName>{stepName}</StepName>
          <StepSubHeader>{`Step ${stepNumber} of ${totalSteps}`}</StepSubHeader>
        </div>
      </HeaderRow>
    </CreateHeaderContainer>
  );
}

CreateHeader.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  stepName: PropTypes.string.isRequired,
  isNewListing: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default CreateHeader;
