import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 6px;
  outline: none;
  border: none;
  background-color: white;

  ${breakpoint('mobile', 'desktop')`
    right: 5px;
  `}
`;

export const TabContainer = styled.div`
  display: flex;
  padding: 15px 0;

  ${breakpoint('mobile', 'desktop')`
    flex-direction: column;

    button {
      margin: 5px 0;
    }
  `}
`;

export const TabItem = styled.button`
  min-width: 146px;
  height: 39px;
  border: 1px solid ${({ active }) => (active ? 'var(--offer1-blue)' : 'var(--gray6)')};
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  line-height: 38px;
  color: ${({ active }) => (active ? 'var(--offer1-blue)' : 'var(--gray6)')};
  text-align: center;
  transition: all 0.2s ease;

  &:hover,
  &.active {
    color: var(--offer1-blue);
    border-color: var(--offer1-blue);
    text-decoration: none;
  }
`;

export const Offer1PrimaryButton = styled.button`
  min-width: 146px;
  height: 39px;
  border: 1px solid var(--offer1-blue);
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  line-height: 38px;
  color: var(--offer1-blue);
  text-align: center;
  transition: all 0.2s ease;

  &:hover,
  &.active {
    color: #fff;
    background-color: var(--offer1-blue);
  }

  &:disabled {
    color: #fff;
    border-color: var(--gray10);
    background-color: var(--gray10);
    cursor: not-allowed;
  }
`;

export const ReviewContainer = styled.div`
  padding: ${({ padding }) => (padding ? '38px 35px' : '')};
  border: ${({ showBorder }) => (showBorder ? '1px solid var(--gray6)' : '')};
  border-radius: 4px;
`;

export const OffersContainer = styled.div`
  display: grid;
  overflow: auto;
`;

export const InstructionContainer = styled.div`
  padding: 30px 15% 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 26px;
    line-height: 24px;
    font-weight: 600;
    color: var(--offer1-blue);
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ControllerContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 12px 0;
`;

export const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border: 1px solid var(--gray6);
  background: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ expanded }) => (expanded ? 'var(--offer1-blue)' : 'var(--offer1-black)')};

  svg {
    transform: rotate(${({ expanded }) => (!expanded ? 180 : 0)}deg);
    path {
      fill: ${({ expanded }) => (expanded ? 'var(--offer1-blue)' : 'var(--offer1-black)')};
    }
  }
`;

export const ContractPlaceholder = styled.div`
  width: 70%;
  margin: auto;
  padding: 0 10px;
  text-align: center;
  color: #000;

  ${breakpoint('mobile', 'desktop')`
    width: 100%;
    svg {
      height: 100%;
    }
  `}

  svg {
    width: 100%;
  }
`;

export const BlueText = styled.h2`
  color: var(--offer1-blue);
  font-weight: 600;
  font-size: 24px;
  display: block;
  text-align: center;
`;

export const ActionAlert = styled(Alert)`
  width: fit-content;
`;
