import { AuthAsyncActions, LOGIN_REDIRECT } from 'store/actions/auth';
import { createReducer, generateFieldsAndHandlers } from 'utils/store';
import { values, merge } from 'lodash';

const { initialState, handler } = generateFieldsAndHandlers(...values(AuthAsyncActions));

export default createReducer(
  { ...initialState, isLoadingSso: true },
  {
    ...handler,
    [LOGIN_REDIRECT]: (state) => merge({}, state, { user: null, token: null }),
  }
);
