import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const DropContainer = styled.div`
  margin: 20px;
  width: calc(100% - 40px);
  padding: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ active }) => (active ? 'var(--offer1-blue)' : 'rgba(0, 0, 0, 0.25)')};
  border-radius: 10px;
  outline: none;
  transition: border 0.24s ease-in-out;

  ${breakpoint('mobile', 'desktop')`
    width: 100%;
    flex-direction: column;
    margin: 20px 0;
  `}
`;

export const UploadDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 45px 0 24px;

  ${breakpoint('mobile', 'desktop')`
    margin: 8px 0;
  `}

  span:first-child {
    font-size: 14px;
    line-height: 17px;
  }
  span:last-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.6;
  }
`;
