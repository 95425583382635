import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { PaginationContainer, PaginationButton, PageNumber } from './pagination.styled';

function Pagination({ page, size, onLeft, onRight, className = '' }) {
  return (
    <PaginationContainer className={className}>
      <PaginationButton onClick={onLeft} disabled={page === 0}>
        <Icon name='chevron-left' color={page !== 0 ? '#008EFF' : '#828282'} />
      </PaginationButton>
      <PageNumber>
        {page + 1} / {size}
      </PageNumber>
      <PaginationButton onClick={onRight} disabled={page === size - 1}>
        <Icon name='chevron-right' color={page !== size - 1 ? '#008EFF' : '#828282'} />
      </PaginationButton>
    </PaginationContainer>
  );
}

Pagination.propTypes = {
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,
};

export default Pagination;
