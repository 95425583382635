import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import Helmet from 'react-helmet';
import MainLayout from './mainLayout';
import ListingsPage from 'containers/ListingsPage';
import CustomerRegistration from 'containers/RegistrationPage/customerRegistration';
import SellerRegistration from 'containers/SellerRegistrationPage';
import LoginPage from 'containers/LoginPage';
import LogoutPage from 'containers/LogoutPage';
import HomePageV2 from 'containers/HomePageV2';
import ListingDetailPage from 'containers/ListingDetailPage';
import routePaths from './routeConstants';
import CreateNewListingPage from 'containers/CreateNewListingPage';
import OfferDashboardPage from 'containers/OfferDashboardPage';
import CreateOfferPage from 'containers/CreateOfferPage';
import OfferDetailPage from 'containers/OfferDetailPage';
import AllListingsPage from 'containers/AllListingsPage';
import AgentProfilePage from 'containers/AgentProfilePage';
import ReviewOfferPage from 'containers/ReviewOfferPage';
import SearchPage from 'containers/SearchPage';
import PropertyPage from 'containers/PropertyPage';
import PropertyContractPage from 'containers/PropertyContractPage';
import AccountVerificationPage from 'containers/AccountVerificationPage';
import ForgotPasswordPage from 'containers/ForgotPassword';
import ForgotPasswordSuccessPage from 'containers/ForgotPassword/forgotPasswordSuccess';
import ResetPasswordPage from 'containers/AccountVerificationPage/resetPassword';
import BuyerRegistrationPage from 'containers/BuyerRegistrationPage';
import BuyingPage from 'containers/BuyingPage';
import AdminPage from 'containers/AdminPage';
import NotFoundPage from 'containers/NotFoundPage';
import ConsumerCreateOffer from 'containers/ConsumerCreateOffer';
import ConsumerOnboarding from 'containers/ConsumerOnboarding';
import { ProtectedRoute, LoadingMessage, SavingMessage } from 'components';
import { history } from 'store/configureStore';
import { AuthAsyncActions } from 'store/actions/auth';
import { AppAsyncActions } from 'store/actions/app';
import { initializeGA } from 'services/initializeGA';
import GlobalStyle from 'styles/globalStyles';
import { ToastContainer } from 'react-toastify';
import SubscriptionConfirmPage from 'containers/SubscriptionConfirmPage';

function App() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(AuthAsyncActions.SSO.StatusSelector());

  useEffect(() => {
    dispatch(AuthAsyncActions.SSO.Actions.REQUEST());
    dispatch(AppAsyncActions.FetchMetadata.Actions.REQUEST());
    initializeGA();
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Offer1</title>
        <meta name='description' content='Offer1' />
      </Helmet>
      <GlobalStyle />
      {!isLoading && (
        <ConnectedRouter history={history}>
          <MainLayout>
            <Switch>
              <Redirect from='/' exact to={routePaths.LOGIN_PATH} />
              <Route exact path={routePaths.LOGIN_PATH} component={LoginPage} />
              <Route path={routePaths.LOGOUT_PATH} component={LogoutPage} />
              <Route path={routePaths.SELLER_REGISTRATION_PATH} component={SellerRegistration} />
              <Route path={routePaths.BUYER_REGISTRATION_PATH} component={BuyerRegistrationPage} />
              <Route path={routePaths.CUSTOMER_REGISTRATION_PATH} component={CustomerRegistration} />
              <Route path={routePaths.LISTINGS_DETAIL_PATH} component={ListingDetailPage} />
              <ProtectedRoute path={routePaths.LISTINGS_PATH} component={ListingsPage} />
              <ProtectedRoute path={routePaths.COMPLETE_LISTING_PATH} exact component={CreateNewListingPage} />
              <ProtectedRoute path={routePaths.ALL_LISTINGS_PATH} component={AllListingsPage} />
              <ProtectedRoute exact path={routePaths.OFFERS_PATH} component={OfferDashboardPage} />
              <ProtectedRoute exact path={routePaths.OFFERS_DETAIL_PATH} component={OfferDetailPage} />
              <ProtectedRoute exact path={routePaths.CREATE_OFFER_PATH} component={CreateOfferPage} />
              <ProtectedRoute exact path={routePaths.REVIEW_OFFER_PATH} component={ReviewOfferPage} />
              <ProtectedRoute exact path={routePaths.CONSUMER_CREATE_OFFER_PATH} component={ConsumerCreateOffer} />
              <Route exact path={routePaths.SEARCH_PAGE} component={SearchPage} />
              <ProtectedRoute exact path={routePaths.HOME_PATH} component={HomePageV2} />
              <ProtectedRoute exact path={routePaths.AGENT_PROFILE_PATH} component={AgentProfilePage} />
              <ProtectedRoute exact path={routePaths.CREATE_PROPERTY_PATH} component={PropertyPage} />
              <ProtectedRoute exact path={routePaths.PROPERTY_CONTRACT_PATH} component={PropertyContractPage} />
              <Route path={routePaths.EMAIL_VERIFICATION_PATH} component={AccountVerificationPage} />
              <Route path={routePaths.FORGOT_PASSWORD_PATH} exact component={ForgotPasswordPage} />
              <Route path={routePaths.FORGOT_PASSWORD_SUCCESS_PATH} exact component={ForgotPasswordSuccessPage} />
              <ProtectedRoute exact path={routePaths.BUYING_PATH} component={BuyingPage} />
              <ProtectedRoute path={routePaths.ADMIN_PATH} component={AdminPage} />
              <Route path={routePaths.RESET_PASSWORD_PATH} component={ResetPasswordPage} />
              <ProtectedRoute path={routePaths.CONSUMER_ONBOARDING} component={ConsumerOnboarding} />
              <Route path={routePaths.PAY_CONFIRMATION} component={SubscriptionConfirmPage} />
              <Route path='*' component={NotFoundPage} />
            </Switch>
          </MainLayout>
        </ConnectedRouter>
      )}
      <LoadingMessage />
      <SavingMessage />
      <ToastContainer autoClose={2000} theme='colored' hideProgressBar />
    </>
  );
}

export default App;
