import React, { useState, useEffect, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useResponsive } from 'hooks';
import { FormField, PercentField, CurrencyField, RadioButtons, DatePicker } from 'components';
import { formatPercentage, isDateValid, isDatePickerValid } from 'utils';
import moment from 'moment';
import { isNil } from 'lodash';
import { yesNoOptions as preapprovedOptions, daysToCloseOptions, purchaseOptions } from 'constants/optionConstants';
import Alert from 'react-bootstrap/Alert';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { ListingsAsyncActions } from 'store/actions/listings';
import { getLoanTypes } from 'store/selectors/listings';

function OfferTerms(props) {
  const isMobile = useResponsive();
  const dispatch = useDispatch();
  const otherLoanTypes = useSelector(getLoanTypes);

  const { listingId, listingState, terms, ownershipPurpose, loanTypes, proofOfFundsRequired, errors, isCounter } =
    props;

  const [isOther, setIsOther] = useState(terms?.loanType === 'OTHER');

  useEffect(() => {
    dispatch(ListingsAsyncActions.FetchListingLoanTypes.Actions.REQUEST(listingId));

    return () => {
      dispatch(ListingsAsyncActions.FetchListingLoanTypes.Actions.RESET());
    };
  }, [dispatch, listingId]);

  useEffect(() => {
    setIsOther(terms?.loanType === 'OTHER' || !!otherLoanTypes?.find((loanType) => loanType.value === terms?.loanType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherLoanTypes]);

  const otherLoanTypesOptions = useMemo(() => {
    return otherLoanTypes ? [...otherLoanTypes, { name: 'Other', value: 'OTHER' }] : [];
  }, [otherLoanTypes]);

  const onPurchaseMethodSelected = (event) => {
    const newTerms = { ...terms, loan: event.target.value === 'loan' };
    props.onStateChanged(newTerms);
  };

  const onDaysToCloseMethodSelected = (event) => {
    const newTerms = { ...terms, useDaysToClose: event.target.value === 'day' };
    props.onStateChanged(newTerms);
  };

  const onPreapprovedSelected = (event) => {
    const newTerms = {
      ...terms,
      preapproved: event.target.value === 'yes',
    };
    props.onStateChanged(newTerms);
  };

  const onRadioSelected = (event) => {
    const { name, value } = event.target;

    const newTerms = {
      ...terms,
      [name]: value,
    };
    props.onStateChanged(newTerms);
  };

  const onLoanTypeRadioSelected = (event) => {
    const { name, value } = event.target;

    if (value === 'OTHER') {
      setIsOther(true);
      if (name === 'loanType') {
        return;
      }
    } else if (otherLoanTypes?.find((loanType) => loanType.value === value)) {
      setIsOther(true);
    } else {
      setIsOther(false);
    }

    const newTerms = {
      ...terms,
      loanType: value,
    };

    props.onStateChanged(newTerms);

    if (value === 'CARRY' || value === 'ASSUMPTION') {
      Swal.fire({
        text: 'This loan option requires seller approval, only select this option if the seller is aware and willing to cooperate.',
      });
    }
  };

  const onDownPaymentDollarChange = (dollarData) => {
    const dpPct = formatPercentage(parseInt(terms.offerAmount), dollarData.value, 2);
    const updatedState = {
      ...terms,
      downPaymentPercentage: dpPct,
      downPaymentDollar: dollarData.value,
      loanAmountPercentage: 100 - dpPct,
      loanAmountDollar: parseInt(terms.offerAmount) - dollarData.value,
    };
    props.onStateChanged(updatedState);
  };

  const onDownPaymentPctChange = (percentData) => {
    const dpAmt = (percentData.value * parseInt(terms?.offerAmount)) / 100;
    const updatedState = {
      ...terms,
      downPaymentPercentage: percentData.value,
      downPaymentDollar: dpAmt,
      loanAmountPercentage: 100 - percentData.value,
      loanAmountDollar: parseInt(terms.offerAmount) - dpAmt,
    };
    props.onStateChanged(updatedState);
  };

  const onLoanAmountDollarChange = (dollarData) => {
    const dpPct = formatPercentage(parseInt(terms.offerAmount), dollarData.value, 2);
    const updatedState = {
      ...terms,
      loanAmountPercentage: dpPct,
      loanAmountDollar: dollarData.value,
      downPaymentPercentage: 100 - dpPct,
      downPaymentDollar: parseInt(terms.offerAmount) - dollarData.value,
    };
    props.onStateChanged(updatedState);
  };

  const onLoanAmountPctChange = (percentData) => {
    const dpAmt = Math.round((percentData.value * parseInt(terms.offerAmount)) / 100);
    const updatedState = {
      ...terms,
      loanAmountPercentage: percentData.value,
      loanAmountDollar: dpAmt,
      downPaymentPercentage: 100 - percentData.value,
      downPaymentDollar: parseInt(terms.offerAmount) - dpAmt,
    };

    props.onStateChanged(updatedState);
  };

  const onEarnestDollarChange = (dollarData) => {
    const dpPct = formatPercentage(parseInt(terms.offerAmount), dollarData.value, 2);
    const updatedState = {
      ...terms,
      earnestDepositPercentage: dpPct,
      earnestDepositDollar: dollarData.value,
    };
    props.onStateChanged(updatedState);
  };

  const onEarnestPctChange = (percentData) => {
    const dpAmt = (percentData.value * parseInt(terms.offerAmount)) / 100;
    const updatedState = {
      ...terms,
      earnestDepositPercentage: percentData.value,
      earnestDepositDollar: dpAmt,
    };
    props.onStateChanged(updatedState);
  };

  const onIncreasedDollarChange = (dollarData) => {
    const dpPct = formatPercentage(parseInt(terms.offerAmount), dollarData.value, 2);
    const updatedState = {
      ...terms,
      increasedDepositPercentage: dpPct,
      increasedDepositDollar: dollarData.value,
    };
    props.onStateChanged(updatedState);
  };

  const onIncreasedDepositPctChange = (percentData) => {
    const dpAmt = (percentData.value * parseInt(terms.offerAmount)) / 100;
    const updatedState = {
      ...terms,
      increasedDepositPercentage: percentData.value,
      increasedDepositDollar: dpAmt,
    };
    props.onStateChanged(updatedState);
  };

  const onStateChanged = (data) => {
    const updatedTerms = { ...terms, [data.field]: data.value };
    if (data.field === 'offerAmount') {
      if (terms.loan) {
        updatedTerms.loanAmountDollar = (updatedTerms.loanAmountPercentage * parseInt(data.value)) / 100;
        updatedTerms.downPaymentDollar = (updatedTerms.downPaymentPercentage * parseInt(data.value)) / 100;
      }
      updatedTerms.earnestDepositDollar = (updatedTerms.earnestDepositPercentage * parseInt(data.value)) / 100;
    }

    if (data.field === 'exactClosingDate') {
      if (typeof data.value === 'object') {
        updatedTerms.exactClosingDate = data.value.format('YYYY-MM-DD');
      }
      if (isDateValid(data.value, 'MM/DD/YYYY')) {
        updatedTerms.exactClosingDate = moment(data.value).format('YYYY-MM-DD');
      }
    }

    props.onStateChanged(updatedTerms);
  };

  const isCalifornia = listingState === 'CA';

  return (
    <>
      <div className='my-2' id='ownershipPurpose'>
        <label htmlFor='purchase-method' className='control-label'>
          1) What would be the Ownership Purpose?*
        </label>
        <RadioButtons
          items={ownershipPurpose}
          groupName='ownershipPurpose'
          selectedOption={terms?.ownershipPurpose}
          onStateChanged={onRadioSelected}
          vertical={isMobile}
          disabled={isCounter}
          error={errors?.ownershipPurpose}
        />
      </div>
      <Row className='my-2'>
        <Col sm={4}>
          <CurrencyField
            fieldId='offerAmount'
            label='2) Offer Amount'
            placeholder='$'
            value={terms?.offerAmount}
            onChange={onStateChanged}
            required
            error={errors?.offerAmount}
          />
        </Col>
      </Row>
      <div id='useDaysToClose'>
        <label htmlFor='days-to-close-method' className='control-label'>
          3) How do you want to select your closing date?*
        </label>
        <RadioButtons
          items={daysToCloseOptions}
          groupName='days-to-close-method'
          selectedOption={
            terms?.useDaysToClose === undefined || terms?.useDaysToClose === null
              ? ''
              : terms?.useDaysToClose === true
              ? 'day'
              : 'date'
          }
          onStateChanged={onDaysToCloseMethodSelected}
          error={errors?.useDaysToClose}
        />
      </div>
      <Row className='my-2 ms-0'>
        <Col sm={3}>
          {terms?.useDaysToClose && (
            <FormField
              type='number'
              fieldId='daysToClose'
              label=''
              initialValue={terms?.daysToClose}
              onStateChanged={onStateChanged}
              required
              error={errors?.daysToClose}
            />
          )}
          {!isNil(terms?.useDaysToClose) && !terms?.useDaysToClose && (
            <DatePicker
              onStateChanged={onStateChanged}
              fieldId='exactClosingDate'
              label=''
              required
              placeholder='MM/DD/YYYY'
              initialValue={
                terms?.exactClosingDate &&
                (isDateValid(terms?.exactClosingDate, 'YYYY-MM-DD')
                  ? moment(terms?.exactClosingDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
                  : null)
              }
              validator={(value) => isDatePickerValid(value, 'Closing Date')}
              error={errors?.exactClosingDate}
            />
          )}
        </Col>
      </Row>
      <div className='my-2' id='loan'>
        <label htmlFor='purchase-method' className='control-label'>
          4) Method of Purchase*
        </label>
        <RadioButtons
          items={purchaseOptions}
          groupName='purchase-method'
          selectedOption={
            terms?.loan === undefined || terms?.loan === null ? '' : terms?.loan === true ? 'loan' : 'cash'
          }
          onStateChanged={onPurchaseMethodSelected}
          disabled={isCounter}
          error={errors?.loan}
        />
      </div>
      <div className='ms-5 my-2' id='loanType'>
        {terms.loan && (
          <>
            <div className='ms-0'>
              <label htmlFor='purchase-method' className='control-label'>
                4a) Type of Loan*
              </label>
              <RadioButtons
                items={loanTypes}
                groupName='loanType'
                selectedOption={isOther ? 'OTHER' : terms.loanType}
                onStateChanged={onLoanTypeRadioSelected}
                vertical={isMobile}
                disabled={isCounter}
                error={errors?.loanType}
              />
              {isOther && (
                <>
                  <RadioButtons
                    items={otherLoanTypesOptions}
                    groupName='otherLoanType'
                    selectedOption={terms.loanType}
                    onStateChanged={onLoanTypeRadioSelected}
                    vertical={isMobile}
                    disabled={isCounter}
                    error={errors?.loanType}
                  />
                  {terms.loanType === 'OTHER' && (
                    <Row sm={4} className='my-2 align-items-center'>
                      <div className='ms-4 mt-2'>Explain 'Other':</div>
                      <FormField
                        fieldId='loanOtherDesc'
                        initialValue={terms.loanOtherDesc}
                        editable={!isCounter}
                        onStateChanged={onStateChanged}
                      />
                    </Row>
                  )}
                </>
              )}
            </div>
            <div className='ms-0 my-2' id='preapproved'>
              <label htmlFor='purchase-method' className='control-label'>
                4b) Have you been pre-approved for this purchase?{proofOfFundsRequired ? '*' : ''}
              </label>
              <RadioButtons
                items={preapprovedOptions}
                groupName='preapproved'
                selectedOption={isNil(terms.preapproved) ? '' : terms.preapproved ? 'yes' : 'no'}
                onStateChanged={onPreapprovedSelected}
                disabled={isCounter}
                error={errors?.preapproved}
              />
            </div>
          </>
        )}
        {terms.loan && (
          <>
            <Row className='align-items-end'>
              <Col sm={4}>
                <PercentField
                  fieldId='downPaymentPercentage'
                  label='4c) Down Payment'
                  placeholder='%'
                  value={terms.downPaymentPercentage}
                  onChange={onDownPaymentPctChange}
                  required
                  error={errors?.downPaymentPercentage}
                  disabled={isCounter}
                />
              </Col>
              <Col sm={4}>
                <CurrencyField
                  fieldId='downPaymentDollar'
                  placeholder='$'
                  value={terms.downPaymentDollar}
                  onChange={onDownPaymentDollarChange}
                  error={errors?.downPaymentDollar}
                  disabled={isCounter}
                />
              </Col>
            </Row>
            <Row className='align-items-end'>
              <Col sm={4}>
                <PercentField
                  fieldId='loanAmountPercentage'
                  label='4d) Loan Amount'
                  placeholder='%'
                  value={terms.loanAmountPercentage}
                  onChange={onLoanAmountPctChange}
                  required
                  error={errors?.loanAmountPercentage}
                  disabled={isCounter}
                />
              </Col>
              <Col sm={4}>
                <CurrencyField
                  fieldId='loanAmountDollar'
                  placeholder='$'
                  value={terms.loanAmountDollar}
                  onChange={onLoanAmountDollarChange}
                  error={errors?.loanAmountDollar}
                  disabled={isCounter}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className='my-2' id='emd'>
        <label htmlFor='purchase-method' className='control-label'>
          5) Earnest Money and Initial Deposit
        </label>
        <Row className='align-items-end ms-1 my-2'>
          <Col sm={4}>
            <PercentField
              fieldId='earnestDepositPercentage'
              label='Amount'
              placeholder='%'
              value={terms.earnestDepositPercentage}
              onChange={onEarnestPctChange}
              required
              error={errors?.earnestDepositPercentage}
            />
          </Col>
          <Col sm={4}>
            <CurrencyField
              fieldId='earnestDepositDollar'
              label=''
              placeholder='$'
              value={terms.earnestDepositDollar}
              onChange={onEarnestDollarChange}
              error={errors?.earnestDepositDollar}
            />
          </Col>
          {!isCalifornia && (
            <Col sm={4}>
              <FormField
                type='number'
                fieldId='earnestDepositDays'
                label='Day(s) to Deliver to Escrow'
                initialValue={terms?.earnestDepositDays}
                onStateChanged={onStateChanged}
                error={errors?.earnestDepositDays}
              />
            </Col>
          )}
          <Col sm={12}>
            <Alert variant='info'>EMD is due within the standard 3 days after acceptance by wire transfer.</Alert>
          </Col>
        </Row>
      </div>
      <div className='my-2' id='amd'>
        <label htmlFor='purchase-method' className='control-label'>
          6) Additional Deposit
        </label>
        <Row className='align-items-end ms-1 my-2'>
          <Col sm={4}>
            <PercentField
              fieldId='increasedDepositPercentage'
              label='Amount'
              placeholder='%'
              value={terms.increasedDepositPercentage}
              onChange={onIncreasedDepositPctChange}
              error={errors?.increasedDepositPercentage}
            />
          </Col>
          <Col sm={4}>
            <CurrencyField
              fieldId='increasedDepositDollar'
              label=''
              placeholder='$'
              value={terms.increasedDepositDollar}
              onChange={onIncreasedDollarChange}
              error={errors?.increasedDepositDollar}
            />
          </Col>
          <Col sm={4}>
            <FormField
              type='number'
              fieldId='increasedDepositDays'
              label='Day(s) to Deliver to Escrow'
              initialValue={terms?.increasedDepositDays}
              onStateChanged={onStateChanged}
              error={errors?.increasedDepositDays}
            />
          </Col>
        </Row>
      </div>
      {isCalifornia && (
        <FormField
          type='textarea'
          fieldId='additionalFinanceTerms'
          label='7) Additional Financial Terms'
          placeholder='(Optional)'
          initialValue={terms.additionalFinanceTerms ?? ''}
          onStateChanged={onStateChanged}
          maxLength={170}
        />
      )}
      <FormField
        type='textarea'
        fieldId='termsComments'
        label='Additional Comments on Terms of Offer: (Comments are visible to all parties and will not be a part of any agreement)'
        placeholder='(Optional)'
        initialValue={terms.termsComments ?? ''}
        onStateChanged={onStateChanged}
        error={errors?.termsComments}
        maxLength={500}
      />
    </>
  );
}

export default OfferTerms;
