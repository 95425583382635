import React from 'react';
import { FormField, CurrencyField, RadioButtons } from 'components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  yesNoOptions as warrantyOptions,
  buyerSellerOptions,
  buyerSellerOptionsWithBoth,
} from 'constants/optionConstants';
import styled from 'styled-components';

const StyledCol = styled(Col)`
  max-width: 400px;
`;

function OfferServicesHomeWarranty({ services, errors, showBothParty = true, onStateChanged }) {
  const onHandleWarranty = (event) => {
    const data = {
      value: event.target.value === 'yes',
      field: 'warrantyIncluded',
    };
    onStateChanged(data);
  };

  const onWarrantyMaxCostChanged = (event) => {
    const data = {
      value: event.value && event.value > 2000 ? 2000 : event.value,
      field: 'warrantyMaxCost',
    };
    onStateChanged(data);
  };

  const onRadioSelected = (event) => {
    const data = {
      value: event.target.value,
      field: event.target.name,
    };
    onStateChanged(data);
  };

  return (
    <>
      <p>1) Home Warranty</p>
      <label id='warrantyIncluded' htmlFor='warrantyIncluded'>
        Does the buyer require a home warranty?*
      </label>
      <RadioButtons
        items={warrantyOptions}
        groupName='warrantyIncluded'
        selectedOption={services.warrantyIncluded === null ? null : services.warrantyIncluded ? 'yes' : 'no'}
        onStateChanged={onHandleWarranty}
        error={errors?.warrantyIncluded}
      />
      {services.warrantyIncluded && (
        <Row className='mb-3 ms-5 me-2'>
          <StyledCol className='border p-3 mt-2'>
            <FormField
              fieldId='warrantyCompany'
              label='Home Warranty Company:'
              placeholder='Company Name'
              initialValue={services.warrantyCompany}
              onStateChanged={onStateChanged}
              required
              error={errors?.warrantyCompany}
            />
            <CurrencyField
              fieldId='warrantyMaxCost'
              label='Cost to not exceed'
              placeholder='$'
              value={services.warrantyMaxCost}
              onChange={onWarrantyMaxCostChanged}
              required
              error={errors?.warrantyMaxCost}
            />
            <label htmlFor='warrantyPayer' className='px-3' id='warrantyPayer'>
              Which party will cover the cost:
            </label>
            <RadioButtons
              items={showBothParty ? buyerSellerOptionsWithBoth : buyerSellerOptions}
              groupName='warrantyPayer'
              selectedOption={services.warrantyPayer}
              onStateChanged={onRadioSelected}
              error={errors?.warrantyPayer}
            />
          </StyledCol>
        </Row>
      )}
    </>
  );
}

export default OfferServicesHomeWarranty;
