import { put, take } from 'redux-saga/effects';
import { TOGGLE_SAVING } from 'store/actions/app';
import { AdminAsyncActions } from 'store/actions/admin';
import { AuthAsyncActions } from 'store/actions/auth';
import { AgentAsyncActions } from 'store/actions/agent';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import { OffersAsyncActions } from 'store/actions/offers';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { ContractAsyncActions } from 'store/actions/contract';
import { BuyersAsyncActions } from 'store/actions/buyers';

export default function* root() {
  const savingActions = [
    AdminAsyncActions.UpdateUserRole,
    AdminAsyncActions.UploadContract,
    AuthAsyncActions.UpdateAccount,
    AgentAsyncActions.RecordQuestionnaire,
    CreateOfferAsyncActions.CreateOffer,
    CreateOfferAsyncActions.UpdateOffer,
    CreateOfferAsyncActions.UpdateState,
    OffersAsyncActions.UpdateState,
    OffersAsyncActions.CreateCounterOffer,
    OffersAsyncActions.UpdateCounterOffer,
    OffersAsyncActions.UpdateGlobalCounterOffer,
    CreateListingAsyncActions.CreateListing,
    CreateListingAsyncActions.UpdateListing,
    CreateListingAsyncActions.UpdateState,
    CreateListingAsyncActions.SendListingToSeller,
    CreateListingAsyncActions.CreateProvisionalListing,
    CreateListingAsyncActions.EditListingURL,
    ContractAsyncActions.SaveListingContract,
    ContractAsyncActions.SaveListingContractState,
    BuyersAsyncActions.UpdateBuyerProfile,
    ContractAsyncActions.SaveSignatureContract,
  ];
  const showActions = savingActions.map(({ Types }) => Types.REQUEST);
  const hideActions = savingActions.map(({ Types }) => [Types.SUCCESS, Types.FAILURE]).flat();

  while (true) {
    yield take(showActions);
    yield put({ type: TOGGLE_SAVING });
    yield take(hideActions);
    yield put({ type: TOGGLE_SAVING });
  }
}
