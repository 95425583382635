import { apiCall } from 'utils/apiCall';

export async function verifyEmail(token, email) {
  const response = await apiCall(`/users/activation/${email}/${token}`, 'put');
  return response.data;
}

export async function verifyAgent(state, licenseNumber, lastName) {
  const response = await apiCall('/dre/verify', 'get', { state, licenseNumber, lastName });
  return response.data;
}

export async function uploadFile(file, fileType, onUploadProgress) {
  const response = await apiCall(`/upload/${fileType}`, 'post', file, true, onUploadProgress);
  return response.data;
}

export async function reportError(message, stackTrace) {
  const response = await apiCall('/ui-logger', 'post', {
    level: 'ERROR',
    message: message.length > 200 ? message.substring(0, 199) : message,
    stackTrace: stackTrace.length > 512 ? stackTrace.substring(0, 511) : stackTrace,
  });
  return response.data;
}

export async function reportInfo(message, stackTrace, userId) {
  const path = userId ? `/ui-logger/${userId}` : '/ui-logger';
  const response = await apiCall(path, 'post', {
    level: 'INFO',
    message: message.length > 200 ? message.substring(0, 199) : message,
    stackTrace: stackTrace.length > 512 ? stackTrace.substring(0, 511) : stackTrace,
  });
  return response.data;
}
