import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const CustomFileUpload = styled.label`
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

export const StyledProfilePicNotice = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 40px;
  color: #828282;
  ${breakpoint('mobile', 'desktop')`
    margin-left: 0;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #555;

  .edit-avatar {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    color: #f5f5f5;
    opacity: 0;
    svg {
      font-size: 4rem;
      align-self: center;
    }
  }

  &:hover .edit-avatar {
    opacity: 1;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;
