import ReactGA from 'react-ga';
import { history } from 'store/configureStore';

export const initializeGA = () => {
  const TRACKING_ID = 'UA-189025556-1';
  ReactGA.initialize(TRACKING_ID);

  history.listen((location) => {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
  });
};
