import React from 'react';
import Col from 'react-bootstrap/Col';

function OfferFieldSummary({ title, children, ...rest }) {
  return (
    <Col {...rest} className='mt-3'>
      <p className='mb-0'>{title}</p>
      <hr className='mt-1 mb-2' />
      <b>{children}</b>
    </Col>
  );
}

export default OfferFieldSummary;
