import React from 'react';
import { FormattedMessage } from 'components';
import Popover from 'react-bootstrap/Popover';
import { PopoverBody, RpaItem } from './rpaTooltip.styled';

export const RpaItem29Tooltip = (props) => (
  <Popover id='rpa29-popover' {...props}>
    <Popover.Header as='h6'>
      <FormattedMessage id='tooltip.rpa29.label' />
    </Popover.Header>
    <PopoverBody>
      <div className='d-flex'>
        <div className='me-1'>A.</div>
        <RpaItem>
          <FormattedMessage id='tooltip.rpa29.description.A' />
        </RpaItem>
      </div>
      <div className='d-flex'>
        <div className='me-1'>B.</div>
        <RpaItem>
          <FormattedMessage id='tooltip.rpa29.description.B' />
        </RpaItem>
      </div>
    </PopoverBody>
  </Popover>
);

export const RpaItem31Tooltip = (props) => (
  <Popover id='rpa31-popover' {...props}>
    <Popover.Header as='h6'>
      <FormattedMessage id='tooltip.rpa31.label' />
    </Popover.Header>
    <PopoverBody>
      <div className='d-flex'>
        <div className='me-1'>A.</div>
        <RpaItem>
          <FormattedMessage id='tooltip.rpa31.description.A' />
        </RpaItem>
      </div>
      <div className='d-flex'>
        <div className='me-1'>B.</div>
        <RpaItem>
          <FormattedMessage id='tooltip.rpa31.description.B' />
        </RpaItem>
      </div>
    </PopoverBody>
  </Popover>
);
