import React from 'react';
import { FileSelect, CompanyInput, Divider, Typography } from 'components';
import Alert from 'react-bootstrap/Alert';

function AddListingReports({
  listingState,
  inspectionReport,
  termiteReport,
  questionnaireReport,
  titlePrelimReport,
  listingReports,
  listing,
  onStateChanged,
  onServicesChanged,
  deleteFile,
  errors,
}) {
  return (
    <>
      <Alert variant='success'>
        To improve your chances of a successful closing without an escrow fallout, upload the property reports listed
        below.
      </Alert>
      <Typography as='h6' size={28}>
        Property Reports
      </Typography>
      <div className='d-flex flex-column'>
        <FileSelect
          className='my-2'
          type='INSPECTION'
          title='Inspection Report'
          file={inspectionReport}
          onFileSelected={onStateChanged}
          deleteFile={(type, fileId) => deleteFile(type, fileId)}
        />
        <FileSelect
          className='my-2'
          type='TERMITE'
          title='Termite Report'
          file={termiteReport}
          onFileSelected={onStateChanged}
          deleteFile={(type, fileId) => deleteFile(type, fileId)}
        />
        <FileSelect
          className='my-2'
          type='QUESTIONNAIRE'
          title='Seller Property Questionnaire'
          file={questionnaireReport}
          onFileSelected={onStateChanged}
          deleteFile={(type, fileId) => deleteFile(type, fileId)}
        />

        <FileSelect
          className='my-2'
          type='TITLE_PRELIM'
          title='Title Prelim'
          file={titlePrelimReport}
          onFileSelected={onStateChanged}
          deleteFile={(type, fileId) => deleteFile(type, fileId)}
        />
        <FileSelect
          className='my-2'
          multiple
          type='OTHER'
          title='Other Reports'
          file={listingReports}
          onFileSelected={onStateChanged}
          deleteFile={(type, fileId) => deleteFile(type, fileId)}
        />
      </div>
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <Typography as='h4' size={28}>
        Services
      </Typography>
      <p className='mt-4'>
        Please select a company or input the full and correct name and contact information of your preferred service
        provider for each category. This will be shared with all cooperative agents and buyers.
      </p>
      <Typography as='h6' size={28}>
        Title Company Information*
      </Typography>
      <CompanyInput
        id='titleCompany'
        type='TITLE'
        value={listing?.titleCompany}
        onStateChanged={onServicesChanged}
        errors={errors?.titleCompany}
      />
      <Divider className='mx-n2 mx-md-n4 my-3' />
      <Typography as='h6' size={28}>
        Escrow Company Information*
      </Typography>
      <CompanyInput
        id='escrowCompany'
        type='ESCROW'
        value={listing?.escrowCompany}
        sameAs={listing?.titleCompany}
        sameAsLabel='Same as Title'
        onStateChanged={onServicesChanged}
        errors={errors?.escrowCompany}
      />
      {listingState === 'CA' && (
        <>
          <Divider className='mx-n2 mx-md-n4 my-3' />
          <Typography as='h6' size={28}>
            Natural Hazard Company Information*
          </Typography>
          <CompanyInput
            id='hazardCompany'
            type='HAZARD'
            value={listing?.hazardCompany}
            errors={errors?.hazardCompany}
            userNameOnly
            onStateChanged={onServicesChanged}
          />
        </>
      )}
    </>
  );
}

export default AddListingReports;
