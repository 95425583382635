import { call, put } from 'redux-saga/effects';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import { apiCall } from 'utils/apiCall';

function* fetchPreFilled(action) {
  const {
    FetchPreFilledOffer: {
      Actions: { SUCCESS, FAILURE },
    },
  } = CreateOfferAsyncActions;
  const {
    payload: { listingId, profileId },
  } = action;

  try {
    const url = profileId
      ? `/offers/pre-filled/${listingId}?profileId=${profileId}`
      : `/offers/pre-filled/${listingId}`;
    const response = yield call(apiCall, url, 'post');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchPreFilled;
