import styled from 'styled-components';

export const Header = styled.div`
  background-color: var(--offer1-blue);
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  margin-top: 1px;

  .step-number {
    display: inline-block;
    background-color: white;
    color: var(--offer1-blue);
    border-radius: 50%;
    font-size: 20px;
    line-height: 30px;
    min-width: 30px;
    height: 30px;
    text-align: center;
    margin-right: 10px;
  }

  svg:last-child {
    transform: rotate(${({ expanded }) => (!expanded ? 180 : 0)}deg);
    color: #fff;
    path {
      fill: #fff;
    }
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 8px 0;
`;
