import { createSelector } from 'reselect';

export const getState = (state) => state.app;

export const getLoading = createSelector(getState, ({ loading }) => loading);

export const getSaving = createSelector(getState, ({ saving }) => saving);

export const getMetaListingStates = createSelector(getState, ({ meta }) => {
  return meta ? [...meta.listingStates].sort((a, b) => a.name.localeCompare(b.name)) : [];
});

export const getMetaStates = createSelector(getState, ({ meta }) =>
  [...meta.states].sort((a, b) => a.name.localeCompare(b.name))
);

export const getOwnerTypes = createSelector(getState, ({ meta }) => meta?.ownerTypes || []);

export const getLoanTypes = createSelector(getState, ({ meta }) => meta?.loanTypes || []);

export const getOwnershipTypes = createSelector(getState, ({ meta }) => meta?.ownershipTypes || []);

export const getPropertyTypes = createSelector(getState, ({ meta }) => meta?.propertyTypes || []);
