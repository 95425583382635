import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

export const Header = styled(Modal.Header)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 24px;

  h3 {
    font-size: 32px;
    font-weight: bold;
    color: var(--offer1-green);
  }

  p {
    color: var(--gray2);
  }

  .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;
