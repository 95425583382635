import { AppAsyncActions, TOGGLE_LOADING, TOGGLE_SAVING } from 'store/actions/app';
import { createReducer, generateFieldsAndHandlers } from 'utils/store';
import { values, merge } from 'lodash';

const { initialState, handler } = generateFieldsAndHandlers(...values(AppAsyncActions));

export default createReducer(
  { ...initialState, loading: false, saving: false },
  {
    ...handler,
    [TOGGLE_LOADING]: (state) => merge({}, state, { loading: !state.loading }),
    [TOGGLE_SAVING]: (state) => merge({}, state, { saving: !state.saving }),
  }
);
