import React from 'react';
import { withRouter } from 'react-router-dom';
import { reportError } from 'services/client';
import { OFFER1_BLUE } from 'constants/styleConstants';
import routes from 'containers/App/routeConstants';
import dyncorn from './assets/images/dynocorn.png';
import Rollbar from 'rollbar';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  logErrorToMyService = (message, stacktrace) => {
    reportError(message, stacktrace);
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    const rollbarConfig = {
      accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
      environment: process.env.REACT_APP_ENV,
    };
    const rollbar = new Rollbar(rollbarConfig);
    rollbar.error(error?.toString(), error);
    this.logErrorToMyService(error?.toString(), errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='mt-5 text-center'>
          <span>
            Oops, something went wrong...
            <br />
            Don't worry, our Dynocorn has been notified and is looking into the issue.
            <br />
            Let's fly back to the home page and try again.
          </span>
          <div
            style={{ cursor: 'pointer', color: OFFER1_BLUE }}
            onClick={() => {
              this.props.history.push(routes.HOME_PATH);
            }}
          >
            Go to home
          </div>
          <img src={dyncorn} alt='dynocorn help' />
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
