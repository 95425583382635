import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Icon from '../Icon';

const Tooltip = ({
  overlay,
  placement = 'auto',
  trigger = 'hover',
  icon = <Icon name='exclamation-circle' size={18} />,
}) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 400 }}
    overlay={overlay}
    trigger={[trigger, 'focus']}
    rootClose={trigger === 'click'}
  >
    <span className='ms-1'>{icon}</span>
  </OverlayTrigger>
);

Tooltip.propTypes = {
  placement: PropTypes.string,
  overlay: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  icon: PropTypes.element,
};

export default Tooltip;
