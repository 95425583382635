import styled from 'styled-components';

export const ContinueButton = styled.button`
  padding: 16px 48px;
  border-color: var(--offer1-blue);
  border-width: 2px;
  border-radius: 5px;
  background-color: var(--offer1-blue);
  color: white;
`;

export const ListingAgentPending = styled.div`
  margin-top: 150px;
  text-align: center;
  width: 100%;
`;
