import React from 'react';
import MessagesPropertyTile from './messagesPropertyTile';
import OffersPropertyTile from './offersPropertyTile';

function PropertyCardTile({ offerings, messages }) {
  return (
    <table className='table table-dark'>
      <tbody>
        <tr>
          <OffersPropertyTile offerings={offerings} />
        </tr>
        <tr>
          <MessagesPropertyTile messages={messages} />
        </tr>
      </tbody>
    </table>
  );
}

export default PropertyCardTile;
