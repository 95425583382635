import axios from 'axios';
import { loginRedirect } from 'store/actions/auth';

export default function configureAxios(store) {
  axios.defaults.baseURL = process.env.REACT_APP_SERVICES_API;
  axios.defaults.withCredentials = true;
  axios.defaults.timeout = 30000;

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      const token = store.getState().auth.token;
      if (token) {
        config.headers['Authorization'] = token;
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // 401 -> Redirect to login page
      if (error.response?.status === 401) {
        store.dispatch(loginRedirect(window.location.pathname));
      }
      return Promise.reject(error);
    }
  );
}
