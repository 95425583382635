import React from 'react';
import { useAuthUser } from 'hooks';
import { Icon } from 'components';
import { FooterContainer, FooterContainerWithBackground, FooterText } from './footer.styled';

function Footer() {
  const { user } = useAuthUser();

  return user ? (
    <footer className='footer mt-auto'>
      <FooterContainer>
        <Icon name='offer1' width={130} height={30} />
        <p>{new Date().getFullYear()}© Offer1. All Rights Reserved</p>
      </FooterContainer>
    </footer>
  ) : (
    <FooterContainerWithBackground>
      <Icon name='offer1' width={215} height={79} />
      <FooterText className='d-flex justify-content-center'>
        {new Date().getFullYear()}© Offer1. All Rights Reserved
      </FooterText>
    </FooterContainerWithBackground>
  );
}

export default Footer;
