import React from 'react';
import { FormField, CheckBox, AddendumUpload, Typography } from 'components';
import { buyerAddendaItemsCA, buyerAddendaItemsFL } from 'constants/offerConstants';
import { propertyAddendaItemsCA, propertyAddendaItemsFL } from 'constants/listingConstants';
import { CheckBoxContainer } from './offerSellerAddenda.styled';
import OfferSellerAddenda from './offerSellerAddenda';

function OfferOtherTermsAddenda({
  listingState,
  otherTerms,
  propertyAddenda,
  buyerAddenda,
  remainInPropertyAnswer,
  remainInPropertyFile,
  customAddendum,
  customAddendumFile,
  errors,
  onStateChanged,
}) {
  const onCheckboxChange = (event) => {
    const { name, checked } = event.target;

    onStateChanged({
      field: 'buyerAddenda',
      value: { ...buyerAddenda, [name]: checked },
    });
  };

  const handlePropertyAddendaSelected = (name) => (file) => {
    onStateChanged({
      field: 'buyerAddenda',
      value: {
        ...buyerAddenda,
        [name]: file,
      },
    });
  };

  const handlePropertyOtherDescriptionChange = (value) => {
    onStateChanged({
      field: 'buyerAddenda',
      value: {
        ...buyerAddenda,
        otherDescription: value,
      },
    });
  };

  return (
    <>
      <Typography as='h6' bold>
        1. Other/Additional Terms
      </Typography>
      <FormField
        type='textarea'
        fieldId='otherTerms'
        label='Use other terms for contract items that were not adequately covered or need additional explanation. Whatever is inputted in this box will be added to contract.'
        maxLength={listingState === 'FL' ? 1800 : 260}
        placeholder='(Optional)'
        rows={Math.ceil((listingState === 'FL' ? 1800 : 260) / 80)}
        initialValue={otherTerms ?? ''}
        onStateChanged={onStateChanged}
        error={errors?.otherTerms}
      />
      <Typography as='h6' bold>
        2. Below are Seller Added Addenda.
      </Typography>
      <OfferSellerAddenda
        addendaItems={listingState === 'FL' ? propertyAddendaItemsFL : propertyAddendaItemsCA}
        addenda={propertyAddenda}
        remainInPropertyAnswer={remainInPropertyAnswer}
        remainInPropertyFile={remainInPropertyFile}
        customAddendum={customAddendum}
        customAddendumFile={customAddendumFile}
      />
      <Typography as='h6' bold>
        3. Buyer addenda that will be part of your offer.
      </Typography>
      <CheckBoxContainer>
        {(listingState === 'FL' ? buyerAddendaItemsFL : buyerAddendaItemsCA).map((item) => (
          <React.Fragment key={`buyer-addenda-${item.name}`}>
            <CheckBox name={item.name} checked={buyerAddenda?.[item.name]} onChange={onCheckboxChange}>
              {item.label}
            </CheckBox>
            {item.name === 'other' && buyerAddenda?.[item.name] && (
              <FormField
                fieldId='otherDescription'
                initialValue={buyerAddenda?.['otherDescription']}
                onStateChanged={({ value }) => handlePropertyOtherDescriptionChange(value)}
              />
            )}
            {buyerAddenda?.[item.name] && (
              <AddendumUpload
                id={item.name}
                addendumFile={buyerAddenda?.[`${item.name}File`]}
                onFileChange={handlePropertyAddendaSelected(`${item.name}File`)}
                error={errors?.buyerAddenda?.[item.name]}
              />
            )}
          </React.Fragment>
        ))}
      </CheckBoxContainer>
    </>
  );
}

export default OfferOtherTermsAddenda;
