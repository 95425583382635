import React, { useState, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FormField, RadioButtons, Divider, Typography } from 'components';
import { AllocationQuestion } from './offerCostAllocations.styled';
import { buyerSellerOptionsWithBothAndNA } from 'constants/optionConstants';
import OfferCostAllocationTable from './offerCostAllocationTableCA';

function OfferCostAllocationsCA({ costAllocations, errors, isCounter, onStateChanged }) {
  const [additionalItem, setAdditionalItem] = useState({
    item: '',
    responsiblePayer: undefined,
  });

  const onAllocationSelected = useCallback(
    (event) => {
      const { name, value } = event.target;

      if (name === 'additionalAllocator') {
        setAdditionalItem((prev) => ({
          ...prev,
          responsiblePayer: value,
        }));
        return;
      }

      onStateChanged({ value: value, field: name });
    },
    [onStateChanged]
  );

  const onStateAllocationSelected = useCallback(
    (event) => {
      const { name, value } = event.target;
      costAllocations.stateItems.forEach(function (part, index) {
        if (part.fieldId === name) {
          costAllocations.stateItems[index] = {
            ...costAllocations.stateItems[index],
            responsiblePayer: value,
          };
        }
      });
      onStateChanged({ value: costAllocations.stateItems, field: 'stateItems' });
    },
    [costAllocations.stateItems, onStateChanged]
  );

  const handleItemChange = useCallback((event) => {
    setAdditionalItem((prev) => ({
      ...prev,
      item: event.value,
    }));
  }, []);

  const handleAddItem = () => {
    if (
      additionalItem.item === '' ||
      !additionalItem.responsiblePayer ||
      costAllocations.additionalItems?.map((allocation) => allocation.item).includes(additionalItem.item)
    ) {
      return;
    }
    onStateChanged({
      value: [...(costAllocations.additionalItems || []), additionalItem],
      field: 'additionalItems',
    });

    setAdditionalItem({
      item: '',
      responsiblePayer: undefined,
    });
  };

  const handleDeleteItem = (item) => {
    onStateChanged({
      value: costAllocations.additionalItems.filter((allocator) => allocator.item !== item),
      field: 'additionalItems',
    });
  };

  return (
    <>
      {/* <div className='ms-3 mb-3'>
              <p>
                The Cost allocation has been pre-selected by the seller based on industry norms.
                <br /> You are free to adjust them as needed to match your buyer’s requirements, <br />
                however, any adjustments may weaken your offer or affect its attractiveness.
              </p>
            </div> */}
      <Typography as='p' size={28}>
        Cost Allocations
      </Typography>
      {costAllocations?.stateItems?.map(({ fieldId, item, responsiblePayer }, index) => (
        <React.Fragment key={`${fieldId}-${responsiblePayer}`}>
          <Row className='ms-1 mt-2'>
            <AllocationQuestion md={12}>{`${index + 1}) ${item}`}</AllocationQuestion>
          </Row>
          <Row className='ms-2 mb-2'>
            <RadioButtons
              fontSize={16}
              items={buyerSellerOptionsWithBothAndNA}
              groupName={fieldId}
              selectedOption={responsiblePayer}
              onStateChanged={onStateAllocationSelected}
            />
          </Row>
        </React.Fragment>
      ))}
      <Divider className='mx-n2 mx-md-n4 my-2' />
      <Typography as='p' size={28}>
        Additional Cost Allocations (Optional)
      </Typography>
      <Typography size={18}>
        <b>DO NOT</b> create a new cost allocation for Title/Closing Services or Home Warranty. These will be addressed
        in the next step.
      </Typography>
      <Row className='d-flex align-items-end'>
        <Col sm='auto'>
          <FormField
            fieldId='item'
            label='Item'
            placeholder='Enter Item'
            initialValue={additionalItem.item}
            onStateChanged={handleItemChange}
          />
        </Col>
        <Col sm='auto'>
          <RadioButtons
            items={buyerSellerOptionsWithBothAndNA}
            groupName='additionalAllocator'
            selectedOption={additionalItem.responsiblePayer}
            onStateChanged={onAllocationSelected}
          />
        </Col>
        <Col sm='auto'>
          <Button className='ms-1' variant='outline-primary' onClick={handleAddItem}>
            Add
          </Button>
        </Col>
      </Row>
      {costAllocations.additionalItems?.map((allocation) => (
        <Row className='d-flex align-items-end' key={allocation.item}>
          <Col sm='auto'>
            <FormField fieldId={allocation.item} label='' placeholder='Enter Item' initialValue={allocation.item} />
          </Col>
          <Col sm='auto'>
            <RadioButtons
              items={buyerSellerOptionsWithBothAndNA}
              groupName={allocation.item}
              selectedOption={allocation.responsiblePayer}
            />
          </Col>
          <Col sm='auto'>
            <Button className='ms-1' variant='outline-primary' onClick={() => handleDeleteItem(allocation.item)}>
              Delete
            </Button>
          </Col>
        </Row>
      ))}
      <Divider className='mx-n2 mx-md-n4 my-2' />
      <Typography as='p' size={28}>
        Detailed Cost Allocations Breakdown
      </Typography>
      <OfferCostAllocationTable costAllocations={costAllocations} />
      <FormField
        type='textarea'
        fieldId='costAllocationsComments'
        label='Additional Comments on Cost Allocation: (Comments are visible to all parties and will not be a part of any agreement)'
        placeholder='(Optional)'
        maxLength={500}
        initialValue={costAllocations.costAllocationsComments}
        onStateChanged={onStateChanged}
        error={errors?.costAllocationsComments}
      />
    </>
  );
}

export default OfferCostAllocationsCA;
