import { put, take } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { AppAsyncActions, TOGGLE_LOADING } from 'store/actions/app';
import { DashboardAsyncActions } from 'store/actions/dashboard';
import { AdminAsyncActions } from 'store/actions/admin';
import { ListingsAsyncActions } from 'store/actions/listings';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { OffersAsyncActions } from 'store/actions/offers';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { ContractAsyncActions } from 'store/actions/contract';

export default function* root() {
  const loadingActions = [
    AuthAsyncActions.SSO,
    AuthAsyncActions.Login,
    AuthAsyncActions.SignUp,
    AuthAsyncActions.ForgotPassword,
    AuthAsyncActions.VerifyAccount,
    AppAsyncActions.FetchMetadata,
    DashboardAsyncActions.FetchDashboard,
    AdminAsyncActions.ListUsers,
    AdminAsyncActions.ListAgents,
    AdminAsyncActions.ListListings,
    AdminAsyncActions.ListContracts,
    AdminAsyncActions.FetchAgentQuestionnaire,
    AdminAsyncActions.FetchListingDetails,
    AdminAsyncActions.FetchOfferDashboard,
    AdminAsyncActions.ResetPassword,
    ListingsAsyncActions.FetchListing,
    ListingsAsyncActions.ListListings,
    ListingsAsyncActions.FetchListingDetails,
    BuyersAsyncActions.ListBuyers,
    BuyersAsyncActions.FetchBuyer,
    BuyersAsyncActions.FetchBuyerOffers,
    BuyersAsyncActions.FetchBuyerProfile,
    BuyersAsyncActions.FetchBuyerProfiles,
    OffersAsyncActions.FetchOffer,
    OffersAsyncActions.ListOffers,
    OffersAsyncActions.FetchGlobalCounterOffer,
    CreateOfferAsyncActions.FetchPreFilledOffer,
    CreateOfferAsyncActions.DeleteOffer,
    CreateListingAsyncActions.DeleteListing,
    ContractAsyncActions.FetchListingContract,
  ];
  const showActions = loadingActions.map(({ Types }) => Types.REQUEST);
  const hideActions = loadingActions.map(({ Types }) => [Types.SUCCESS, Types.FAILURE]).flat();

  while (true) {
    yield take(showActions);
    yield put({ type: TOGGLE_LOADING });
    yield take(hideActions);
    yield put({ type: TOGGLE_LOADING });
  }
}
