import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import routeConstants from '../App/routeConstants';
import { Icon } from 'components';
import InviteAgentModal from '../HomePage/inviteAgentModal';
import { useDispatch } from 'react-redux';
import { AgentAsyncActions } from 'store/actions/agent';
import { ModalContent, ButtonRow } from './propertyCreated.styled';

function PropertyCreated() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showNotifyAgent, setShowNotifyAgent] = useState(false);
  const [agent, setAgent] = useState({});

  const handleViewDashBoard = () => {
    history.push(routeConstants.HOME_PATH);
  };

  const handleClose = () => {
    setShowNotifyAgent(false);
  };

  const handleSend = () => {
    if (agent) {
      dispatch(
        AgentAsyncActions.SendMessage.Actions.REQUEST({
          recipient: {
            email: agent.email,
            firstName: agent.firstName,
            lastName: agent.lastName,
          },
          message: '',
        })
      );
    }
    setShowNotifyAgent(false);
  };

  const handleShow = () => {
    setShowNotifyAgent(true);
  };

  const handleChange = useCallback(
    (state) => {
      const updatedAgent = { ...agent, [state.field]: state.value };
      setAgent(updatedAgent);
    },
    [agent]
  );

  return (
    <>
      {showNotifyAgent === false && (
        <div className='modal-90h p-3'>
          <ModalContent>
            <Icon name='checkmark' size={100} />
            <h4 className='mt-3'>Your Listing Has Been Saved</h4>
            <p>
              <b>Notice:</b> Only certified agents can create Listings on Offer1. If you have an agent please invite
              them now.
            </p>
            <ButtonRow className='row'>
              <button className='btn btn-primary btn-sm m-2' onClick={handleShow}>
                INVITE AGENT
              </button>
              <button className='btn btn-secondary btn-sm m-2' onClick={handleViewDashBoard}>
                GO TO DASHBOARD
              </button>
            </ButtonRow>
          </ModalContent>
        </div>
      )}
      {showNotifyAgent && (
        <InviteAgentModal onSend={handleSend} onClose={handleClose} onChanged={handleChange} agent={agent} />
      )}
    </>
  );
}

export default PropertyCreated;
