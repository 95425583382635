import { call, put } from 'redux-saga/effects';
import { OffersAsyncActions } from 'store/actions/offers';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* updateCounter(action) {
  const {
    UpdateCounterOffer: {
      Actions: { SUCCESS, FAILURE },
    },
  } = OffersAsyncActions;
  const {
    payload: { counterOffer },
  } = action;

  try {
    yield call(apiCall, '/offers/counter', 'put', counterOffer);
    yield put(SUCCESS());
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default updateCounter;
