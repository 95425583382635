import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
import 'yup-phone';
import { useAuthUser } from 'hooks';
import { TitleDivider, PrimaryGreenButton, OutGrayButton, FormikField } from 'components';
import routeConstants from 'containers/App/routeConstants';

function AgentProfilePage({ user, onUpdateUser, listingStates, allStates }) {
  const history = useHistory();

  const { user: loginUser } = useAuthUser();

  const schema = useMemo(
    () =>
      Yup.object({
        licenseState: Yup.string().required('Required'),
        licenseNumber: Yup.string().required('Required'),
        brokerageName: Yup.string().required('Required'),
        brokerageAddress: Yup.string().required('Required'),
        brokerageCity: Yup.string().required('Required'),
        brokerageState: Yup.string().required('Required'),
        brokerageZip: Yup.string().required('Required'),
        brokerageLicenseNumber: Yup.string().required('Required'),
      }),
    []
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const newUser = {
      id: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      agent: {
        phone: values.phoneNumber,
        licenseNumber: values.licenseNumber,
        licenseState: values.licenseState,
        brokerage: {
          id: user.agent?.brokerage?.id,
          name: values.brokerageName,
          addressLine1: values.brokerageAddress,
          addressLine2: values.brokerageAddressLine2,
          city: values.brokerageCity,
          state: values.brokerageState,
          zip: values.brokerageZip,
          licenseNumber: values.brokerageLicenseNumber,
        },
      },
    };
    onUpdateUser(newUser);
    setSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Agent Profile</title>
        <meta name='description' content='Agent Profile' />
      </Helmet>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          phoneNumber: user?.phone || '',
          email: user?.email || '',
          licenseState: user?.agent?.licenseState || '',
          licenseNumber: user?.agent?.licenseNumber || '',
          brokerageName: user?.agent?.brokerage?.name || '',
          brokerageAddress: user?.agent?.brokerage?.addressLine1 || '',
          brokerageAddressLine2: user?.agent?.brokerage?.addressLine2 || '',
          brokerageCity: user?.agent?.brokerage?.city || '',
          brokerageState: user?.agent?.brokerage?.state || '',
          brokerageZip: user?.agent?.brokerage?.zip || '',
          brokerageLicenseNumber: user?.agent?.brokerage?.licenseNumber || '',
        }}
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div>
              <Row className='mt-2'>
                <FormikField
                  type='select'
                  md='4'
                  name='licenseState'
                  label='License State'
                  selectLabel='Select State'
                  options={listingStates}
                  required
                />
                <FormikField md='4' name='licenseNumber' label='License Number' required />
              </Row>
              <div className='mt-5'></div>
              <TitleDivider title='Real Estate Affiliation (Brokerage)' />
              <Row className='mt-2'>
                <FormikField md='4' name='brokerageName' label='Brokerage Name' required />
                <FormikField md='4' name='brokerageLicenseNumber' label='Brokerage License Number' required />
              </Row>
              <Row className='mt-2'>
                <FormikField md='4' name='brokerageAddress' label='Street Address' required />
                <FormikField md='4' name='brokerageAddressLine2' label='Street Address Cont' />
              </Row>
              <Row className='mt-2'>
                <FormikField md='4' name='brokerageCity' label='City' required />
                <FormikField
                  type='select'
                  md='2'
                  name='brokerageState'
                  label='State'
                  selectLabel='Select State'
                  options={listingStates}
                  required
                />
                <FormikField md='2' name='brokerageZip' label='Zip Code' required />
              </Row>
              {loginUser.id === user.id && (
                <div className='d-flex justify-content-end my-4'>
                  <PrimaryGreenButton type='submit' disabled={isSubmitting || !dirty}>
                    Save changes
                  </PrimaryGreenButton>
                  <OutGrayButton className='ms-3' onClick={() => history.push(routeConstants.HOME_PATH)}>
                    Cancel
                  </OutGrayButton>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

AgentProfilePage.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  listingStates: PropTypes.array.isRequired,
  allStates: PropTypes.array.isRequired,
};

export default AgentProfilePage;
