import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import routes from 'containers/App/routeConstants';

function* loginRedirect(action) {
  const {
    payload: { redirect },
  } = action;
  yield put(
    push(routes.LOGIN_PATH, {
      redirect,
    })
  );
}

export default loginRedirect;
