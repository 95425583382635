import styled from 'styled-components';

export const StepRow = styled.button`
  font-size: 16px;
  margin: 12px 4px;
  cursor: default;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;

  > span:first-child {
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    padding: 4px;
    margin-right: 8px;

    background: ${({ active }) => (active ? 'var(--offer1-green)' : ' #fff')};
    border: 2px solid ${({ active }) => (active ? 'var(--offer1-green)' : ' #6c757d')};
    color: ${({ active }) => (active ? '#fff' : ' #6c757d')};
    text-align: center;

    font: 16px Arial, sans-serif;
  }

  > span:last-child {
    font-weight: ${({ active }) => (active ? 700 : 400)};
    text-align: left;
  }

  &:not(:disabled)&:hover {
    cursor: pointer;
    > span:first-child {
      background: var(--offer1-green);
      border: 2px solid var(--offer1-green);
      color: #fff;
    }

    > span:last-child {
      font-weight: 700;
    }
  }

  &:disabled {
    > span:last-child {
      color: black;
    }
  }
`;

export const StepNumber = styled.span``;
