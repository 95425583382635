import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import messages from 'messages/en.json';

const FormattedMessage = ({ id }) =>
  (get(messages, id) || '')
    .split('\n')
    .map((para, index) => <p key={index}>{para}</p>);

FormattedMessage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FormattedMessage;
