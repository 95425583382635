import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthUser } from 'hooks';

function ProtectedRoute({ component: Component, ...rest }) {
  const { user } = useAuthUser();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...rest} {...props} />;
        } else {
          return <Redirect to={{ pathname: '/login', state: { redirect: location.pathname } }} />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
