import React from 'react';
import PropTypes from 'prop-types';
import { StyledGroup } from './group.styled';
import GlobalStyle from 'styles/globalStyles';

function Group({ children, className, ...props }) {
  return (
    <>
      <GlobalStyle />
      <StyledGroup className={className} {...props}>
        {children}
      </StyledGroup>
    </>
  );
}

const defaultProps = {
  wrap: false,
  verticalAlign: 'start',
};

Group.defaultProps = defaultProps;

Group.propTypes = {
  className: PropTypes.string,
  gutter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  horizontalGutter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  verticalGutter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  columns: PropTypes.number,
  wrap: PropTypes.bool,
  verticalAlign: PropTypes.oneOf(['start', 'center', 'end']),
};

export default Group;
