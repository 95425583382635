const isSameAddress = (addressA, addressB) => {
  if (!addressA || !addressB) {
    return true;
  }
  return (
    addressA.addressLine1 === addressB.addressLine1 &&
    addressA.addressLine2 === addressB.addressLine2 &&
    addressA.city === addressB.city &&
    addressA.state === addressB.state &&
    addressA.zip === addressB.zip
  );
};

export default isSameAddress;
