import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 25px;
  border: 3px solid var(--light-blue);
  background-image: url('/pre-auth-bg.svg');
  background-repeat: no-repeat;
  margin-top: 50px;
  background-size: cover;
  padding: 10px;
`;

export const SectionTitle = styled.span`
  display: block;
  color: var(--offer1-green);
  font-size: 48px;
  font-weight: bold;
  text-align: center;

  ${breakpoint('xs', 'md')`
    font-size: 36px;
  `}
`;

export const SectionBody = styled.span`
  font-size: 18px;
  color: var(--gray1);
  text-align: center;
  margin-top: 0.5rem;
  width= 50%;
`;

export const HouseImage = styled.img`
  width: 200px;
  height: 200px;

  ${breakpoint('mobile', 'desktop')`
    display: none;
  `}
`;
