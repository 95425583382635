import { all, takeLatest } from 'redux-saga/effects';
import { ListingsAsyncActions } from 'store/actions/listings';
import list from './list';
import search from './search';
import fetchLiked from './fetchLiked';
import updateLiked from './updateLiked';
import fetchDetails from './fetchDetails';
import fetchListing from './fetchListing';
import fetchStateIncludedItems from './fetchStateIncludedItems';
import fetchLoanTypes from './fetchLoanTypes';
import searchListingByAgent from './searchListingsByAgent';

export default function* root() {
  const {
    ListListings,
    SearchListings,
    SearchListingsByAgent,
    FetchListingsLiked,
    UpdateListingsLiked,
    FetchListingDetails,
    FetchListing,
    FetchStateIncludedItems,
    FetchListingLoanTypes,
  } = ListingsAsyncActions;
  yield all([
    takeLatest(FetchListingDetails.Types.REQUEST, fetchDetails),
    takeLatest(FetchListing.Types.REQUEST, fetchListing),
    takeLatest(ListListings.Types.REQUEST, list),
    takeLatest(SearchListings.Types.REQUEST, search),
    takeLatest(SearchListingsByAgent.Types.REQUEST, searchListingByAgent),
    takeLatest(FetchListingsLiked.Types.REQUEST, fetchLiked),
    takeLatest(UpdateListingsLiked.Types.REQUEST, updateLiked),
    takeLatest(FetchStateIncludedItems.Types.REQUEST, fetchStateIncludedItems),
    takeLatest(FetchListingLoanTypes.Types.REQUEST, fetchLoanTypes),
  ]);
}
