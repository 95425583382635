import { all, fork } from 'redux-saga/effects';
import app from './app';
import auth from './auth';
import dashboard from './dashboard';
import admin from './admin';
import listings from './listings';
import buyers from './buyers';
import offers from './offers';
import agent from './agent';
import createOffer from './createOffer';
import createListing from './createListing';
import contract from './contract';
import subscriptions from './subscriptions';

const rootSaga = function* root() {
  yield all([
    fork(app),
    fork(auth),
    fork(dashboard),
    fork(admin),
    fork(listings),
    fork(buyers),
    fork(offers),
    fork(agent),
    fork(createOffer),
    fork(createListing),
    fork(contract),
    fork(subscriptions),
  ]);
};

export default rootSaga;
