import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, SectionTitle, CostAllocationSectionItem } from './offerCommon.styled';
import { isParamsNil } from 'utils';

function OfferCostAllocations({
  isSellerPreference = false,
  placeholder = false,
  sellerPreferenceOffer,
  offer,
  globalCounter,
  isEditing = false,
}) {
  const formatPayer = useCallback(
    (payer) => {
      if (!isSellerPreference && isParamsNil(payer)) {
        return '';
      }
      return payer ? payer.toUpperCase() : '';
    },
    [isSellerPreference]
  );

  const items = useMemo(() => {
    return [...(offer?.costAllocations?.stateItems || []), ...(offer?.costAllocations?.additionalItems || [])];
  }, [offer]);

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle match={!isSellerPreference}>{isSellerPreference && 'Cost Allocations'}</SectionTitle>
          <CostAllocationSectionItem highlight={false}>
            <ul>
              {!isSellerPreference && !isEditing && items.length === 0 && (
                <>
                  <li>
                    <b>Additional Cost to be paid by seller</b>: None
                  </li>
                  <li>
                    <b>Additional Cost to be paid by buyer</b> None
                  </li>
                </>
              )}
              {!isSellerPreference &&
                !isEditing &&
                items.map((item) => (
                  <li key={`${item.item}-${item.responsiblePayer}`}>
                    <b>{item.item}</b>
                    {' - ' + formatPayer(item.responsiblePayer)}
                  </li>
                ))}
              {isEditing && 'Details being edited'}
            </ul>
          </CostAllocationSectionItem>
        </>
      )}
    </Container>
  );
}

OfferCostAllocations.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  sellerPreferenceOffer: PropTypes.object,
  offer: PropTypes.object,
  globalCounter: PropTypes.object,
};
export default OfferCostAllocations;
