import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  margin: -16px -16px 0;
  padding: 10px 16px;
  border-bottom: 1px solid var(--gray10);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  h1 {
    font-size: 16px;
    line-height: 38px;
    font-weight: 600;
    margin: 0;
    color: var(--offer1-blue);
  }
  ${breakpoint('mobile', 'desktop')`
    top: 58px;
    display: ${({ showInMobile }) => (showInMobile ? 'flex' : 'none')};
  `}
`;

export const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
