import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormField, PhoneField, EmailField, CheckBox } from 'components';

const CompanyInput = ({
  id,
  type,
  value,
  userNameOnly,
  repOnly,
  errors,
  sameAs,
  sameAsLabel,
  disabled,
  onStateChanged,
}) => {
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [sameAsChecked, setSameAsChecked] = useState(false);

  useEffect(() => {
    onStateChanged({
      field: id,
      value: {
        address: {
          addressLine1: `${address || ''}`,
          addressLine2: null,
          city: null,
          id: null,
          latitude: null,
          longitude: null,
          state: null,
          zip: null,
          zipPlus: null,
        },
        email,
        id: null,
        name,
        officerName: `${firstName || ''}${lastName ? ' ' : ''}${lastName || ''}`,
        phone,
        type,
      },
    });
    //eslint-disable-next-line
  }, [name, firstName, lastName, email, phone, address]);

  useEffect(() => {
    setName(value?.name);
    setFirstName(value?.officerName?.split(' ')[0] || '');
    setLastName(value?.officerName?.split(' ')[1] || '');
    setEmail(value?.email);
    setPhone(value?.phone);
    setAddress(value?.address?.addressLine1);
    setSameAsChecked(
      sameAs &&
        value &&
        value.name === sameAs.name &&
        value.officerName === sameAs.officerName &&
        value.email === sameAs.email &&
        value.phone === sameAs.phone &&
        value.address &&
        value.address.addressLine1 === sameAs.address.addressLine1
    );
    //eslint-disable-next-line
  }, []);

  const handleSameAs = (event) => {
    const checked = event.target.checked;
    setSameAsChecked(checked);
  };

  useEffect(() => {
    if (sameAs && sameAsChecked) {
      setName(sameAs.name);
      setFirstName(sameAs.officerName?.split(' ')[0] || '');
      setLastName(sameAs.officerName?.split(' ')[1] || '');
      setEmail(sameAs.email);
      setPhone(sameAs.phone);
      setAddress(sameAs.address?.addressLine1);
      // setAddress(sameAs);
    }
  }, [sameAs, sameAsChecked]);

  return (
    <>
      <Row>
        {sameAs !== undefined && (
          <CheckBox className='ms-3' checked={sameAsChecked} onChange={handleSameAs} disabled={disabled}>
            {sameAsLabel}
          </CheckBox>
        )}
        <Col sm={6}>
          <FormField
            fieldId={`${id}Name`}
            onStateChanged={(event) => setName(event.value)}
            initialValue={name}
            label='Company Name'
            required
            editable={!disabled}
            error={errors?.[`${id}Name`]}
          />
        </Col>
      </Row>
      {!userNameOnly && (
        <>
          <Row>
            <Col sm={6}>
              <FormField
                fieldId={`${id}-firstName`}
                onStateChanged={(event) => setFirstName(event.value)}
                initialValue={firstName}
                editable={!disabled}
                label={repOnly ? 'Rep Firstname' : 'Rep/Officer Firstname'}
              />
            </Col>
            <Col sm={6}>
              <FormField
                fieldId={`${id}-lastName`}
                onStateChanged={(event) => setLastName(event.value)}
                initialValue={lastName}
                editable={!disabled}
                label={repOnly ? 'Rep Lastname' : 'Rep/Officer Lastname'}
              />
            </Col>
          </Row>
          <Row>
            <FormField
              fieldId={`${id}-address`}
              onStateChanged={(event) => setAddress(event.value)}
              initialValue={address}
              editable={!disabled}
              label='Address'
            />
          </Row>
          <Row>
            <Col sm={6}>
              <EmailField
                fieldId={`${id}-email`}
                label='Email'
                initialValue={email}
                editable={!disabled}
                onStateChanged={(event) => setEmail(event.value)}
              />
            </Col>
            <Col sm={6}>
              <PhoneField
                fieldId={`${id}-phone`}
                onChange={(event) => setPhone(event.value)}
                value={phone}
                editable={!disabled}
                label={repOnly ? 'Rep Mobile' : 'Rep/Officer Mobile'}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CompanyInput;
