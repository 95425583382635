import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const BottomNavContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 68px;
  background-color: var(--gray5);
  overflow-y: auto;
`;

export const BottomNavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 20%;
  color: var(--gray11);
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  min-width: 73px;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 16px;
    line-height: 24px;
  }

  &:hover,
  &.active {
    border-color: ${({ color }) => color ?? 'var(--offer1-blue)'};
    color: ${({ color }) => color ?? 'var(--offer1-blue)'};
    text-decoration: none;
    background-color: #fff;
  }
`;
