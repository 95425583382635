import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  padding: 4px 12px;
  margin-top: 2px;
  border: solid 1px;
  border-radius: 5px;
  border-color: ${(props) => (props.error ? 'red' : 'var(--gray3)')};
`;

export const SearchButton = styled.button`
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: var(--offer1-green);
  color: white;
  font-size: 16px;
  line-height: 38px;
  width: 146px;
  height: 39px;
`;
