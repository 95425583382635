import React from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useField } from 'formik';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const FormikField = ({
  label,
  type = 'text',
  md,
  as = Col,
  name,
  required,
  selectLabel,
  options,
  isInModal,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Form.Group as={as} md={md} controlId={`validationFormik${name}`}>
      <Form.Label>
        {label}
        {required && '*'}
      </Form.Label>
      {(type === 'text' || type === 'password' || type === 'email' || type === 'number') && (
        <Form.Control type={type} {...field} {...props} isInvalid={meta.touched && !!meta.error} />
      )}
      {type === 'phone' && (
        <MaskedInput
          {...field}
          {...props}
          className={classNames('form-control', {
            'is-invalid': meta.touched && !!meta.error,
          })}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
      )}
      {type === 'currency' && (
        <MaskedInput
          {...field}
          {...props}
          onChange={(e) => {
            helpers.setTouched(true);
            helpers.setValue(+e.target.value.replaceAll('$', '').replaceAll(',', ''));
          }}
          className={classNames('form-control', {
            'is-invalid': meta.touched && !!meta.error,
          })}
          mask={createNumberMask({
            prefix: '$',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            integerLimit: 15,
            allowNegative: false,
            allowLeadingZeroes: true,
          })}
        />
      )}
      {type === 'select' && (
        <Form.Control
          {...field}
          {...props}
          as='select'
          isInvalid={meta.touched && !!meta.error}
          disabled={props.readOnly}
        >
          <option value='' label={selectLabel} />
          {options.map((option) => (
            <option key={option.value} value={option.value} label={option.name} />
          ))}
        </Form.Control>
      )}
      <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikField;
