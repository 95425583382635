import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HeaderContainer, OfferInfoContainer, OfferIdText, ActionBanner } from './offerHeader.styled';
import { ButtonWrapper, Icon } from 'components';
import OfferHistoryModal from './offerHistoryModal';
import { getOfferTitle, getOfferIdStr, getOfferHeaderBackground, URLUtils } from 'utils';

function OfferHeader({
  isSellerPreference = false,
  placeholder = false,
  listing,
  offer,
  originalOffer,
  counterOffers,
  globalCounter,
  showCompare = false,
  onCompareChange,
  compareChecked = false,
  actionDisabled = false,
  historyDisabled = false,
  historyView = false,
  actionRequired = false,
  bannerHeader,
}) {
  const [showHistory, setShowHistory] = useState(false);

  const handleCompareClick = () => {
    onCompareChange(!compareChecked, offer);
  };

  const getName = (user) => {
    return `${user.firstName.charAt(0)}. ${user.lastName}`;
  };

  const isLiveEdit = (originalOffer, counterOffers) => {
    return (
      (originalOffer?.state === 'LIVE_EDIT' || originalOffer?.state === 'LIVE_EDIT_BUYER') &&
      (!counterOffers || counterOffers.length === 0)
    );
  };

  return (
    <>
      <HeaderContainer
        preference={isSellerPreference}
        historyView={historyView}
        background={getOfferHeaderBackground(offer?.offerType, offer?.state)}
      >
        {!historyView && actionRequired && (
          <ActionBanner>
            <Icon name='warning' variant='warning' />
            Action Required
          </ActionBanner>
        )}
        {!historyView && !actionRequired && bannerHeader && (
          <ActionBanner variant='warning-cone'>{bannerHeader}</ActionBanner>
        )}
        {!historyView && isLiveEdit(originalOffer, counterOffers) && (
          <ActionBanner variant='warning-cone'>
            <Icon name='warning-cone' variant='warning-cone' />
            Buyer Agent Editing Offer
          </ActionBanner>
        )}
        {!historyView && offer?.state === 'ACCEPTED' && <ActionBanner variant='alert'>Offer Accepted</ActionBanner>}
        {!placeholder && (
          <>
            {isSellerPreference && (
              <div className='m-auto d-flex align-items-center'>
                <Icon name='selling' className='m-auto' width={42} height={32} />
                <OfferInfoContainer className='fw-bold'>Seller's Preferences</OfferInfoContainer>
              </div>
            )}
            {!isSellerPreference && (
              <>
                {!historyView && (
                  <div>
                    <div className='d-flex m-auto'>
                      <OfferInfoContainer>
                        <span className='fw-bold'>{getOfferTitle(offer.offerType)}</span>
                      </OfferInfoContainer>
                    </div>
                    <div className='d-flex m-auto'>
                      <OfferInfoContainer>
                        <OfferIdText>[ID# {getOfferIdStr(offer.id)}]</OfferIdText>
                        {offer.primaryBuyer && (
                          <span className='fw-bold'>
                            {offer.primaryBuyer.ownerType === 'INDIVIDUAL'
                              ? getName(offer.primaryBuyer.user)
                              : offer.primaryBuyer.orgName}
                          </span>
                        )}
                        {offer.secondaryBuyers?.length > 0 && offer.secondaryBuyers[0]?.user && (
                          <span className='fw-bold'>{getName(offer.secondaryBuyers[0].user)}</span>
                        )}
                      </OfferInfoContainer>
                    </div>
                    <div className='d-flex flex-column align-items-start'>
                      {!actionDisabled && showCompare && (
                        <ButtonWrapper className='mt-1' onClick={handleCompareClick}>
                          <Icon name='dart' color={!compareChecked ? '#333333' : '#B2251E'} />
                        </ButtonWrapper>
                      )}
                      {offer.rpaUrl && (
                        <ButtonWrapper
                          className='mt-1 text-decoration-underline text-white'
                          onClick={() => URLUtils.openDocument(offer.rpaUrl)}
                        >
                          View Contract
                        </ButtonWrapper>
                      )}
                      {!actionDisabled && !historyDisabled && (
                        <ButtonWrapper
                          className='mt-1 text-decoration-underline text-white'
                          onClick={() => setShowHistory(true)}
                        >
                          View History
                        </ButtonWrapper>
                      )}
                    </div>
                  </div>
                )}
                {historyView && (
                  <>
                    <div className='d-flex m-auto'>
                      <OfferInfoContainer>
                        {(offer.createTime || offer.updateTime) && (
                          <OfferIdText>
                            [
                            {offer.createTime
                              ? moment(offer.createTime).format('MM/DD/YYYY')
                              : offer.updateTime
                              ? moment(offer.updateTime).format('MM/DD/YYYY')
                              : ''}
                            ]
                          </OfferIdText>
                        )}
                        {offer.primaryBuyer && <span className='fw-bold'>{getOfferTitle(offer.offerType)}</span>}
                      </OfferInfoContainer>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </HeaderContainer>
      {showHistory && (
        <OfferHistoryModal
          show={showHistory}
          listing={listing}
          offer={offer}
          counterOffers={counterOffers}
          originalOffer={originalOffer}
          globalCounter={globalCounter}
          onClose={() => setShowHistory(false)}
        />
      )}
    </>
  );
}

OfferHeader.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  offer: PropTypes.object,
  originalOffer: PropTypes.object,
  counterOffers: PropTypes.array,
  globalCounter: PropTypes.object,
  showCompare: PropTypes.bool,
  onCompareChange: PropTypes.func,
  compareChecked: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  historyDisabled: PropTypes.bool,
  historyView: PropTypes.bool,
  actionRequired: PropTypes.bool,
};

export default OfferHeader;
