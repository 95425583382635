import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Input = styled.input`
  width: 150px;
  padding: 4px 12px;
  margin-top: 2px;
  border: solid 1px;
  border-radius: 5px;
  border-color: ${(props) => (props.error ? 'red' : 'var(--gray3')};
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  width: 250px;
`;
