import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';

export const BuyerTitle = styled.span`
  color: var(--offer1-blue);
  font-size: 12px;
`;

export const CloseButton = styled.button`
  font-size: 40px;
  border: none;
  background-color: white;
`;

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  line-height: 30px;
  color: ${(props) => (props.active ? 'var(--offer1-blue)' : 'black')};
  border-bottom: ${(props) => props.active && '4px solid var(--offer1-blue)'};
  cursor: pointer;
`;

export const ActiveBadge = styled(Badge)`
  margin-left: 5px;
  padding: 5px 12px;
  background-color: #bbbbbb;
  color: white;
`;
