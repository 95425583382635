import React from 'react';
import routeConstants from 'containers/App/routeConstants';

function OfferDisplayFields(props) {
  const { title, children } = props;
  return (
    <div className='col mt-3 ms-2 text-break'>
      <p className='mb-0'>{title}</p>
      <hr className='mt-12 mb-12' />
      <b>
        {process.env.REACT_APP_URL + routeConstants.LISTING_PATH}/{children}
      </b>
    </div>
  );
}

export default OfferDisplayFields;
