import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const StyledButtonWrap = styled(Button)`
  &.btn-primary:not(:disabled):not(.disabled):active:focus,
  &.btn-primary:not(:disabled):not(.disabled).active:focus,
  &.show > &.btn-primary.dropdown-toggle:focus,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled)&.btn-primary:hover,
  &.btn-primary:focus,
  &.btn-primary:active,
  &.btn-primary:disabled,
  &.open > &.dropdown-toggle.btn-primary {
    background-color: ${(props) => (props.$primary ? props.color : 'transparent')};
    color: ${(props) => (props.$primary ? '#FFF' : props.color)};
    border-color: ${(props) => props.color};
    filter: brightness(0.9);
  }
`;

export const StyledPrimaryButton = styled(StyledButtonWrap)`
  background-color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  padding: 6px 12px;
  &.btn {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    border: none;
  }
`;

export const StyledOutLineGrayButton = styled(StyledButtonWrap)`
  background-color: transparent;
  color: var(--gray7);
  border-color: var(--gray7);
`;

export const StyledOutLineButton = styled(StyledButtonWrap)`
  background-color: transparent;
  color: ${(props) => props.color};
  border-color: ${(props) => props.color};
  padding: 6px 12px;
`;

export const ButtonWrapperWrapper = styled.button`
  // position: relative;
  border: none;
  background: transparent;
  padding: 0;
`;
