import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

function* fetchOfferDashboard(action) {
  const {
    FetchOfferDashboard: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { listingId, userId, actions = 'buyer' },
  } = action;

  try {
    const response = yield call(apiCall, `/admin/inpersonate/listing/${listingId}/user/${userId}`, 'get', { actions });
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchOfferDashboard;
