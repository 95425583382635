import styled from 'styled-components';
import { Icon } from 'components';

export const Container = styled.div`
  flex: 1 1;
  min-width: 206px;
  cursor: default;
  margin-left: ${({ preference }) => (preference ? 0 : 10)}px;
  border: 1px solid var(--gray6);
  border-top: none;
  background-color: ${({ $placeholder }) => $placeholder && 'var(--gray8)'};
`;

export const SectionTitle = styled(({ children, match, ...rest }) => (
  <div {...rest}>
    {children}
    {match && <Icon name='checkmark' />}
  </div>
))`
  position: relative;
  height: 32px;
  color: #000;
  background-color: var(--gray8);
  border-top: 1px solid var(--gray6);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 10px 0px 6px 20px;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-6px, -8px);
  }
`;

export const SectionEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SectionItem = styled.div`
  height: ${({ height }) => height ?? 64}px;
  background-color: #fff;
  padding: 4px 0px 6px 20px;
  color: #000;
  font-size: 16px;
  line-height: 24px;

  background-color: ${({ highlight, isEdit }) => (isEdit ? 'var(--grey2)' : highlight && 'var(--offer1-lightgreen)')};
`;

export const SectionItemWithNoHeight = styled.div`
  background-color: #fff;
  padding: 4px 0px 6px 20px;
  color: #000;
  font-size: 16px;
  line-height: 24px;
`;

export const CostAllocationSectionItem = styled.div`
  height: 500px;
  background-color: #fff;
  padding: 4px 0px 6px 0px;
  color: #000;
  font-size: 16px;
  line-height: 24px;

  background-color: ${({ highlight }) => highlight && 'var(--offer1-lightgreen)'};

  ul {
    padding-right: 1rem;
  }
`;

export const SeeMore = styled.button`
  color: var(--offer1-blue);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const OtherTermsText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
