import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Geocode from 'react-geocode';
import { useResponsive } from 'hooks';
import { Avatar, Map, Dots, ShareListingModal, Icon, CountDown } from 'components';
import MessageAgentModal from './messageAgentModal';
import { formatCurrency, URLUtils, joinString } from 'utils';
import routes from 'containers/App/routeConstants';
import apiConstants from 'constants/apiConstants';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { getBuyerProfiles } from 'store/selectors/buyers';
import { ListingsAsyncActions } from 'store/actions/listings';
import { AgentAsyncActions } from 'store/actions/agent';
import {
  SmallText,
  NormalText,
  LightNormalText,
  MediumText,
  LargeText,
  BlueText,
  Offer1GreenButton,
  Offer1PrimaryButton,
  PropertyContainer,
  PropertyCarousel,
  DotsContainer,
  PropertyImage,
  PropertyDetailContainer,
  PropertyCol,
  PropertyInfoContainer,
  PropertyAddress,
  MainContainer,
  OfferContainer,
  OfferCol,
  PropertyItem,
  PropertyReportContainer,
  AgentContainer,
  ProfileContainer,
  ProfileName,
  ProfileDetail,
  MessageButton,
  DescriptionContainer,
  DescriptionTitle,
  ShowMoreButton,
  SoldDiv,
  Offer1WhiteButton,
} from './listingDetailPage.styled';
import LoginRedirectModal from './LoginRedirectModal';
import BuyerProfileRedirectModal from './buyerProfileRedirectModal';
import Swal from 'sweetalert2';
import EllipsisToolTip from 'ellipsis-tooltip-react-chan';

function PropertyPublicViewContainer({
  defaultZoomLevel,
  listing,
  user,
  offerCount,
  highestOffer,
  globalCounterState,
  auctionExpirationDate,
}) {
  const dispatch = useDispatch();

  const [location, setLocation] = useState({ lat: undefined, lng: undefined });
  const [isMapView, setIsMapView] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [showRedirect, setShowRedirect] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showBuyerProfileRedirect, setShowBuyerProfileRedirect] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();
  const isMobile = useResponsive();

  const profiles = useSelector(getBuyerProfiles);

  const fetchLatLng = useCallback(async () => {
    const address = joinString(
      [listing?.address.addressLine1, listing?.address.addressLine2, listing?.address.city, listing?.address.state],
      ', '
    );
    const response = await Geocode.fromAddress(address, apiConstants.GOOGLE_API_KEY, 'en', 'us');
    const geocode = response.results[0].geometry.location;
    const location = {
      address: address,
      lat: geocode.lat,
      lng: geocode.lng,
    };
    setLocation(location);
  }, [listing]);

  const options = useMemo(() => {
    const options = new Set();
    profiles?.profiles?.forEach((p) => {
      options.add({ id: p.id, name: p.label });
    });
    return [...options.values()];
  }, [profiles]);

  useEffect(() => {
    if (user) {
      dispatch(BuyersAsyncActions.FetchBuyerProfiles.Actions.REQUEST());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      dispatch(ListingsAsyncActions.FetchListingsLiked.Actions.REQUEST(listing?.id));
    }

    return () => {
      dispatch(ListingsAsyncActions.FetchListingsLiked.Actions.RESET());
    };
  }, [dispatch, listing?.id, user]);

  useEffect(() => {
    if (!listing?.address.latitude || !listing?.address.longitude) {
      fetchLatLng();
    } else {
      setLocation({
        lat: listing?.address.latitude,
        lng: listing?.address.longitude,
      });
    }
  }, [fetchLatLng, listing]);

  const onMessage = useCallback(
    (message, userType) => {
      const emailSubject = listing?.address?.addressLine1
        ? `Buyer inquiry: ${listing?.address?.addressLine1}`
        : 'Buyer inquiry';

      dispatch(
        AgentAsyncActions.SendMessage.Actions.REQUEST({
          message,
          subject: emailSubject,
          userType,
          recipient: {
            id: listing?.listingAgent.id,
            email: listing?.listingAgent.email,
            firstName: listing?.listingAgent.firstName,
            lastName: listing?.listingAgent.lastName,
          },
        })
      );
      setShowMessage(false);
    },
    [
      dispatch,
      listing?.listingAgent.id,
      listing?.listingAgent.email,
      listing?.address?.addressLine1,
      listing?.listingAgent.firstName,
      listing?.listingAgent.lastName,
    ]
  );

  const handleClickReport = (reportItem, defaultUrl) => {
    if (!user) {
      setShowRedirect(true);
      return;
    }

    let reportUrl;

    if (reportItem !== 'otherReport') {
      reportUrl = listing[reportItem]?.url;
    } else {
      reportUrl = defaultUrl;
    }

    if (reportUrl) {
      URLUtils.openDocument(reportUrl);
    } else {
      Swal.fire({
        title: 'No Report',
        text: 'Report was not provided by the seller',
        confirmButtonText: 'OK',
      });
    }
  };

  const getPropertyUrl = (path) => {
    return path
      ? path.startsWith('http')
        ? path
        : URLUtils.getDocumentUrl(path)
      : URLUtils.getPublicImageUrl('house.svg');
  };

  const createOffer = (profileId) => {
    const state = { highestOffer, offerCount, listing, profileId };
    if (user?.agent) {
      if (listing?.address?.state === user?.agent?.licenseState) {
        history.push('/create-offer', state);
      } else {
        Swal.fire({
          icon: 'warning',
          text: 'Sorry, you must be licensed in the same State as the property.',
          confirmButtonText: 'OK',
        });
      }
    } else {
      history.push('/create-offer-consumer', state);
    }
  };

  const onStartFromProfile = (buyerProfileId) => {
    setShowBuyerProfileRedirect(false);
    createOffer(buyerProfileId);
  };

  const onMakeOffer = () => {
    if (user && options?.length > 0) {
      setShowBuyerProfileRedirect(true);
    } else if (user) {
      createOffer();
    } else {
      setShowRedirect(true);
    }
  };

  const renderPropertyDetails = () => {
    return (
      <>
        {auctionExpirationDate && (
          <PropertyCol divider={!isMobile}>
            <CountDown endDate={auctionExpirationDate} showLabel />
          </PropertyCol>
        )}
        <PropertyCol divider={!isMobile}>
          <PropertyItem>
            <LargeText>{listing?.numberBedrooms}</LargeText>
            <Icon name='bed' />
            <NormalText>Bedrooms</NormalText>
          </PropertyItem>
        </PropertyCol>
        <PropertyCol divider={!isMobile}>
          <PropertyItem>
            <LargeText>{listing?.numberBaths}</LargeText>
            <Icon name='bath' />
            <NormalText>Bathrooms</NormalText>
          </PropertyItem>
        </PropertyCol>
        <PropertyCol>
          <PropertyItem>
            <LargeText>{listing?.squareFeet.toLocaleString()}</LargeText>
            <Icon name='square' />
            <SmallText>Home Sq. Ft.</SmallText>
          </PropertyItem>
        </PropertyCol>
      </>
    );
  };

  const renderContent = () => {
    return (
      <PropertyContainer>
        <PropertyDetailContainer>
          <PropertyInfoContainer>
            <PropertyCol divider={!isMobile}>
              <PropertyAddress>
                <EllipsisToolTip>
                  {listing?.address.addressLine1}
                  <br />
                  {listing?.address.addressLine2 && (
                    <>
                      {listing?.address.addressLine2}
                      <br />
                    </>
                  )}
                  {listing?.address.city}, {listing?.address.state} {listing?.address.zip}
                </EllipsisToolTip>
              </PropertyAddress>
            </PropertyCol>
            {!isMobile && renderPropertyDetails()}
          </PropertyInfoContainer>
          <PropertyInfoContainer>
            <Offer1WhiteButton onClick={() => setShowShare(true)}>
              <Icon name='share' />
              Share
            </Offer1WhiteButton>
          </PropertyInfoContainer>
        </PropertyDetailContainer>
        <PropertyCarousel>
          {!isMapView && (
            <PropertyImage
              src={getPropertyUrl(listing?.primaryImage?.url)}
              cover={!!listing?.primaryImage?.url}
              alt='home'
            />
          )}
          {isMapView && (
            <Map
              pinCenter={location}
              pins={[{ ...location, text: listing?.address.addressLine1 }]}
              zoomLevel={defaultZoomLevel}
            />
          )}
          <DotsContainer>
            <Dots length={2} active={isMapView ? 1 : 0} onChange={() => setIsMapView((prevView) => !prevView)} />
          </DotsContainer>
        </PropertyCarousel>
        {isMobile && <PropertyDetailContainer>{renderPropertyDetails()}</PropertyDetailContainer>}
        <MainContainer>
          <OfferContainer>
            <OfferCol xs={12} md={4}>
              <div>
                <div>List Price</div>
                <BlueText>{formatCurrency(listing?.price, 0)}</BlueText>
              </div>
            </OfferCol>
            <OfferCol xs={12} md={4}>
              <div>
                <div>[{offerCount} offers]</div>
                <BlueText
                  onClick={() => !user && setShowRedirect(true)}
                  style={{ cursor: !user ? 'pointer' : 'default' }}
                >
                  {!user
                    ? 'See Highest Offer'
                    : globalCounterState === 'ACCEPTED' || (offerCount >= 3 && highestOffer)
                    ? formatCurrency(highestOffer, 0)
                    : '$$$'}
                </BlueText>
              </div>
            </OfferCol>
            <OfferCol xs={12} md={4}>
              {listing?.state === 'ACTIVE' && (
                <Offer1GreenButton
                  onClick={() => onMakeOffer()}
                  disabled={listing?.state === 'SOLD' || listing?.state === 'PENDING_ESCROW'}
                >
                  Make an Offer
                </Offer1GreenButton>
              )}
              {listing?.state !== 'ACTIVE' && listing?.state !== 'SOLD' && <p>Currently Not Accepting Offers</p>}
              {listing?.state === 'SOLD' && <SoldDiv>SOLD</SoldDiv>}
            </OfferCol>
          </OfferContainer>
          <Row className='m-0 mt-md-3'>
            <Col md={8} className='p-0'>
              <DescriptionContainer>
                <DescriptionTitle>Property Details</DescriptionTitle>
                {listing?.description}
              </DescriptionContainer>
            </Col>
            <Col md={4} className='p-0' style={{ marginTop: isMobile ? 17 : 0 }}>
              <AgentContainer>
                <NormalText>Contact Listing Agent:</NormalText>
                <ProfileContainer>
                  <Avatar src={listing?.listingAgent.profilePictureUrl} />
                  <ProfileDetail>
                    <ProfileName>{`${listing?.listingAgent.firstName} ${listing?.listingAgent.lastName}`}</ProfileName>
                    <LightNormalText>{listing?.listingAgent.agent?.brokerage?.name}</LightNormalText>
                    <LightNormalText>DRE#: {listing?.listingAgent.agent.licenseNumber}</LightNormalText>
                  </ProfileDetail>
                </ProfileContainer>
                <MessageButton onClick={() => (user ? setShowMessage(true) : setShowRedirect(true))}>
                  <Icon name='paper-plane' />
                  Message
                </MessageButton>
              </AgentContainer>
            </Col>
          </Row>
          <PropertyReportContainer>
            <MediumText style={{ color: 'var(--gray2)', marginBottom: 8 }}>Property Reports</MediumText>
            <Row className='m-0'>
              <Col md={12} lg={6} xl={3}>
                <Offer1PrimaryButton
                  onClick={() => handleClickReport('inspectionReport')}
                  disabled={listing?.state === 'SOLD' || listing?.state === 'PENDING_ESCROW'}
                >
                  <Icon name='tools' />
                  General Inspection
                </Offer1PrimaryButton>
              </Col>
              <Col md={12} lg={6} xl={3}>
                <Offer1PrimaryButton
                  onClick={() => handleClickReport('questionnaireReport')}
                  disabled={listing?.state === 'SOLD' || listing?.state === 'PENDING_ESCROW'}
                >
                  <Icon name='checklist' />
                  Seller Questionnaire
                </Offer1PrimaryButton>
              </Col>
              <Col md={12} lg={6} xl={3}>
                <Offer1PrimaryButton
                  onClick={() => handleClickReport('titlePrelimReport')}
                  disabled={listing?.state === 'SOLD' || listing?.state === 'PENDING_ESCROW'}
                >
                  <Icon name='scroll' />
                  Preliminary Title
                </Offer1PrimaryButton>
              </Col>
              <Col md={12} lg={6} xl={3}>
                <Offer1PrimaryButton
                  stroke='true'
                  onClick={() => handleClickReport('termiteReport')}
                  disabled={listing?.state === 'SOLD' || listing?.state === 'PENDING_ESCROW'}
                >
                  <Icon name='bug' />
                  Termite Report
                </Offer1PrimaryButton>
              </Col>
              {!showMore &&
                listing?.listingReports.map((report) => (
                  <Col md={12} lg={6} xl={3}>
                    <Offer1PrimaryButton
                      stroke='true'
                      onClick={() => handleClickReport('otherReport', report.url)}
                      disabled={listing?.state === 'SOLD' || listing?.state === 'PENDING_ESCROW'}
                    >
                      <Icon name='chart' />
                      {report.name}
                    </Offer1PrimaryButton>
                  </Col>
                ))}
            </Row>
            {user && listing?.listingReports?.length > 0 && (
              <ShowMoreButton className='me-3' onClick={() => setShowMore((prev) => !prev)} more={showMore}>
                {showMore ? 'Show More' : 'Show Less'} <Icon name='chevron-down' color='#828282' />
              </ShowMoreButton>
            )}
          </PropertyReportContainer>
        </MainContainer>
      </PropertyContainer>
    );
  };

  const listingAddress = joinString(
    [
      listing?.address.addressLine1,
      listing?.address.addressLine2,
      listing?.address.city,
      listing?.address.state,
      listing?.address.zip,
    ],
    ', '
  );

  return (
    <React.Fragment>
      {pathname.includes('listing') && (
        <Helmet>
          <title>{listingAddress}</title>
          <meta name='description' content={listingAddress} />
        </Helmet>
      )}
      {renderContent()}
      {showMessage && (
        <MessageAgentModal listing={listing} onSubmit={onMessage} onClose={() => setShowMessage(false)} />
      )}
      {showRedirect && (
        <LoginRedirectModal
          onClose={() => setShowRedirect(false)}
          onRedirect={() => history.push(routes.CUSTOMER_REGISTRATION_PATH, { redirect: pathname })}
        />
      )}
      {showBuyerProfileRedirect && (
        <BuyerProfileRedirectModal
          profiles={options}
          onClose={() => setShowBuyerProfileRedirect(false)}
          onSubmit={onStartFromProfile}
        />
      )}
      <ShareListingModal show={showShare} onClose={() => setShowShare(false)} listingId={listing?.customListingUrl} />
    </React.Fragment>
  );
}

PropertyPublicViewContainer.propTypes = {
  defaultZoomLevel: PropTypes.number,
  listing: PropTypes.object,
  user: PropTypes.object,
  highestOffer: PropTypes.number,
  offerCount: PropTypes.number,
  globalCounterState: PropTypes.string,
  sendMessage: PropTypes.func,
};

export default PropertyPublicViewContainer;
