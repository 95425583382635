import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser, useResponsive } from 'hooks';
import OfferReviewDay from './offerReviewDay';
import CounteringOfferPopup from './counteringOfferPopup';
import PropertyPublicViewContainer from '../ListingDetailPage/propertyPublicViewContainer';
import CreateNewListingPage from 'containers/CreateNewListingPage';
import routeConstants from 'containers/App/routeConstants';
import {
  Collapse,
  Pagination,
  ListingInfo,
  FormattedMessage,
  PopoverTooltip,
  OfferReviewCard,
  Icon,
  Typography,
} from 'components';
import OfferCompareModal from './offerCompareModal';
import {
  CloseButton,
  TabContainer,
  TabItem,
  ReviewContainer,
  OffersContainer,
  InstructionContainer,
  Offer1PrimaryButton,
  ControllerContainer,
  CollapseHeader,
  ContractPlaceholder,
  BlueText,
  ActionAlert,
} from './reviewOfferModal.styled';
import PropertyContractContainer from '../PropertyContractPage/contractContainer';
import OfferHeader from './offerHeader';
import OfferTerms from './offerTerms';
import OfferContingencies from './offerContingencies';
import OfferCreditItems from './offerCreditItems';
import OfferCostAllocations from './offerCostAllocations';
import OfferServices from './offerServices';
import OfferOtherTermsAddenda from './offerOtherTermsAddenda';
import OfferDocuments from './offerDocuments';
import OfferComments from './offerComments';
import OfferContact from './offerContact';
import OfferFooter from './offerFooter';
import { ReactComponent as Relax } from './assets/relax.svg';
import { OffersAsyncActions } from 'store/actions/offers';
import { getCounterOffer } from 'store/selectors/offers';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { isNil } from 'lodash';
import { fixBootstrapModal, restoreBootstrapModal } from 'utils';
import OfferEditAmountModal from './offerEditAmountModal';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import OfferTermsCompareModal from './offerTermsCompareModal';

const MySwal = withReactContent(Swal);

const MAX_COUNT_IN_LINE = 4;

function ReviewOfferModal({ listing, initialOffers, onClose }) {
  const isMobile = useResponsive();

  const dispatch = useDispatch();
  const { isSuccess: updateStateSuccess, error: updateStateError } = useSelector(
    OffersAsyncActions.UpdateState.StatusSelector()
  );
  const { isSuccess: createCounterSuccess, error: createCounterError } = useSelector(
    OffersAsyncActions.CreateCounterOffer.StatusSelector()
  );
  const { isSuccess: updateCounterSuccess, error: updateCounterError } = useSelector(
    OffersAsyncActions.UpdateCounterOffer.StatusSelector()
  );
  const { isSuccess: updateGlobalSuccess } = useSelector(OffersAsyncActions.UpdateGlobalCounterOffer.StatusSelector());
  const { isSuccess: updateOfferSuccess } = useSelector(CreateOfferAsyncActions.UpdateOffer.StatusSelector());
  const newCounter = useSelector(getCounterOffer);
  const { user, isSellerAgent } = useAuthUser({ listing });
  const history = useHistory();

  const isSeller = useMemo(
    () =>
      user.id === listing?.primaryOwner?.user?.id ||
      listing?.secondaryOwners?.some((owner) => owner.user.id === user?.id),
    [user, listing]
  );
  const totalPage = useMemo(
    () =>
      Math.ceil(
        (initialOffers.offers.length + (initialOffers.offers?.globalCounter?.globalCounterDetails ? 1 : 0)) /
          MAX_COUNT_IN_LINE
      ),
    [initialOffers]
  );
  const tabs = useMemo(
    () => ['Offers', ...(isSeller || isSellerAgent ? ['Edit'] : []), 'Contract', 'Public View'],
    [isSeller, isSellerAgent]
  );
  const globalCounterButtonText = useMemo(() => {
    return initialOffers?.globalCounter ? '+ Edit Highest and Best Due Date' : '+ Set Highest and Best Due Date';
  }, [initialOffers]);

  const [page, setPage] = useState(0);
  const [nextState, setNextState] = useState(null);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [counterIndex, setCounterIndex] = useState(-1);
  const [isEditCounter, setIsEditCounter] = useState(false);
  const [hasGlobalCounter, setHasGlobalCounter] = useState(false);
  const [compareOffers, setCompareOffers] = useState([]);
  const [showCompare, setShowCompare] = useState(false);
  const [showCompareOtherTerms, setShowCompareOtherTerms] = useState(false);
  const [offerToEdit, setOfferToEdit] = useState(null);

  const address =
    listing.address !== null
      ? listing.address
      : {
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          zip: null,
        };

  const steps = useMemo(
    () => [
      'Terms of Offer',
      'Contingencies',
      'Credits & Items',
      'Services',
      'Cost Allocations',
      'Contact Information',
      'Other Terms and Addenda',
      'Documents',
      'Comments',
    ],
    []
  );

  useEffect(() => {
    return () => {
      dispatch(OffersAsyncActions.UpdateState.Actions.RESET());
      dispatch(OffersAsyncActions.CreateCounterOffer.Actions.RESET());
      dispatch(OffersAsyncActions.UpdateCounterOffer.Actions.RESET());
      dispatch(OffersAsyncActions.UpdateGlobalCounterOffer.Actions.RESET());
      dispatch(CreateOfferAsyncActions.UpdateOffer.Actions.RESET());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateStateSuccess) {
      MySwal.fire({
        title: 'Your offer has been updated',
      }).then(() => {
        onClose();
      });
    }
  }, [updateStateSuccess, onClose]);

  useEffect(() => {
    if (updateStateError) {
      setNextState(null);
    }
  }, [updateStateError]);

  useEffect(() => {
    if (createCounterSuccess || updateCounterSuccess) {
      MySwal.fire({
        title: <FormattedMessage id='offer.counterOfferSuccess.label' />,
        html: (
          <FormattedMessage
            id={
              newCounter?.state === 'PENDING_SELLER_AGENT_RESPONSE'
                ? 'offer.counterOfferSuccess.seller.description'
                : 'offer.counterOfferSuccess.buyer.description'
            }
          />
        ),
        icon: 'success',
        allowOutsideClick: false,
      }).then(() => {
        onClose();
      });
    }
    setCounterIndex(-1);
    setNextState(null);
    setIsEditCounter(false);
  }, [createCounterSuccess, createCounterError, updateCounterSuccess, updateCounterError, newCounter, onClose]);

  useEffect(() => {
    if (updateGlobalSuccess) {
      MySwal.fire({
        title: <FormattedMessage id='offer.updateHighestAndBest.label' />,
        html: <FormattedMessage id='offer.updateHighestAndBest.description' />,
        icon: 'success',
      });
    }
  }, [updateGlobalSuccess]);

  useEffect(() => {
    if (updateOfferSuccess) {
      MySwal.fire({
        title: 'Your offer has been updated',
      }).then(() => {
        onClose();
      });
    }
  }, [updateOfferSuccess, onClose]);

  useEffect(() => {
    if (
      (listing.state === 'PENDING_ESCROW' || listing.state === 'SELLER_REVIEW' || listing.state === 'ACTIVE') &&
      !isNil(initialOffers.acceptedOffer)
    ) {
      setCurrentTab('Contract');
    } else if (initialOffers.offers.length > 0) {
      setCurrentTab('Offers');
    } else if (
      (isSeller || isSellerAgent) &&
      (listing.state === 'DRAFT' ||
        listing.state === 'PENDING_SELLER_APPROVAL' ||
        listing.state === 'PENDING_AGENT_UPDATES' ||
        listing.state === 'SELLER_APPROVED')
    ) {
      setCurrentTab('Edit');
    } else {
      setCurrentTab('Public View');
    }
  }, [initialOffers, tabs, listing.state, isSeller, isSellerAgent]);

  const updateOfferState = useCallback(
    (offerId, offerState) => dispatch(OffersAsyncActions.UpdateState.Actions.REQUEST(offerId, offerState)),
    [dispatch]
  );

  const updateOffer = useCallback(
    (offer) => dispatch(CreateOfferAsyncActions.UpdateOffer.Actions.REQUEST(offer)),
    [dispatch]
  );

  const createCounterOffer = useCallback(
    (counterOffer) => dispatch(OffersAsyncActions.CreateCounterOffer.Actions.REQUEST(counterOffer)),
    [dispatch]
  );

  const updateCounterOffer = useCallback(
    (counterOffer) => dispatch(OffersAsyncActions.UpdateCounterOffer.Actions.REQUEST(counterOffer)),
    [dispatch]
  );

  const updateViewedOffers = useCallback(
    (viewedOffers) => dispatch(OffersAsyncActions.UpdateViewedOffers.Actions.REQUEST(viewedOffers)),
    [dispatch]
  );

  const updateGlobalCounter = useCallback(
    (globalCounter, listingId) =>
      dispatch(OffersAsyncActions.UpdateGlobalCounterOffer.Actions.REQUEST(globalCounter, listingId)),
    [dispatch]
  );

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const handleAccept = async (offerShowing, newState) => {
    const { isConfirmed } = await MySwal.fire({
      title: (
        <FormattedMessage
          id={
            offerShowing.offerType === 'SELLER_MULTIPLE_COUNTER' && newState !== 'ACCEPTED'
              ? 'offer.select.label'
              : 'offer.accept.label'
          }
        />
      ),
      html:
        offerShowing.offerType === 'SELLER_MULTIPLE_COUNTER' ? (
          <>
            <FormattedMessage
              id={newState === 'ACCEPTED' ? 'offer.accept.SMCO.description' : 'offer.accept.SMCOBuyer.description'}
            />
            {newState !== 'ACCEPTED' && (
              <>
                <Typography as='p' bold>
                  Your buyer(s) must approve your acceptance of this MCO for it to be sent to the sellers. Your client
                  will receive an email notification with details on the actions to take.
                </Typography>
                <PopoverTooltip id='smco-desc' contentId={'tooltip.offer.accept.SMCO.description'} />
              </>
            )}
          </>
        ) : (
          <FormattedMessage id='offer.accept.description' />
        ),
      icon: 'success',
      showCloseButton: true,
      confirmButtonText: 'OK',
    });
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleReject = async (offerShowing, newState) => {
    fixBootstrapModal();
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id='offer.reject.label' />,
      html: <FormattedMessage id='offer.reject.description' />,
      input: 'textarea',
      inputLabel: 'Description Below',
      inputPlaceholder: 'Type your message here...',
      showCancelButton: true,
      confirmButtonText: 'Reject',
    });
    restoreBootstrapModal();
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleCancel = async (offerShowing, newState) => {
    fixBootstrapModal();
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id='offer.cancel.label' />,
      html: <FormattedMessage id='offer.cancel.description' />,
      showCloseButton: true,
      confirmButtonText: 'Continue',
    });
    restoreBootstrapModal();
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleRescind = async (offerShowing, newState, rescindId) => {
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id={`offer.${rescindId}.label`} />,
      html: <FormattedMessage id={`offer.${rescindId}.description`} />,
      icon: 'error',
      showCloseButton: true,
      confirmButtonText: 'Rescind',
    });
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleCloseCounter = () => {
    setCounterIndex(-1);
  };

  const handleSend = (counterOffer) => {
    const counterRequest = {
      ...counterOffer,
      state: nextState,
    };
    if (!isEditCounter) {
      counterRequest.id = null;
      createCounterOffer(counterRequest);
    } else {
      updateCounterOffer(counterRequest);
    }
  };

  const handleApprove = async (offerShowing, newState) => {
    const isMCO = offerShowing.offerType === 'SELLER_MULTIPLE_COUNTER';
    const { isConfirmed } = await MySwal.fire({
      title: <FormattedMessage id={isMCO ? 'offer.approve.SMCO.label' : 'offer.approve.label'} />,
      text: isMCO
        ? 'Upon clicking "approve" below the seller(s) will be immediately notified.  If they select your offer you will be tasked with digitally signing the agreement.  Congratulations, you are a few steps away from entering an agreement.'
        : 'Upon approving the acceptance you will be asked to digitally sign the agreement. Congratulations you are almost in contract to sell this home!',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Approve',
    });
    if (isConfirmed) {
      updateOfferState(offerShowing.id, newState);
    }
  };

  const handleCounter = (index, newState) => {
    setCounterIndex(index);
    setNextState(newState);
  };

  const handleEdit = async (index, nextState) => {
    const { isConfirmed } = await MySwal.fire({
      title: 'You are about to edit your offer',
      text: 'The seller will not be able to accept or counter your offer while you are editing this offer so proceed rapidly.',
      icon: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Continue',
    });
    if (!isConfirmed) {
      return;
    }
    if (
      nextState === 'PENDING_SELLER_COUNTER_REVIEW' ||
      nextState === 'PENDING_BUYER_COUNTER_REVIEW' ||
      (initialOffers.offers[index]?.counterOffers?.length > 0 &&
        (initialOffers.offers[index].counterOffers[0].offerType === 'BUYER_COUNTER' ||
          initialOffers.offers[index].counterOffers[0].offerType === 'SELLER_COUNTER'))
    ) {
      setCounterIndex(index);
      setNextState(nextState);
      setIsEditCounter(true);
    } else if (nextState === 'DRAFT' || nextState === 'LIVE_EDIT') {
      const offer = initialOffers.offers[index];
      updateOfferState(offer.originalOffer.id, nextState);
      history.push(routeConstants.OFFERS_PATH + `/${offer.originalOffer.id}`, {
        listing,
        highestOffer: initialOffers.highestOffer,
        offerCount: initialOffers.offerCount,
      });
    }
  };

  const handleAddGlobalCounter = () => {
    setHasGlobalCounter(true);
  };

  const handleSaveGlobalCounter = (globalCounter) => {
    updateGlobalCounter(globalCounter, listing.id);
    setHasGlobalCounter(false);
  };

  const handleCloseGlobalCounter = () => {
    setHasGlobalCounter(false);
  };

  const getHeaderMessage = useMemo(() => {
    const listingAgentMsg =
      'Please consult with your seller and respond to any offer below within 24 hrs. If all terms are acceptable then click Accept, if the seller wishes to counter some or all items, click COUNTER.';
    const consumerMsg = 'Please consult with your agent within 24 hrs.';
    const buyerAgentMsg =
      'Please consult with your buyer and respond to any counter offers within 24 hrs. If all terms are acceptable then click Accept, if the buyer wishes to counter some or all items, click COUNTER.';

    return isSellerAgent ? listingAgentMsg : user.agent ? buyerAgentMsg : consumerMsg;
  }, [user?.agent, isSellerAgent]);

  const renderDiffMessage = () => {
    const equalObjects = (obj1, obj2) => {
      const obj1Length = Object.keys(obj1).length;
      const obj2Length = Object.keys(obj2).length;

      if (obj1Length === obj2Length) {
        return Object.keys(obj1).every((key) => key in obj2 && obj2[key] === obj1[key]);
      }
      return false;
    };

    if (initialOffers) {
      const offer = initialOffers.offers.find((offer) => offer.originalOffer.agent.id === user.id);
      if (offer) {
        const globalCounter = initialOffers?.globalCounter?.globalCounterDetails;
        const globalCounterDiff = !isNil(globalCounter) ? !equalObjects(offer.originalOffer, globalCounter) : null;
        const counterDiff =
          offer.counterOffers && offer.counterOffers.length > 0
            ? !equalObjects(offer.counterOffers[0], offer.originalOffer)
            : null;
        if (globalCounterDiff || counterDiff) {
          //return 'Warning: Elements of your response do not match the sellers preferences.';
        }
      }
    }
    return null;
  };

  const handleCompareChange = (index, compare, offer) => {
    if (compare) {
      const newCompareOffers = [...compareOffers];
      let i;
      for (i = 0; i < compareOffers.length; i++) {
        if (compareOffers[i].index > index) {
          newCompareOffers.splice(i, 0, { index, offer });
          break;
        }
      }
      if (i === compareOffers.length) {
        newCompareOffers.push({ index, offer });
      }
      setCompareOffers(newCompareOffers);
    } else {
      const newCompareOffers = compareOffers.filter((offer) => offer.index !== index);
      setCompareOffers(newCompareOffers);
    }
  };

  const handleEditOfferAmount = (newOfferAmount) => {
    updateOffer({
      ...offerToEdit.offer,
      terms: { ...offerToEdit.offer.terms, offerAmount: newOfferAmount },
    });
    setOfferToEdit(null);
  };

  const handleEditAdditionalTerms = async (editOffer) => {
    const { isConfirmed } = await MySwal.fire({
      title: 'You are about to edit your offer',
      text: 'The seller will not be able to accept or counter your offer while you are editing this offer so proceed rapidly.',
      icon: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Continue',
    });
    if (!isConfirmed) {
      return;
    }
    updateOfferState(editOffer.id, 'LIVE_EDIT');
    history.push(routeConstants.OFFERS_PATH + `/${editOffer.id}`, {
      listing,
      highestOffer: initialOffers.highestOffer,
      offerCount: initialOffers.offerCount,
    });
    setOfferToEdit(null);
  };

  const renderOfferStep = (step, props) => {
    switch (step) {
      case 0:
        return <OfferTerms {...props} />;
      case 1:
        return <OfferContingencies {...props} />;
      case 2:
        return <OfferCreditItems {...props} />;
      case 3:
        return <OfferServices {...props} />;
      case 4:
        return <OfferCostAllocations {...props} />;
      case 5:
        return <OfferContact {...props} />;
      case 6:
        return <OfferOtherTermsAddenda {...props} onCompareOtherTerms={() => setShowCompareOtherTerms(true)} />;
      case 7:
        return <OfferDocuments {...props} />;
      case 8:
        return <OfferComments {...props} />;
      default:
        return null;
    }
  };

  const filterActions = (offer) => {
    return offer.offerActions.reduce((acc, { action }) => {
      if (action === 'APPROVE' || action === 'ACCEPT' || action === 'EDIT') {
        acc.push(action);
      }
      return acc;
    }, []);
  };

  const handleUpdateViewedOffers = () => {
    // only save viewed offers if on the Offer tab and it's a seller
    if (currentTab === 'Offers' && isSeller) {
      const seenOfferIds = pageOffers.map((offer) => offer.originalOffer.id);
      if (seenOfferIds.length > 0) {
        const viewedOffers = { viewedOfferIds: seenOfferIds, listingId: listing.id };
        updateViewedOffers(viewedOffers);
      }
    }
  };

  const sortedOffers = useMemo(() => {
    return [...initialOffers.offers].sort((a, b) => {
      const offerToShowA = a.counterOffers?.length > 0 ? a.counterOffers[0] : a.originalOffer;
      const offerToShowB = b.counterOffers?.length > 0 ? b.counterOffers[0] : b.originalOffer;
      if (offerToShowA.state !== offerToShowB.state) {
        return offerToShowA.state === 'ACCEPTED' ? -1 : 1;
      } else if (offerToShowA.state === 'ACCEPTED') {
        return b.highestNetAmount - a.highestNetAmount;
      }
      const actionsA = filterActions(a);
      const actionsB = filterActions(b);
      if (actionsA.length !== actionsB.length) {
        return actionsB.length - actionsA.length;
      } else if (actionsA.length === 1 && actionsA[0] !== actionsB[0]) {
        if (actionsA[0] === 'APPROVE') return 1;
        return -1;
      }
      return b.highestNetAmount - a.highestNetAmount;
    });
  }, [initialOffers]);

  const pageOffers = useMemo(
    () =>
      sortedOffers.slice(
        isMobile ? 0 : MAX_COUNT_IN_LINE * page,
        isMobile ? sortedOffers.length : MAX_COUNT_IN_LINE * (page + 1)
      ),
    [sortedOffers, page, isMobile]
  );

  useEffect(() => {
    handleUpdateViewedOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, isSeller, page]);

  const hasMultipleCounterOffer = useMemo(() => {
    return initialOffers.offers?.some((offer) => {
      const offerToShow = offer.counterOffers?.length > 0 ? offer.counterOffers[0] : offer.originalOffer;
      return offerToShow.offerType === 'SELLER_MULTIPLE_COUNTER';
    });
  }, [initialOffers]);

  const placeHolderCount = useMemo(() => MAX_COUNT_IN_LINE - pageOffers.length, [pageOffers.length]);
  const isComparable = initialOffers?.offers?.length > 4;

  const hasActionRequired = useMemo(() => {
    return pageOffers?.some((offer) => offer.isActionRequired);
  }, [pageOffers]);

  return (
    <>
      <CloseButton onClick={onClose}>
        <Icon name='close' />
      </CloseButton>
      <ListingInfo
        address={address}
        price={listing?.price}
        offerCount={initialOffers.offerCount}
        highestOffer={initialOffers.highestOffer}
        showReviewDay
        auctionExpirationDate={initialOffers.globalCounter?.auctionExpirationDate}
        isMobile={isMobile}
        menuTabs={tabs}
        onClickMenu={handleTabChange}
      />
      {!isMobile && (
        <TabContainer>
          {tabs.map((tab) => (
            <TabItem className='me-1' active={currentTab === tab} key={tab} onClick={() => handleTabChange(tab)}>
              {tab}
            </TabItem>
          ))}
        </TabContainer>
      )}
      <ReviewContainer
        padding={(currentTab === 'Offers' || currentTab === 'Contract') && !isMobile}
        showBorder={!isMobile}
      >
        {currentTab === 'Offers' && !isMobile && initialOffers.offers.length > 0 && (
          <OffersContainer>
            <div className='text-start'>
              {isComparable && (
                <>
                  <span className='ms-3 me-1'>Compare (max4)=</span>
                  <Icon name='dart' color='#B2251E' />
                </>
              )}
              <span className='ms-3 me-1'>Seller’s Match=</span>
              <Icon name='checkmark' />
            </div>
            <InstructionContainer>
              <h4>Review Offers</h4>
              <p className='text-justify text-center'>{getHeaderMessage}</p>
              <p className='text-justify text-center' style={{ color: 'red' }}>
                {renderDiffMessage()}
              </p>
            </InstructionContainer>

            {hasActionRequired && (
              <ActionAlert variant='danger' className=''>
                Scroll down to review and take action.
              </ActionAlert>
            )}
            <ControllerContainer>
              {isSellerAgent && (
                <Offer1PrimaryButton onClick={handleAddGlobalCounter}>{globalCounterButtonText}</Offer1PrimaryButton>
              )}
              {compareOffers.length >= 2 && (
                <Offer1PrimaryButton className='mx-2' onClick={() => setShowCompare(true)}>
                  Compare Offers
                </Offer1PrimaryButton>
              )}
              <Pagination
                page={page}
                size={totalPage}
                onLeft={() => setPage(page - 1)}
                onRight={() => setPage(page + 1)}
                className='ms-auto'
              />
            </ControllerContainer>

            <div className='d-flex'>
              <OfferHeader isSellerPreference />
              {pageOffers.map((offer, pageIndex) => {
                const offerToShow = offer.counterOffers?.length > 0 ? offer.counterOffers[0] : offer.originalOffer;
                const index = MAX_COUNT_IN_LINE * page + pageIndex;
                const { originalOffer, counterOffers, offerActions } = offer;

                const newCounterOfferType = offerActions.find(({ action }) => action === 'COUNTER')?.newCounterType;

                return (
                  <React.Fragment key={offerToShow.id}>
                    <OfferHeader
                      listing={listing}
                      offer={offerToShow}
                      globalCounter={
                        offerToShow.offerType === 'GLOBAL_COUNTER'
                          ? offer.originalOffer
                          : initialOffers?.globalCounter?.globalCounterDetails
                      }
                      counterOffers={offer.counterOffers}
                      originalOffer={offer.originalOffer}
                      showCompare={
                        isComparable &&
                        (isSeller || isSellerAgent) &&
                        (offerToShow.offerType === 'INITIAL' || offerToShow.offerType === 'BUYER_COUNTER')
                      }
                      compareChecked={compareOffers.some((offer) => offer.index === index)}
                      onCompareChange={(compare, offer) => handleCompareChange(index, compare, offer)}
                      actionRequired={offer.isActionRequired}
                      bannerHeader={offer.bannerMessage}
                    />

                    <Offcanvas
                      placement='end'
                      show={counterIndex === index}
                      scroll
                      className='p-0'
                      style={{ width: 'fit-content' }}
                      onHide={handleCloseCounter}
                    >
                      <Offcanvas.Body className='p-0' style={{ overflowX: 'hidden' }}>
                        <CounteringOfferPopup
                          originOffer={
                            offer.offerType === 'GLOBAL_COUNTER' || !counterOffers || counterOffers.length === 0
                              ? originalOffer
                              : counterOffers[0]
                          }
                          listing={listing}
                          offerType={newCounterOfferType}
                          onSend={handleSend}
                          onClose={handleCloseCounter}
                          isMultipleCounterOffer={initialOffers.offerCount > 1}
                        />
                      </Offcanvas.Body>
                    </Offcanvas>
                  </React.Fragment>
                );
              })}
              {Array(placeHolderCount)
                .fill()
                .map((_, index) => (
                  <OfferHeader key={index} placeholder />
                ))}
            </div>

            {steps.map((step, index) => (
              <Collapse
                key={index}
                header={CollapseHeader}
                headerContent={
                  <>
                    <span style={{ width: 236 }}>
                      {index + 1}. {step}
                    </span>
                    <Icon name='chevron-down' />
                  </>
                }
                defaultCollapsed={index === 0}
              >
                <div className='d-flex'>
                  {renderOfferStep(index, {
                    isSellerPreference: true,
                    listingState: listing.address.state,
                    sellerPreferenceOffer: listing.offerDetails?.instantAuction ?? {},
                  })}
                  {pageOffers.map((offer, pageIndex) => {
                    const offerToShow = offer.counterOffers?.length > 0 ? offer.counterOffers[0] : offer.originalOffer;
                    // const editIndex =
                    //   MAX_COUNT_IN_LINE * page +
                    //   pageIndex -
                    //   (initialOffers.globalCounter?.globalCounterDetails ? 1 : 0);
                    const hasLiveEditTransition = offer.offerActions.some(
                      ({ transitionState }) => transitionState === 'LIVE_EDIT'
                    );

                    return renderOfferStep(index, {
                      key: offerToShow.id,
                      listingState: listing.address.state,
                      sellerPreferenceOffer: listing.offerDetails?.instantAuction ?? {},
                      offer: offerToShow,
                      globalCounter:
                        offerToShow.offerType === 'GLOBAL_COUNTER'
                          ? offer.originalOffer
                          : initialOffers?.globalCounter?.globalCounterDetails,
                      isGlobalCounter: offerToShow.offerType === 'GLOBAL_COUNTER',
                      isEditing: offerToShow.state === 'LIVE_EDIT',
                      ...(hasLiveEditTransition
                        ? {
                            onEditOfferAmount: () =>
                              setOfferToEdit({
                                offer: offer.originalOffer,
                              }),
                          }
                        : {}),
                    });
                  })}
                  {Array(placeHolderCount)
                    .fill()
                    .map((_, placeholderIndex) =>
                      renderOfferStep(placeholderIndex, { key: placeholderIndex, placeholder: true })
                    )}
                </div>
              </Collapse>
            ))}

            <div className='d-flex'>
              <OfferFooter isSellerPreference />
              {pageOffers.map((offer, pageIndex) => {
                const offerToShow = offer.counterOffers?.length > 0 ? offer.counterOffers[0] : offer.originalOffer;
                const index =
                  MAX_COUNT_IN_LINE * page + pageIndex - (initialOffers.globalCounter?.globalCounterDetails ? 1 : 0);

                let onAccept = null;
                let onReject = null;
                let onApprove = null;
                let onRescind = null;
                let onCancel = null;
                let onCounter = null;
                let onEdit = null;
                let onBuyerRescind = null;
                let isMultipleCounter = false;
                let isFinalCounter = false;

                const isBuyerAgent = offer.originalOffer
                  ? user?.id === offer.originalOffer.agent.id
                  : user?.id === offer.agent.id;

                offer.offerActions.forEach(({ action, transitionState, newCounterType }) => {
                  if (action === 'EDIT') {
                    onEdit = () => handleEdit(index, transitionState);
                  } else if (action === 'RESCIND') {
                    if (isSellerAgent) {
                      onRescind = () => handleRescind(offerToShow, transitionState, 'rescind');
                    } else if (isBuyerAgent) {
                      onBuyerRescind = () => handleRescind(offerToShow, transitionState, 'buyerRescind');
                    }
                  } else if (action === 'ACCEPT') {
                    onAccept = () => handleAccept(offerToShow, transitionState);
                  } else if (action === 'CANCEL') {
                    onCancel = () => handleCancel(offerToShow, transitionState);
                  } else if (action === 'REJECT') {
                    onReject = () => handleReject(offerToShow, transitionState);
                  } else if (action === 'APPROVE') {
                    onApprove = () => handleApprove(offerToShow, transitionState);
                  } else if (action === 'COUNTER') {
                    onCounter = () => handleCounter(index, transitionState);
                    isMultipleCounter = newCounterType === 'SELLER_MULTIPLE_COUNTER';
                    isFinalCounter = newCounterType === 'SELLER_FINAL_COUNTER';
                  }
                });

                return (
                  <OfferFooter
                    key={offerToShow.id}
                    offer={offerToShow}
                    globalCounter={
                      offerToShow.offerType === 'GLOBAL_COUNTER'
                        ? offer.originalOffer
                        : initialOffers?.globalCounter?.globalCounterDetails
                    }
                    isGlobalCounter={false}
                    onAccept={onAccept}
                    onReject={onReject}
                    onCancel={onCancel}
                    onRescind={onRescind}
                    onApprove={onApprove}
                    onEdit={onEdit}
                    onCounter={onCounter}
                    isMultipleCounter={isMultipleCounter}
                    isFinalCounter={isFinalCounter}
                    onBuyerRescind={onBuyerRescind}
                  />
                );
              })}
              {Array(placeHolderCount)
                .fill()
                .map((_, index) => (
                  <OfferFooter key={index} placeholder />
                ))}
            </div>

            <ControllerContainer>
              {!isMobile && isSellerAgent && (
                <Offer1PrimaryButton onClick={handleAddGlobalCounter}>{globalCounterButtonText}</Offer1PrimaryButton>
              )}
              {compareOffers.length >= 2 && (
                <Offer1PrimaryButton className='mx-2' onClick={() => setShowCompare(true)}>
                  Compare Offers
                </Offer1PrimaryButton>
              )}
              <Pagination
                page={page}
                size={totalPage}
                onLeft={() => setPage(page - 1)}
                onRight={() => setPage(page + 1)}
                className='ms-auto'
              />
            </ControllerContainer>
          </OffersContainer>
        )}
        {currentTab === 'Offers' && isMobile && initialOffers.offers.length > 0 && (
          <div className='mt-2'>
            {isSellerAgent && (
              <Offer1PrimaryButton onClick={handleAddGlobalCounter}>{globalCounterButtonText}</Offer1PrimaryButton>
            )}
            {pageOffers.map((offer, pageIndex) => {
              return (
                <OfferReviewCard
                  key={`offer-${pageIndex}`}
                  offer={offer}
                  listing={listing}
                  highestOffer={initialOffers.highestOffer}
                  offerCount={initialOffers.offerCount}
                  globalCounterDetails={initialOffers?.globalCounter?.globalCounterDetails}
                  actions={offer.offerActions}
                  onClose={onClose}
                />
              );
            })}
          </div>
        )}
        {currentTab === 'Offers' && initialOffers.offers.length === 0 && (
          <>
            <BlueText>View Offers</BlueText>
            <ContractPlaceholder>
              <p>
                Is that smell of coffee brewing or a house being sold? This is where will be able to manage all your
                offers. We are excited to see you here once your listing has offers!
              </p>
              <Relax />
            </ContractPlaceholder>
          </>
        )}
        {currentTab === 'Contract' && (
          <>
            {initialOffers?.acceptedOffer ? (
              <PropertyContractContainer listing={listing} offer={initialOffers.acceptedOffer} />
            ) : (
              <ContractPlaceholder>
                <p>
                  Is that smell of coffee brewing or a contract coming together? As you get ready to close a deal this
                  is where your contract will live. We are excited to see you here once its ready for you!
                </p>
                <Relax />
              </ContractPlaceholder>
            )}
          </>
        )}
        {currentTab === 'Edit' && (
          <CreateNewListingPage currentListing={listing} offerCount={initialOffers.offers.length} />
        )}
        {currentTab === 'Public View' && (
          <PropertyPublicViewContainer
            property={listing?.property}
            defaultZoomLevel={17}
            editable={false}
            listing={listing}
            highestOffer={initialOffers.highestOffer}
            globalCounterState={initialOffers?.globalCounter?.globalCounterDetails?.state}
            offerCount={initialOffers.offerCount}
            user={user}
          />
        )}
      </ReviewContainer>
      <Offcanvas
        show={hasGlobalCounter}
        placement='end'
        className='p-0'
        style={{ width: 'fit-content' }}
        onHide={handleCloseGlobalCounter}
      >
        <Offcanvas.Body className='p-0'>
          <OfferReviewDay
            listing={listing}
            hasMultipleCounterOffer={hasMultipleCounterOffer}
            onSave={handleSaveGlobalCounter}
            onClose={handleCloseGlobalCounter}
          />
        </Offcanvas.Body>
      </Offcanvas>
      {showCompare && (
        <OfferCompareModal
          show={showCompare}
          listing={listing}
          offers={compareOffers}
          onClose={() => setShowCompare(false)}
        />
      )}
      {showCompareOtherTerms && (
        <OfferTermsCompareModal
          show={showCompareOtherTerms}
          initialPage={page}
          listing={listing}
          offers={sortedOffers}
          onClose={() => setShowCompareOtherTerms(false)}
        />
      )}
      {offerToEdit && (
        <OfferEditAmountModal
          offer={offerToEdit.offer}
          onEditAdditionalTerms={() => handleEditAdditionalTerms(offerToEdit.offer)}
          onClose={() => setOfferToEdit(null)}
          onSave={handleEditOfferAmount}
        />
      )}
    </>
  );
}

ReviewOfferModal.propTypes = {
  listing: PropTypes.object.isRequired,
  initialOffers: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReviewOfferModal;
