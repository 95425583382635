import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Header, CardDeck, PropertyCard, TabsBar, Empty, PopupMenu } from 'components';
import { useAuthUser, useResponsive } from 'hooks';
import { ListingsAsyncActions } from 'store/actions/listings';
import { AgentAsyncActions } from 'store/actions/agent';
import { getListings } from 'store/selectors/listings';
import routes from 'containers/App/routeConstants';
import { MainContainer } from 'containers/App/mainLayout.styled';
import { URLUtils } from 'utils';
import { listingFilters, listingFiltersLabel } from 'constants/listingConstants';
import FilterImg from 'assets/images/filter.svg';
import ImportListingModal from './importListingModal';

function ListingsContainer() {
  const isMobile = useResponsive();
  const dispatch = useDispatch();
  const allListings = useSelector(getListings);
  const { isAgent, isListingAgent, user } = useAuthUser();
  const [showImport, setShowImport] = useState(false);

  const { filter } = useParams();

  const filteredListing = useMemo(() => {
    if (!allListings) {
      return null;
    }
    if (filter === 'all') {
      return allListings;
    }
    return allListings.filter(({ listing }) => {
      if (filter === 'active') {
        return (
          listing.state === 'ACTIVE' ||
          listing.state === 'SELLER_REVIEW' ||
          listing.state === 'PENDING_ESCROW' ||
          listing.state === 'PENDING' ||
          listing.state === 'PENDING_AGENT_UPDATES' ||
          listing.state === 'PENDING_SELLER_APPROVAL' ||
          listing.state === 'SELLER_APPROVED' ||
          listing.state === 'DRAFT'
        );
      } else if (filter === 'sold') {
        return listing.state === 'SOLD';
      } else if (filter === 'lost') {
        return listing.state === 'CANCELLED' || listing.state === 'DELETED';
      } else {
        return false;
      }
    });
  }, [allListings, filter]);

  useEffect(() => {
    if (!isListingAgent) {
      dispatch(AgentAsyncActions.FetchQuestionnaire.Actions.REQUEST());
    }
  }, [dispatch, isListingAgent]);

  useEffect(() => {
    dispatch(ListingsAsyncActions.ListListings.Actions.REQUEST());
  }, [dispatch]);

  if (listingFilters.every(({ value }) => value !== filter)) {
    return <Redirect to='all' />;
  }

  const handleOnImport = (value) => {
    setShowImport(value);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Your properties</title>
        <meta name='description' content='Your Properties' />
      </Helmet>
      <MainContainer>
        <Header
          title='Selling'
          ctas={[
            ...(isAgent
              ? [
                  {
                    title: 'Create Listing',
                    variant: 'primary',
                    to: user?.agent ? routes.LISTING_CREATE_PATH : routes.CREATE_PROPERTY_PATH,
                  },
                ]
              : []),
            ...(!isMobile && isAgent && user?.agent?.licenseState === 'FL'
              ? [
                  {
                    title: 'Import Listing',
                    variant: 'secondary',
                    onClick: handleOnImport,
                  },
                ]
              : []),
          ]}
        />
        {isAgent && user?.agent?.licenseState === 'FL' && showImport && (
          <ImportListingModal onClose={() => setShowImport(false)} />
        )}
        {!isMobile ? (
          <div>
            <TabsBar tabs={listingFilters} />
          </div>
        ) : (
          <PopupMenu tabs={listingFiltersLabel} handleTabChange={() => {}}>
            <img className='mt-3' src={FilterImg} alt='filter' width='24' height='24' />
          </PopupMenu>
        )}
        {filteredListing?.length > 0 && (
          <CardDeck gap={24}>
            {filteredListing.map((listing, index) => (
              <PropertyCard
                key={`property-${listing.listing.id}-${index}`}
                listing={listing.listing}
                offerCount={listing.offerCount}
                highestOffer={listing.highestOffer}
                auctionExpirationDate={listing.auctionExpirationDate}
                user={user}
                isPendingAction={listing.hasPendingOffers}
              />
            ))}
          </CardDeck>
        )}
        {filteredListing?.length === 0 && (
          <Empty
            src={URLUtils.getPublicImageUrl('homeselling-man.png')}
            width={90}
            height={234}
            title='No Current Listings?'
            description={
              user?.agent
                ? `Get started by clicking 'Create' or 'Import' in the top right.`
                : 'Get started by Contacting Your Agent.'
            }
          />
        )}
      </MainContainer>
    </React.Fragment>
  );
}

export default ListingsContainer;
