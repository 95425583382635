import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

function* deleteUser(action) {
  const {
    DeleteUser: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { id },
  } = action;

  try {
    yield call(apiCall, `/admin/user/${id}`, 'delete');
    yield put(SUCCESS());
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default deleteUser;
