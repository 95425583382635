import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  BlueText,
  PropertyCardContainer,
  PropertyStatus,
  PropertyActionText,
  PropertyAddress,
  PropertyImage,
  PropertyInfoContainer,
  PropertyInfoItem,
  PropertyInfoLabel,
  PropertyInfoValue,
  PropertyActionImg,
  HorizontalSeparator,
  VerticalSeparator,
  GlobalCounterContainer,
  GlobalCounterInfo,
  CounterDownContainer,
  NoActiveReviewDay,
  GlobalCounterLabel,
  CountDownContainer,
  AgentContainer,
  AgentName,
  OwnerType,
  ContactContainer,
  AgentProfile,
  ActionButtonContainer,
  CardButton,
} from './propertyCard.styled';
import { NotificationContainer } from './buyerCard.styled';
import { CountDown, ShareListingModal, PopoverTooltip, ButtonWrapper, Avatar, Icon } from 'components';
import { getPropertyCardStatus, getPropertyCardActionText, formatCurrency, formatPhoneNumber } from 'utils';
import { useAuthUser } from 'hooks';
import routes from 'containers/App/routeConstants';
import moment from 'moment';
import EllipsisToolTip from 'ellipsis-tooltip-react-chan';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function PropertyCard({
  listing,
  user,
  highestOffer,
  offerCount = 0,
  auctionExpirationDate,
  className,
  isPendingAction,
}) {
  const { isAgent } = useAuthUser();

  const [showShare, setShowShare] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const address = listing.address === null ? undefined : listing.address ?? undefined;

  const handleOnClick = () => {
    const fromSeller = location.pathname.includes('listings') || location.pathname.includes('home');
    const path = [
      user && fromSeller ? routes.OFFER_STATUS : routes.LISTING_PATH,
      user &&
      (location.pathname.includes('listings') ||
        location.pathname.includes('home') ||
        location.pathname.includes('search'))
        ? listing.id
        : listing.customListingUrl,
      fromSeller ? 'seller' : '',
    ]
      .join('/')
      .trim();

    history.push(path);
  };

  const handleShareClick = (e) => {
    e.stopPropagation();
    setShowShare(true);
  };

  const getImageUrl = (path) => {
    return path
      ? path.startsWith('http')
        ? path
        : [process.env.REACT_APP_DOC_URL, path].join('/')
      : process.env.PUBLIC_URL + '/house.svg';
  };

  const getPropertyOwner = () => {
    if (user?.id === listing?.listingAgent?.id) {
      return listing.primaryOwner !== null &&
        listing.primaryOwner.user !== null &&
        listing.primaryOwner?.user.firstName &&
        listing.primaryOwner?.user.lastName
        ? `${listing.primaryOwner?.user.firstName} ${listing.primaryOwner?.user.lastName}`
        : 'Owner has not been entered';
    } else {
      return `${listing.listingAgent?.firstName} ${listing.listingAgent?.lastName}`;
    }
  };

  const getPropertyOwnerType = () => {
    if (user?.id === listing?.listingAgent?.id) {
      return listing.primaryOwner !== null &&
        listing.primaryOwner.user !== null &&
        listing.primaryOwner?.user.firstName &&
        listing.primaryOwner?.user.lastName &&
        listing.primaryOwner !== null
        ? 'Primary Owner'
        : '';
    } else {
      return 'Agent';
    }
  };

  const getFormatPhoneNumber = () => {
    if (user?.id === listing?.listingAgent?.id) {
      return formatPhoneNumber(
        listing.primaryOwner && listing.primaryOwner.user ? listing.primaryOwner.user.phone : ''
      );
    } else {
      return formatPhoneNumber(listing?.listingAgent?.agent.phone);
    }
  };

  const getEmail = () => {
    if (user?.id === listing?.listingAgent?.id) {
      return listing.primaryOwner && listing.primaryOwner.user ? listing.primaryOwner.user.email : '';
    } else {
      return listing?.listingAgent?.email;
    }
  };

  return (
    <>
      <div className={className}>
        <PropertyCardContainer>
          <PropertyStatus status={listing.state}>
            {getPropertyCardStatus(listing.state, listing.contractId)}
          </PropertyStatus>
          <div>
            {(listing.state === 'SOLD' || listing.state === 'PENDING_ESCROW') && (
              <NavLink to={`/property-contract/${listing.id}`} onClick={(e) => e.stopPropagation()}>
                Contract Summary
              </NavLink>
            )}
          </div>

          <NotificationContainer>
            {(getPropertyCardActionText(listing.state) !== '' || isPendingAction) &&
              listing.state !== 'PENDING_ESCROW' && (
                <>
                  <PropertyActionImg>
                    <Icon name='alarm' color='var(--danger)' />
                  </PropertyActionImg>
                  <PropertyActionText status={listing.state}>
                    {isPendingAction && listing.state === 'ACTIVE'
                      ? 'Review offer'
                      : getPropertyCardActionText(listing.state)}
                  </PropertyActionText>
                </>
              )}
          </NotificationContainer>
          <PropertyAddress>
            {address && (
              <EllipsisToolTip>
                {address?.addressLine1}
                <br />
                {address?.addressLine2 && (
                  <>
                    {address.addressLine2}
                    <br />
                  </>
                )}
                {`${address?.city}, ${address?.state} ${address?.zip}`}
              </EllipsisToolTip>
            )}
            <div className='d-flex flex-column'>
              <ButtonWrapper>
                <Icon name='heart' />
              </ButtonWrapper>
              <ButtonWrapper className='mt-2' onClick={handleShareClick}>
                <Icon name='share' />
              </ButtonWrapper>
            </div>
          </PropertyAddress>
          <PropertyImage src={getImageUrl(listing.primaryImage?.url)} alt='home' />
          <PropertyInfoContainer>
            <PropertyInfoItem>
              <PropertyInfoLabel>[List Price]</PropertyInfoLabel>
              <PropertyInfoValue>
                <EllipsisToolTip>{listing?.price ? formatCurrency(listing.price, 0) : '$TBD'}</EllipsisToolTip>
              </PropertyInfoValue>
            </PropertyInfoItem>
            <VerticalSeparator />
            <PropertyInfoItem>
              <PropertyInfoLabel>[Offers]</PropertyInfoLabel>
              <PropertyInfoValue>{offerCount}</PropertyInfoValue>
            </PropertyInfoItem>
            <VerticalSeparator />
            <PropertyInfoItem>
              <PropertyInfoLabel>[Highest Offer]</PropertyInfoLabel>
              <BlueText>
                <EllipsisToolTip>
                  {offerCount >= 3 && highestOffer !== null && highestOffer !== undefined
                    ? formatCurrency(highestOffer)
                    : '$$$'}
                </EllipsisToolTip>
              </BlueText>
            </PropertyInfoItem>
          </PropertyInfoContainer>
          <HorizontalSeparator />
          <GlobalCounterContainer>
            Offer Review Day
            <GlobalCounterInfo
              className={classNames({
                'justify-content-center': !auctionExpirationDate,
              })}
            >
              <CounterDownContainer>
                {auctionExpirationDate ? (
                  <>
                    <GlobalCounterLabel>[Days | Hrs | Min | Sec]</GlobalCounterLabel>
                    <CountDownContainer>
                      <CountDown endDate={auctionExpirationDate} showLabel={false} fontSize={12} color={'white'} />
                    </CountDownContainer>
                  </>
                ) : (
                  <NoActiveReviewDay>No active review day</NoActiveReviewDay>
                )}
              </CounterDownContainer>
              {auctionExpirationDate && (
                <>
                  {!isMobile && <VerticalSeparator />}
                  <div className='d-flex flex-column'>
                    <PropertyInfoLabel>[End Date]</PropertyInfoLabel>
                    <BlueText>{moment(auctionExpirationDate).format('MM/DD/YYYY')}</BlueText>
                  </div>
                </>
              )}
            </GlobalCounterInfo>
          </GlobalCounterContainer>
          <HorizontalSeparator />
          <AgentContainer>
            <AgentProfile>
              <Avatar
                size={40}
                src={
                  isAgent ? listing?.primaryOwner?.user?.profilePictureUrl : listing?.listingAgent?.profilePictureUrl
                }
              />
              <div className='d-flex flex-column ms-2'>
                <AgentName>{getPropertyOwner()}</AgentName>
                <OwnerType>{getPropertyOwnerType()}</OwnerType>
              </div>
            </AgentProfile>
            {isAgent && (
              <ContactContainer>
                {isMobile ? (
                  <a href={`tel:${getFormatPhoneNumber()}`} onClick={(e) => e.stopPropagation()}>
                    <Icon name='call' />
                  </a>
                ) : (
                  <PopoverTooltip
                    id='property-card-phone-number'
                    placement='top'
                    trigger='hover'
                    icon={
                      <CopyToClipboard
                        text={getFormatPhoneNumber()}
                        onCopy={() => toast.success('Copied to clipboard', { icon: '📞' })}
                      >
                        <div>
                          <Icon name='call' />
                        </div>
                      </CopyToClipboard>
                    }
                    content={getFormatPhoneNumber()}
                  />
                )}
                {isMobile ? (
                  <a href={`mailto:${getEmail()}`} onClick={(e) => e.stopPropagation()}>
                    <Icon name='envelope' />
                  </a>
                ) : (
                  <PopoverTooltip
                    id='property-card-email'
                    placement='top'
                    trigger='hover'
                    icon={
                      <CopyToClipboard
                        text={getEmail() || 'N/A'}
                        onCopy={() => toast.success('Copied to clipboard', { icon: '✉️' })}
                      >
                        <div>
                          <Icon name='envelope' />
                        </div>
                      </CopyToClipboard>
                    }
                    content={getEmail() || 'N/A'}
                  />
                )}
              </ContactContainer>
            )}
          </AgentContainer>
          <HorizontalSeparator />
          <ActionButtonContainer>
            <CardButton onClick={handleOnClick}>View Details</CardButton>
          </ActionButtonContainer>
        </PropertyCardContainer>
      </div>
      <ShareListingModal show={showShare} onClose={() => setShowShare(false)} listingId={listing.customListingUrl} />
    </>
  );
}

PropertyCard.propTypes = {
  listing: PropTypes.object,
  user: PropTypes.object,
  className: PropTypes.string,
  highestOffer: PropTypes.number,
  offerCount: PropTypes.number,
  auctionExpirationDate: PropTypes.string,
  isPendingAction: PropTypes.bool,
};

export default PropertyCard;
