import styled from 'styled-components';

export const Container = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
`;

export const Line = styled.div`
  height: 2px;
  width: 20px;
  background: var(--offer1-blue);
  transition: all 0.2s ease;
`;

export const LineTop = styled(Line)`
  transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'none')};
  transform-origin: top left;
  margin-bottom: 5px;
`;

export const LineMiddle = styled(Line)`
  opacity: ${(props) => (props.isOpen ? 0 : 1)};
  transform: ${(props) => (props.isOpen ? 'translateX(-16px)' : 'none')};
`;

export const LineBottom = styled(Line)`
  transform: ${(props) =>
    props.isOpen ? 'translateX(-1px) rotate(-45deg)' : 'none'};
  transform-origin: top left;
  margin-top: 5px;
`;
