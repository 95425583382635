import styled from 'styled-components';

export const PDFCanvas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
`;

export const PageNav = styled.div`
  display: flex;
  margin: 1rem auto;
  align-items: center;
  justify-content: space-between;
`;

export const PageText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

export const SignatureContainer = styled.div`
  display: flex;

  margin: auto;
`;

export const SignatureCanvasPanel = styled.div`
  border: 1px solid;
  width: 300px;
  height: 80px;
`;

export const InitialsCanvasPanel = styled.div`
  border: 1px solid;
  width: 80px;
  height: 80px;
`;

export const SignatureLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SignatureImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
