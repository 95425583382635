import { call, put } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';

function* logout() {
  const {
    LogOut: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;

  try {
    yield put(SUCCESS(null, null));
    yield call(apiCall, '/logout', 'post');
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default logout;
