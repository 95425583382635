import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Helmet from 'react-helmet';
import CreateProperty from 'containers/CreateNewListingPage/createProperty';
import PropertyCreated from './PropertyCreated';
import routes from 'containers/App/routeConstants';
import { PropertyContainer } from './propertyPage.styled';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import { Divider } from 'components';

function PropertyDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSuccess } = useSelector(CreateListingAsyncActions.CreateProvisionalListing.StatusSelector());
  const [property, setProperty] = useState({
    price: 0,
    description: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      county: '',
      state: '',
      zip: '',
    },
    numberBedrooms: 0,
    numberBaths: 0,
    propertyType: null,
    squareFeet: 0,
    ownerType: null,
  });

  useEffect(() => {
    return () => dispatch(CreateListingAsyncActions.CreateProvisionalListing.Actions.RESET());
  }, [dispatch]);

  const handlePropertyChanged = useCallback(
    ({ field, value }) => {
      setProperty((prevProperty) => ({ ...prevProperty, [field]: value }));
    },
    [setProperty]
  );

  const saveListing = useCallback(() => {
    dispatch(CreateListingAsyncActions.CreateProvisionalListing.Actions.CreateProvisionalListing(property));
  }, [dispatch, property]);

  const handleCancel = () => {
    history.push(routes.LISTINGS_PATH_ALL);
  };

  return (
    <>
      <Helmet>
        <title>Edit Property</title>
        <meta name='description' content='Edit Property' />
      </Helmet>

      <Row className='mb-4 col-lg-10'>
        {isSuccess && <PropertyCreated />}
        {!isSuccess && (
          <PropertyContainer>
            <CreateProperty property={property} onStateChanged={handlePropertyChanged} />
            <Divider />
            <button className='btn btn-secondary btn-sm m-2' onClick={handleCancel}>
              Cancel
            </button>
            <div className='float-right mb-4'>
              <button className='btn btn-primary btn-sm m-2' onClick={saveListing}>
                Submit
              </button>
            </div>
          </PropertyContainer>
        )}
      </Row>
    </>
  );
}

export default PropertyDetail;
