import React from 'react';
import { PopoverTooltip, CheckBox } from 'components';

const IncludedItemsPanel = ({
  stateIncludedOptions,
  onSelected,
  includedOptions,
  listingState,
  showHeader = true,
  blockUncheck = false,
}) => {
  const handleSelectedItem = (fieldId, description) => {
    const updatedItemOptions = [];

    if (!includedOptions) {
      includedOptions = [{ name: description, stateListItemId: fieldId }];
    } else {
      let itemNotFound = true;
      includedOptions.forEach(function (item) {
        if (item.stateListItemId !== fieldId) {
          updatedItemOptions.push(item);
        } else if (item.stateListItemId === fieldId) {
          itemNotFound = false;
        }
      });
      if (itemNotFound) {
        updatedItemOptions.push({ name: description, stateListItemId: fieldId });
      }
      includedOptions = [...updatedItemOptions];
    }
    onSelected(includedOptions);
  };

  const isIncludedItem = (currentItem) => {
    if (includedOptions) {
      for (var i = 0; i < includedOptions.length; i++) {
        if (includedOptions[i].stateListItemId === currentItem.id) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      {showHeader && (
        <p>
          {listingState === 'FL'
            ? 'The following items if present in the house at the time of showing (or listing) are included in the sale unless excluded below.'
            : 'Do you have included Items?*'}
          <PopoverTooltip
            id='tooltip.includedItem.label'
            labelId='Included Items'
            contentId={
              listingState === 'FL' ? 'tooltip.includedItem.description.fl' : 'tooltip.includedItem.description'
            }
          />
        </p>
      )}
      {listingState === 'FL' && (
        <p>
          range(s)/oven(s), refrigerator(s), dishwasher(s), disposal, ceiling fan(s), light fixture(s), drapery rods and
          draperies, blinds, window treatments, smoke detector(s), garage door opener(s), thermostat(s), doorbell(s),
          television wall mount(s) and television mounting hardware, security gate and other access devices, mailbox
          keys, and storm shutters/storm protection items and hardware ("Personal Property").
        </p>
      )}
      {listingState !== 'FL' &&
        stateIncludedOptions?.map((itemOption) => (
          <CheckBox
            key={itemOption.rpaMappingField}
            name={itemOption.rpaMappingField}
            checked={isIncludedItem(itemOption)}
            onChange={() =>
              (!blockUncheck || !isIncludedItem(itemOption)) &&
              handleSelectedItem(itemOption.id, itemOption.description)
            }
          >
            {itemOption.description}
          </CheckBox>
        ))}
    </>
  );
};

export default IncludedItemsPanel;
