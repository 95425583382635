import styled from 'styled-components';

export const BuyerCardContainer = styled.div`
  width: 370px;
  border-radius: 4px;
  border: 1px solid var(--gray6);
  padding: 30px 20px 30px 20px;
  color: var(--gray2);
  cursor: pointer;
`;

export const BuyerStatus = styled.div`
  width: 100%;
  background-color: ${({ status }) =>
    status === 'APPROVED' ? 'var(--offer1-green)' : status === 'UNAPPROVED' ? 'var(--danger)' : 'var(--gray3)'};
  color: white;
  line-height: 25px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
`;

export const PendingActionsContainer = styled.div`
  color: ${(props) => props.color};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const NotificationContainer = styled.div`
  height: 32px;
`;

export const NotificationCount = styled.div`
  position: absolute;
  height: 13px;
  width: 13px;
  left: 6px;
  top: 0px;
  border-radius: 6.5px;
  color: white;
  background-color: ${(props) => props.color};
  line-height: 13px;
  text-align: center;
  font-size: 10px;
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  margin: 14px 0;
`;

export const ProfileImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 2px solid ${({ first }) => (first ? 'var(--offer1-blue)' : 'var(--gray7)')};
  margin-left: ${({ first }) => (first ? 0 : -25)}px;
  z-index: ${({ first }) => (first ? 1 : 0)};
`;

export const BuyerContainer = styled.div`
  min-height: 36px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ empty }) => empty && 'var(--gray8)'};
`;

export const BuyerName = styled.div`
  color: ${({ first }) => (first ? 'var(--offer1-blue)' : 'var(--gray7)')};
  font-size: 20px;
  line-height: 20px;
`;

export const BuyerTitle = styled.div`
  color: var(--gray2);
  font-size: 14px;
  line-height: 20px;
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 65px;
  margin-left: 10px;
`;

export const VerticalSeparator = styled.div`
  background-color: var(--gray6);
  width: 1px;
  height: 60px;
`;

export const HorizontalSeparator = styled.div`
  background-color: var(--gray6);
  width: 100%;
  height: 1px;
`;

export const CountContainer = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
`;

export const OfferCount = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  height: 27px;
`;

export const BlueText = styled.div`
  font-size: 20px;
  line-height: 22px;
  color: var(--offer1-blue);
  margin-right: 6px;
`;
