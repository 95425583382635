import React, { useEffect } from 'react';
import { getOfferDashboard } from 'store/selectors/admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ButtonWrapper, Icon } from 'components';
import { ModalHeader } from './adminPage.styled';
import { AdminAsyncActions } from 'store/actions/admin';
import { formatCurrency } from 'utils/formattingUtils';

function ListingOfferDashboardModal({ show, user, onClose, showBuyerAgent }) {
  const dispatch = useDispatch();
  const offers = useSelector(getOfferDashboard);

  useEffect(() => {
    return () => dispatch(AdminAsyncActions.FetchOfferDashboard.Actions.RESET());
  }, [dispatch]);

  return (
    <>
      <Modal show={show} backdrop='static' keyboard={false} size='lg' centered>
        <ModalHeader>
          <h3>Offer Dashboard</h3>
          <ButtonWrapper className='close-button' onClick={onClose}>
            <Icon name='close' />
          </ButtonWrapper>
        </ModalHeader>
        <Modal.Body>
          <h4>
            Details for {user?.firstName} {user?.lastName}
          </h4>
          {offers?.offers?.map((offer, index) => (
            <>
              <hr />
              <p>
                <b>Offer ID:</b> {offer.originalOffer.id}
              </p>
              {showBuyerAgent && (
                <p>
                  <b>Buyer Agent:</b> {offer.originalOffer.agent.firstName} {offer.originalOffer.agent.lastName}
                </p>
              )}
              <p>
                <b>Offer Amount:</b> {formatCurrency(offer.highestNetAmount)}
              </p>
              <p>
                <b>
                  Offer Type:{' '}
                  {offer.counterOffers?.size > 0 ? offer.counterOffers[0].offerType : offer.originalOffer.offerType}
                </b>
              </p>
              <p>
                <b>Actions:</b>
                {offers?.offers[index]?.offerActions?.map((action) => (
                  <>
                    <div key={`${action.action}`}>{action.action}</div>
                    <ul>
                      <li>transition state: {action.transitionState ?? 'NA'}</li>
                      <li>counter type: {action.newCounterType ?? 'NA'}</li>
                    </ul>
                  </>
                ))}
              </p>
            </>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}

ListingOfferDashboardModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ListingOfferDashboardModal;
