import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const SectionTitle = styled.span`
  color: var(--offer1-green);
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  ${breakpoint('xs', 'md')`
    font-size: 32px;
  `}
`;

export const SectionText = styled.p`
  font-size: 24px;
  color: var(--gray1);
  text-align: center;
  ${breakpoint('xs', 'md')`
    font-size: 18px;
  `}
`;

export const LogoutContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  border: 3px solid var(--light-blue);
  background-image: url('/pre-auth-bg.svg');
  padding: 10px 10px 10px 10px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  span.emoji {
    margin: 25px 0;
  }
  ${breakpoint('xs', 'md')`
    span.emoji {
      font-size: 0.8em;
    }
  `}
`;
