import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CreateOfferPage from 'containers/CreateOfferPage';
import { Container, Header, CloseButton } from './counteringOffer.styled';

const CounteringOfferPopup = ({ originOffer, listing, onClose, isMultipleCounterOffer, offerType, onSend }) => {
  const counteringOffer = useMemo(() => {
    return offerType
      ? {
          ...originOffer,
          offerType,
          counteredOfferId: originOffer.offerType === 'INITIAL' ? originOffer.id : originOffer.counteredOfferId,
        }
      : originOffer;
  }, [offerType, originOffer]);

  return (
    <Container>
      <Header>
        <span>{isMultipleCounterOffer ? 'Multiple Counter Offer' : 'Counter Offer'}</span>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Header>
      <CreateOfferPage existingOffer={counteringOffer} existingListing={listing} isCounter={true} onCounter={onSend} />
    </Container>
  );
};

CounteringOfferPopup.prototype = {
  originOffer: PropTypes.object.isRequired,
  offerType: PropTypes.string.isRequired,
};

export default CounteringOfferPopup;
