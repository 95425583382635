import styled from 'styled-components';

export const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 0 6px;
  background: ${(props) => (props.isActive ? 'var(--offer1-green)' : '#fff')};
  border: 1px solid #ffffff;
  box-shadow: 1px 3px 8px 2px rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;
