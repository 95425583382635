import Row from 'react-bootstrap/Row';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const SectionRow = styled(Row)`
  align-items: center;
  padding: 10px 0;
`;

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid darkgray;
  background-color: white;
  cursor: default;
  min-width: 620px;
  width: 620px;

  ${breakpoint('mobile', 'desktop')`
    min-width: 100%;
    width: 100%;
  `}
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
  background-color: var(--offer1-blue);
  padding: 10px 20px;
  font-size: 28px;
  font-weight: bold;
`;

export const CloseButton = styled.button`
  font-size: 40px;
  line-height: 56px;
  border: none;
  color: #fff;
  background-color: transparent;
`;

export const SectionTitle = styled.div`
  background-color: #eeeeee;
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  padding: 0px 20px;
  font-weight: 600;
  position: sticky;
  top: 78px;
  z-index: 1;

  div {
    display: inline-block;
  }

  span {
    float: right;
    font-size: 16px;
  }

  .chevron-down {
    margin-right: 8px;
    ${({ expanded }) => (!expanded ? 'transform: rotate(180deg);' : '')}
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;
