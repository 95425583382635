import { createAsyncAction } from 'utils/store';

export const TOGGLE_LOADING = 'app/TOGGLE_LOADING';
export const TOGGLE_SAVING = 'app/TOGGLE_SAVING';

export const FetchMetadata = createAsyncAction('app', 'fetchMetaData', {
  REQUEST: [],
  SUCCESS: ['meta'],
});

export const AppAsyncActions = {
  FetchMetadata,
};
