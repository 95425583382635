import React from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

function DropDown({ items, selectedOption, groupName, onStateChanged, label, disabled = false, error, className }) {
  return (
    <Form.Group className={className ?? 'my-2'}>
      {label && <Form.Label htmlFor={groupName}>{label}</Form.Label>}
      <select
        name={groupName}
        id={groupName}
        value={selectedOption}
        className={classNames('form-control', {
          'is-invalid': !!error,
        })}
        onChange={onStateChanged}
        disabled={disabled}
      >
        <option value='' label='-- Select --' />
        {items.map((item) => (
          <option
            key={item.id ? item.id : item.value}
            value={String(item.id ? item.id : item.value)}
            className='me-1 ms-3'
          >
            {item.name}
          </option>
        ))}
      </select>
    </Form.Group>
  );
}

DropDown.propTypes = {
  groupName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onStateChanged: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  label: PropTypes.string,
  classNames: PropTypes.string,
};

export default DropDown;
