import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormField, CompanyInput, CurrencyField } from 'components';
import OfferServicesHomeWarranty from './offerServicesHomeWarranty';

function OfferServicesFL({ services, stateData, errors, isCounter, onChange, onStateDataChange }) {
  useEffect(() => {
    onChange({ field: 'titleCompany', value: services.titleCompany });
    onChange({ field: 'escrowCompany', value: services.escrowCompany });
    //eslint-disable-next-line
  }, []);

  const onRadioSelected = (event) => {
    const data = {
      value: event.target.value,
      field: event.target.name,
    };
    onChange(data);
  };

  return (
    <>
      <OfferServicesHomeWarranty services={services} errors={errors} showBothParty={false} onStateChanged={onChange} />
      <Row className='mb-3'>
        <Col>
          <p>2) Confirmation of Services</p>
          <Row className='justify-content-around'>
            <Col
              as='label'
              lg={isCounter ? 12 : 3}
              className='border p-3 my-1 d-flex flex-column justify-content-between'
            >
              <p className='text-justify'>
                2.1 Seller designates Closing Agent and pays for Owner's Title Policy and Changes
              </p>
              <input
                className='mx-auto'
                type='radio'
                name='confirmationServices'
                value='SELLER'
                checked={services.confirmationServices === 'SELLER'}
                onChange={onRadioSelected}
              />
            </Col>
            <Col
              as='label'
              lg={isCounter ? 12 : 3}
              className='border p-3 my-1 d-flex flex-column justify-content-between'
            >
              <p className='text-justify'>2.2 Buyer designates Closing Agent and pays for Owner's Title Policy</p>
              <input
                className='mx-auto'
                type='radio'
                name='confirmationServices'
                value='BUYER'
                checked={services.confirmationServices === 'BUYER'}
                onChange={onRadioSelected}
              />
            </Col>
            <Col
              as='label'
              lg={isCounter ? 12 : 3}
              className='border p-3 my-1 d-flex flex-column justify-content-between'
            >
              <p className='text-justify'>
                2.3 Miami-Dade/Broward Provisional: Buyer designates Closing Agent, seller provides copy of prior
                owner’s policy for a continuation or update
              </p>
              <input
                className='mx-auto'
                type='radio'
                name='confirmationServices'
                value='BOTH'
                checked={services.confirmationServices === 'BOTH'}
                onChange={onRadioSelected}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {services.confirmationServices === 'BOTH' && (
        <Row className='ps-4 mb-3'>
          <Col sm={6}>
            <CurrencyField
              fieldId='titleContinuationCost'
              label='Max cost allocated to seller in the update of owner’s title policy. (default is $200)'
              placeholder='default is $200'
              value={stateData.titleContinuationCost}
              onChange={onStateDataChange}
            />
          </Col>
        </Row>
      )}
      <Row className='ps-4 mb-3'>
        <p>2a) Owners Title Policy</p>
        <CompanyInput
          key={services.confirmationServices === 'SELLER' ? 'sellerTitleCompany' : 'titleCompany'}
          id='titleCompany'
          type='TITLE'
          value={services.confirmationServices === 'SELLER' ? services.sellerTitleCompany : services.titleCompany}
          disabled={services.confirmationServices === 'SELLER'}
          onStateChanged={onChange}
          errors={errors?.titleCompany}
        />
      </Row>
      <Row className='ps-4 mb-3'>
        <p>2b) Escrow Company</p>
        <CompanyInput
          key={services.confirmationServices === 'SELLER' ? 'sellerEscrowCompany' : 'escrowCompany'}
          id='escrowCompany'
          type='ESCROW'
          sameAs={services.confirmationServices !== 'SELLER' ? services.titleCompany : undefined}
          sameAsLabel='Same as Title'
          value={services.confirmationServices === 'SELLER' ? services.sellerEscrowCompany : services.escrowCompany}
          disabled={services.confirmationServices === 'SELLER'}
          onStateChanged={onChange}
          errors={errors?.escrowCompany}
        />
      </Row>
      <FormField
        type='textarea'
        fieldId='servicesComments'
        label='Additional Comments on Services: (Comments are visible to all parties and will not be a part of any agreement)'
        placeholder='(Optional)'
        maxLength={500}
        initialValue={services.servicesComments || ''}
        onStateChanged={onChange}
        error={errors?.servicesComments}
        editable={!isCounter}
      />
    </>
  );
}

OfferServicesFL.propTypes = {
  services: PropTypes.object,
  stateData: PropTypes.object,
  onChange: PropTypes.func,
  onStateDataChange: PropTypes.func,
};

export default OfferServicesFL;
