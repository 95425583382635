import { createGlobalStyle } from 'styled-components';
import { OFFER1_GREEN, OFFER1_BLUE, OFFER1_GRAY } from 'constants/styleConstants';

const GlobalStyle = createGlobalStyle`
  :root {
    --offer1-blue: ${OFFER1_BLUE};
    --offer1-green: ${OFFER1_GREEN};
    --offer1-lightgreen: #F4F7EB;
    --offer1-grey3: #828282;
    --light-blue: #b7e9f7;
    --gray1: #5a5a5a;
    --gray2: ${OFFER1_GRAY};
    --gray3: #cccccc;
    --gray4: #f8f8f8;
    --gray5: #f5f5f5;
    --gray6: #bdbdbd;
    --gray7: #4F4F4F;
    --gray8: #F2F2F2;
    --gray9: #767676;
    --offer1-black: #333333;
    --gray10: #E0E0E0;
    --gray11: #6C757D;
    --gray12: #bfbfbf;
    --danger: #E9605A;
    --orange: #FBC94A;
    --text-body: #212529;
  }

  .__react_component_tooltip {
    z-index: 9999;
    max-width: 250px;
  }

  @supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
      --active: ${OFFER1_GREEN};
      --active-inner: #fff;
      --focus: 2px rgba(142, 176, 51, 0.3);
      --border: #bbbbbb;
      --border-hover: rgba(142, 176, 51, 0.5);
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 21px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 7px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
      &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
      &.is-invalid {
        border-color: var(--danger);
      }
    }
    input[type='radio'] {
      border-radius: 50%;
      &:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, .7));
      }
      &:checked {
        --s: .5;
      }
    }
  }

  .swal2-file, .swal2-input, .swal2-textarea {
    background: white;
  }
  
  .swal2-container {
    font-family: Montserrat;
  }

  .swal2-popup {
    padding: 32px 20px;
    background-image: url('/login-bg.svg');
    background-size: cover;
    border: 1px solid #828282;
  }

  .swal2-close {
    position: absolute;
    top: 16px;
    right: 16px;
    box-sizing: border-box;
    border: 2px solid #828282;
    display: inline-flex;
    width: 25px;
    height: 25px;
    font-size: 22px;
    font-weight: bold;
    color: #828282;
    border-radius: 7px;

    &:hover {
      border-color: #f27474;
    }
  }

  .swal2-title {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: ${OFFER1_GREEN};
    text-align: left;
    margin-bottom: 16px;
    padding: 0;
    
    p {
      margin-bottom: 0;
    }
  }

  .swal2-html-container {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-align: left;
    margin: unset;
  }

  .swal2-checkbox {
    margin: 1em 0;
    cursor: pointer;
    display: flex;
    align-items: flex-start;

    .swal2-label {
      margin-left: 8px;
    }
  }

  .swal2-actions {
    width: 100%;
    justify-content: flex-end;
    
    button {
      text-transform: uppercase;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      padding: 10px 8px;
    }
  }

  .swal2-styled.swal2-confirm {
    outline-color: ${OFFER1_GREEN};
    background-color: ${OFFER1_GREEN};

    &:focus {
      box-shadow: 0 0 0 3px rgba(142, 176, 51, 0.5);
    }
  }

  .swal2-styled.swal2-cancel {
    outline-color: ${OFFER1_BLUE};
    background-color: ${OFFER1_BLUE};

    &:focus {
      box-shadow: 0 0 0 3px rgba(0, 142, 255, 0.5);
    }
  }

  .swal2-checkbox input, .swal2-radio input {
    margin: 0 0.4em;
  }
`;

export default GlobalStyle;
