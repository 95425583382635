import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  text-align: center;
`;

export const SubscriptionTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  margin: 10px 20px 10px 20px;
`;

export const SubscriptionText = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin: 10px 20px 10px 20px;
`;
