import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Container as CommentsContainer, SectionTitle, SectionItem, SeeMore } from './offerCommon.styled';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function OfferComments({ isSellerPreference = false, placeholder = false, offer, isEditing = false }) {
  const showCommentsModal = () => {
    MySwal.fire({
      title: 'Comments',
      html: (
        <Container>
          {offer.terms?.termsComments && (
            <Row>
              <Col xs={4} className='fw-bold'>
                Terms:
              </Col>
              <Col xs={8}>{offer.terms.termsComments}</Col>
            </Row>
          )}
          {offer.contingencies?.contingenciesComments && (
            <Row>
              <Col xs={4} className='fw-bold'>
                Contingencies:
              </Col>
              <Col xs={8}>{offer.contingencies.contingenciesComments}</Col>
            </Row>
          )}
          {offer.creditItems?.creditItemsComments && (
            <Row>
              <Col xs={4} className='fw-bold'>
                Credits & Items:
              </Col>
              <Col xs={8}>{offer.creditItems.creditItemsComments}</Col>
            </Row>
          )}
          {offer.costAllocations?.costAllocationsComments && (
            <Row>
              <Col xs={4} className='fw-bold'>
                Cost Allocations:
              </Col>
              <Col xs={8}>{offer.costAllocations.costAllocationsComments}</Col>
            </Row>
          )}
          {offer.services?.serviceComments && (
            <Row>
              <Col sm={4} className='fw-bold'>
                Services:
              </Col>
              <Col sm={8}>{offer.services.serviceComments}</Col>
            </Row>
          )}
        </Container>
      ),
      showCloseButton: true,
    });
  };

  return (
    <CommentsContainer preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle>{isSellerPreference && 'Comments'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference && !isEditing && <SeeMore onClick={showCommentsModal}>See comments</SeeMore>}
            {isEditing && 'Details being edited'}
          </SectionItem>
        </>
      )}
    </CommentsContainer>
  );
}

OfferComments.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  offer: PropTypes.object,
};
export default OfferComments;
