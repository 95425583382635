import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormField, CurrencyField, RadioButtons, DatePicker, Typography, PopoverTooltip } from 'components';
import moment from 'moment';
import { isNil } from 'lodash';
import 'react-calendar/dist/Calendar.css';
import { isDateValid, isDatePickerValid } from 'utils';
import { yesNoOptions as contingencyOptions } from 'constants/optionConstants';

function BuyerContingencies({ listingState, contingencies, stateData, onChange, onStateDataChange, errors }) {
  const isCalifornia = listingState === 'CA';

  const getSelectedValue = (selectOption) => {
    return isNil(selectOption) ? '' : selectOption ? 'yes' : 'no';
  };

  const onRadioSelected = (event) => {
    const data = {
      value: event.target.value === 'yes',
      field: event.target.name,
    };
    onChange(data);
  };

  const onChangeDatePicker = (data) => {
    let closingDate = data.value;
    if (typeof data.value === 'object') {
      closingDate = data.value.format('YYYY-MM-DD');
    }
    if (isDateValid(data.value, 'MM/DD/YYYY')) {
      closingDate = moment(data.value).format('YYYY-MM-DD');
    }
    onChange({ field: data.field, value: closingDate });
  };

  const onAppraisalOtherAmountOptionChange = (event) => {
    if (event.target.value === 'no') {
      onChange({ field: 'appraisalOtherAmount', value: null });
    } else {
      onChange({ field: 'appraisalOtherAmount', value: 0 });
    }
  };

  return (
    <>
      <div className='my-2' id='contingentOnSale'>
        <label htmlFor='contingentOnSale'>Contingent on the sale of current home or another property</label>
        <RadioButtons
          items={contingencyOptions}
          groupName='contingentOnSale'
          selectedOption={getSelectedValue(contingencies.contingentOnSale)}
          onStateChanged={onRadioSelected}
          error={errors?.contingentOnSale}
        />
        {contingencies.contingentOnSale && (
          <div className='ms-4 my-2'>
            <>
              <div className='ms-1' id='propertyListedForSale'>
                <label htmlFor='purchase-method'>Listed for sale</label>
                <RadioButtons
                  items={contingencyOptions}
                  groupName='propertyListedForSale'
                  selectedOption={getSelectedValue(contingencies.propertyListedForSale)}
                  onStateChanged={onRadioSelected}
                  error={errors?.propertyListedForSale}
                />
              </div>
              {contingencies.propertyListedForSale && (
                <Row>
                  <Col sm={4}>
                    <DatePicker
                      fieldId='listingDate'
                      label='Listing Date'
                      required
                      placeholder='MM/DD/YYYY'
                      initialValue={
                        contingencies.listingDate ? moment(contingencies.listingDate).format('MM/DD/YYYY') : null
                      }
                      validator={(value) => isDatePickerValid(value, 'Listing Date')}
                      onStateChanged={onChangeDatePicker}
                      error={errors?.listingDate}
                    />
                  </Col>
                </Row>
              )}
            </>
            {contingencies.propertyListedForSale && (
              <div className='ms-1' id='isEscrow'>
                <label htmlFor='inEscrow'>Currently in escrow</label>

                <RadioButtons
                  items={contingencyOptions}
                  groupName='inEscrow'
                  selectedOption={getSelectedValue(contingencies.inEscrow)}
                  onStateChanged={onRadioSelected}
                  error={errors?.inEscrow}
                />
                {contingencies.inEscrow && (
                  <>
                    <Row>
                      <Col md={4} sm={6}>
                        <DatePicker
                          fieldId='escrowClosedDate'
                          label='What is the expected close date?'
                          required
                          placeholder='MM/DD/YYYY'
                          initialValue={
                            contingencies.escrowClosedDate
                              ? moment(contingencies.escrowClosedDate).format('MM/DD/YYYY')
                              : null
                          }
                          validator={(value) => isDatePickerValid(value, 'Closing Date')}
                          onStateChanged={onChangeDatePicker}
                          error={errors?.escrowClosedDate}
                        />
                      </Col>
                    </Row>
                    <Row className='ms-1'>
                      <Col md={4} sm={6}>
                        <FormField
                          fieldId='escrowNumber'
                          label='Escrow Number'
                          initialValue={contingencies.escrowNumber}
                          onStateChanged={onChange}
                          error={errors?.escrowNumber}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormField
                          fieldId='escrowCompany'
                          label='Escrow Company'
                          initialValue={contingencies.escrowCompany}
                          onStateChanged={onChange}
                          error={errors?.escrowCompany}
                        />
                      </Col>
                      <Col md={4} sm={6}>
                        <FormField
                          fieldId='escrowOfficerName'
                          label='Escrow Officer'
                          placeholder='Full Name'
                          initialValue={contingencies.escrowOfficerName}
                          onStateChanged={onChange}
                          error={errors?.escrowOfficerName}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className='my-2'>
        <label htmlFor='contingentOnLoan' id='contingentOnLoan'>
          Loan contingency period
        </label>
        <RadioButtons
          items={contingencyOptions}
          groupName='contingentOnLoan'
          selectedOption={getSelectedValue(contingencies.contingentOnLoan)}
          onStateChanged={onRadioSelected}
          error={errors?.contingentOnLoan}
        />
        {contingencies.contingentOnLoan && (
          <Row className='ms-1'>
            <Col md={3} sm={4}>
              <FormField
                type='number'
                fieldId='daysLoan'
                label='Enter Number of Days'
                initialValue={contingencies.daysLoan}
                onStateChanged={onChange}
                error={errors?.daysLoan}
              />
            </Col>
          </Row>
        )}
      </div>
      {isCalifornia && (
        <div className='my-2' id='contingentOnAppraisal'>
          <label htmlFor='contingentOnAppraisal'>Contingent upon an appraisal</label>
          <RadioButtons
            items={contingencyOptions}
            groupName='contingentOnAppraisal'
            selectedOption={getSelectedValue(contingencies.contingentOnAppraisal)}
            onStateChanged={onRadioSelected}
            error={errors?.contingentOnAppraisal}
          />
          {!(listingState === 'FL' && contingencies.contingentOnLoan) && contingencies.contingentOnAppraisal && (
            <>
              <Row className='ms-1'>
                <Col md={3} sm={4}>
                  <FormField
                    type='number'
                    fieldId='daysAppraisal'
                    label='Enter Number of Days'
                    initialValue={contingencies.daysAppraisal}
                    onStateChanged={onChange}
                    error={errors?.daysAppraisal}
                  />
                </Col>
              </Row>
              {isCalifornia && (
                <>
                  <Row className='ms-1'>
                    <label>
                      Is the buyer <u>willing and able</u> to complete the transaction if the property appraises for
                      less than their offer price?
                    </label>
                    <RadioButtons
                      items={contingencyOptions}
                      groupName='appraisalOtherAmount'
                      selectedOption={isNil(contingencies.appraisalOtherAmount) ? 'no' : 'yes'}
                      onStateChanged={onAppraisalOtherAmountOptionChange}
                    />
                  </Row>
                  {!isNil(contingencies.appraisalOtherAmount) && (
                    <>
                      <Row className='ms-3'>
                        <label>Minimal acceptable appraisal amount</label>
                        <Col md={3} sm={4}>
                          <CurrencyField
                            fieldId='appraisalOtherAmount'
                            placeholder='$'
                            value={contingencies.appraisalOtherAmount}
                            onChange={onChange}
                            error={errors?.appraisalOtherAmount}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
      <div className='my-2'>
        <label htmlFor='contingentOnInspection' id='contingentOnInspection'>
          Do you require an inspection contingency period
        </label>
        <RadioButtons
          items={contingencyOptions}
          groupName='contingentOnInspection'
          selectedOption={getSelectedValue(contingencies.contingentOnInspection)}
          onStateChanged={onRadioSelected}
          error={errors?.contingentOnInspection}
        />
        {contingencies.contingentOnInspection && (
          <Row className='ms-1'>
            <Col md={3} sm={4}>
              <FormField
                type='number'
                fieldId='daysInspection'
                label='Enter Number of Days'
                initialValue={contingencies.daysInspection}
                onStateChanged={onChange}
                error={errors?.daysInspection}
              />
            </Col>
          </Row>
        )}
      </div>
      {isCalifornia && (
        <div className='my-2'>
          <label htmlFor='daysBuyerInfoAccess' id='daysBuyerInfoAccess'>
            How many days shall buyer have access to the property for information purposes?
          </label>
          <div>
            <Typography as='span' color='offer1-blue'>
              If left blank 17 days is the default
            </Typography>
            <PopoverTooltip
              placement='right'
              trigger='click'
              content="Buyer's right to access the Property for informational purposes is NOT a contingency, does NOT create cancellation rights, and applies even if contingencies are removed."
            />
          </div>
          <Row className='ms-1'>
            <Col md={3} sm={4}>
              <FormField
                type='number'
                fieldId='daysBuyerInfoAccess'
                label='Enter Number of Days'
                initialValue={contingencies.daysBuyerInfoAccess}
                onStateChanged={onChange}
                error={errors?.daysBuyerInfoAccess}
              />
            </Col>
          </Row>
        </div>
      )}
      <div className='my-2'>
        {isCalifornia && (
          <>
            <label htmlFor='daysRequiredOfBuyerReviewDisclosures' id='daysRequiredOfBuyerReviewDisclosures'>
              Days buyer has after acceptance to review: Seller Documents, Preliminary Title Report, Common Interest
              Disclosure or Review of any Leased or Liened items.
            </label>
            <div>
              <Typography as='span' color='offer1-blue'>
                Default is 17 days unless other amount is entered.
              </Typography>
              <PopoverTooltip
                placement='right'
                trigger='click'
                content='Buyer receives the later of default/entered amount or 5 days after receipt automatically.'
              />
            </div>
            <Row className='ms-1'>
              <Col md={3} sm={4}>
                <FormField
                  type='number'
                  fieldId='daysRequiredOfBuyerReviewDisclosures'
                  label='Enter Number of Days'
                  initialValue={contingencies.daysRequiredOfBuyerReviewDisclosures}
                  onStateChanged={onChange}
                  error={errors?.daysRequiredOfBuyerReviewDisclosures}
                />
              </Col>
            </Row>
          </>
        )}
        {listingState === 'FL' && (
          <>
            <label htmlFor='floodOrElevationDays' id='floodOrElevationDays'>
              Days buyer has to discover flood zone and elevation concerns if applicable. (standard is 20 days)
            </label>
            <Row className='ms-1'>
              <Col md={3} sm={4}>
                <FormField
                  type='number'
                  fieldId='floodOrElevationDays'
                  label='Enter Number of Days'
                  initialValue={stateData.floodOrElevationDays}
                  onStateChanged={onStateDataChange}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default BuyerContingencies;
