import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormField, EmailField, PhoneField, FileUpload } from 'components';
import { FaFileContract } from 'react-icons/fa';

function Partnership({
  onCorpChanged,
  name,
  affidavit,
  partnership,
  onDeleteFile,
  user,
  onUserChanged,
  onFileChanged,
  errors,
}) {
  const handleCorpChanged = (state) => {
    onCorpChanged(state);
  };

  const handleFileSelected = (docType) => async (uploadedFile, fileType) => {
    onFileChanged(docType, uploadedFile, fileType);
  };

  const handleUserChanged = (state) => {
    const updateUser = { ...(user || {}) };
    updateUser[state.field] = state.value;
    onUserChanged(updateUser);
  };

  return (
    <div>
      <h6>Primary Owner</h6>
      <hr />

      <Row>
        <Col className='ms-1'>
          <FormField
            fieldId='orgName'
            label='Partnership'
            initialValue={name}
            onStateChanged={handleCorpChanged}
            required
          />
        </Col>
      </Row>
      <Row className='mb-4 ms-2 text-center'>
        <FileUpload
          className='ms-4'
          type='OTHER'
          icon={FaFileContract}
          title='Partnership Agreement'
          file={partnership}
          onFileSelected={handleFileSelected('partnershipAgreement')}
          deleteFile={() => onDeleteFile('partnershipAgreement')}
          height={150}
          width={150}
        />
        <FileUpload
          className='ms-4'
          type='OTHER'
          icon={FaFileContract}
          title='Authorized Signer Affidavit'
          file={affidavit}
          onFileSelected={handleFileSelected('signerAffidavit')}
          deleteFile={() => onDeleteFile('signerAffidavit')}
          height={150}
          width={150}
        />
      </Row>

      <label className='control-label ms-4'>Partnership Contact*</label>

      <Row>
        <Col sm={6}>
          <FormField
            fieldId='firstName'
            label=''
            placeholder='First Name'
            initialValue={user?.firstName}
            onStateChanged={handleUserChanged}
            required
            error={errors?.firstName}
          />
        </Col>
        <Col sm={6}>
          <FormField
            fieldId='lastName'
            label=''
            placeholder='Last Name'
            initialValue={user?.lastName}
            onStateChanged={handleUserChanged}
            required
            error={errors?.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <PhoneField
            fieldId='phone'
            label='Mobile'
            value={user?.phone || ''}
            onChange={handleUserChanged}
            error={errors?.phone}
          />
        </Col>
        <Col sm={6}>
          <EmailField
            fieldId='email'
            label='Email'
            initialValue={user?.email || ''}
            onStateChanged={handleUserChanged}
            required
            error={errors?.email}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Partnership;
