import React from 'react';
import PropTypes from 'prop-types';
import { validate } from 'isemail';

import FormField from './formField';

// prevent passing type and validator props from this component to the rendered form field component
const EmailField = ({ type, validator, ...restProps }) => {
  // validateEmail function using the validate() method of the isemail package
  const validateEmail = (value) => {
    if (!validate(value)) throw new Error('Email is invalid');
  };

  // pass the validateEmail to the validator prop
  return <FormField validator={validateEmail} {...restProps} />;
};

EmailField.propTypes = {
  label: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  onStateChanged: PropTypes.func,
  autoComplete: PropTypes.string,
};

export default EmailField;
