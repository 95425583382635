import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthUser } from 'hooks';
import PropertyPublicViewContainer from './propertyPublicViewContainer';
import NotFoundPage from 'containers/NotFoundPage';
import { getListingDetails } from 'store/selectors/listings';
import { ListingsAsyncActions } from 'store/actions/listings';

const ListingDetailPage = () => {
  const { id } = useParams();
  const { listing, highestOffer, offerCount, auctionExpirationDate } = useSelector(getListingDetails);
  const { isSuccess, error } = useSelector(ListingsAsyncActions.FetchListingDetails.StatusSelector());
  const { user } = useAuthUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ListingsAsyncActions.FetchListingDetails.Actions.REQUEST(id));

    return () => dispatch(ListingsAsyncActions.FetchListingDetails.Actions.RESET());
  }, [dispatch, id]);

  if (error?.httpStatus === 404) {
    return <NotFoundPage />;
  }

  return (
    <div className='mb-5'>
      {isSuccess && (
        <PropertyPublicViewContainer
          defaultZoomLevel={17}
          listing={listing}
          highestOffer={highestOffer ?? 0}
          offerCount={offerCount ?? 0}
          globalCounterState={listing?.globalCounter?.globalCounterDetails?.state}
          auctionExpirationDate={auctionExpirationDate}
          user={user}
        />
      )}
    </div>
  );
};

export default ListingDetailPage;
