import styled from 'styled-components';

export const NotificationContainer = styled.div`
  background-color: #2e2e31;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
`;

export const NotificationBody = styled.p`
  font-size: 16px;
`;

export const CloseButton = styled.div`
  font-size: 40px;
  line-height: 35px;
  cursor: pointer;
`;
