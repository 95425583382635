import React from 'react';
import { PrimaryBlueButton, Icon } from 'components';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { DropContainer, UploadDescriptionContainer } from './contractDropzone.styled';

function ContractDropzone({ onDrop }) {
  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    validator: fileLengthValidator,
    noClick: true,
    noKeyboard: true,
    accept:
      'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    onDrop,
  });

  function fileLengthValidator(file) {
    if (file.size > 10485760) {
      Swal.fire({
        text: 'Your file size is too big. Please only upload files of 10MB or less.',
        icon: 'error',
      });
      return true;
    }

    return null;
  }

  return (
    <DropContainer {...getRootProps()} active={isDragActive}>
      <input {...getInputProps()} />
      <Icon name='upload-cloud' />
      <UploadDescriptionContainer>
        <span>Select a file or drag and drop here</span>
        <span>PDF, .doc, .docx only, file size no more than 10MB</span>
      </UploadDescriptionContainer>
      <PrimaryBlueButton onClick={open}>Select</PrimaryBlueButton>
    </DropContainer>
  );
}

export default ContractDropzone;
