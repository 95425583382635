import styled from 'styled-components';
import Datetime from 'react-datetime';

export const CalendarViewStyled = styled(Datetime)`
  .rdtPicker {
    border-radius: 10px;
    margin-top: -15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
`;

export const calendarView = (mode, renderDefault) => {
  // Only for years, months and days view
  if (mode === 'time') return renderDefault();

  return <CalendarViewStyled>{renderDefault()}</CalendarViewStyled>;
};
