import { call, put, select } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const containsRole = (roles, role) => {
  return roles.some(({ name }) => name === role);
};

function* update(action) {
  const {
    UpdateAccount: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { user },
  } = action;

  const currentUser = yield select((state) => state.auth.user);

  try {
    const response = yield call(apiCall, '/users', 'put', user);
    const promotedToOffer = !containsRole(currentUser.roles, 'OFFER') && containsRole(response.data.roles, 'OFFER');
    const promotedToListing =
      !containsRole(currentUser.roles, 'LISTING') && containsRole(response.data.roles, 'LISTING');
    if (promotedToOffer || promotedToListing) {
      Swal.fire({
        title: 'Congrats! 🎉',
        text: `You've been granted access to create ${promotedToListing ? 'listings' : 'offers'}`,
        confirmButtonText: 'OK',
      });
    }
    yield put(SUCCESS({ ...response.data }));
  } catch (error) {
    toast.error('Error updating user profile');
    yield put(FAILURE(error));
  }
}

export default update;
