import { call, put } from 'redux-saga/effects';
import { ListingsAsyncActions } from 'store/actions/listings';
import { apiCall } from 'utils/apiCall';

function* fetchStateIncludedItems(action) {
  const {
    FetchStateIncludedItems: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ListingsAsyncActions;
  const {
    payload: { state, city },
  } = action;

  try {
    const response = yield call(apiCall, `/list-items/${state}?city=${city}`);
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchStateIncludedItems;
