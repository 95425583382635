const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  mobile: 0,
  tablet: 576,
  desktop: 768,
};

export { breakpoints };
