import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const ProfileContainer = styled.div`
  margin-bottom: 50px;
`;

export const RequireText = styled.span`
  font-size: 14px;
  padding: 8px 0px;
`;

export const OutLineGreyButton = styled(Button)`
  background-color: transparent;
  color: var(--gray7);
  border-color: var(--gray7);
`;
