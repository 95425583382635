const BUYER_EDITING = 'BUYER_EDITING';
const BUYER_SENT = 'BUYER_SENT';

// Offer State
const OFFER_STATE_DRAFT = 'DRAFT';
const OFFER_STATE_DELETED = 'DELETED';
const OFFER_STATE_ACTIVE = 'ACTIVE';
const OFFER_STATE_ACCEPTED = 'ACCEPTED';
const OFFER_STATE_COUNTERED = 'COUNTERED';
const OFFER_STATE_REJECTED = 'REJECTED';
const OFFER_STATE_CANCELLED = 'CANCELLED';
const OFFER_STATE_PENDING_AGENT_UPDATES = 'PENDING_AGENT_UPDATES';
const OFFER_STATE_PENDING_BUYER_APPROVAL = 'PENDING_BUYER_APPROVAL';
const OFFER_STATE_BUYER_APPROVED = 'BUYER_APPROVED';
const OFFER_STATE_BUYER_REJECTED = 'BUYER_REJECTED';
const OFFER_STATE_PENDING_SELLER_AGENT_RESPONSE = 'PENDING_SELLER_AGENT_RESPONSE';
const OFFER_STATE_PENDING_SELLER_COUNTER_REVIEW = 'PENDING_SELLER_COUNTER_REVIEW';
const OFFER_STATE_SELLER_REJECTED_COUNTER_OFFER_CREATION = 'SELLER_REJECTED_COUNTER_OFFER_CREATION';
const OFFER_STATE_PENDING_SELLER_ACCEPT_REVIEW = 'PENDING_SELLER_ACCEPT_REVIEW';
const OFFER_STATE_PENDING_BUYER_AGENT_RESPONSE = 'PENDING_BUYER_AGENT_RESPONSE';
const OFFER_STATE_PENDING_BUYER_COUNTER_REVIEW = 'PENDING_BUYER_COUNTER_REVIEW';
const OFFER_STATE_BUYER_REJECTED_COUNTER_OFFER_CREATION = 'BUYER_REJECTED_COUNTER_OFFER_CREATION';
const OFFER_STATE_PENDING_BUYER_ACCEPT_REVIEW = 'PENDING_BUYER_ACCEPT_REVIEW';
const OFFER_STATE_LIVE_EDIT = 'LIVE_EDIT';
const OFFER_STATE_LIVE_EDIT_BUYER = 'LIVE_EDIT_BUYER';

export const offerSteps = [
  { number: 1, name: 'Contact Information' },
  { number: 2, name: 'Terms of Offer' },
  { number: 3, name: 'Contingencies' },
  { number: 4, name: 'Credits & Items' },
  { number: 5, name: 'Services' },
  { number: 6, name: 'Cost Allocations' },
  { number: 7, name: 'Other Terms and Addenda' },
  { number: 8, name: 'Review & Submit' },
];

export const offerStepsLabel = [
  'Terms of Offer',
  'Contingencies',
  'Credits & Items',
  'Services',
  'Cost Allocations',
  'Contact Information',
  'Other Terms and Addenda',
  'Documents',
  'Comments',
];

export const buyerAddendaItemsCA = [
  { name: 'buyer1031IntentToExchange', label: 'Buyer 1031 intent to exchange (C.A.R form BXA)' },
  { name: 'noContingencies', label: 'Contingency Removal (C.A.R. form CR)' },
  { name: 'saleOfBuyerHomeContingency', label: "Sale of Buyer's Property Contingency (C.A.R. form COP)" },
];

export const buyerAddendaItemsFL = [
  { name: 'sellerFinance', label: 'Seller Financing' },
  { name: 'mortgAssumumption', label: 'Mortgage Assumption' },
  { name: 'fhaVAFinancing', label: 'FHA/VA Financing' },
  { name: 'appraisalContingency', label: 'Appraisal Contingency' },
  { name: 'insuranceRider', label: 'Homeowners/Flood Ins. Contingency' },
  { name: 'interestBearingAcct', label: 'Interest-Bearing Acct.' },
  { name: 'rezoningCont', label: 'Rezoning Contingency' },
  { name: 'leaseOption', label: 'Lease Option' },
  { name: 'preClosingOccBuyer', label: 'Pre-Closing Occupancy' },
  { name: 'saleOfBuyerHomeContingency', label: 'Sale of Buyer’s Property' },
  {
    name: 'buyerAttorneyApproval',
    label: "Buyer's Attorney Approval",
  },
  { name: 'bindingArbitration', label: 'Binding Arbitration' },
  { name: 'seasonalVacationRentals', label: 'Seasonal/Vacation Rentals' },
  { name: 'other', label: 'Other' },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  OFFER_STATE_DRAFT,
  OFFER_STATE_DELETED,
  OFFER_STATE_ACTIVE,
  OFFER_STATE_ACCEPTED,
  OFFER_STATE_COUNTERED,
  OFFER_STATE_REJECTED,
  OFFER_STATE_CANCELLED,
  OFFER_STATE_PENDING_AGENT_UPDATES,
  OFFER_STATE_PENDING_BUYER_APPROVAL,
  OFFER_STATE_BUYER_APPROVED,
  OFFER_STATE_BUYER_REJECTED,
  BUYER_EDITING,
  BUYER_SENT,
  OFFER_STATE_PENDING_SELLER_AGENT_RESPONSE,
  OFFER_STATE_PENDING_SELLER_COUNTER_REVIEW,
  OFFER_STATE_SELLER_REJECTED_COUNTER_OFFER_CREATION,
  OFFER_STATE_PENDING_SELLER_ACCEPT_REVIEW,
  OFFER_STATE_PENDING_BUYER_AGENT_RESPONSE,
  OFFER_STATE_PENDING_BUYER_COUNTER_REVIEW,
  OFFER_STATE_BUYER_REJECTED_COUNTER_OFFER_CREATION,
  OFFER_STATE_PENDING_BUYER_ACCEPT_REVIEW,
  OFFER_STATE_LIVE_EDIT,
  OFFER_STATE_LIVE_EDIT_BUYER,
};
