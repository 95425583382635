import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import OfferInLine from './offerInLine';
import { CloseButton } from './offerHistoryModal.styled';

function OfferHistoryModal({ show, listing, counterOffers, originalOffer, globalCounter, onClose }) {
  const offers = counterOffers ? [...counterOffers, originalOffer] : [originalOffer];

  return (
    <Modal show={show} aria-labelledby='offer-history' backdrop='static' dialogClassName='modal-90wh' scrollable>
      <Modal.Body className='d-flex flex-column' scrollable>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <OfferInLine listing={listing} offers={offers} globalCounter={globalCounter} />
      </Modal.Body>
    </Modal>
  );
}

OfferHistoryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  offer: PropTypes.object.isRequired,
  counterOffers: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

export default OfferHistoryModal;
