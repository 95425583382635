import React from 'react';
import PropTypes from 'prop-types';
import { Container, SectionTitle, SectionItem } from './offerCommon.styled';

function OfferContact({ isSellerPreference = false, placeholder = false, offer }) {
  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle>{isSellerPreference && 'Primary Buyer'}</SectionTitle>
          <SectionItem height={80}>
            {!isSellerPreference && offer.primaryBuyer && (
              <>
                <div>{offer.primaryBuyer.user.firstName + ' ' + offer.primaryBuyer.user.lastName}</div>
                <div>{offer.primaryBuyer.ownerType}</div>
                {offer.primaryBuyer.ownerType !== 'INDIVIDUAL' && <div>{offer.primaryBuyer.orgName}</div>}
              </>
            )}
          </SectionItem>
          <SectionTitle>{isSellerPreference && 'Secondary Buyers'}</SectionTitle>
          <SectionItem>
            {!isSellerPreference &&
              offer.secondaryBuyers &&
              offer.secondaryBuyers.map((secondaryBuyer) => (
                <div key={secondaryBuyer.id}>{secondaryBuyer.user.firstName + ' ' + secondaryBuyer.user.lastName}</div>
              ))}
          </SectionItem>
        </>
      )}
    </Container>
  );
}

OfferContact.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  offer: PropTypes.object,
};
export default OfferContact;
