import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { OFFER1_RED } from 'constants/styleConstants';
import { NavBar } from './createTopNavBar.styled';
import styled from 'styled-components';
import { useAuthUser } from 'hooks';

const CommentBox = styled.div`
  background-color: ${OFFER1_RED};
  color: white;
  padding: 4px 8px;
`;

function CreateTopNavBar({
  showRescind,
  comment,
  isListing,
  isPrimaryOwner,
  isOfferBuyer,
  isSellerAgent,
  isBuyerAgent,
  isEnd,
  status,
  onUpdate,
  validSubscription,
}) {
  const [showComment] = useState(true);
  const { isListingAgent } = useAuthUser();

  return (
    <React.Fragment>
      {showComment && comment && <CommentBox>Reason(s) for rejection: {comment}</CommentBox>}
      <NavBar>
        {showRescind && (
          <Button variant='warning' size='sm' className='m-2' onClick={() => onUpdate('DRAFT')}>
            Rescind
          </Button>
        )}
        {isListing && status === 'DRAFT' && isSellerAgent && isEnd && isListingAgent && validSubscription && (
          <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdate('ACTIVE')}>
            Publish Listing
          </Button>
        )}
        {isListing && status === 'PENDING_SELLER_APPROVAL' && isPrimaryOwner && (
          <Button variant='primary' size='sm' className='m-2' onClick={() => onUpdate('SELLER_APPROVED')}>
            Approve
          </Button>
        )}
        {isEnd && !isListing && status === 'PENDING_BUYER_APPROVAL' && isOfferBuyer && (
          <div className='d-flex'>
            <Button variant='secondary' size='sm' className='m-2' onClick={() => onUpdate('BUYER_REJECTED')}>
              Reject
            </Button>
            <Button
              variant='primary'
              size='sm'
              className='m-2'
              onClick={() => onUpdate('PENDING_SELLER_AGENT_RESPONSE')}
            >
              Approve
            </Button>
          </div>
        )}
        {isEnd && !isListing && status === 'LIVE_EDIT_BUYER' && isOfferBuyer && (
          <div className='d-flex'>
            <Button
              variant='primary'
              size='sm'
              className='m-2'
              onClick={() => onUpdate('PENDING_SELLER_AGENT_RESPONSE')}
            >
              Approve
            </Button>
          </div>
        )}
        {isEnd && !isListing && status === 'LIVE_EDIT_BUYER' && isOfferBuyer && (
          <div className='d-flex'>
            <Button
              variant='primary'
              size='sm'
              className='m-2'
              onClick={() => onUpdate('PENDING_SELLER_AGENT_RESPONSE')}
            >
              Confirm Edits
            </Button>
          </div>
        )}
        {isEnd && !isListing && (status === 'LIVE_EDIT' || status === 'PENDING_SELLER_AGENT_RESPONSE') && isBuyerAgent && (
          <div className='d-flex'>
            <Button
              variant='primary'
              size='sm'
              className='m-2'
              onClick={() => onUpdate('PENDING_SELLER_AGENT_RESPONSE')}
            >
              Confirm Edits
            </Button>
          </div>
        )}
      </NavBar>
    </React.Fragment>
  );
}

CreateTopNavBar.propTypes = {
  isListing: PropTypes.bool,
  isSellerAgent: PropTypes.bool,
  isPrimaryOwner: PropTypes.bool,
  isOfferBuyer: PropTypes.bool,
  isEnd: PropTypes.bool,
  status: PropTypes.string,
  showRescind: PropTypes.bool,
  onUpdate: PropTypes.func,
  validSubscription: PropTypes.bool,
};

export default CreateTopNavBar;
