import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

function* fetchAgentQuestionnaire(action) {
  const {
    FetchAgentQuestionnaire: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { email },
  } = action;

  try {
    const response = yield call(apiCall, '/admin/questionnaire/email', 'get', { email });
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default fetchAgentQuestionnaire;
