import styled from 'styled-components';

export const CloseButton = styled.button`
  font-size: 40px;
  border: none;
  background-color: white;
  float: right;
  line-height: 20px;
`;

export const NavBar = styled.div`
  float: right;
`;
