import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import FastestValidator from 'fastest-validator';
import { RadioButtons, CheckBox, ButtonWrapper, Icon, PrimaryGreenButton } from 'components';
import { Header, Input, HorizontalRule, ErrorMessage } from './questionnaireModal.styled';
import { isNil } from 'lodash';
import { yesNoOptions } from 'constants/optionConstants';

function QuestionnaireModal({
  show,
  title,
  subTitle,
  initialQuestionnaire,
  readOnly = false,
  onClose,
  onSaveAgentQuestionnaire,
  saveButtonLabel,
}) {
  const [error, setError] = useState({});
  const [questionnaire, setQuestionnaire] = useState(null);

  useEffect(() => {
    if (initialQuestionnaire) {
      setQuestionnaire(initialQuestionnaire);
    } else {
      setQuestionnaire({
        transactions: 0,
        listingTransactions: 0,
        listingsActive: 0,
        techComfort: null,
        listingLeads: 0,
        buyerLeads: 0,
        newMethods: null,
        testimony: null,
        listingReady: null,
        verify: false,
      });
    }
  }, [initialQuestionnaire]);

  const onQuestionnaireChange = (event) => {
    let { name, value, type } = event.target;

    if (type === 'number') {
      value = parseInt(value);
    }

    const newObject = { ...questionnaire, [name]: value };
    setQuestionnaire(newObject);
  };

  const onYesNoChange = (event) => {
    const { name, value } = event.target;
    const newQuestionnaire = { ...questionnaire, [name]: value === 'yes' };
    setQuestionnaire(newQuestionnaire);
  };

  const onCheckBoxChange = (event) => {
    const { name, checked } = event.target;

    const newObject = { ...questionnaire, [name]: checked };
    setQuestionnaire(newObject);
  };

  const booleanToYesNo = (value) => {
    if (isNil(value)) return null;
    return value ? 'yes' : 'no';
  };

  const onSubmit = () => {
    if (readOnly) {
      onSaveAgentQuestionnaire();
    } else {
      const validator = new FastestValidator();
      const validation = validator.validate(questionnaire, {
        transactions: { type: 'number', integer: true },
        listingTransactions: { type: 'number', integer: true },
        listingsActive: { type: 'number', integer: true },
        techComfort: 'boolean',
        listingLeads: { type: 'number', integer: true },
        buyerLeads: { type: 'number', integer: true },
        newMethods: 'boolean',
        testimony: 'boolean',
        listingReady: 'boolean',
        verify: 'boolean',
      });

      if (validation === true && questionnaire.verify) {
        onSaveAgentQuestionnaire(questionnaire);
      } else {
        setError(
          validation !== true
            ? validation.reduce(
                (acc, { field, type }) => {
                  acc[field] = type === 'required' ? 'Required' : 'Invalid value';
                  return acc;
                },
                !questionnaire.verify ? { verify: 'Required' } : {}
              )
            : { verify: 'Required' }
        );
      }
    }
  };

  return (
    <Modal show={show} backdrop='static' keyboard={false} size='lg' centered>
      <Header>
        <h3>{title}</h3>
        <p>{subTitle}</p>
        <ButtonWrapper className='close-button' onClick={onClose}>
          <Icon name='close' />
        </ButtonWrapper>
      </Header>
      <Modal.Body>
        {questionnaire && (
          <Container style={{ color: 'var(--gray7)' }}>
            <Row>
              <Col md={9} className='mt-4'>
                1) How many transactions have you personally closed in the last 12 months?
                <br />
                <span className='fw-light'>
                  (if you are part of a team, only enter your personal production contribution)
                </span>
              </Col>
              <Col md={3} className='mt-4'>
                <Input
                  name='transactions'
                  type='number'
                  min='0'
                  onChange={onQuestionnaireChange}
                  readOnly={readOnly}
                  value={questionnaire.transactions}
                />
              </Col>
              <Col md={9} className='mt-4'>
                2) How many listing transactions have you closed in the last 12 months?
              </Col>
              <Col md={3} className='mt-4'>
                <Input
                  name='listingTransactions'
                  type='number'
                  min='0'
                  onChange={onQuestionnaireChange}
                  readOnly={readOnly}
                  value={questionnaire.listingTransactions}
                />
              </Col>
              <Col md={9} className='mt-4'>
                3) How many listings do you currently have or will have active in the next month?
              </Col>
              <Col md={3} className='mt-4'>
                <Input
                  name='listingsActive'
                  type='number'
                  min='0'
                  onChange={onQuestionnaireChange}
                  readOnly={readOnly}
                  value={questionnaire.listingsActive}
                />
              </Col>
              <Col md={9} className='mt-4'>
                4) Are you comfortable being an early adopter of technology?
              </Col>
              <Col md={3} className='mt-4'>
                <RadioButtons
                  items={yesNoOptions}
                  groupName='techComfort'
                  selectedOption={booleanToYesNo(questionnaire.techComfort)}
                  onStateChanged={!readOnly ? onYesNoChange : null}
                />
              </Col>
              {error.techComfort && <ErrorMessage md={12}>Required</ErrorMessage>}
              <Col md={9} className='mt-4'>
                5) How many listing leads per month do you think you would be able to expertly manage?
              </Col>
              <Col md={3} className='mt-4'>
                <Input
                  name='listingLeads'
                  type='number'
                  min='0'
                  onChange={onQuestionnaireChange}
                  readOnly={readOnly}
                  value={questionnaire.listingLeads}
                />
              </Col>
              <Col md={9} className='mt-4'>
                6) How many new buyer leads per month do you think you would be able to expertly manage?
              </Col>
              <Col md={3} className='mt-4'>
                <Input
                  name='buyerLeads'
                  type='number'
                  min='0'
                  onChange={onQuestionnaireChange}
                  readOnly={readOnly}
                  value={questionnaire.buyerLeads}
                />
              </Col>
              <Col md={9} className='mt-4'>
                7) Would you try new methods of listing if it helps you sell homes faster at a higher price and have
                happier clients?
              </Col>
              <Col md={3} className='mt-4'>
                <RadioButtons
                  items={yesNoOptions}
                  groupName='newMethods'
                  selectedOption={booleanToYesNo(questionnaire.newMethods)}
                  onStateChanged={!readOnly ? onYesNoChange : null}
                />
              </Col>
              {error.newMethods && <ErrorMessage md={12}>Required</ErrorMessage>}
              <Col md={9} className='mt-4'>
                8) If you are happy with Offer1 would you be willing to do a quick video testimony sharing your
                experience?
              </Col>
              <Col md={3} className='mt-4'>
                <RadioButtons
                  items={yesNoOptions}
                  groupName='testimony'
                  selectedOption={booleanToYesNo(questionnaire.testimony)}
                  readOnly={readOnly}
                  onStateChanged={!readOnly ? onYesNoChange : null}
                />
              </Col>
              {error.testimony && <ErrorMessage md={12}>Required</ErrorMessage>}
              <Col md={9} className='mt-4'>
                9) Do you have a listing ready to publish now?
              </Col>
              <Col md={3} className='mt-4'>
                <RadioButtons
                  items={yesNoOptions}
                  groupName='listingReady'
                  selectedOption={booleanToYesNo(questionnaire.listingReady)}
                  readOnly={readOnly}
                  onStateChanged={!readOnly ? onYesNoChange : null}
                />
              </Col>
              {error.listingReady && <ErrorMessage md={12}>Required</ErrorMessage>}
            </Row>
            <HorizontalRule className='mt-4' />
            <Row>
              <Col md={12} className='mt-3'>
                <CheckBox name='verify' checked={questionnaire.verify} onChange={!readOnly ? onCheckBoxChange : null}>
                  I agree to provide verification of my production numbers upon request.
                </CheckBox>
              </Col>
              {error.verify && <ErrorMessage>Must agree to provide verification of production numbers</ErrorMessage>}
            </Row>
          </Container>
        )}
        {!questionnaire && <h3 className='text-center'>No Agent Questionnaire</h3>}
      </Modal.Body>
      <Modal.Footer>
        {onSaveAgentQuestionnaire && <PrimaryGreenButton onClick={onSubmit}>{saveButtonLabel}</PrimaryGreenButton>}
      </Modal.Footer>
    </Modal>
  );
}

QuestionnaireModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveAgentQuestionnaire: PropTypes.func,
};

export default QuestionnaireModal;
