import { call, put } from 'redux-saga/effects';
import { ListingsAsyncActions } from 'store/actions/listings';
import { apiCall } from 'utils/apiCall';

function* updateLiked(action) {
  const {
    UpdateListingsLiked: {
      Actions: { SUCCESS, FAILURE },
    },
  } = ListingsAsyncActions;
  const {
    payload: { listingId, liked },
  } = action;

  try {
    const response = yield call(apiCall, `/listings/liked/${listingId}`, liked ? 'put' : 'delete');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default updateLiked;
