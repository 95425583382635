import styled from 'styled-components';

export const TimelineContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
`;

export const TimelineItem = styled.li`
  display: flex;
  position: relative;
  list-style: none;
`;

export const TimelineSeparator = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TimelineDot = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background-color: ${({ active }) => (active ? 'var(--offer1-blue)' : 'var(--gray6)')};
`;

export const TimelineConnector = styled.span`
  width: 4px;
  height: 26px;
  flex-grow: 1;
  background-color: var(--gray6);
`;

export const TimelineContent = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  padding-left: 12px;
  flex: 1;
  text-transform: capitalize;
  color: ${({ active }) => (active ? 'var(--offer1-blue)' : 'var(--gray6)')};

  svg {
    margin-left: 6px;
  }
`;
