import React from 'react';
import { Footer, TopNavBar, MainLeftNavBar } from 'components';
import ErrorBoundary from 'ErrorBoundary';
import { RootContainer, Main } from './mainLayout.styled';
import { Provider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
};

function MainLayout({ children }) {
  return (
    <Provider config={rollbarConfig}>
      <RootContainer>
        <MainLeftNavBar />
        <Main>
          <TopNavBar />
          <ErrorBoundary>{children}</ErrorBoundary>
          <Footer />
        </Main>
      </RootContainer>
    </Provider>
  );
}

export default MainLayout;
