import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { FormField, DropDown } from 'components';
import Autocomplete from 'react-google-autocomplete';
import apiConstants from 'constants/apiConstants';
import { getMetaStates, getMetaListingStates } from 'store/selectors/app';

function Address({
  address,
  children,
  onStateChanged,
  useListingStates,
  limitToStates = [],
  showCounty = false,
  errors,
}) {
  const addressRef = useRef();
  const states = useSelector(useListingStates ? getMetaListingStates : getMetaStates);

  const availableStates = useMemo(
    () => states.filter((state) => !limitToStates.length || limitToStates.includes(state.value)),
    [states, limitToStates]
  );

  useEffect(() => {
    addressRef.current.value = address?.addressLine1 ?? '';
  }, [address]);

  const handleChange = (state) => {
    const newAddress = {
      ...address,
      [state.field]: state.value,
    };
    onStateChanged(newAddress);
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    address[name] = value;
    onStateChanged(address);
  };

  const handlePlaceChange = (place) => {
    let addressLine1 = '';
    let addressLine2 = '';
    let zip = '';
    let city = '';
    let state = '';
    let county = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          addressLine1 = `${component.long_name} ${addressLine1}`;
          break;

        case 'route':
          addressLine1 += component.short_name;
          break;

        case 'subpremise':
          addressLine2 = component.long_name;
          break;

        case 'postal_code':
          zip = `${component.long_name}${zip}`;
          break;

        case 'postal_code_suffix':
          zip = `${zip}-${component.long_name}`;
          break;

        case 'locality':
          city = component.long_name;
          break;

        case 'administrative_area_level_2':
          county = showCounty ? component.long_name : '';
          break;

        case 'administrative_area_level_1':
          state = component.short_name;
          break;

        default:
          break;
      }
    }

    addressRef.current.value = addressLine1;
    onStateChanged({
      ...address,
      addressLine1,
      addressLine2,
      city,
      county,
      state: availableStates.some(({ value }) => value === state) ? state : '',
      zip,
    });
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Form.Group className='pb-2'>
            <Form.Label className='mb-2'>Street Address*</Form.Label>
            <Autocomplete
              id='addressLine1'
              name='addressLine1'
              ref={addressRef}
              className={classNames('form-control', {
                'is-invalid': errors?.addressLine1,
              })}
              apiKey={apiConstants.GOOGLE_API_KEY}
              onPlaceSelected={handlePlaceChange}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'us' },
              }}
              onChange={handleStateChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormField
            fieldId='addressLine2'
            label='Street Address Cont.'
            onStateChanged={handleChange}
            initialValue={address?.addressLine2 ?? ''}
          />
        </Col>
        <Col sm={6}>
          <FormField
            fieldId='city'
            label='City'
            onStateChanged={handleChange}
            initialValue={address?.city ?? ''}
            required
            error={errors?.city}
          />
        </Col>
      </Row>
      <Row>
        {showCounty && (
          <Col sm={6}>
            <FormField
              fieldId='county'
              label='County'
              onStateChanged={handleChange}
              initialValue={address?.county ?? ''}
            />
          </Col>
        )}
        <Col sm={6}>
          <DropDown
            label='State*'
            groupName='state'
            selectedOption={address?.state ?? 'select'}
            onStateChanged={handleStateChange}
            items={availableStates}
            error={errors?.state}
          />
        </Col>
        <Col sm={6}>
          <FormField
            fieldId='zip'
            label='ZIP'
            onStateChanged={handleChange}
            initialValue={address?.zip ?? ''}
            required
            error={errors?.zip}
          />
        </Col>
        {children && <Col sm={6}>{children}</Col>}
      </Row>
    </>
  );
}

export default Address;
