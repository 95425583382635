import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Dropzone from './dropzone';
import PropertyItem from '../Property/propertyItem';
import { uploadFile } from 'services/client';
import { URLUtils, sanitizeFileName } from 'utils';
import Swal from 'sweetalert2';

function FileSelect({ className, type, title, file, multiple, deleteFile, onFileSelected, readOnly = false }) {
  const handleChange = async (files) => {
    if (files.length) {
      if (files[0].size > 20971520) {
        Swal.fire({
          text: 'Your file size is too big. Please only upload files of 20MB or less.',
          icon: 'error',
        });
      } else {
        try {
          const formData = new FormData();
          const sanitizedFileName = sanitizeFileName(files[0].name);
          formData.append('file', files[0], sanitizedFileName);
          const uploadedFile = await uploadFile(formData, type);
          onFileSelected(uploadedFile, type);
        } catch (error) {
          Swal.fire({
            text: 'Something went wrong while uploading your document',
            icon: 'error',
          });
        }
      }
    }
  };

  return (
    <>
      <Row className={className}>
        <Col sm={3}>
          <p>{title}</p>
        </Col>
        <Col sm={7} className='d-flex flex-column'>
          {file && file.url && (
            <PropertyItem
              included
              name={file.name}
              onClickRemove={readOnly ? undefined : () => deleteFile(type, file.id)}
              onClick={() => URLUtils.openDocument(file.url)}
            />
          )}
          {multiple &&
            file?.map((f) => (
              <PropertyItem
                key={f.id}
                included
                name={f.name}
                onClickRemove={readOnly ? undefined : () => deleteFile(type, f.id)}
                onClick={() => URLUtils.openDocument(f.url)}
              />
            ))}
        </Col>
        <Col sm={2}>
          {!readOnly && (!file || (file && !file.url) || (multiple ? multiple : false)) && (
            <Dropzone handleChange={handleChange}>
              <Button>+ Add</Button>
            </Dropzone>
          )}
        </Col>
      </Row>
    </>
  );
}

FileSelect.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  multiple: PropTypes.bool,
  onFileSelected: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default FileSelect;
