import { all, takeLatest } from 'redux-saga/effects';
import { OffersAsyncActions } from 'store/actions/offers';
import fetch from './fetch';
import list from './list';
import updateState from './updateState';
import createCounter from './createCounter';
import updateCounter from './updateCounter';
import fetchGlobal from './fetchGlobal';
import updateGlobal from './updateGlobal';
import updateViewedOffers from './updateViewedOffers';

export default function* root() {
  const {
    FetchOffer,
    ListOffers,
    UpdateState,
    CreateCounterOffer,
    UpdateCounterOffer,
    FetchGlobalCounterOffer,
    UpdateGlobalCounterOffer,
    UpdateViewedOffers,
  } = OffersAsyncActions;
  yield all([
    takeLatest(ListOffers.Types.REQUEST, list),
    takeLatest(FetchOffer.Types.REQUEST, fetch),
    takeLatest(UpdateState.Types.REQUEST, updateState),
    takeLatest(CreateCounterOffer.Types.REQUEST, createCounter),
    takeLatest(UpdateCounterOffer.Types.REQUEST, updateCounter),
    takeLatest(FetchGlobalCounterOffer.Types.REQUEST, fetchGlobal),
    takeLatest(UpdateGlobalCounterOffer.Types.REQUEST, updateGlobal),
    takeLatest(UpdateViewedOffers.Types.REQUEST, updateViewedOffers),
  ]);
}
