import styled from 'styled-components';

export const LabelText = styled.span`
  font-size: 16px;
`;

export const ValueText = styled.span`
  font-size: 24px;
  font-weight: bold;
`;
