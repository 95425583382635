import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, DropDown } from 'components';
import { ModalContainer } from './listingDetailPage.styled';
import {
  ModalBox,
  ModalContent,
  ModalHeader,
  HouseImage,
  WelcomeText,
  LoginText,
  GetStartedButton,
} from './loginRedirectModal.styled';

function BuyerProfileRedirectModal({ onClose, onSubmit, profiles }) {
  const [buyerProfile, setBuyerProfile] = useState();

  const handleProfileChange = (e) => {
    const { value } = e.target;
    setBuyerProfile(value);
  };

  return (
    <ModalContainer>
      <ModalBox>
        <ModalHeader>
          <div onClick={onClose}>
            <Icon name='close' />
          </div>
        </ModalHeader>
        <ModalContent>
          <HouseImage></HouseImage>
          <WelcomeText>Get a head start</WelcomeText>
          <LoginText>Select a buyer profile to jump start your offer creation.</LoginText>
          <DropDown
            label='Select Profile'
            groupName='buyerprofiles'
            selectedOption={buyerProfile ? buyerProfile : 'select'}
            onStateChanged={handleProfileChange}
            items={profiles}
          />
          <GetStartedButton className='' onClick={() => onSubmit(buyerProfile)}>
            Get Started
          </GetStartedButton>
        </ModalContent>
      </ModalBox>
    </ModalContainer>
  );
}

BuyerProfileRedirectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default BuyerProfileRedirectModal;
