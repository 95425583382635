import React from 'react';
import PropTypes from 'prop-types';
import { TabsBarContainer, TabsBarItem } from './tabsBar.styled';

function TabsBar({ tabs }) {
  return (
    <TabsBarContainer>
      {tabs.map((tab, index) => (
        <TabsBarItem key={`tabbar-${tab.label}-${index}`} to={`${tab.value}`} color={tab.color}>
          {tab.label}
        </TabsBarItem>
      ))}
    </TabsBarContainer>
  );
}

TabsBar.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default TabsBar;
