import { createSelector } from 'reselect';

export const getState = (state) => state.auth;

export const getToken = createSelector(getState, ({ token }) => token);

export const getCurrentUser = createSelector(getState, ({ user }) => user);

export const getSignupError = createSelector(getState, ({ signUpError }) => signUpError);

export const getSignupSuccess = createSelector(getState, ({ isSuccessSignup }) => isSuccessSignup);
