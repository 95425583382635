import { createAsyncAction } from 'utils/store';

export const FetchDashboard = createAsyncAction('dashboard', 'fetchDashboard', {
  REQUEST: ['id'],
  SUCCESS: ['listings', 'notifications', 'offers', 'buyers', 'tasks'],
});

export const DashboardAsyncActions = {
  FetchDashboard,
};
