import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';

const CustomCollapse = ({
  header,
  headerClassName,
  headerContent,
  bodyClassName,
  defaultCollapsed = true,
  children,
}) => {
  const [open, setOpen] = useState(defaultCollapsed);

  const Header = header;
  return (
    <>
      <Header className={headerClassName} onClick={() => setOpen(!open)} aria-expanded={open} expanded={open}>
        {headerContent}
      </Header>
      <Collapse in={open}>
        <div className={bodyClassName}>{children}</div>
      </Collapse>
    </>
  );
};

export default CustomCollapse;
