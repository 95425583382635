import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import { useAuthUser } from 'hooks';
import { URLUtils } from 'utils';
import routes from 'containers/App/routeConstants';
import { LogoImage } from './topNavBar.styled';

function NavBar() {
  const location = useLocation();

  const { user } = useAuthUser();

  if (user) {
    return null;
  } else {
    return (
      <Navbar className='px-2 py-3'>
        <Navbar.Brand href='/'>
          <LogoImage src={URLUtils.getPublicImageUrl('logo.svg')} alt='logo' />
        </Navbar.Brand>
        <NavItem className='ms-auto'>
          <Link
            className='btn btn-outline-primary shadow-lg px-4'
            to={{ pathname: routes.LOGIN_PATH, state: location.state }}
          >
            Log In
          </Link>
        </NavItem>
      </Navbar>
    );
  }
}

export default NavBar;
