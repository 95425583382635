import { createSelector } from 'reselect';

export const getState = (state) => state.dashboard;

export const getListings = createSelector(getState, ({ listings }) => listings ?? []);

export const getOffers = createSelector(getState, ({ offers }) => offers ?? []);

export const getBuyers = createSelector(getState, ({ buyers }) => buyers ?? []);

export const getTasks = createSelector(getState, ({ tasks }) => tasks ?? []);
