import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PasswordField } from 'components';
import routeConstants from 'containers/App/routeConstants';
import { AuthAsyncActions } from 'store/actions/auth';
import QueryString from 'query-string';
import { ResetContent, SectionTitle, SectionBody, Button } from './resetPassword.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = QueryString.parse(location.search);
  const { isSuccess, error } = useSelector(AuthAsyncActions.ResetPassword.StatusSelector());
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [verifyPasswordValid, setVerifyPasswordValid] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  useEffect(() => {
    return () => dispatch(AuthAsyncActions.ResetPassword.Actions.RESET());
  }, [dispatch]);

  const setNewPassword = () => {
    setRequestSent(true);
    dispatch(AuthAsyncActions.ResetPassword.Actions.REQUEST(params.token, params.email, password));
  };

  const passwordChanged = (state) => {
    setPassword(state.value);
    setPasswordValid(state.errors.length === 0);
  };

  const verifypasswordChanged = (state) => {
    setVerifyPasswordValid(state.errors.length === 0);
  };

  return (
    <MainContainerMd>
      {!isSuccess && (
        <ResetContent>
          <div className='row d-flex justify-content-center my-4'>
            <SectionTitle>Reset Your Password</SectionTitle>
          </div>
          <hr />
          <div className='justify-content-center'>
            <div className='my-3 px-3'>
              {error && requestSent && (
                <p className='text-danger text-md-center mt-2 h6'>
                  There was an error setting your password. Try again.
                </p>
              )}
              <SectionBody>
                {'Hi '}
                {params.firstName ? params.firstName : ''}
                {', '}Please enter your new password below
              </SectionBody>
            </div>
            <div className='d-flex flex-column'>
              <PasswordField
                fieldId='password'
                label='Password'
                onStateChanged={passwordChanged}
                onVerifyStateChanged={verifypasswordChanged}
                thresholdLength={8}
                minStrength={1}
                required
              />

              {passwordValid && verifyPasswordValid && (
                <Button
                  type='button'
                  className='btn auth-button text-uppercase py-2 mx-auto mb-3'
                  onClick={setNewPassword}>
                  UPDATE PASSWORD
                </Button>
              )}
            </div>
          </div>
        </ResetContent>
      )}

      {isSuccess && (
        <ResetContent className='py-4'>
          <SectionTitle className='my-2'>Success</SectionTitle>
          <hr />
          <div className='d-flex flex-column align-items-center'>
            <SectionBody>Your password was successfully updated.</SectionBody>
            <Button
              type='button'
              className='btn auth-button text-uppercase px-3 py-2 mx-auto mt-2'
              onClick={() => history.push(routeConstants.LOGIN_PATH)}>
              GO TO SIGNIN
            </Button>
          </div>
        </ResetContent>
      )}
    </MainContainerMd>
  );
}

export default ResetPasswordPage;
