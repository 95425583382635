import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { ButtonWrapper, CardDeck, MlsPropertyCard, Icon } from 'components';
import { useAuthUser, useResponsive } from 'hooks';
import Spinner from 'react-bootstrap/Spinner';
import { Header } from './importListingModal.styled';
import { MainContainer } from 'containers/App/mainLayout.styled';
import { ListingsAsyncActions } from 'store/actions/listings';
import { getSearchListings } from 'store/selectors/listings';

function ImportListingModal({ onClose }) {
  const dispatch = useDispatch();
  const listingsToShow = useSelector(getSearchListings);
  const { isLoading } = useSelector(ListingsAsyncActions.SearchListingsByAgent.StatusSelector());
  const { user } = useAuthUser();

  const listingsDisplay = listingsToShow?.map((listing) => (
    <MlsPropertyCard
      key={listing.listing.id}
      listing={listing.listing}
      offerCount={listing.offerCount}
      highestOffer={listing.highestOffer}
      buyerOffer={listing.buyerOffer}
      buyerOfferState={listing.buyerOfferState}
      buyer={listing.buyer}
      auctionExpirationDate={listing.auctionExpirationDate}
      user={user}
    />
  ));

  const isMobile = useResponsive();

  const agentId = useMemo(() => user?.agent?.licenseNumber, [user]);

  const state = useMemo(() => user?.agent?.licenseState, [user]);

  useEffect(() => {
    dispatch(ListingsAsyncActions.SearchListingsByAgent.Actions.REQUEST(agentId, state));
  }, [dispatch, agentId, state]);

  return (
    <Modal show backdrop='static' keyboard={false} size='xl' centered>
      <Header>
        <h3>Import Listing</h3>
        <ButtonWrapper className='close-button' onClick={onClose}>
          <Icon name='close' />
        </ButtonWrapper>
      </Header>
      <Modal.Body>
        <MainContainer
          className={classNames({
            'text-center': isMobile,
          })}
        >
          {isLoading && (
            <div className='mt-4 w-100 d-flex justify-content-center'>
              <Spinner className='spinner-img' as='span' animation='border' role='status' aria-hidden='true' />
            </div>
          )}
          {!isLoading && (
            <>
              <div className='mt-3 bg-light'>
                <CardDeck center>{listingsDisplay}</CardDeck>
              </div>
              {listingsToShow?.length === 0 && <h4>No results found</h4>}
            </>
          )}
        </MainContainer>
      </Modal.Body>
    </Modal>
  );
}

export default ImportListingModal;
