import { call, put } from 'redux-saga/effects';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* update(action) {
  const {
    UpdateOffer: {
      Actions: { SUCCESS, FAILURE },
    },
  } = CreateOfferAsyncActions;
  const {
    payload: { offer },
  } = action;

  try {
    const response = yield call(apiCall, '/offers', 'put', offer);
    yield put(SUCCESS(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(FAILURE(error));
  }
}

export default update;
