import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuthUser } from 'hooks';
import FilterBar from './filterBar';
import { PropertyCard } from 'components';
import { Container } from './allListingsPage.styled';

const AllListings = () => {
  // const dispatch = useDispatch();
  const [listingFilter, setListingFilter] = useState({
    price: { min: null, max: null },
    address: null,
    city: null,
    state: null,
    zip: null,
  });

  const { user } = useAuthUser();

  const allListings = useSelector((state) => state.listings.allListings);
  const filteredListing = useMemo(() => {
    let listingsToShow = allListings;
    if (listingFilter.price.min) {
      listingsToShow = listingsToShow.filter(({ price }) => price >= listingFilter.price.min);
    }
    if (listingFilter.price.max) {
      listingsToShow = listingsToShow.filter(({ price }) => price <= listingFilter.price.max);
    }
    if (listingFilter.address) {
      listingsToShow = listingsToShow.filter(({ property }) =>
        property.address.addressLine1.toLowerCase().includes(listingFilter.address.toLowerCase())
      );
    }
    if (listingFilter.city) {
      listingsToShow = listingsToShow.filter(({ property }) =>
        property.address.city.toLowerCase().includes(listingFilter.city.toLowerCase())
      );
    }
    if (listingFilter.state) {
      listingsToShow = listingsToShow.filter(({ property }) =>
        property.address.state.toLowerCase().includes(listingFilter.state.toLowerCase())
      );
    }
    if (listingFilter.zip) {
      listingsToShow = listingsToShow.filter(({ property }) =>
        property.address.zip.toLowerCase().includes(listingFilter.zip.toLowerCase())
      );
    }
    return listingsToShow;
  }, [allListings, listingFilter]);

  // const getListings = useCallback(() => {
  //   dispatch({ type: LOAD_ALL_LISTINGS });
  // }, [dispatch]);

  // useEffect(() => {
  //   getListings();
  // }, [getListings]);

  return (
    <React.Fragment>
      <div style={{ height: 30 }} className='d-flex justify-content-between my-3'>
        <FilterBar onFilter={(listingFilter) => setListingFilter(listingFilter)} />
      </div>
      <div className='py-5 bg-light'>
        <Container>
          {filteredListing.map((listing) => (
            <PropertyCard key={listing.id} listing={listing} user={user} />
          ))}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllListings;
