import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { OFFER1_GREEN } from 'constants/styleConstants';
import { NotificationContainer, NotificationHeader, NotificationBody, CloseButton } from './offerNotification.styled';

function OfferNotification({ onClose, onViewDetail }) {
  return (
    <NotificationContainer>
      <NotificationHeader>
        <span>Progress! New counter offer is in.</span>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </NotificationHeader>
      <NotificationBody>
        View offer for 1575 Honeysuckle St. Encintas, CA 92024 from buyer(s): William Sonoma, Stephanie Sonoma
      </NotificationBody>
      <Button
        className='text-uppercase mb-2'
        variant='success'
        style={{ backgroundColor: OFFER1_GREEN }}
        onClick={onViewDetail}
      >
        View Details
      </Button>
    </NotificationContainer>
  );
}

OfferNotification.propType = {
  onClose: PropTypes.func.isRequired,
  onViewDetail: PropTypes.func.isRequired,
};

export default OfferNotification;
