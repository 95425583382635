export const yesNoBooleanOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const yesNoOptions = [
  { name: 'Yes', value: 'yes' },
  { name: 'No', value: 'no' },
];

export const yesNoOptionsWithNA = [
  { name: 'Yes', value: 'yes' },
  { name: 'No', value: 'no' },
  { name: 'N/A', value: 'n/a' },
];

export const buyerSellerOptions = [
  { name: 'Buyer', value: 'BUYER' },
  { name: 'Seller', value: 'SELLER' },
];

export const buyerSellerOptionsWithNA = [
  { name: 'Buyer', value: 'BUYER' },
  { name: 'Seller', value: 'SELLER' },
  { name: 'N/A', value: 'NA' },
];

export const buyerSellerOptionsWithBoth = [
  { name: 'Buyer', value: 'BUYER' },
  { name: 'Seller', value: 'SELLER' },
  { name: 'Both', value: 'BOTH' },
];

export const buyerSellerOptionsWithBothAndNA = [
  { name: 'Buyer', value: 'BUYER' },
  { name: 'Seller', value: 'SELLER' },
  { name: '50/50', value: 'BOTH' },
  { name: 'N/A', value: 'NA' },
];

export const buyerSellerOptionsWithPayOwn = [
  { name: 'Buyer', value: 'BUYER' },
  { name: 'Seller', value: 'SELLER' },
  { name: 'Each pay their own', value: 'PAY_OWN' },
];

export const remainInPropertyOptions = [
  { name: 'Yes, 29 days or less  use Seller in Possession (C.A.R from SIP)', value: 'less_30' },
  { name: 'Yes, 30 days or more use Lease After Sale (C.A.R form RLAS) ', value: 'more_30' },
  { name: 'No', value: 'no' },
];

export const timeFrameOptions = [
  { name: '0-1 month', value: '0-1' },
  { name: '1-3 months', value: '1-3' },
  { name: '3-6 months', value: '3-6' },
  { name: '6-12 months', value: '6-12' },
  { name: '12+ months', value: '12+' },
];

export const daysToCloseOptions = [
  { name: 'Days to Close', value: 'day' },
  { name: 'Specific Date', value: 'date' },
];

export const daysToCloseBooleanOptions = [
  { name: 'Days To Close', value: true },
  { name: 'Specific Date', value: false },
];

export const cashOptions = [
  { name: 'Straight Cash', value: 'CASH' },
  { name: 'Hard Money', value: 'HARD_MONEY' },
];

export const purchaseOptions = [
  { name: 'Cash', value: 'cash' },
  { name: 'Loan', value: 'loan' },
];

export const purchaseBooleanOptions = [
  { name: 'Cash', value: false },
  { name: 'Loan', value: true },
];

export const sellerPossessionFeeOptions = [
  { name: "Buyer's Total PITI", value: 'buyerPITI' },
  { name: 'Nothing', value: 'nothing' },
  { name: 'Other', value: 'other' },
];
