import styled, { keyframes } from 'styled-components';
import { OFFER1_GRAY } from 'constants/styleConstants';

export const RadioWrapper = styled.div`
  width: 40px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: rgb(130, 189, 219);
  border-radius: 10px;
  position: relative;
  margin-left: 1%;
  margin-right: 1%;
`;

export const slidein = keyframes`
  from {
      transform: translate(20px, 0);
  }
  to {
      transform: translate(0px, 0px);
  }
`;

export const slideno = keyframes`
  from {
    transform: translate(-20px, 0);
  }
  to {
    transform: translate(0px, 0px);
  }
}
`;

export const Input = styled.input`
  display: none;
`;

export const Yes = styled(Input)`
  & + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    text-align: center;
  }
  &:checked + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(51, 114, 170);
    -moz-animation-duration: 0.4s;
    -moz-animation-name: ${slidein};
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: ${slidein};
    animation-duration: 0.4s;
    animation-name: ${slidein};
    -webkit-transform: translateZ(0);
  }
`;

export const No = styled(Input)`
  & + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    text-align: center;
  }
  &:checked + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(51, 114, 170);
    -moz-animation-duration: 0.4s;
    -moz-animation-name: ${slideno};
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: ${slideno};
    animation-duration: 0.4s;
    animation-name: ${slideno};
    -webkit-transform: translateZ(0);
  }
`;

export const RadioLabel = styled.span`
  color: ${(props) => (props.color ? props.color : OFFER1_GRAY)};
`;
