import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Text } from './ellipsisLinesToolTip.styled';

const EllipsisLinesToolTip = ({ lines = 1, text }) => {
  const [isTextClamped, setIsTextClamped] = useState(false);

  return (
    <>
      <Text lines={lines} ref={(ref) => setIsTextClamped(ref && ref.scrollHeight > ref.clientHeight)} data-tip={text}>
        {text}
      </Text>
      {isTextClamped && <ReactTooltip place='top' effect='solid' />}
    </>
  );
};

export default EllipsisLinesToolTip;
