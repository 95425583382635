import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CalendarViewStyled } from './datePicker.styled';

/*
 React-datetime Docs API:
 https://github.com/arqex/react-datetime#specify-available-units
*/

export const DatePicker = ({
  onStateChanged = (f) => f,
  fieldId,
  label,
  comment,
  additionalControls,
  required,
  validator = (f) => f,
  placeholder,
  initialValue,
  editable,
  error = null,
}) => {
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState([]);

  const onPickerChanged = (value) => {
    const field = fieldId;
    const isEmpty = value.length === 0;
    const requiredMissing = dirty && required && isEmpty;
    let errors = [];

    if (requiredMissing) {
      // if required and is empty, add required error to state
      errors = [...errors, label ? ` ${label} is required` : `required`];
    } else if ('function' === typeof validator) {
      try {
        validator(value);
      } catch (e) {
        // if validator throws error, add validation error to state
        errors = [...errors, e.message];
      }
    }

    setDirty(!dirty || dirty);
    setErrors(errors);

    onStateChanged({
      value,
      errors,
      dirty: !dirty || dirty,
      field,
    });
  };

  useEffect(() => {
    if (error) {
      setDirty(true);
      setErrors([error]);
    }
  }, [error]);

  const hasErrors = errors.length > 0;
  const controlClass = classNames('form-control', additionalControls, {
    'is-invalid': dirty && hasErrors,
  });

  const onInputMask = (e) => {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    const len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }
    // If they don't add the slash, do it for them...
    if (len === 2) {
      input.value += '/';
    }

    // If they don't add the slash, do it for them...
    if (len === 5) {
      input.value += '/';
    }
  };

  const renderInput = (props, openCalendar, closeCalendar) => {
    // function onClear(value) {
    //   props.onChange({ target: { value: '' } });
    // }
    return (
      <div className='form-group px-3 pb-2' id={fieldId}>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <span>
            {label && label !== '' && (
              <label htmlFor={fieldId} className='control-label'>
                {label === ' ' ? <span>&nbsp;</span> : label}{' '}
              </label>
            )}
          </span>
          {/** Render the first error if there are any errors **/}
          {hasErrors && <div className='error form-hint fw-bold text-end m-0 mb-2'>{errors[0]}</div>}
        </div>
        <input
          {...props}
          maxLength='10'
          className={controlClass}
          placeholder={placeholder}
          readOnly={editable === undefined ? false : !editable}
          onKeyPress={onInputMask}
        />
      </div>
    );
  };
  return (
    <CalendarViewStyled
      timeFormat={false}
      onChange={onPickerChanged}
      renderInput={renderInput}
      initialValue={initialValue}
      //renderView={(mode, renderDefault) => calendarView(mode, renderDefault)}
    />
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  editable: PropTypes.bool,
  validator: PropTypes.func,
  onStateChanged: PropTypes.func,
  additionalControls: PropTypes.string,
  error: PropTypes.string,
};

export default DatePicker;
