import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 61%;
  height: 100%;
  border: 1px solid black;
  border-left: 0px;
  margin-bottom: 64px;
  background-color: var(--gray4);
`;

export const HorizontalRule = styled.div`
  width: ${(props) => (props.full ? '100%' : '60%')};
  border-style: solid none none none;
  border-width: 1px;
  border-color: var(--gray3);
  margin: 0px 0px 8px 0px;
  align-self: center;
`;

export const Header = styled.p`
  font-size: 28px;
  padding: 16px 32px 0px 32px;
  font-weight: 500;
`;

export const Subheader = styled.p`
  font-size: 22px;
  font-weight: 500;
  padding: 16px 32px 0px 32px;
`;

export const DescriptionText = styled.p`
  padding: ${(props) => (props.topMargin ? '12px' : '0px')} ${(props) => (props.rightMargin ? '32px' : '8px')} 0px 32px;
  font-size: ${(props) => (props.larger ? '16px' : '14px')};
`;

export const InputLabel = styled.span`
  padding: 4px 0px;
  font-size: 14px;
  font-weight: 600;
`;

export const FormPartOne = styled.div`
  width: 90%;
  padding: 0px 32px 0px 32px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-content: center;
`;

export const FormPartTwo = styled.div`
  width: 100%;
  padding: 0px 32px 0px 16px;
  display: flex;
  align-content: center;
  justify-content: between;
  font-size: 16px;
`;

export const FormPartThree = styled.div`
  width: 93%;
  padding: 0px 32px 0px 32px;
  display: grid;
  grid-template-columns: 33% 30% 33%;
  align-content: center;
  font-size: 16px;
`;

export const CheckContainer = styled.div`
  display: flex;
  align-content: center;
  ${(props) => (props.smallText ? 'font-size: 14px;' : '')}
  margin: 4px ${(props) => (props.margins ? '32px' : '8px')} 0px ${(props) => (props.margins ? '32px' : '0px')};
`;

export const Input = styled.input`
  width: 100%;
  padding: 4px 12px;
  margin-top: 2px;
  border: solid 1px;
  border-radius: 5px;
  border-color: ${(props) => (props.error ? 'red' : 'var(--gray3)')};
`;

export const InputContainer = styled.div`
  padding: 4px 16px 4px 0px;
`;

export const ContinueButton = styled.button`
  padding: 16px 48px;
  border-color: var(--offer1-blue);
  border-width: 2px;
  border-radius: 5px;
  background-color: var(--offer1-blue);
  color: white;
`;

export const ButtonsBox = styled.div`
  padding: 16px 32px 0px 32px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  padding: 0px 32px;
  color: red;
  font-size: 14px;
  align-self: start;
`;
