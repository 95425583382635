import React, { useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router';
import { Formik } from 'formik';
import { useAuthUser } from 'hooks';
import routeConstants from 'containers/App/routeConstants';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { FormikField, PrimaryBlueButton } from 'components';
import { URLUtils } from 'utils';
import * as Yup from 'yup';
import 'yup-phone';
import { AuthAsyncActions } from 'store/actions/auth';
import { OnboardingContent, OnboardingImage, SectionTitle, SectionText } from './consumerOnboarding.styled';
import { MainContainerMd } from 'containers/App/mainLayout.styled';

function ConsumerOnboarding() {
  const { user } = useAuthUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector(AuthAsyncActions.UpdateAccount.StatusSelector());

  useEffect(() => {
    return () => dispatch(AuthAsyncActions.UpdateAccount.Actions.RESET());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      history.push(routeConstants.HOME_PATH);
    }
  }, [history, isSuccess]);

  const schema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string().max(25, 'Must be 35 characters or less').required('Required'),
        lastName: Yup.string().max(25, 'Must be 35 characters or less').required('Required'),
        phone: Yup.string().required('Required').phone('US', false, 'Invalid phone number'),
      }),
    []
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const newUser = {
      id: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    };
    setSubmitting(false);
    dispatch(AuthAsyncActions.UpdateAccount.Actions.REQUEST(newUser));
  };

  if (user.agent || user.lastLogin) {
    return <Redirect to={routeConstants.HOME_PATH} />;
  }

  return (
    <>
      <Helmet>
        <title>Consumer Onboarding</title>
        <meta name='description' content='Consumer Onboarding' />
      </Helmet>
      <div className='mb-4 d-flex justify-content-center'>
        <MainContainerMd>
          <OnboardingContent>
            <div className='d-flex justify-content-center'>
              <OnboardingImage src={URLUtils.getPublicImageUrl('consumer-onboarding.svg')} alt='consumer onboarding' />
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={{
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                phone: user?.phone || '',
              }}
            >
              {({ handleSubmit, isValid, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div>
                    <div className='d-flex justify-content-center mt-4'>
                      <SectionTitle>Verify Account</SectionTitle>
                    </div>
                    <SectionText className='mx-3'>
                      Your agent has provided us with the following details. Please verify that this information is
                      correct as it will be on your purchase agreement.
                    </SectionText>
                    <Row className='pt-3'>
                      <FormikField md='6' name='firstName' label='First Name' required />
                      <FormikField md='6' name='lastName' label='Last Name' required />
                    </Row>
                    <Row className='pt-2'>
                      <FormikField md='6' type='phone' name='phone' label='Mobile' required />
                    </Row>
                    <div className='d-flex justify-content-center my-4'>
                      <PrimaryBlueButton type='submit' disabled={!isValid || isLoading || isSubmitting}>
                        Get Started
                      </PrimaryBlueButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </OnboardingContent>
        </MainContainerMd>
      </div>
    </>
  );
}

export default ConsumerOnboarding;
