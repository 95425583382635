import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import NotFoundPage from 'containers/NotFoundPage';
import { OffersAsyncActions } from 'store/actions/offers';
import { ListingsAsyncActions } from 'store/actions/listings';
import { getOffers } from 'store/selectors/offers';
import { getListing } from 'store/selectors/listings';
import PropertyContainer from './contractContainer';

function PropertyContractPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const listingOffers = useSelector(getOffers);
  const listing = useSelector(getListing);
  const { error } = useSelector(ListingsAsyncActions.FetchListing.StatusSelector());

  useEffect(() => {
    dispatch(ListingsAsyncActions.FetchListing.Actions.REQUEST(id));
    dispatch(OffersAsyncActions.ListOffers.Actions.REQUEST(id));

    return () => {
      dispatch(ListingsAsyncActions.FetchListing.Actions.RESET());
      dispatch(OffersAsyncActions.ListOffers.Actions.RESET());
    };
  }, [dispatch, id]);

  if (error?.httpStatus === 404) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>Property contract</title>
        <meta name='description' content='Property contract' />
      </Helmet>
      <div className='shadow-sm border rounded mt-3 mb-5 p-3'>
        {listing && (listingOffers.acceptedOffer || listingOffers.offers.length > 0) && (
          <PropertyContainer
            listing={listing}
            offer={listingOffers.acceptedOffer ? listingOffers.acceptedOffer : listingOffers.offers[0].originalOffer}
          />
        )}
      </div>
    </>
  );
}

export default PropertyContractPage;
