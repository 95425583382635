import { createAsyncAction } from 'utils/store';

export const FetchOffer = createAsyncAction('offers', 'fetchOffer', {
  REQUEST: ['id'],
  SUCCESS: ['offer'],
});

export const ListOffers = createAsyncAction('offers', 'listOffers', {
  REQUEST: ['id', 'actions'],
  SUCCESS: ['offers'],
});

export const UpdateState = createAsyncAction('offers', 'updateState', {
  REQUEST: ['offerId', 'offerState'],
  SUCCESS: ['offer'],
});

export const CreateCounterOffer = createAsyncAction('offers', 'createCounterOffer', {
  REQUEST: ['counterOffer'],
  SUCCESS: ['counterOffer'],
});

export const UpdateCounterOffer = createAsyncAction('offers', 'updateCounterOffer', {
  REQUEST: ['counterOffer'],
  SUCCESS: [],
});

export const FetchGlobalCounterOffer = createAsyncAction('offers', 'fetchGlobalCounterOffer', {
  REQUEST: ['listingId'],
  SUCCESS: ['globalCounter'],
});

export const UpdateGlobalCounterOffer = createAsyncAction('offers', 'updateGlobalCounterOffer', {
  REQUEST: ['globalCounter', 'listingId'],
  SUCCESS: [],
});

export const UpdateViewedOffers = createAsyncAction('offers', 'updateViewedOffers', {
  REQUEST: ['viewedOffers'],
  SUCCESS: [],
});

export const OffersAsyncActions = {
  FetchOffer,
  ListOffers,
  UpdateState,
  CreateCounterOffer,
  UpdateCounterOffer,
  FetchGlobalCounterOffer,
  UpdateGlobalCounterOffer,
  UpdateViewedOffers,
};
