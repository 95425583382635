import React from 'react';
import PropTypes from 'prop-types';
import { URLUtils } from 'utils';
import { EllipsisLinesToolTip, Icon, Typography, Group } from 'components';
import { Container, SectionTitle, SectionItem, SeeMore, OtherTermsText } from './offerCommon.styled';
import { buyerAddendaItemsCA, buyerAddendaItemsFL } from 'constants/offerConstants';
import Swal from 'sweetalert2';

function OfferOtherTermsAddenda({
  isSellerPreference = false,
  placeholder = false,
  listingState,
  offer,
  globalCounter,
  isGlobalCounter,
  isEditing = false,
  onCompareOtherTerms,
}) {
  const handleClickDocument = (doc) => {
    if (doc.url) {
      URLUtils.openDocument(doc.url);
    } else {
      console.error('Cannot find document');
    }
  };

  const buyerAddendaItems = listingState === 'FL' ? buyerAddendaItemsFL : buyerAddendaItemsCA;

  const handleOpenOtherTerms = () => {
    Swal.fire({
      titleText: 'Other Terms',
      text: offer.otherTerms,
      showCloseButton: true,
    });
  };

  return (
    <Container preference={isSellerPreference} $placeholder={placeholder}>
      {!placeholder && (
        <>
          <SectionTitle>
            {isSellerPreference && (
              <Group horizontalGutter={8}>
                <Typography>Other Terms</Typography>
                {onCompareOtherTerms && <Icon name='pencil-square' onClick={onCompareOtherTerms} />}
              </Group>
            )}
            {!isSellerPreference && !isGlobalCounter && !isEditing && !!offer.otherTerms && (
              <Icon name='pencil-square' onClick={handleOpenOtherTerms} />
            )}
          </SectionTitle>
          <SectionItem
            height={78}
            highlight={globalCounter && offer.otherTerms && offer.otherTerms !== globalCounter.otherTerms}
          >
            {!isSellerPreference && !isGlobalCounter && !isEditing && (
              <OtherTermsText>{offer.otherTerms}</OtherTermsText>
            )}
            {isEditing && 'Details being edited'}
          </SectionItem>
          {buyerAddendaItems.map(({ name, label }) => {
            return (
              <>
                <SectionTitle key={`sect-title-${name}`}>{isSellerPreference && label}</SectionTitle>
                <SectionItem key={`sect-item-${name}`} height={78}>
                  {!isSellerPreference && !isGlobalCounter && !isEditing && (
                    <>
                      {offer.buyerAddenda?.[name] && offer.buyerAddenda[`${name}File`] ? (
                        <>
                          <SeeMore
                            key={`see-more-${name}`}
                            onClick={() => handleClickDocument(offer.buyerAddenda[`${name}File`])}
                          >
                            {offer.buyerAddenda[`${name}File`].name}
                          </SeeMore>
                        </>
                      ) : (
                        'Not Specified'
                      )}
                    </>
                  )}
                  {isEditing && 'Details being edited'}
                </SectionItem>
                {name === 'other' && (
                  <>
                    <SectionTitle key={name}>{isSellerPreference && 'Other Description'}</SectionTitle>
                    <SectionItem height={78}>
                      {!isSellerPreference && !isGlobalCounter && !isEditing && (
                        <>
                          {offer.buyerAddenda?.['otherDescription'] ? (
                            <EllipsisLinesToolTip lines={3} text={offer.buyerAddenda?.['otherDescription']} />
                          ) : (
                            'Not Specified'
                          )}
                        </>
                      )}
                      {isEditing && 'Details being edited'}
                    </SectionItem>
                  </>
                )}
              </>
            );
          })}
        </>
      )}
    </Container>
  );
}

OfferOtherTermsAddenda.propTypes = {
  isSellerPreference: PropTypes.bool,
  placeholder: PropTypes.bool,
  offer: PropTypes.object,
};
export default OfferOtherTermsAddenda;
