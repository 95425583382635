import styled from 'styled-components';

export const FooterContainer = styled.div`
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 206px;
  height: 181px;
  min-height: ${({ historyView, mobileView }) => (historyView ? 135 : mobileView ? 81 : 181)}px;
  height: ${({ historyView }) => (historyView ? '135px' : 'unset')};
  padding: 10px 20px;
  border: 1px solid var(--gray6);
  border-radius: 0px 0px 4px 4px;
  margin-left: ${({ preference }) => (preference ? 0 : 10)}px;
  background-color: var(--gray8);
  font-size: 14px;
  line-height: 20px;
`;

export const OfferInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled.button`
  width: 100%;
  max-width: 155px;
  height: 38px;
  margin: 4px 0;
  font-size: 16px;
  text-align: center;
  border: 1px solid ${({ green, red }) => (green ? 'var(--offer1-green)' : red ? 'red' : 'var(--offer1-black)')};
  border-radius: 4px;
  background-color: ${({ green, red }) => (green ? 'var(--offer1-green)' : red ? 'red' : '#fff')};
  color: ${({ white }) => (white ? 'var(--offer1-black)' : '#fff')};
`;

export const OfferIdText = styled.span`
  line-break: anywhere;
`;
