import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';

const getOrder = (listing) => {
  if (listing.state === 'ACTIVE' || listing.state === 'SELLER_REVIEW') {
    // Active
    return 1;
  } else if (
    listing.state === 'SELLER_APPROVED' ||
    listing.state === 'PENDING_SELLER_APPROVAL' ||
    listing.state === 'PENDING_AGENT_UPDATES'
  ) {
    // Pre-marketing
    return 2;
  } else if (listing.state === 'DRAFT') {
    // Draft
    return 3;
  } else if (listing.state === 'PENDING_ESCROW') {
    // Pending
    return 4;
  } else if (listing.state === 'SOLD') {
    // Sold
    return 5;
  } else if (listing.state === 'CANCELLED' || listing.state === 'DELETED') {
    // Deleted
    return 6;
  }
  return 7;
};

function* listListings() {
  const {
    ListListings: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  try {
    const response = yield call(apiCall, '/admin/listings', 'get');
    const listings = response.data.sort((a, b) => getOrder(a) - getOrder(b));
    yield put(SUCCESS(listings));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default listListings;
