import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEnhancedReducer, useAuthUser } from 'hooks';
import { CreateBottomNavBar, CreateTopNavBar, ListingInfo, Divider } from 'components';
import { FaRegSave } from 'react-icons/fa';
import routeConstants from '../App/routeConstants';
import OfferTerms from '../CreateOfferPage/offerTerms';
import { initialOffer } from '../CreateOfferPage/offerObj';
import OfferContact from '../CreateOfferPage/offerContact';
import { CreateOfferAsyncActions } from 'store/actions/createOffer';
import actionType from '../CreateOfferPage/actionTypes';
import { getCreateOffer } from 'store/selectors/createOffer';
import { getLoanTypes, getOwnershipTypes } from 'store/selectors/app';
import Swal from 'sweetalert2';
import moment from 'moment';
import { isNil } from 'lodash';

function offerReducer(state, action) {
  switch (action.type) {
    case actionType.OFFER_INIT:
      return action.payload;

    case actionType.OFFER_UPDATE_TERM:
      return {
        ...state,
        terms: action.payload,
      };
    case actionType.OFFER_UPDATE_CONTACT_INFO:
      return {
        ...state,
        primaryBuyer: {
          ...state.primaryBuyer,
          [action.payload.field]: action.payload.value,
          ...(action.payload.field === 'ownerType' && action.payload.value === 'INDIVIDUAL' && { orgName: '' }),
        },
        ...(action.payload.field === 'ownerType' &&
          action.payload.value !== 'INDIVIDUAL' &&
          action.payload.value !== 'TRUST' && { secondaryBuyers: [] }),
      };

    case actionType.OFFER_UPDATE_SECONDARY_BUYERS:
      return {
        ...state,
        secondaryBuyers: action.payload.value,
      };
    default:
      return state;
  }
}

const END_STEP = 2;

function ConsumerCreateOffer({ existingOffer }) {
  const reduxDispatch = useDispatch();
  const { isSuccess: isCreatedSuccess, error: createError } = useSelector(
    CreateOfferAsyncActions.CreateOffer.StatusSelector()
  );
  const createdOffer = useSelector(getCreateOffer);
  const { isSuccess: isUpdatedSuccess, error: updateError } = useSelector(
    CreateOfferAsyncActions.UpdateOffer.StatusSelector()
  );
  const ownershipPurpose = useSelector(getOwnershipTypes);
  const loanTypes = useSelector(getLoanTypes);
  const history = useHistory();
  const [offer, dispatch] = useEnhancedReducer(offerReducer, initialOffer);
  const { isOfferBuyer } = useAuthUser({ offer });
  const [showSavedNow, setShowSavedNow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const locationState = useLocation().state;
  const { listing, offerCount, highestOffer } = locationState ?? {};
  const [errors, setErrors] = useState({
    primaryBuyer: {},
    terms: {},
  });

  const [stepNumber, setStepNumber] = useState(1);
  const steps = useMemo(
    () => [
      { number: 1, name: 'Contact Information' },
      { number: 2, name: 'Terms of Offer' },
    ],
    []
  );

  useEffect(() => {
    return () => {
      reduxDispatch(CreateOfferAsyncActions.FetchPreFilledOffer.Actions.RESET());
      reduxDispatch(CreateOfferAsyncActions.CreateOffer.Actions.RESET());
      reduxDispatch(CreateOfferAsyncActions.UpdateOffer.Actions.RESET());
    };
  }, [reduxDispatch]);

  useEffect(() => {
    if (listing && !existingOffer) {
      reduxDispatch(CreateOfferAsyncActions.FetchPreFilledOffer.Actions.REQUEST(listing.id));
    }
    // eslint-disable-next-line
  }, [listing, reduxDispatch]);

  const setOffer = useCallback(
    (value) => {
      dispatch({
        type: actionType.OFFER_INIT,
        payload: value,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (isCreatedSuccess || isUpdatedSuccess) {
      setOffer(createdOffer);
      setShowSavedNow(true);
      if (isCreatedSuccess && isSubmitted) {
        Swal.fire({
          title: 'Offer Sent',
          text: 'Your provisional Offer has been created and saved. Click below to be taken to your dashboard and await next steps.',
          confirmButtonText: 'Continue',
        }).then(() => {
          history.push(routeConstants.OFFERS_PATH);
        });
      }
    }
  }, [setOffer, createdOffer, isSubmitted, isCreatedSuccess, isUpdatedSuccess, history]);

  useEffect(() => {
    if (offer.id || existingOffer) {
      setStepNumber(END_STEP);
    }
  }, [offer, createdOffer, existingOffer]);

  const saveOffer = (offer) => {
    setShowSavedNow(false);
    if (offer.id == null) {
      const newOffer = { ...offer, listingId: listing.id };
      reduxDispatch(CreateOfferAsyncActions.CreateOffer.Actions.REQUEST(newOffer));
    } else {
      reduxDispatch(CreateOfferAsyncActions.UpdateOffer.Actions.REQUEST(offer));
    }
  };

  const handleBack = () => {
    if (stepNumber > 0) {
      if (checkRequirements(stepNumber)) {
        return;
      }
      setStepNumber(stepNumber - 1);
      saveOffer(offer);
    }
  };

  const handleContinue = () => {
    if (stepNumber < steps.length) {
      if (checkRequirements(stepNumber)) {
        return;
      }
      setStepNumber(stepNumber + 1);
      saveOffer(offer);
    }
  };

  const checkComments = (comments) => {
    return comments && comments.trim().length > 500 ? true : false;
  };

  const checkError = (err, selectors) => {
    return Object.keys(err).some((key) => {
      const el = err[key];
      if (typeof el === 'object') {
        return checkError(el);
      }
      if (el) {
        Swal.fire({
          icon: 'warning',
          title: 'Some Answers Required',
          text: 'Please fill out all fields marked with an asterisk to continue.',
          confirmButtonText: 'Continue',
        }).then(() => {
          setTimeout(() => {
            document.querySelector(`${selectors?.[key] || '#' + key}`)?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }, 300);
        });
      }
      return !!el;
    });
  };

  const checkRequirements = (step) => {
    switch (step) {
      case 1: {
        const { primaryBuyer } = offer;
        const primaryBuyerError = {
          primaryBuyer: !primaryBuyer || !primaryBuyer.ownerType,
          user: {
            firstName: !primaryBuyer?.user?.firstName && 'First Name is required',
            lastName: !primaryBuyer?.user?.lastName && 'Last Name is required',
            email: !primaryBuyer?.user?.email
              ? 'Email is required'
              : primaryBuyer?.user?.email === listing?.listingAgent?.email
              ? "You cannot use the seller agent's email"
              : '',
            phone: !primaryBuyer?.user?.phone && 'Mobile is required',
          },
          certifyBuyer: primaryBuyer?.certifyBuyer !== true,
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          primaryBuyer: primaryBuyerError,
        }));
        return checkError(primaryBuyerError);
      }
      case 2: {
        const { terms } = offer;
        const termsError = {
          ownershipPurpose: !terms?.ownershipPurpose,
          offerAmount: !terms?.offerAmount,
          useDaysToClose: isNil(terms?.useDaysToClose),
          daysToClose: !isNil(terms?.useDaysToClose) && !terms?.daysToClose,
          exactClosingDate:
            terms?.useDaysToClose === false &&
            !moment(moment(terms?.exactClosingDate).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid(),
          loan: isNil(terms?.loan),
          loanType: terms?.loan && !terms?.loanType,
          preapproved: terms?.loan && isNil(terms?.preapproved),
          downPaymentPercentage: terms?.loan && !terms?.downPaymentPercentage,
          downPaymentDollar: terms?.loan && !terms?.downPaymentDollar,
          loanAmountPercentage: terms?.loan && !terms?.loanAmountPercentage,
          loanAmountDollar: terms?.loan && !terms?.loanAmountDollar,
          earnestDepositPercentage: !terms?.earnestDepositPercentage,
          earnestDepositDollar: !terms?.earnestDepositDollar,
          termsComments: checkComments(terms?.termsComments),
        };
        setErrors((prevErrors) => ({
          ...prevErrors,
          terms: termsError,
        }));
        return checkError(termsError);
      }

      default:
        return false;
    }
  };

  const handleSave = () => {
    setIsSubmitted(true);
    saveOffer(offer);
  };

  const handleOfferTermsChange = (terms) => {
    dispatch({
      type: actionType.OFFER_UPDATE_TERM,
      payload: terms,
    });
    setShowSavedNow(false);
  };

  const handlePrimaryOwnerChange = (data) => {
    setShowSavedNow(false);
    dispatch({
      type: actionType.OFFER_UPDATE_CONTACT_INFO,
      payload: data,
    });
  };

  const handleSecondaryOwnerChange = (data) => {
    setShowSavedNow(false);
    dispatch({
      type: actionType.OFFER_UPDATE_SECONDARY_BUYERS,
      payload: data,
    });
  };

  if (!locationState) {
    return <Redirect to={routeConstants.OFFERS_PATH} />;
  }

  const isError = !!createError || !!updateError;

  return (
    <React.Fragment>
      <React.Fragment>
        <ListingInfo
          address={listing.address}
          price={listing.price}
          offerCount={offerCount}
          highestOffer={highestOffer}
          globalCounterState={listing.globalCounter?.globalCounterDetails?.state}
        />
        {isError && <h4 className='mx-auto mt-4'>Something went wrong with saving your offer</h4>}
        {!isError && (
          <div className='m-4'>
            <React.Fragment>
              <div className='create-listing-step'>
                <CreateTopNavBar
                  showRescind={stepNumber === END_STEP && isOfferBuyer && offer.state !== 'PROVISIONAL'}
                  showDelete={stepNumber === END_STEP}
                  comment={offer.state === 'BUYER_REJECTED' && offer.comment ? offer.comment : null}
                />
                {stepNumber === 1 && (
                  <OfferContact
                    primaryBuyer={offer.primaryBuyer}
                    secondaryBuyers={offer.secondaryBuyers}
                    onStateChanged={handlePrimaryOwnerChange}
                    onSecondaryBuyerChanged={handleSecondaryOwnerChange}
                    errors={errors?.primaryBuyer}
                  />
                )}
                {stepNumber === 2 && (
                  <OfferTerms
                    listingState={listing?.address?.state}
                    listingId={listing?.id}
                    terms={offer.terms}
                    ownershipPurpose={ownershipPurpose}
                    loanTypes={loanTypes}
                    proofOfFundsRequired={listing?.proofOfFundsRequired}
                    sellerFinancingAvailable={listing?.sellerFinancingAvailable}
                    onStateChanged={handleOfferTermsChange}
                    errors={errors?.terms}
                  />
                )}

                <Divider />
                <CreateBottomNavBar
                  showBack={stepNumber > 1 && offer.state !== 'PENDING_BUYER_APPROVAL'}
                  isEnd={stepNumber === steps.length}
                  isReview={stepNumber === 2}
                  onBack={handleBack}
                  onContinue={handleContinue}
                  onSave={handleSave}
                  onSaveDraft={handleSave}
                  onUpdateStatus={handleSave}
                  isOfferBuyer={false}
                  isBuyerAgent={false}
                  status={offer.state}
                  isListing={false}
                  showContinue={stepNumber !== steps.length}
                  canSubmit={offer.state === 'PROVISIONAL'}
                  consumer={true}
                />
                {showSavedNow && (
                  <div className='mt-1 ms-2 d-flex align-items-center'>
                    <FaRegSave size={20} className='me-2' />
                    Saved Now
                  </div>
                )}
              </div>
            </React.Fragment>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

export default ConsumerCreateOffer;
