import { all, takeLatest } from 'redux-saga/effects';
import { AgentAsyncActions } from 'store/actions/agent';
import fetchQuestionnaire from './fetchQuestionnaire';
import recordAgentQuestionnaire from './recordAgentQuestionnaire';
import sendMessage from './sendMessage';

export default function* root() {
  const { FetchQuestionnaire, RecordQuestionnaire, SendMessage } = AgentAsyncActions;
  yield all([
    takeLatest(FetchQuestionnaire.Types.REQUEST, fetchQuestionnaire),
    takeLatest(RecordQuestionnaire.Types.REQUEST, recordAgentQuestionnaire),
    takeLatest(SendMessage.Types.REQUEST, sendMessage),
  ]);
}
