import { call, put } from 'redux-saga/effects';
import { AuthAsyncActions } from 'store/actions/auth';
import { apiCall } from 'utils/apiCall';
import routes from 'containers/App/routeConstants';
import { push } from 'connected-react-router';

function* forgotPassword(action) {
  const {
    ForgotPassword: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AuthAsyncActions;
  const {
    payload: { email },
  } = action;

  try {
    yield call(apiCall, `/users/forgot-password?email=${email}`, 'post');
    yield put(SUCCESS());
    yield put(push(routes.FORGOT_PASSWORD_SUCCESS_PATH));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default forgotPassword;
