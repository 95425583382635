import React from 'react';
import { ItemCost, ItemName, ItemSeparator, ReceiptCardContainer } from './receipt.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ReceiptPanel({ itemName, itemPrice }) {
  return (
    <ReceiptCardContainer className='justify-content-md-center'>
      <Row className='justify-content-md-center'>
        <Col md='auto'>
          <ItemName>{itemName}</ItemName>
        </Col>
        <Col md='auto'>
          <ItemSeparator>................................</ItemSeparator>
        </Col>
        <Col sm={2}>
          <ItemCost>{`$${itemPrice}`}</ItemCost>
        </Col>
      </Row>
    </ReceiptCardContainer>
  );
}

export default ReceiptPanel;
