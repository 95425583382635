import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';

import createRootReducer from 'store/reducers';
import rootSaga from 'store/sagas';

export const history = createBrowserHistory();

const createReduxStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = [applyMiddleware(routerMiddleware(history), sagaMiddleware, thunkMiddleware)];
  if (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(createRootReducer(history), compose(...enhancer));

  sagaMiddleware.run(rootSaga);

  return store;
};

export default createReduxStore;
