import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAuthUser } from 'hooks';
import AgentProfile from './agentProfile';
import ConsumerProfile from './consumerProfile';
import { Header, TabBar, QuestionnaireModal } from 'components';
import { getMetaListingStates, getMetaStates } from 'store/selectors/app';
import { AuthAsyncActions } from 'store/actions/auth';
import { AgentAsyncActions } from 'store/actions/agent';
import { SubscriptionsAsyncActions } from 'store/actions/subscriptions';
import { getSubscription, getPricing } from 'store/selectors/subscriptions';
import { ProfileContainer } from './agentProfilePage.styled';
import ProfilePicture from './profilePicture';
import { MainContainer } from 'containers/App/mainLayout.styled';
import { URLUtils } from 'utils';

function ProfilePage() {
  const dispatch = useDispatch();
  const { user, isAgent, isListingAgent } = useAuthUser();
  const listingStates = useSelector(getMetaListingStates);
  const allStates = useSelector(getMetaStates);
  const { isSuccess, error } = useSelector(AuthAsyncActions.UpdateAccount.StatusSelector());
  const [tab, setTab] = useState('consumer');
  const [showAgentApplication, setShowAgentApplication] = useState(false);

  const tabs = useMemo(
    () => [
      { name: 'Personal Info', value: 'consumer' },
      { name: 'Agent Details', value: 'agent' },
      { name: 'Billing', value: 'billing' },
    ],
    []
  );

  const consumerTabs = useMemo(
    () => [
      { name: 'Personal Info', value: 'consumer' },
      { name: 'Billing', value: 'billing' },
    ],
    []
  );

  useEffect(() => {
    return () => {
      dispatch(AuthAsyncActions.UpdateAccount.Actions.RESET());
      dispatch(AgentAsyncActions.RecordQuestionnaire.Actions.RESET());
    };
  }, [dispatch]);

  const subscription = useSelector(getSubscription);

  useEffect(() => {
    dispatch(SubscriptionsAsyncActions.FetchSubscription.Actions.REQUEST());

    return () => {
      dispatch(SubscriptionsAsyncActions.FetchSubscription.Actions.RESET());
    };
  }, [dispatch]);

  const pricing = useSelector(getPricing);

  useEffect(() => {
    dispatch(SubscriptionsAsyncActions.FetchPricing.Actions.REQUEST());

    return () => {
      dispatch(SubscriptionsAsyncActions.FetchPricing.Actions.RESET());
    };
  }, [dispatch]);

  const updateUser = useCallback(
    (user) => {
      dispatch(AuthAsyncActions.UpdateAccount.Actions.REQUEST(user));
    },
    [dispatch]
  );

  const recordAgentQuestionnaire = useCallback(
    (questionnaire) => {
      setShowAgentApplication(false);
      dispatch(
        AgentAsyncActions.RecordQuestionnaire.Actions.REQUEST({
          ...questionnaire,
        })
      );
    },
    [dispatch]
  );

  const onChangeTab = (newTab) => {
    if ('billing' === newTab && subscription) {
      URLUtils.openNewWindow('https://billing.stripe.com/p/login/7sI02RcU6c436zKdQQ');
    } else {
      setTab(newTab);
    }
  };

  const tabBar = (
    <TabBar
      tabs={user?.agent ? tabs : consumerTabs}
      value={tab}
      updateStatus={isSuccess ? 'Profile has been updated' : error ? 'Error updating user profile' : ''}
      onChange={onChangeTab}
    />
  );

  return (
    <MainContainer>
      <Header
        title='Your Profile'
        ctas={
          !isListingAgent && isAgent
            ? [
                {
                  title: user.agent?.questionnaire ? 'View List Agent Application' : 'Apply To Be A List Agent',
                  variant: 'primary',
                  onClick: () => setShowAgentApplication(true),
                },
              ]
            : null
        }
      />
      {isAgent && tabBar}
      <ProfileContainer
        className={classNames('shadow-sm border rounded p-4', {
          'mt-3': !isAgent,
        })}
      >
        <Row>
          <Col md={8}>
            <ProfilePicture
              onChange={(file) =>
                updateUser({
                  ...user,
                  profilePictureUrl: file?.url ?? '',
                })
              }
              src={user.profilePictureUrl}
              agent={isAgent}
            />
          </Col>
          <Col className='offset-md-4'></Col>
          <Col md={12}>
            {tab === 'consumer' && <ConsumerProfile user={user} onUpdateUser={updateUser} allStates={allStates} />}
            {tab === 'agent' && (
              <AgentProfile user={user} onUpdateUser={updateUser} listingStates={listingStates} allStates={allStates} />
            )}
            {tab === 'billing' && !subscription && (
              <stripe-pricing-table
                pricing-table-id={pricing?.pricingTableId ?? process.env.REACT_APP_PRODUCT_TABLE}
                publishable-key={process.env.REACT_APP_STRIPE_KEY}
                client-reference-id={user?.id}
                customer-email={user?.email}
              ></stripe-pricing-table>
            )}
          </Col>
        </Row>

        <QuestionnaireModal
          show={showAgentApplication}
          title='Listing Agent Application'
          subTitle='Please complete the questions below as accurately as possible.'
          onClose={() => setShowAgentApplication(false)}
          saveButtonLabel={user?.agent?.questionnaire ? 'Save' : 'Submit'}
          onSaveAgentQuestionnaire={recordAgentQuestionnaire}
          initialQuestionnaire={user?.agent?.questionnaire}
        />
      </ProfileContainer>
    </MainContainer>
  );
}

export default ProfilePage;
