import React from 'react';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { FaCameraRetro } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import OfferFieldSummary from 'containers/CreateOfferPage/offerFieldSummary';
import { Icon, Collapse, PropertyItem, FileUpload, FileSelect, CheckBox, Typography } from 'components';
import { formatCurrency, formatPhoneNumber, joinString, URLUtils } from 'utils';
import OfferDisplayFields from 'containers/CreateOfferPage/offerDisplayFields';
import { Header, CheckBoxContainer } from './listingReview.styled';
import {
  listingReviewSteps,
  propertyAddendaItemsCA,
  advisoriesItemsCA,
  propertyAddendaItemsFL,
  advisoriesItemsFL,
} from 'constants/listingConstants';
import { isNil } from 'lodash';

function ListingReview({ listing, onHandleJumpToStep, isListingAgent, onEditUrl }) {
  const onEdit = (step) => (e) => {
    e.stopPropagation();
    onHandleJumpToStep(step);
  };

  const renderHeader = (step, onClick) => (
    <>
      <div className='d-flex align-items-center me-2'>
        <span className='step-number'>{step}</span>
        <span>{listingReviewSteps[step - 1].name}</span>
      </div>
      <div className='d-flex align-items-center justify-content-end'>
        {isListingAgent && step !== listingReviewSteps.length && (
          <Button variant='light' onClick={onClick || onEdit(step)}>
            <MdEdit />
            <span className='ms-2'>Edit</span>
          </Button>
        )}
        <Icon className='ms-3' name='chevron-down' />
      </div>
    </>
  );

  const {
    comment,
    price,
    propertyType,
    squareFeet,
    numberBedrooms,
    numberBaths,
    address,
    description,
    primaryImage,
    hasIncludedItems,
    includedStateSpecifiedItems,
    includedItems,
    hasExcludedItems,
    excludedItems,
    rpaBreachContract,
    rpaDisputeResolution,
    proofOfFundsRequired,
    sellerFinancingAvailable,
    inspectionReport,
    termiteReport,
    questionnaireReport,
    titlePrelimReport,
    listingReports,
    titleCompany,
    escrowCompany,
    hazardCompany,
    primaryOwner,
    secondaryOwners,
    propertyAddenda,
    remainInPropertyAnswer,
    remainInPropertyFile,
    customAddendum,
    customAddendumFile,
    advisories,
    customListingUrl,
    listingAgent,
    legalDescription,
    // Florida Properties
    tenantOccupiedPostCoe,
    assignNo,
    assignLiability,
  } = listing ?? {};

  const isCalifornia = address.state === 'CA';
  const isFlorida = address.state === 'FL';
  const propertyAddendaItems = isFlorida ? propertyAddendaItemsFL : propertyAddendaItemsCA;
  const advisoriesItems = isFlorida ? advisoriesItemsFL : advisoriesItemsCA;

  return (
    <>
      {!comment && (
        <Typography className='ms-0 ms-sm-5' color='danger'>
          {`Scroll down to review and take action${
            isListingAgent ? '.' : '. Contact your List Agent to edit any details of this Property.'
          }`}
        </Typography>
      )}
      {comment && (
        <>
          <Typography className='ms-0 ms-sm-5' color='danger' bold>
            Address your seller's reasons for rejection below
          </Typography>
          <div className='p-2'>
            <Typography color='danger' bold underline>
              Seller Comments:
            </Typography>
            &nbsp;
            <Typography italic>{comment}</Typography>
          </div>
        </>
      )}
      <Collapse headerClassName='mt-3' header={Header} headerContent={renderHeader(1)} bodyClassName='border border-1'>
        <div className='mx-4 mb-3'>
          <Row>
            <OfferFieldSummary sm={6} title='Listing Details'>
              {price ? formatCurrency(price, 0) : '$TBD'}
              <br />
              {propertyType}
              <br />
              {`${squareFeet} sq. ft`}
              <br />
              {`${numberBedrooms} bedrooms / ${numberBaths} bathrooms`}
            </OfferFieldSummary>
            <OfferFieldSummary sm={6} title='Address'>
              {address.addressLine1}
              <br />
              {address.addressLine2 && (
                <>
                  {address.addressLine2}
                  <br />
                </>
              )}
              {address.city}
              {', '}
              {address.county && address.county + ', '}
              {address.state}
              {', '}
              {address.zip}
              {address?.apn && (
                <>
                  <br />
                  {`APN/Tax ID: ${address.apn}`}
                </>
              )}
            </OfferFieldSummary>
            <OfferFieldSummary sm={12} title='Property Legal Description'>
              {legalDescription}
            </OfferFieldSummary>
            <OfferFieldSummary sm={12} title='Property Description'>
              {description}
            </OfferFieldSummary>
            <OfferFieldSummary sm={12} title='Property Image'>
              {primaryImage && (
                <FileUpload className='ms-2' icon={FaCameraRetro} title='Add Photo' file={primaryImage} readOnly />
              )}
              {!primaryImage && 'No Photos'}
            </OfferFieldSummary>
          </Row>
        </div>
      </Collapse>
      <Collapse header={Header} headerContent={renderHeader(2)} bodyClassName='border border-1 px-4 pb-3'>
        <Row>
          {isCalifornia && (
            <>
              <OfferFieldSummary md={4} sm={6} title='Liquidated Damages Clause'>
                {rpaBreachContract === null ? 'N/A' : rpaBreachContract ? 'Yes' : 'No'}
              </OfferFieldSummary>
              <OfferFieldSummary md={4} sm={6} title='Agreement to Arbitrate Clause'>
                {rpaDisputeResolution === null ? 'N/A' : rpaDisputeResolution ? 'Yes' : 'No'}
              </OfferFieldSummary>
            </>
          )}
          {isFlorida && (
            <>
              <OfferFieldSummary
                sm={6}
                title='Will the property be subject to lease or occupancy from a 3rd party after the close of escrow?'
              >
                {tenantOccupiedPostCoe === null ? 'Not Specified' : tenantOccupiedPostCoe ? 'Yes' : 'No'}
              </OfferFieldSummary>
              <OfferFieldSummary sm={6} title='May this contract be assigned?'>
                {assignNo === null
                  ? 'Not Specified'
                  : assignNo
                  ? `${[
                      joinString(
                        [
                          'Yes',
                          assignLiability === 'ASSIGN_YES_NO_LIABILITY'
                            ? 'Assigned with NO further liability'
                            : assignLiability === 'ASSIGN_YES_LIABILITY'
                            ? 'Assigned with liability'
                            : '',
                        ],
                        ', '
                      ),
                    ]}`
                  : 'No'}
              </OfferFieldSummary>
            </>
          )}
          <OfferFieldSummary sm={6} title='Require proof of funds and pre-approval for all offers'>
            {proofOfFundsRequired === null ? 'N/A' : proofOfFundsRequired ? 'Yes' : 'No'}
          </OfferFieldSummary>
          {isFlorida && (
            <OfferFieldSummary sm={6} title='Could a buyer request seller financing on their offer?'>
              {sellerFinancingAvailable === null ? 'N/A' : sellerFinancingAvailable ? 'Yes' : 'No'}
            </OfferFieldSummary>
          )}
        </Row>
      </Collapse>
      <Collapse header={Header} headerContent={renderHeader(3)} bodyClassName='border border-1 px-4 pb-3'>
        <Row>
          {(hasIncludedItems || includedStateSpecifiedItems) && (
            <OfferFieldSummary sm={6} title={isFlorida ? 'Additional Included Items' : 'Included Items'}>
              {!isFlorida &&
                includedStateSpecifiedItems?.map((item, index) => (
                  <PropertyItem key={`${item.name}-${index}`} name={item.name} included />
                ))}
              {hasIncludedItems &&
                includedItems?.map((item, index) => <PropertyItem key={`${item.name}-${index}`} name={item.name} />)}
            </OfferFieldSummary>
          )}
          {hasExcludedItems && (
            <OfferFieldSummary sm={6} title='Excluded Items'>
              {excludedItems?.map((item, index) => (
                <PropertyItem key={`${item.name}-${index}`} name={item.name} />
              ))}
            </OfferFieldSummary>
          )}
        </Row>
      </Collapse>
      <Collapse header={Header} headerContent={renderHeader(4)} bodyClassName='border border-1 px-4 pb-3'>
        {inspectionReport && (
          <FileSelect type='INSPECTION' title='Inspection Report' file={inspectionReport} readOnly />
        )}
        {termiteReport && <FileSelect type='TERMITE' title='Termite Report' file={termiteReport} readOnly />}
        {questionnaireReport && (
          <FileSelect type='QUESTIONNAIRE' title='Seller Property Questionnaire' file={questionnaireReport} readOnly />
        )}
        {titlePrelimReport && <FileSelect type='TITLE_PRELIM' title='Title Prelim' file={titlePrelimReport} readOnly />}
        {listingReports?.length > 0 && (
          <FileSelect className='ms-2' title='Other Reports' file={listingReports} multiple readOnly />
        )}
        {titleCompany && (
          <Row className='mt-3'>
            <OfferFieldSummary title='Title Company'>
              {titleCompany.name && (
                <>
                  {titleCompany.name}
                  <br />
                </>
              )}
              {titleCompany.officerName && (
                <>
                  {titleCompany.officerName}
                  <br />
                </>
              )}
              {titleCompany.email && (
                <>
                  {titleCompany.email}
                  <br />
                </>
              )}
              {formatPhoneNumber(titleCompany.phone)}
            </OfferFieldSummary>
          </Row>
        )}
        {escrowCompany && (
          <Row className='mt-3'>
            <OfferFieldSummary title='Escrow Company'>
              {escrowCompany.name && (
                <>
                  {escrowCompany.name}
                  <br />
                </>
              )}
              {escrowCompany.officerName && (
                <>
                  {escrowCompany.officerName}
                  <br />
                </>
              )}
              {escrowCompany.email && (
                <>
                  {escrowCompany.email}
                  <br />
                </>
              )}
              {formatPhoneNumber(escrowCompany.phone)}
            </OfferFieldSummary>
          </Row>
        )}
        {isCalifornia && hazardCompany && (
          <Row className='mt-3'>
            <OfferFieldSummary title='Hazard Company'>{hazardCompany.name}</OfferFieldSummary>
          </Row>
        )}
      </Collapse>
      <Collapse header={Header} headerContent={renderHeader(5)} bodyClassName='border border-1 px-4 pb-3'>
        <Row>
          <OfferFieldSummary sm={6} title='Owner'>
            {listing.ownerType && listing.ownerType !== 'INDIVIDUAL' && (
              <div>
                It's a {listing.ownerType.toLowerCase()}: {listing.orgName}
              </div>
            )}
            <div>
              {primaryOwner?.user?.firstName} {primaryOwner?.user?.lastName}
            </div>
            {primaryOwner?.user?.email && <div>{primaryOwner.user.email}</div>}
            {primaryOwner?.user?.phone && <div>{formatPhoneNumber(primaryOwner.user.phone)}</div>}
            <div>{primaryOwner?.address?.addressLine1}</div>
            {primaryOwner?.address?.addressLine2 && <div>{primaryOwner.address.addressLine2}</div>}
            {primaryOwner?.address?.city}
            {', '}
            {primaryOwner?.address?.state} {primaryOwner?.address?.zip}
          </OfferFieldSummary>
        </Row>
        {secondaryOwners?.map((owner) => (
          <Row className='mx-1 mb-3'>
            <OfferFieldSummary sm={6} title='Additional Owner'>
              {owner?.user?.firstName} {owner?.user?.lastName}
              <br />
              {owner?.user?.email}
              <br />
              {formatPhoneNumber(owner?.user?.phone)}
              <br />
              {owner?.address?.addressLine1} {owner?.address?.addressLine2}
              <br />
              {owner?.address?.city}
              {', '}
              {owner?.address?.state} {owner?.address?.zip}
            </OfferFieldSummary>
          </Row>
        ))}
      </Collapse>
      <Collapse header={Header} headerContent={renderHeader(6)} bodyClassName='border border-1 px-4 pb-3'>
        <Row>
          <OfferFieldSummary sm={12} md={6} title='Is the property subject to any of the following property addenda?'>
            <CheckBoxContainer>
              {propertyAddendaItems.map(
                (item) =>
                  propertyAddenda?.[item.name] && (
                    <React.Fragment key={`addenda-${item.name}`}>
                      <CheckBox name={item.name} checked={propertyAddenda?.[item.name]} readOnly>
                        {item.label}
                        {item.name === 'other' && <span>({propertyAddenda?.['otherDescription']})</span>}
                      </CheckBox>
                      {propertyAddenda?.[`${item.name}File`] && (
                        <PropertyItem
                          included
                          name={propertyAddenda?.[`${item.name}File`].name}
                          onClick={() => URLUtils.openDocument(propertyAddenda?.[`${item.name}File`].url)}
                        />
                      )}
                      {propertyAddenda?.[item.name] && !propertyAddenda?.[`${item.name}File`] && (
                        <span className='text-danger'>***Form NEEDED</span>
                      )}
                    </React.Fragment>
                  )
              )}
            </CheckBoxContainer>
          </OfferFieldSummary>
          {isCalifornia && (
            <>
              <OfferFieldSummary
                sm={12}
                md={6}
                title='Does the Seller wish to remain in the property after the close of escrow?'
              >
                {isNil(remainInPropertyAnswer) && 'Not specified'}
                {remainInPropertyAnswer === 'no' && 'No'}
                {(remainInPropertyAnswer === 'less_30' || remainInPropertyAnswer === 'more_30') && (
                  <>
                    {remainInPropertyAnswer === 'less_30'
                      ? 'Yes, 29 days or less use Seller in Possession (C.A.R from SIP)'
                      : 'Yes, 30 days or more use Lease After Sale (C.A.R form RLAS)'}
                    {remainInPropertyFile ? (
                      <PropertyItem
                        included
                        name={remainInPropertyFile.name}
                        onClick={() => URLUtils.openDocument(remainInPropertyFile.url)}
                      />
                    ) : (
                      <span className='text-danger'>***Form NEEDED</span>
                    )}
                  </>
                )}
              </OfferFieldSummary>
              <OfferFieldSummary sm={12} md={6} title='Do you wish to add a custom Addendum?'>
                {isNil(customAddendum) && 'Not specified'}
                {!isNil(customAddendum) && (customAddendum ? 'Yes' : 'No')}
                {customAddendumFile ? (
                  <PropertyItem
                    included
                    name={customAddendumFile.name}
                    onClick={() => URLUtils.openDocument(customAddendumFile.url)}
                  />
                ) : !isNil(customAddendum) && customAddendum ? (
                  <span className='text-danger'>***Form NEEDED</span>
                ) : (
                  <></>
                )}
              </OfferFieldSummary>
            </>
          )}
          <OfferFieldSummary sm={12} md={6} title='Do you wish to include any of the following Advisories?'>
            <CheckBoxContainer>
              {advisoriesItems.map(
                (item) =>
                  advisories?.[item.name] && (
                    <CheckBox key={`advisory-${item.name}`} name={item.name} checked={advisories?.[item.name]} readOnly>
                      {item.label}
                    </CheckBox>
                  )
              )}
            </CheckBoxContainer>
          </OfferFieldSummary>
        </Row>
      </Collapse>
      <Collapse
        header={Header}
        headerContent={renderHeader(7, (e) => {
          e.stopPropagation();
          onEditUrl();
        })}
        bodyClassName='border border-1 px-3 pb-3'
      >
        <Row>
          <OfferDisplayFields title='Listing URL'>{customListingUrl}</OfferDisplayFields>
        </Row>
      </Collapse>
      <Collapse header={Header} headerContent={renderHeader(8)} bodyClassName='border border-1 px-4 pb-3'>
        <Row>
          <OfferFieldSummary sm={6} title='Agent'>
            {listingAgent?.firstName} {listingAgent?.lastName}
            <br />
            {listingAgent?.email}
            <br />
            {formatPhoneNumber(listingAgent?.phone)}
            <br />
            {listingAgent?.agent?.licenseNumber}
            <br />
            {listingAgent?.agent?.brokerage?.name}
            <br />
            {listingAgent?.agent?.brokerage?.licenseNumber}
          </OfferFieldSummary>
        </Row>
      </Collapse>
    </>
  );
}

export default ListingReview;
