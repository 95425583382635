import { all, takeLatest } from 'redux-saga/effects';
import { ContractAsyncActions } from 'store/actions/contract';
import fetch from './fetch';
import update from './update';
import updateState from './updateState';
import signContract from './signContract';

export default function* root() {
  const { FetchListingContract, SaveListingContract, SaveListingContractState, SaveSignatureContract } =
    ContractAsyncActions;
  yield all([
    takeLatest(FetchListingContract.Types.REQUEST, fetch),
    takeLatest(SaveListingContract.Types.REQUEST, update),
    takeLatest(SaveListingContractState.Types.REQUEST, updateState),
    takeLatest(SaveSignatureContract.Types.REQUEST, signContract),
  ]);
}
