import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { Header, CardDeck, BuyerCard, OfferCard, Empty } from 'components';
import { useAuthUser } from 'hooks';
import ActiveOfferModal from './activeOfferModal';
import { BuyersAsyncActions } from 'store/actions/buyers';
import { getBuyers, getBuyerOffers } from 'store/selectors/buyers';
import { MainContainer } from 'containers/App/mainLayout.styled';
import routes from 'containers/App/routeConstants';
import { URLUtils } from 'utils';

function OfferDashboardPage() {
  const dispatch = useDispatch();
  const buyers = useSelector(getBuyers);
  const buyersOffers = useSelector(getBuyerOffers);
  const [activeBuyer, setActiveBuyer] = useState();
  const { user, isAgent } = useAuthUser();

  useEffect(() => {
    dispatch(BuyersAsyncActions.ListBuyers.Actions.REQUEST());
    dispatch(BuyersAsyncActions.FetchBuyerOffers.Actions.REQUEST());

    return () => {
      dispatch(BuyersAsyncActions.ListBuyers.Actions.RESET());
      dispatch(BuyersAsyncActions.FetchBuyerOffers.Actions.RESET());
    };
  }, [dispatch]);

  const handleActionRequired = (buyer) => {
    setActiveBuyer(buyer);
  };

  return (
    <>
      <Helmet>
        <title>{isAgent ? 'Your Buyers' : 'Your Offers'}</title>
        <meta name='description' content='Your Offers' />
      </Helmet>
      <MainContainer>
        <Header
          title={isAgent ? 'Your Buyers' : 'Your Offers'}
          ctas={[{ title: 'Create an Offer', to: routes.SEARCH_PAGE }]}
        />
        {buyers?.length === 0 && buyersOffers?.length === 0 && (
          <Empty
            src={URLUtils.getPublicImageUrl('homesearch-man.svg')}
            width={229}
            height={219}
            title='Looking for a property?'
            description={`Get started by clicking 'Create an Offer' in the top right corner.`}
          />
        )}
        {activeBuyer === undefined && buyers?.length > 0 && (
          <>
            <CardDeck center className='bg-light w-100 my-3 py-3'>
              {buyers.map((buyer) => (
                <BuyerCard buyer={buyer} key={buyer.user.id} onActionRequired={() => handleActionRequired(buyer)} />
              ))}
            </CardDeck>
          </>
        )}
        {activeBuyer && <ActiveOfferModal onClose={() => setActiveBuyer(undefined)} buyer={activeBuyer} />}
        {!isAgent && buyersOffers?.length > 0 && (
          <CardDeck className='my-3'>
            {buyersOffers.map((offer) => (
              <OfferCard key={offer.listing.id} offer={offer} user={user} />
            ))}
          </CardDeck>
        )}
      </MainContainer>
    </>
  );
}

export default OfferDashboardPage;
