import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import Helmet from 'react-helmet';
import { Header, ItemStatus, AgentName } from './adminPage.styled';
import { AdminAsyncActions } from 'store/actions/admin';
import { AuthAsyncActions } from 'store/actions/auth';
import { getUsers, getAgents } from 'store/selectors/admin';
import AdminTable from './adminTable';
import { SelectColumnFilter } from './filters';
import { QuestionnaireModal, PrimaryGreenButton, OutGrayButton } from 'components';
import { formatPhoneNumber } from 'utils';
import moment from 'moment';

export default function UsersAdminPage({ isAgent }) {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const dispatch = useDispatch();
  const [questionnaire, setQuestionnaire] = useState(null);

  const handleShowQuestionnaire = (cell) => {
    setQuestionnaire(cell.row.original.agent.questionnaire);
    setShowQuestionnaire(true);
  };

  const handleResetUserPassword = useCallback(
    (email) => dispatch(AdminAsyncActions.ResetPassword.Actions.REQUEST(email)),
    [dispatch]
  );

  const login = useCallback(
    (userEmail) => dispatch(AuthAsyncActions.ImpostorLogin.Actions.REQUEST(userEmail)),
    [dispatch]
  );

  const handleDeleteUser = useCallback((id) => dispatch(AdminAsyncActions.DeleteUser.Actions.REQUEST(id)), [dispatch]);

  const handleApproveListingAgent = useCallback(
    (userId, isDelete = false) => dispatch(AdminAsyncActions.UpdateUserRole.Actions.REQUEST(userId, isDelete)),
    [dispatch]
  );

  const columns = useMemo(
    () => [
      ...(!isAgent
        ? [
            {
              Header: 'Status',
              accessor: 'status',
              Cell: ({ cell: { value } }) => <ItemStatus active={value === 'ACTIVE'}>{value}</ItemStatus>,
              Filter: SelectColumnFilter,
              filter: 'includes',
              disableSortBy: true,
            },
          ]
        : []),
      {
        id: 'name',
        Header: 'Name',
        accessor: (user) =>
          `${user.firstName || ''} ${user.lastName || ''} ${user.agent?.licenseNumber && !isAgent ? '*' : ''}`,
        filter: 'text',
      },
      ...(isAgent
        ? [
            {
              Header: 'App Date',
              accessor: (user) =>
                `${
                  user.agent?.questionnaire?.updateTime
                    ? moment(user.agent.questionnaire.updateTime).format('MM/DD/YY')
                    : 'NA'
                }`,
              Cell: ({ cell }) =>
                cell.value !== 'NA' ? (
                  <AgentName onClick={() => handleShowQuestionnaire(cell)}>{cell.value}</AgentName>
                ) : (
                  cell.value
                ),
              filter: 'text',
            },
            {
              Header: 'Listing Ready',
              accessor: (user) => `${user.agent?.questionnaire?.listingReady ? 'Yes' : 'No'}`,
              filter: 'text',
            },
            {
              id: 'approveAgent',
              Header: 'Approved',
              accessor: (listing) => ({
                id: listing.id,
                isListingAgent: listing.roles?.some(({ name }) => name === 'LISTING'),
              }),
              disableFilters: true,
              Cell: ({ cell: { value } }) => (
                <Switch
                  checked={value.isListingAgent}
                  onChange={() => handleApproveListingAgent(value.id, value.isListingAgent)}
                  onColor='#86d3ff'
                  onHandleColor='#2693e6'
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  height={18}
                  width={48}
                />
              ),
              disableSortBy: true,
            },
          ]
        : []),
      {
        Header: 'Phone',
        accessor: (user) => (user?.phone ? formatPhoneNumber(user.phone) : ''),
        filter: 'text',
      },
      { Header: 'Email', accessor: 'email', filter: 'text' },
      ...(isAgent
        ? [
            {
              Header: 'State',
              accessor: 'agent.licenseState',
              filter: 'text',
            },
            {
              Header: 'DRE License',
              accessor: 'agent.licenseNumber',
              filter: 'text',
            },
            {
              Header: 'Broker',
              accessor: 'agent.brokerage.name',
              filter: 'text',
            },
          ]
        : []),
      ...(!isAgent
        ? [
            {
              id: 'passwordReset',
              Header: 'Password Reset',
              accessor: 'email',
              disableFilters: true,
              disableSortBy: true,
              Cell: ({ cell }) => (
                <PrimaryGreenButton onClick={() => handleResetUserPassword(cell.value)}>Reset</PrimaryGreenButton>
              ),
            },
            {
              id: 'deleteAccount',
              Header: 'Delete Account',
              accessor: 'id',
              disableFilters: true,
              disableSortBy: true,
              Cell: ({ cell }) => <OutGrayButton onClick={() => handleDeleteUser(cell.value)}>Delete</OutGrayButton>,
            },
            {
              id: 'impostorLogin',
              Header: 'Login As',
              accessor: 'email',
              disableFilters: true,
              disableSortBy: true,
              Cell: ({ cell }) => <OutGrayButton onClick={() => login(cell.value)}>Login</OutGrayButton>,
            },
          ]
        : []),
    ],
    [isAgent, handleApproveListingAgent, handleResetUserPassword, handleDeleteUser, login]
  );

  const users = useSelector(isAgent ? getAgents : getUsers);

  useEffect(() => {
    if (isAgent) {
      dispatch(AdminAsyncActions.ListAgents.Actions.REQUEST());
    } else {
      dispatch(AdminAsyncActions.ListUsers.Actions.REQUEST());
    }
  }, [isAgent, dispatch]);

  return (
    <>
      <Helmet>
        <title>Admin | {isAgent ? 'Agents' : 'Users'}</title>
        <meta name='description' content={`Admin | ${isAgent ? 'Agents' : 'Users'}`} />
      </Helmet>

      <div className='shadow-sm border rounded'>
        <Header>
          <h2 className='fw-bold'>{isAgent ? 'Agents' : 'Users'}</h2>
          <h6>({users.length} Total Results)</h6>
        </Header>
        <AdminTable columns={columns} data={users} />
      </div>

      {isAgent && showQuestionnaire && (
        <QuestionnaireModal
          title='Agent Questionnaire'
          show={showQuestionnaire}
          initialQuestionnaire={questionnaire}
          readOnly
          onClose={() => setShowQuestionnaire(false)}
          saveButtonLabel='Approve Listing Agent'
        />
      )}
    </>
  );
}
