import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useAuthUser } from 'hooks';
import Badge from 'react-bootstrap/Badge';
import ReactTooltip from 'react-tooltip';
import { DashboardAsyncActions } from 'store/actions/dashboard';
import { getTasks } from 'store/selectors/dashboard';
import { Header, OutGrayButton, PrimaryBlueButton } from 'components';
import { MainContainer } from 'containers/App/mainLayout.styled';
import {
  TopContainer,
  WelcomeContainer,
  HelpItem,
  TaskContainer,
  TaskItem,
  BadgeContainer,
  ActionContainer,
} from './homePage.styled';
import { URLUtils } from 'utils';
import routes from 'containers/App/routeConstants';
import QueryString from 'query-string';
import SubscriptionConfirmPage from 'containers/SubscriptionConfirmPage';
import ImportListingModal from 'containers/ListingsPage/importListingModal';

function Home() {
  const history = useHistory();
  const { user, isAgent } = useAuthUser();
  const dispatch = useDispatch();
  const tasks = useSelector(getTasks);
  const location = useLocation();
  const [checkoutId, setCheckoutId] = useState();
  const [showSubscriptionConfirmation, setShowSubscriptionConfirmation] = useState(false);
  const [showListingImport, setShowListingImport] = useState(false);

  const isFloridaAgent = user?.agent?.licenseState === 'FL';

  useEffect(() => {
    if (user) {
      dispatch(DashboardAsyncActions.FetchDashboard.Actions.REQUEST(!user.agent ? user.id : null));
    }
    return () => {
      dispatch(DashboardAsyncActions.FetchDashboard.Actions.RESET());
    };
  }, [dispatch, user]);

  const handleCreate = (route) => {
    history.push(route);
  };

  const typeBadgeMap = {
    SELLING: 'primary',
    BUYING: 'success',
    PROFILE: 'info',
  };

  useEffect(() => {
    const params = QueryString.parse(location.search);
    if (params) {
      setCheckoutId(params.session_id);
      setShowSubscriptionConfirmation(true);
    }
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name='description' content='Home' />
      </Helmet>
      <MainContainer>
        <Header title='Home' showInMobile={false} />
        <TopContainer>
          <WelcomeContainer>
            <img src={URLUtils.getPublicImageUrl('couple_buyers.svg')} alt='welcome' />
            <span>{`Welcome, ${user.firstName} ${user.lastName}!`}</span>
            {tasks?.length === 0 && <p>Congrats, you have no pending tasks!</p>}
          </WelcomeContainer>
          <div className='flex-grow-1'>
            <span>What would you like to do?</span>
            <HelpItem>
              <span>Want to create a listing</span>
              <OutGrayButton onClick={() => handleCreate(routes.LISTING_CREATE_PATH)}>Go to</OutGrayButton>
            </HelpItem>
            {isAgent && isFloridaAgent && (
              <HelpItem>
                <span>Want to import a listing</span>
                <OutGrayButton onClick={() => setShowListingImport(true)}>Go to</OutGrayButton>
              </HelpItem>
            )}
            <HelpItem>
              <span>Want to create an offer</span>
              <OutGrayButton onClick={() => handleCreate(routes.SEARCH_PAGE)}>Go to</OutGrayButton>
            </HelpItem>
          </div>
        </TopContainer>
        {tasks?.length > 0 && (
          <TaskContainer>
            <span>Tasks</span>
            {tasks.map(({ actionText, actionUrl, description, title, toolTipText, type }, index) => (
              <TaskItem key={`${actionUrl}-${index}`}>
                <BadgeContainer>
                  <Badge bg={typeBadgeMap[type]}>{type}</Badge>
                  <span data-tip={toolTipText}>{title}</span>
                </BadgeContainer>
                <ActionContainer>
                  <span>{description}</span>
                  <PrimaryBlueButton onClick={() => history.push(actionUrl)}>{actionText}</PrimaryBlueButton>
                </ActionContainer>
                {toolTipText && <ReactTooltip place='bottom' effect='solid' />}
              </TaskItem>
            ))}
          </TaskContainer>
        )}
        {isAgent && isFloridaAgent && showListingImport && (
          <ImportListingModal onClose={() => setShowListingImport(false)} />
        )}
      </MainContainer>
      {checkoutId && (
        <SubscriptionConfirmPage
          show={showSubscriptionConfirmation}
          checkoutId={checkoutId}
          onClose={() => setShowSubscriptionConfirmation(false)}
        />
      )}
    </>
  );
}

export default Home;
