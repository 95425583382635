import { call, put } from 'redux-saga/effects';
import { AppAsyncActions } from 'store/actions/app';
import { apiCall } from 'utils/apiCall';

function* meta() {
  const {
    FetchMetadata: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AppAsyncActions;

  try {
    const response = yield call(apiCall, '/meta-data', 'get');
    yield put(SUCCESS(response.data));
  } catch (error) {
    yield put(FAILURE(error));
  }
}

export default meta;
