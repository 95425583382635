import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
import 'yup-phone';
import { useAuthUser } from 'hooks';
import { getMetaListingStates } from 'store/selectors/app';
import { PrimaryGreenButton, OutGrayButton, FormikField, Icon, AddSignatureModal } from 'components';
import routeConstants from 'containers/App/routeConstants';
import {
  SignatureContainer,
  InitialsCanvasPanel,
  SignatureCanvasPanel,
  SignatureLabel,
  SignatureImg,
} from '../../components/Signature/signature.styled';

function ConsumerProfilePage({ user, onUpdateUser, readOnly }) {
  const history = useHistory();
  const { user: loginUser } = useAuthUser();
  const allStates = useSelector(getMetaListingStates);
  const [signDirty, setSignDirty] = useState(false);
  const [editingSignatureType, setEditingSignatureType] = useState('');
  const [signatureBase64, setSignatureBase64] = useState(user?.signature);
  const [initialsBase64, setInitialsBase64] = useState(user?.initials);

  const clearSignature = () => {
    setSignatureBase64(null);
    setInitialsBase64(null);
    setSignDirty(false);
  };

  const handleEditSignature = (signatureType) => {
    setEditingSignatureType(signatureType);
  };

  const handleCreateSignature = (base64) => {
    if (editingSignatureType === 'signature') {
      setSignatureBase64(base64);
      setSignDirty(true);
    }
    if (editingSignatureType === 'initials') {
      setInitialsBase64(base64);
      setSignDirty(true);
    }
    setEditingSignatureType('');
  };

  const schema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string().max(25, 'Must be 35 characters or less').required('Required'),
        lastName: Yup.string().max(25, 'Must be 35 characters or less').required('Required'),
        phoneNumber: Yup.string().required('Required').phone('US', false, 'Invalid phone number'),
        email: Yup.string().email('Invalid email address').required('Required'),
        streetAddress: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        zip: Yup.string().required('Required'),
      }),
    []
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const newUser = {
      id: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      address: {
        addressLine1: values.streetAddress,
        addressLine2: values.streetAddressLine2,
        city: values.city,
        state: values.state,
        zip: values.zip,
      },
      signature: signatureBase64,
      initials: initialsBase64,
    };
    onUpdateUser(newUser);
    setSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Consumer profile</title>
        <meta name='description' content='Consumer profile' />
      </Helmet>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          phoneNumber: user?.phone || '',
          email: user?.email || '',
          streetAddress: user?.address?.addressLine1 || '',
          streetAddressLine2: user?.address?.addressLine2 || '',
          city: user?.address?.city || '',
          state: user?.address?.state || '',
          zip: user?.address?.zip || '',
        }}
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div>
              <Row>
                <FormikField md='4' name='firstName' label='First Name' required readOnly={readOnly} />
                <FormikField md='4' name='lastName' label='Last Name' required readOnly={readOnly} />
              </Row>
              <Row className='pt-2'>
                <FormikField md='4' type='phone' name='phoneNumber' label='Mobile' required readOnly={readOnly} />
                <FormikField md='4' name='email' label='Email Address' readOnly />
              </Row>
              <Row className='pt-2'>
                <FormikField md='4' name='streetAddress' label='Street Address' required readOnly={readOnly} />
                <FormikField md='4' name='streetAddressLine2' label='Street Address Cont' readOnly={readOnly} />
              </Row>
              <Row className='pt-2'>
                <FormikField md='4' name='city' label='City' required readOnly={readOnly} />
                <FormikField
                  type='select'
                  md='2'
                  name='state'
                  label='State'
                  selectLabel='Select State'
                  options={allStates}
                  required
                  readOnly={readOnly}
                />
                <FormikField md='2' name='zip' label='Zip' required readOnly={readOnly} />
              </Row>
              {!readOnly && (
                <Row className='pt-2'>
                  <SignatureContainer>
                    <div>
                      <SignatureLabel>
                        Signature <Icon name='pencil-square' onClick={() => handleEditSignature('signature')} />
                      </SignatureLabel>
                      <SignatureCanvasPanel>
                        {signatureBase64 && <SignatureImg src={signatureBase64} />}
                      </SignatureCanvasPanel>
                    </div>
                    <div className='mx-2'>
                      <SignatureLabel>
                        Initials <Icon name='pencil-square' onClick={() => handleEditSignature('initials')} />
                      </SignatureLabel>
                      <InitialsCanvasPanel>
                        {initialsBase64 && <SignatureImg src={initialsBase64} />}
                      </InitialsCanvasPanel>
                    </div>
                  </SignatureContainer>
                  <div className='d-flex'>
                    <OutGrayButton className='mt-3' onClick={() => clearSignature()}>
                      Clear Signature
                    </OutGrayButton>
                  </div>
                </Row>
              )}
              {!readOnly && loginUser.id === user.id && (
                <div className='d-flex justify-content-end my-4'>
                  <PrimaryGreenButton type='submit' disabled={(isSubmitting || !dirty) && !signDirty}>
                    Save changes
                  </PrimaryGreenButton>
                  <OutGrayButton className='ms-3' onClick={() => history.push(routeConstants.HOME_PATH)}>
                    Cancel
                  </OutGrayButton>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {editingSignatureType && (
        <AddSignatureModal show onClose={() => setEditingSignatureType('')} onCreate={handleCreateSignature} />
      )}
    </>
  );
}

ConsumerProfilePage.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};

export default ConsumerProfilePage;
