import React, { useState, useRef } from 'react';
import { useOnClickOutside } from 'hooks';

function DropdownMenu(props) {
  function DropdownItem({ onClickItem, name }) {
    return (
      <div className='menu-item' onClick={() => onClickItem(name)}>
        {name}
      </div>
    );
  }
  return (
    <div className='offer1-dropdown'>
      {props.options.map((tab) => (
        <DropdownItem key={`dd-${tab}`} name={tab} onClickItem={props.onTabChange} />
      ))}
    </div>
  );
}

function PopupMenu({ tabs, handleTabChange, children }) {
  const [showMenu, setShowMenu] = useState(false);
  const target = useRef(null);

  const handleClickMenu = () => {
    setShowMenu(!showMenu);
  };

  const onHandleTabChange = (tab) => {
    setShowMenu(false);
    handleTabChange(tab);
  };

  useOnClickOutside(target, () => setShowMenu(false));

  return (
    <div className='ms-auto' ref={target}>
      <div className='ms-auto' onClick={() => handleClickMenu()}>
        {children}
      </div>
      {showMenu && <DropdownMenu options={tabs} onTabChange={onHandleTabChange} />}
    </div>
  );
}

export default PopupMenu;
