import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useResponsive } from 'hooks';
import { FormField, RadioButtons, CompanyInput } from 'components';
import {
  buyerSellerOptionsWithPayOwn as companyOptions,
  buyerSellerOptionsWithBoth as titlePayerOptions,
  buyerSellerOptions,
} from 'constants/optionConstants';
import OfferServicesHomeWarranty from './offerServicesHomeWarranty';

function OfferServicesCA({ services, errors, isCounter, onStateChanged }) {
  const isMobile = useResponsive();

  const onRadioSelected = (event) => {
    const data = {
      value: event.target.value,
      field: event.target.name,
    };
    onStateChanged(data);
  };

  return (
    <>
      <OfferServicesHomeWarranty services={services} showBothParty errors={errors} onStateChanged={onStateChanged} />
      <Row className='mb-3'>
        <Col>
          <p>2) Confirmation of Services</p>
          <Row className='justify-content-around'>
            <Col
              as='label'
              lg={isCounter ? 12 : 5}
              className='border p-3 my-1 d-flex flex-column justify-content-between'
            >
              <p className='text-justify'>
                The buyer(s) agree to use the seller preferred and Offer1 approved service providers.
              </p>
              <input
                className='mx-auto'
                type='radio'
                name='confirmationServices'
                value='SELLER'
                checked={services.confirmationServices === 'SELLER'}
                onChange={onRadioSelected}
              />
            </Col>
            <Col
              as='label'
              lg={isCounter ? 12 : 5}
              className='border p-3 my-1 d-flex flex-column justify-content-between'
            >
              <p className='text-justify'>
                The buyer(s) would like to select unapproved service providers and agrees to pay the seller's title and
                escrow cost.
              </p>
              <input
                className='mx-auto'
                type='radio'
                name='confirmationServices'
                value='BUYER'
                checked={services.confirmationServices === 'BUYER'}
                onChange={onRadioSelected}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='ps-4 mb-3'>
        <p>2a) Owners Title Policy</p>
        {services.confirmationServices === 'SELLER' && (
          <Col sm={6}>
            <FormField
              fieldId='titleCompany'
              label='Title Company:'
              placeholder='No Title Company specified'
              initialValue={services?.sellerTitleCompany?.name}
              editable={false}
            />
          </Col>
        )}
        {services.confirmationServices === 'BUYER' && (
          <CompanyInput
            id='titleCompany'
            type='TITLE'
            value={services.titleCompany}
            onStateChanged={onStateChanged}
            errors={errors?.titleCompany}
          />
        )}
        <div className='ms-3' id='titlePayer'>
          <label htmlFor='titlePayer'>Which party will cover the cost:*</label>
          {services.confirmationServices === 'BUYER' && (
            <Alert variant='danger' className='d-inline-flex ms-sm-1 mb-0' style={{ fontSize: 12 }}>
              Seller or shared cost options aren’t available when the buyer selects unapproved services.
            </Alert>
          )}
          <RadioButtons
            items={services.confirmationServices !== 'BUYER' ? titlePayerOptions : titlePayerOptions.slice(0, 1)}
            groupName='titlePayer'
            selectedOption={services.titlePayer}
            onStateChanged={onRadioSelected}
            vertical={isMobile}
            error={errors?.titlePayer}
          />
        </div>
      </Row>
      <Row className='ps-4 mb-3'>
        <p>2b) Escrow Company</p>
        {services.confirmationServices === 'SELLER' && (
          <Col sm={6}>
            <FormField
              fieldId='escrowCompany'
              label='Escrow Company:'
              placeholder='No Escrow Company specified'
              initialValue={services?.sellerEscrowCompany?.name}
              editable={false}
            />
          </Col>
        )}
        {services.confirmationServices === 'BUYER' && (
          <CompanyInput
            id='escrowCompany'
            type='ESCROW'
            value={services.escrowCompany}
            onStateChanged={onStateChanged}
            errors={errors?.escrowCompany}
          />
        )}
        <div className='ms-3' id='escrowPayer'>
          <label htmlFor='escrowPayer'>Which party will cover the cost:*</label>
          {services.confirmationServices === 'BUYER' && (
            <Alert variant='danger' className='d-inline-flex ms-sm-1 mb-0' style={{ fontSize: 12 }}>
              Seller or shared cost options aren’t available when the buyer selects unapproved services.
            </Alert>
          )}
          <RadioButtons
            items={services.confirmationServices !== 'BUYER' ? companyOptions : companyOptions.slice(0, 1)}
            groupName='escrowPayer'
            selectedOption={services.escrowPayer}
            onStateChanged={onRadioSelected}
            vertical={isMobile}
            error={errors?.escrowPayer}
          />
        </div>
      </Row>
      <Row className='ps-4 mb-3'>
        <p>2c) Natural Hazard Disclosure Company</p>
        {services.confirmationServices === 'SELLER' && (
          <Col sm={6}>
            <FormField
              fieldId='hazardCompany'
              label='NHD Company:'
              placeholder='No NHD Company specified'
              initialValue={services?.sellerHazardCompany?.name}
              editable={false}
            />
          </Col>
        )}
        {services.confirmationServices === 'BUYER' && (
          <CompanyInput
            id='hazardCompany'
            type='HAZARD'
            value={services.hazardCompany}
            onStateChanged={onStateChanged}
            repOnly
            errors={errors?.hazardCompany}
          />
        )}
        <div className='ms-3' id='hazardPayer'>
          <label htmlFor='hazardPayer'>Which party will cover the cost:*</label>
          {services.confirmationServices === 'BUYER' && (
            <Alert variant='danger' className='d-inline-flex ms-sm-1 mb-0' style={{ fontSize: 12 }}>
              Seller or shared cost options aren’t available when the buyer selects unapproved services.
            </Alert>
          )}
          <RadioButtons
            items={services.confirmationServices !== 'BUYER' ? buyerSellerOptions : buyerSellerOptions.slice(0, 1)}
            groupName='hazardPayer'
            selectedOption={services.hazardPayer}
            onStateChanged={onRadioSelected}
            vertical={isMobile}
            error={errors?.hazardPayer}
          />
        </div>
      </Row>
      <FormField
        type='textarea'
        fieldId='servicesComments'
        label='Additional Comments on Services: (Comments are visible to all parties and will not be a part of any agreement)'
        placeholder='(Optional)'
        maxLength={500}
        initialValue={services.servicesComments || ''}
        onStateChanged={onStateChanged}
        error={errors?.servicesComments}
        editable={!isCounter}
      />
    </>
  );
}

OfferServicesCA.propTypes = {
  services: PropTypes.object,
  onStateChanged: PropTypes.func,
};

export default OfferServicesCA;
