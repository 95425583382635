import React from 'react';
import Helmet from 'react-helmet';
import InformationCard from './informationCard';
import RegistrationCard from './registrationCard';
import { Container } from './sellerRegistrationPage.styled';

function SellerRegistrationPage() {
  return (
    <>
      <Helmet>
        <title>Seller registration</title>
        <meta name='description' content='Seller registration' />
      </Helmet>
      <Container>
        <InformationCard />
        <RegistrationCard />
      </Container>
    </>
  );
}

export default SellerRegistrationPage;
