import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useAuthUser } from 'hooks';
import { FormField, EmailField } from 'components';
import { CloseButton, MessageBox, InviteTitle } from './inviteAgentModal.styled';

function InviteAgentModal({ agent, onSend, onClose, onChanged }) {
  const { user } = useAuthUser();

  return (
    <Modal.Dialog className='modal-90h' size='md'>
      <Modal.Header className='align-items-center px-2 py-1' onHide={onClose} style={{ color: 'black' }}>
        <div className='d-flex'>
          <div>
            <InviteTitle>Invite Agent</InviteTitle>
          </div>
        </div>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <FormField
            fieldId='firstName'
            label='First Name'
            placeholder='First Name'
            initialValue={agent.firstName}
            onStateChanged={onChanged}
            required
          />
          <FormField
            fieldId='lastName'
            label='Last Name'
            placeholder='Last Name'
            initialValue={agent.lastName}
            onStateChanged={onChanged}
            required
          />
        </Row>
        <Row className='d-flex align-items-end'>
          <EmailField
            fieldId='email'
            label='Email Address'
            placeholder='Email'
            initialValue={agent.email || ''}
            onStateChanged={onChanged}
            required
          />
        </Row>
        <MessageBox>
          <p className='px-4 pt-2'>Hi, {user.firstName} would like to invite you to Offer1.</p>
          <p className='text-justify px-4 pb-2'>
            Hello I used a new Offer technology platform to make an offer on my next home. Please click here to contact
            Offer1 and apply to be on their platform.
          </p>
        </MessageBox>
        <button className='btn btn-primary float-right' onClick={onSend}>
          SEND INVITATION
        </button>
      </Modal.Body>
    </Modal.Dialog>
  );
}

export default InviteAgentModal;
