import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import filesize from 'filesize';
import Swal from 'sweetalert2';
import { uploadFile } from 'services/client';
import { ProgressBar, FileMetaContainer, MiddleDot, FileSize } from './contractUpload.styled';
import { sanitizeFileName } from 'utils';

function ContractUpload({ file, meta, type, onFinishUpload }) {
  const [progress, setProgress] = useState(0);
  const dateRef = useRef(new Date());

  const onUploadProgress = useCallback(
    (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
    [setProgress]
  );

  const startUpload = useCallback(async () => {
    try {
      const formData = new FormData();
      const sanitizedFileName = sanitizeFileName(file.name);
      formData.append('file', file, sanitizedFileName);
      const uploadedFile = await uploadFile(formData, type ?? 'OTHER', onUploadProgress);
      onFinishUpload(uploadedFile);
    } catch (error) {
      Swal.fire({
        text: 'Something went wrong while uploading your document',
        icon: 'error',
      });
    }
  }, [file, type, onFinishUpload, onUploadProgress]);

  useEffect(() => {
    startUpload();
  }, [startUpload]);

  return (
    <div>
      <FileMetaContainer>
        <span>{meta.name}</span>&nbsp;<MiddleDot>·</MiddleDot>&nbsp;
        <span>{moment(dateRef.current).format('MM/DD/YYYY, hh:mm A')}</span>
        &nbsp;<FileSize>{filesize(meta.size)}</FileSize>
      </FileMetaContainer>
      <ProgressBar now={progress} />
    </div>
  );
}

export default ContractUpload;
