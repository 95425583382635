import { all, takeLatest } from 'redux-saga/effects';
import { CreateListingAsyncActions } from 'store/actions/createListing';
import create from './create';
import update from './update';
import deleteListing from './delete';
import updateState from './updateState';
import sendListingToSeller from './sendListingToSeller';
import createProvisional from './createProvisional';
import editListingUrl from './editListingUrl';

export default function* root() {
  const {
    CreateListing,
    UpdateListing,
    DeleteListing,
    UpdateState,
    SendListingToSeller,
    CreateProvisionalListing,
    EditListingURL,
  } = CreateListingAsyncActions;
  yield all([
    takeLatest(CreateListing.Types.REQUEST, create),
    takeLatest(UpdateListing.Types.REQUEST, update),
    takeLatest(DeleteListing.Types.REQUEST, deleteListing),
    takeLatest(UpdateState.Types.REQUEST, updateState),
    takeLatest(SendListingToSeller.Types.REQUEST, sendListingToSeller),
    takeLatest(CreateProvisionalListing.Types.REQUEST, createProvisional),
    takeLatest(EditListingURL.Types.REQUEST, editListingUrl),
  ]);
}
