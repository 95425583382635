import { createAsyncAction } from 'utils/store';

export const FetchListingDetails = createAsyncAction('listings', 'fetchListingDetails', {
  REQUEST: ['listingId'],
  SUCCESS: ['listingDetails'],
});

export const FetchListing = createAsyncAction('listings', 'fetchListing', {
  REQUEST: ['id'],
  SUCCESS: ['listing'],
});

export const ListListings = createAsyncAction('listings', 'listListings', {
  REQUEST: [],
  SUCCESS: ['listings'],
});

export const SearchListings = createAsyncAction('listings', 'searchListings', {
  REQUEST: ['address', 'city', 'state'],
  SUCCESS: ['searchListings'],
});

export const SearchListingsByAgent = createAsyncAction('listings', 'searchListingsByAgent', {
  REQUEST: ['agentId', 'state'],
  SUCCESS: ['searchListings'],
});

export const FetchListingsLiked = createAsyncAction('listings', 'fetchListingsLiked', {
  REQUEST: ['listingId'],
  SUCCESS: ['listingsLiked'],
});

export const UpdateListingsLiked = createAsyncAction('listings', 'updateListingsLiked', {
  REQUEST: ['listingId', 'liked'],
  SUCCESS: ['listingsLiked'],
});

export const FetchStateIncludedItems = createAsyncAction('listings', 'fetchStateIncludedItems', {
  REQUEST: ['state', 'city'],
  SUCCESS: ['stateIncludedItems'],
});

export const FetchListingLoanTypes = createAsyncAction('listings', 'fetchListingLoanTypes', {
  REQUEST: ['listingId'],
  SUCCESS: ['loanTypes'],
});

export const ListingsAsyncActions = {
  FetchListingDetails,
  FetchListing,
  ListListings,
  SearchListings,
  FetchListingsLiked,
  UpdateListingsLiked,
  FetchStateIncludedItems,
  FetchListingLoanTypes,
  SearchListingsByAgent,
};
