import styled from 'styled-components';
import { ModalBox as BaseModalBox, Offer1GreenButton } from './listingDetailPage.styled';

export const ModalBox = styled(BaseModalBox)`
  width: 516px;
`;

export const ModalContent = styled.div`
  background-image: url('/login-bg.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalHeader = styled.div`
  padding: 16px 18px 0;
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const WelcomeText = styled.div`
  margin-top: 10px;
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: var(--offer1-green);
`;

export const LoginText = styled.div`
  margin-top: 5px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--gray2);
`;

export const HouseImage = styled.div`
  width: 100px;
  height: 108px;
  background-image: url('/house.svg');
  background-size: cover;
`;

export const GetStartedButton = styled(Offer1GreenButton)`
  margin: 27px 0 60px;
`;
