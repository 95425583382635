import Popover from 'react-bootstrap/Popover';
import styled from 'styled-components';

export const PopoverBody = styled(Popover.Body)`
  max-height: 300px;
  overflow-y: auto;
`;

export const RpaItem = styled.div`
  word-break: break-word;
`;
