import { call, put } from 'redux-saga/effects';
import { AdminAsyncActions } from 'store/actions/admin';
import { apiCall } from 'utils/apiCall';
import { toast } from 'react-toastify';

function* resetPassword(action) {
  const {
    ResetPassword: {
      Actions: { SUCCESS, FAILURE },
    },
  } = AdminAsyncActions;

  const {
    payload: { email },
  } = action;

  try {
    yield call(apiCall, `/users/forgot-password?email=${email}`, 'post');
    yield put(SUCCESS());
    toast.success('Successfully reset the password');
  } catch (error) {
    toast.error('Error occurred while resetting the password');
    yield put(FAILURE(error));
  }
}

export default resetPassword;
