import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

export const LoginContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  border: 3px solid var(--light-blue);
  background-image: url('/pre-auth-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  margin: 10px;
`;

export const SectionTitle = styled.p`
  color: var(--offer1-green);
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;

  ${breakpoint('xs', 'md')`
    font-size: 36px;
  `}
`;

export const SectionText = styled.p`
  font-size: 14px;
  color: var(--gray1);
  text-align: center;
`;

export const HouseImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  width: 100%;
`;

export const AccountAltText = styled.p`
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
`;
