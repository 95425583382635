import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CountDown, PopupMenu, Icon } from 'components';
import { ListingInfoContainer, AddressInfo, ListInfo, ListInfoLabel, ListInfoValue } from './listingInfo.styled';
import moment from 'moment';
import { isNil } from 'lodash';
import { formatCurrency } from 'utils';

function ListingInfo({
  address,
  price,
  highestOffer,
  offerCount,
  globalCounterState,
  showReviewDay,
  auctionExpirationDate,
  isMobile,
  menuTabs,
  onClickMenu,
}) {
  const onhandleClickMenu = (tab) => {
    onClickMenu(tab);
  };

  const isExpired = useMemo(() => {
    if (!auctionExpirationDate) {
      return true;
    }
    const endDate = new Date(`${auctionExpirationDate} 06:00 PM`);
    const now = new Date();

    return now > endDate;
  }, [auctionExpirationDate]);

  const renderInfo = () => (
    <>
      {!isNil(price) && (
        <ListInfo>
          <ListInfoLabel>[List Price]</ListInfoLabel>
          <ListInfoValue>{formatCurrency(price, 0)}</ListInfoValue>
        </ListInfo>
      )}
      {!isNil(offerCount) && (
        <ListInfo>
          <ListInfoLabel>[Offers]</ListInfoLabel>
          <ListInfoValue>{offerCount}</ListInfoValue>
        </ListInfo>
      )}
      {!isNil(highestOffer) && (
        <ListInfo>
          <ListInfoLabel>[Highest Offer]</ListInfoLabel>
          <ListInfoValue>
            {globalCounterState === 'PENDING_SELLER_ACCEPT_REVIEW' || (offerCount >= 3 && highestOffer)
              ? formatCurrency(highestOffer, 0)
              : '$$$'}
          </ListInfoValue>
        </ListInfo>
      )}
      {showReviewDay && (
        <>
          <ListInfo>
            <ListInfoLabel>[Offer Review Countdown]</ListInfoLabel>
            <ListInfoValue>
              {!auctionExpirationDate ? (
                'Not yet set'
              ) : isExpired ? (
                'Countdown complete, seller to select offer'
              ) : (
                <CountDown endDate={auctionExpirationDate} showLabel={false} fontSize={24} color='var(--offer1-blue)' />
              )}
            </ListInfoValue>
          </ListInfo>
          <ListInfo>
            <ListInfoLabel>[Offer Review End Date]</ListInfoLabel>
            <ListInfoValue>
              {!auctionExpirationDate
                ? 'Not yet set'
                : isExpired
                ? 'Countdown complete, seller to select offer'
                : moment(auctionExpirationDate).format('MM/DD/YYYY') + ' @ 6pm local time'}
            </ListInfoValue>
          </ListInfo>
        </>
      )}
    </>
  );

  return (
    <>
      {!isMobile && (
        <ListingInfoContainer>
          {address && (
            <AddressInfo>
              <div>{address.addressLine1}</div>
              {address.addressLine2 && <div>{address.addressLine2}</div>}
              <div>{`${address.city}, ${address.state} ${address.zip}`}</div>
            </AddressInfo>
          )}
          {renderInfo()}
        </ListingInfoContainer>
      )}
      {isMobile && (
        <>
          <ListingInfoContainer>
            {address && (
              <AddressInfo>
                <div>{address.addressLine1}</div>
                {address.addressLine2 && <div>{address.addressLine2}</div>}
              </AddressInfo>
            )}
            <PopupMenu tabs={menuTabs} handleTabChange={onhandleClickMenu}>
              <Icon name='three-dots' />
            </PopupMenu>
          </ListingInfoContainer>
          <ListingInfoContainer>{renderInfo()}</ListingInfoContainer>
        </>
      )}
    </>
  );
}

ListingInfo.propTypes = {
  address: PropTypes.object,
  price: PropTypes.number,
  highestOffer: PropTypes.number,
  offerCount: PropTypes.number,
  globalCounterState: PropTypes.string,
  showReviewDay: PropTypes.bool,
  auctionExpirationDate: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default ListingInfo;
