import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { insertSpaces } from 'utils/formattingUtils';
function FormField({
  type = 'text',
  label,
  fieldId,
  placeholder,
  initialValue = '',
  required = false,
  editable = true,
  children,
  validator = (f) => f,
  onStateChanged = (f) => f,
  additionalControls,
  onKeyPress,
  min,
  max,
  maxLength,
  rows,
  autoComplete,
  onBlur,
  error = null,
}) {
  // initialize state
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState([]);

  const hasChanged = (e) => {
    e.preventDefault();

    // destructure props - assign default dummy functions to validator and onStateChanged props

    const value = e.target.value;
    const field = e.target.id;
    const isEmpty = value.length === 0;
    const requiredMissing = dirty && required && isEmpty;
    let errors = [];

    if (requiredMissing) {
      // if required and is empty, add required error to state
      errors = [...errors, (label || insertSpaces(fieldId)) + ` is required`];
    } else if ('function' === typeof validator) {
      try {
        validator(value);
      } catch (e) {
        // if validator throws error, add validation error to state
        errors = [...errors, e.message];
      }
    }

    // update state and call the onStateChanged callback fn after the update
    // dirty is only changed to true and remains true on and after the first state update
    setDirty(!dirty || dirty);
    setErrors(errors);

    onStateChanged({
      value,
      errors,
      dirty: !dirty || dirty,
      field,
    });
  };

  useEffect(() => {
    if (error) {
      setDirty(true);
      setErrors([error]);
    }
  }, [required, error]);

  const hasErrors = errors.length > 0 || error;
  const controlClass = classNames('form-control', additionalControls, {
    'is-invalid': dirty && hasErrors,
  });

  return (
    <Form.Group className='my-2'>
      {label && label !== '' && (
        <Form.Label htmlFor={fieldId}>{label === ' ' ? <span>&nbsp;</span> : label + (required ? '*' : '')}</Form.Label>
      )}
      {/** Render the children nodes passed to component **/}
      {children}
      {type !== 'textarea' && (
        <input
          type={type}
          className={controlClass}
          id={fieldId}
          placeholder={placeholder}
          onChange={hasChanged}
          value={initialValue}
          readOnly={!editable}
          min={min}
          max={max}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          {...(autoComplete && { autoComplete })}
        />
      )}
      {type === 'textarea' && (
        <textarea
          type={type}
          className={controlClass}
          id={fieldId}
          placeholder={placeholder}
          onChange={hasChanged}
          onKeyPress={onKeyPress}
          value={initialValue}
          readOnly={editable === undefined ? false : !editable}
          onBlur={onBlur}
          maxLength={maxLength}
          rows={rows}
        />
      )}
      {maxLength && (
        <Form.Text className='d-block error form-hint fw-bold text-end m-0 mb-2'>
          {maxLength - (initialValue || '').length} characters remaining
        </Form.Text>
      )}
      {/** Render the first error if there are any errors **/}
      {hasErrors && <Form.Text className='error form-hint fw-bold text-end m-0 mb-2'>{errors[0]}</Form.Text>}
    </Form.Group>
  );
}

FormField.propTypes = {
  type: PropTypes.oneOf(['text', 'password', 'textarea', 'number']),
  label: PropTypes.string,
  fieldId: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  editable: PropTypes.bool,
  children: PropTypes.node,
  validator: PropTypes.func,
  onStateChanged: PropTypes.func,
  displayName: PropTypes.string,
  additionalControls: PropTypes.string,
  autoComplete: PropTypes.string,
  onKeyPress: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default FormField;
