import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
  border: 3px solid var(--light-blue);
  background-image: url('/pre-auth-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
`;

export const SectionTitle = styled.div`
  color: var(--offer1-green);
  font-size: 48px;
  font-weight: bold;
  text-align: center;
`;

export const SectionText = styled.div`
  font-size: 14px;
  color: var(--gray1);
  text-align: center;
`;

export const HouseImg = styled.img`
  padding-right: 40px;
  padding-bottom: 20px;
  width: 100%;
`;

export const TosText = styled.p`
  font-size: 12px;
  color: var(--gray1);
  text-align: center;
  font-style: italic;
`;
