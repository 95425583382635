import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useAuthUser } from 'hooks';
import ReviewOfferModal from './reviewOfferModal';
import routes from 'containers/App/routeConstants';
import NotFoundPage from 'containers/NotFoundPage';
import { ListingsAsyncActions } from 'store/actions/listings';
import { OffersAsyncActions } from 'store/actions/offers';
import { getOffers } from 'store/selectors/offers';
import { getListing } from 'store/selectors/listings';
import { MainContainer } from 'containers/App/mainLayout.styled';

function ReviewOfferPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const listingOffers = useSelector(getOffers);
  const listing = useSelector(getListing);
  const { isSuccess: isFetchSuccess, error } = useSelector(ListingsAsyncActions.FetchListing.StatusSelector());
  const { isSuccess: isListSuccess } = useSelector(OffersAsyncActions.ListOffers.StatusSelector());
  const { user } = useAuthUser();

  useEffect(() => {
    if (params.actions !== 'seller' && params.actions !== 'buyer') {
      history.push(routes.HOME_PATH);
    }
    dispatch(ListingsAsyncActions.FetchListing.Actions.REQUEST(params.id));
    dispatch(OffersAsyncActions.ListOffers.Actions.REQUEST(params.id, params.actions));

    return () => {
      dispatch(ListingsAsyncActions.FetchListing.Actions.RESET());
      dispatch(OffersAsyncActions.ListOffers.Actions.RESET());
    };
  }, [dispatch, history, params.id, params.actions]);

  useEffect(() => {
    if (listing && !user.id) {
      history.push(`${routes.LISTING_PATH}/${listing.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing]);

  const handleGoBack = useCallback(() => {
    if (params.actions === 'seller') {
      history.push(routes.LISTINGS_PATH_ALL);
    } else if (params.actions === 'buyer') {
      history.push(routes.OFFERS_PATH);
    } else {
      history.goBack();
    }
  }, [history, params.actions]);

  if (error?.httpStatus === 404) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>Review offers</title>
        <meta name='description' content='Review offers' />
      </Helmet>
      <MainContainer>
        {isFetchSuccess && isListSuccess && (
          <ReviewOfferModal
            listing={listing}
            initialOffers={listingOffers}
            onClose={handleGoBack}
            actions={params.actions}
          />
        )}
      </MainContainer>
    </>
  );
}

export default ReviewOfferPage;
