import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import 'yup-phone';
import { HelperText } from './phoneField.styled';

function PhoneField({
  fieldId,
  label,
  placeholder,
  value,
  editable = true,
  onChange,
  touched,
  error,
  helperText,
  validationMessage,
}) {
  const [fieldTouched, setFieldTouched] = useState(touched || false);
  const [errors, setErrors] = useState();

  const phoneSchema = useMemo(
    () =>
      Yup.string()
        .required(validationMessage || 'Phone Number is required')
        .phone('US', false, 'Invalid phone number'),
    [validationMessage]
  );

  return (
    <React.Fragment>
      <Form.Group className='my-2'>
        {label && label !== '' && (
          <Form.Label htmlFor={fieldId}>{label === ' ' ? <span>&nbsp;</span> : label + '*'}</Form.Label>
        )}

        <MaskedInput
          className={classNames('form-control', {
            'is-invalid': (fieldTouched && errors) || error,
          })}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholder={placeholder}
          value={value === null ? '' : value}
          readOnly={!editable}
          onChange={(e) => {
            const value = e.target.value;
            setFieldTouched(true);
            let newError;
            try {
              phoneSchema.validateSync(value);
              newError = false;
            } catch (error) {
              newError = error.message;
            }
            setErrors(newError);
            onChange({
              field: fieldId,
              value: value,
              errors: newError,
            });
          }}
        />
        {helperText && <HelperText>{helperText}</HelperText>}
        {fieldTouched && errors && (
          <Form.Text className='error form-hint fw-bold text-end m-0 mb-2'>{errors}</Form.Text>
        )}
        {!fieldTouched && error && <Form.Text className='error form-hint fw-bold text-end m-0 mb-2'>{error}</Form.Text>}
      </Form.Group>
    </React.Fragment>
  );
}

PhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  editable: PropTypes.bool,
};

export default PhoneField;
