import React from 'react';
import PropTypes from 'prop-types';
import { Property } from './propertyItem.styled';

function PropertyItem({ name, onClickRemove, included = false, onClick }) {
  return (
    <Property included={included}>
      <span onClick={onClick ? onClick : () => {}}>{name}</span>
      {onClickRemove && (
        <div className='ms-3' onClick={onClickRemove} style={{ cursor: 'pointer' }}>
          ✕
        </div>
      )}
    </Property>
  );
}

PropertyItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func,
  included: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PropertyItem;
