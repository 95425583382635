import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import {
  TimelineContainer,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from './timeline.styled';

function Timeline({ steps, step = -1 }) {
  return (
    <TimelineContainer>
      {steps.map((s, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot active={index === step}>{index + 1}</TimelineDot>
            {(index === step - 1 || index === step) && index !== steps.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent active={index === step}>
            {s}
            {index < step && <Icon name='heavy-checkmark' />}
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineContainer>
  );
}

Timeline.propTypes = {
  steps: PropTypes.array.isRequired,
  step: PropTypes.number,
};

export default Timeline;
