import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import FormField from './formField';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { PasswordHintsContainer, PasswordHintError, PasswordHintSuccess } from './passwordField.styled';

class PasswordField extends Component {
  constructor(props) {
    super(props);
    const { minStrength = 1, thresholdLength = 8 } = props;

    // set default minStrength to 2 if not a number or not specified
    // minStrength must be a a number between 0 - 4

    this.minStrength = typeof minStrength === 'number' ? Math.max(Math.min(minStrength, 4), 0) : 1;

    // set default thresholdLength to 7 if not a number or not specified
    // thresholdLength must be a minimum value of 7

    this.thresholdLength = typeof thresholdLength === 'number' ? Math.max(thresholdLength, 8) : 8;

    // initialize internal component state
    this.state = {
      password: '',
      strength: 0,
      verifyPassword: '',
      hasUpperLowerCase: false,
      hasNumner: false,
    };
  }

  stateChanged = (state) => {
    // update the internal state using the updated state from the form field
    this.setState(
      {
        password: state.value,
        strength: zxcvbn(state.value).score,
      },
      () => this.props.onStateChanged(state)
    );
  };

  verifyPasswordChanged = (state) => {
    this.setState(
      {
        verifyPassword: state.value,
      },
      () => this.props.onVerifyStateChanged(state)
    );
  };

  validatePasswordStrong = (value) => {
    // ensure password is long enough
    if (value.length < this.thresholdLength) throw new Error('Password is short');

    if (!this.hasUpperLowerCase(value)) throw new Error('Password is missing upper or lower case letter');

    if (!this.hasNumber(value)) throw new Error('Password is missing upper or lower case letter');
  };

  validatePasswordMatch = (value) => {
    // ensure passwords match
    if (value !== this.state.password) throw new Error('Password does match');
  };

  hasUpperLowerCase(str) {
    return /[a-z]/.test(str) && /[A-Z]/.test(str);
  }

  hasNumber(str) {
    return /[0-9]/.test(str);
  }

  render() {
    const { type, validator, onStateChanged, children, touched, ...restProps } = this.props;
    const { password, verifyPassword, strength } = this.state;

    return (
      <Fragment>
        <div className='position-relative'>
          {/** Pass the validation and stateChanged functions as props to the form field **/}
          <FormField
            type='password'
            validator={this.validatePasswordStrong}
            initialValue={password}
            onStateChanged={this.stateChanged}
            error={touched && !password && 'Password is required'}
            {...restProps}>
            {children}
            {/** Render the password strength meter **/}
            {password.length > 0 && (
              <div className='strength-meter mt-2'>
                <div className='strength-meter-fill' data-strength={strength}></div>
              </div>
            )}
          </FormField>
          {password.length > 0 && (
            <>
              <PasswordHintsContainer>
                {password.length >= 8 && (
                  <PasswordHintSuccess>
                    <AiOutlineCheck /> Use 8 or more characters
                  </PasswordHintSuccess>
                )}
                {password.length < 8 && (
                  <PasswordHintError>
                    <AiOutlineClose /> Use 8 or more characters
                  </PasswordHintError>
                )}
                {!this.hasUpperLowerCase(password) && (
                  <PasswordHintError>
                    <AiOutlineClose /> Use upper and lower case letters (e.g. Ab)
                  </PasswordHintError>
                )}
                {this.hasUpperLowerCase(password) && (
                  <PasswordHintSuccess>
                    <AiOutlineCheck /> Use upper and lower case letters (e.g. Ab)
                  </PasswordHintSuccess>
                )}
                {!this.hasNumber(password) && (
                  <PasswordHintError>
                    <AiOutlineClose /> Use a number (e.g. 123)
                  </PasswordHintError>
                )}
                {this.hasNumber(password) && (
                  <PasswordHintSuccess>
                    <AiOutlineCheck /> Use a number (e.g. 123)
                  </PasswordHintSuccess>
                )}
              </PasswordHintsContainer>
            </>
          )}
          <FormField
            type='password'
            validator={this.validatePasswordMatch}
            onStateChanged={this.verifyPasswordChanged}
            fieldId='verifyPassword'
            label='Confirm password'
            initialValue={verifyPassword}
            required
            error={touched && !verifyPassword && 'Confirm password is required'}
          />
        </div>
      </Fragment>
    );
  }
}

PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  onStateChanged: PropTypes.func,
  minStrength: PropTypes.number,
  thresholdLength: PropTypes.number,
  autoComplete: PropTypes.string,
};

export default PasswordField;
