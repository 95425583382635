import React from 'react';
import PropTypes from 'prop-types';
import IconMap from './iconMap';
import { IconButton } from './Icon.styled';

function Icon({ className, style, name, size, width, height, color, onClick }) {
  const IconComponent = IconMap[name];
  let iconStyle = { ...style };
  if (color) {
    iconStyle.color = color;
  }
  const iconProps = {
    ...(className ? { className } : {}),
    ...(size ? { width: size, height: size } : {}),
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  };
  return IconComponent ? (
    onClick ? (
      <IconButton onClick={onClick}>
        <IconComponent {...iconProps} style={iconStyle} />
      </IconButton>
    ) : (
      <IconComponent {...iconProps} style={iconStyle} />
    )
  ) : (
    'Invalid icon'
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
