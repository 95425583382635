import { isNil } from 'lodash';

const isParamsNil = (...args) => {
  if (args.length === 0) {
    return true;
  }
  for (let i = 0; i < args.length; i++) {
    if (isNil(args[i])) {
      return true;
    }
  }
  return false;
};

export default isParamsNil;
