import { call, put } from 'redux-saga/effects';
import { OffersAsyncActions } from 'store/actions/offers';
import { apiCall } from 'utils/apiCall';
import Swal from 'sweetalert2';

function* updateGlobal(action) {
  const {
    UpdateGlobalCounterOffer: {
      Actions: { SUCCESS, FAILURE },
    },
  } = OffersAsyncActions;
  const {
    payload: { globalCounter, listingId },
  } = action;

  try {
    yield call(apiCall, `/listings/global-counter/${listingId}`, 'put', globalCounter);
    yield put(SUCCESS());
  } catch (error) {
    Swal.fire({
      title: 'An error occurred during your submission',
      icon: 'error',
    });
    yield put(FAILURE(error));
  }
}

export default updateGlobal;
