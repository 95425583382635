import React from 'react';
import PropTypes from 'prop-types';

function CellWithLineBreak({ value }) {
  return value.split('\n').map((item, index, parts) => (
    <React.Fragment key={index}>
      {item}
      {index !== parts.length - 1 && <br />}
    </React.Fragment>
  ));
}

CellWithLineBreak.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CellWithLineBreak;
